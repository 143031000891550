/**
 * Code adapted from the following sources:
 * https://github.com/nuxt-modules/i18n/blob/v7.3.1/src/templates/utils-common.js#L41
 */

import localeCodes from "@/i18n/locale-codes";

/**
 * @typedef {() => string | undefined} GetBrowserLocaleCode
 */

/** @type {GetBrowserLocaleCode} */
const getBrowserLocaleCode = () => {
  /** @type {{ code: string, score: number }[]} */
  const matchedLocaleCodes = []

  // First pass: match exact locale code.
  for (const [index, browserLocaleCode] of navigator.languages.entries()) {
    const matchedLocaleCode = localeCodes.find(
      (appLocaleCode) => appLocaleCode.toLowerCase() === browserLocaleCode.toLowerCase()
    )
    if (matchedLocaleCode) {
      matchedLocaleCodes.push({
        code: matchedLocaleCode,
        score: 1 - index / navigator.languages.length,
      })
      break
    }
  }

  // Second pass: match only language code part of the browser locale code (not including country).
  for (const [index, browserLocaleCode] of navigator.languages.entries()) {
    const browserLanguageCode = browserLocaleCode.split("-")[0].toLowerCase()
    const matchedLocaleCode = localeCodes.find(
      (localeCode) => {
        const appLanguageCode = localeCode.split("-")[0].toLowerCase()
        return appLanguageCode === browserLanguageCode
      }
    )
    if (matchedLocaleCode) {
      matchedLocaleCodes.push({
        code: matchedLocaleCode,
        score: 0.999 - index / navigator.languages.length,
      })
      break
    }
  }

  // Sort the list by score (0 - lowest, 1 - highest).
  if (matchedLocaleCodes.length > 1) {
    matchedLocaleCodes.sort((localeCodeA, localeCodeB) => {
      if (localeCodeA.score === localeCodeB.score) {
        // If scores are equal then pick more specific (longer) code.
        return localeCodeB.code.length - localeCodeA.code.length
      }

      return localeCodeB.score - localeCodeA.score
    })
  }

  return matchedLocaleCodes.length ? matchedLocaleCodes[0].code : undefined
}

export default getBrowserLocaleCode
