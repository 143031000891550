import LoginService from "@/services/service-providers/webapp-services/login-service.js";

export const login = {

  data () {
    return {
      exIdentifyAffiliateKeyId: ""
    }
  },

  computed: {
    messageError: function() {
      return this.passwordRecoverLink();
    },
  },
  methods: {
    login: function () {
      if (!this.username) {
        this.pageError = true;
        this.message = this.$t("mixins.login.emptyUsernameError");
        return;
      }

      if (!this.password) {
        this.pageError = true;
        this.message = this.$t("emptyPasswordError");
        return;
      }

      if (this.username.includes("directintelligence") || this.username.includes("bradesco") && !this.username.includes("bradescobbi")) {
        this.$router.push('/login/ad').then(() => {
          window.location.reload();
        });
        return;
      }

      let self = this;
      this.message = "";
      this.screenLocked = true;
      this.pageError = false;

      self.setDataWhatsAppParamToPost();
      self.setExIdentifyAffiliateKeyId();

      this.googleCaptchaProviderForMixin.verify(
        function(captchaToken) {
          LoginService.login(
            self.username,
            self.password,
            captchaToken,
            self.tokenCompanyRegistration,
            self.twoFactorVerified,
            self.accountUserExternalId,
            self.accountOrganizationExternalId,
            // verificar com o andre o que será passado para rastrear o usuário
          )
            .then(function(response) {
              self.screenLocked = false;
              self.$store.commit("UserModule/login", response.data.data);         
                            
              self.$emit("loginSuccessful");
              self.$gtm.trackEvent({
                event: "login", 
                loginMethod: "email ou telefone",
                partner_key_id: self.exIdentifyAffiliateKeyId
              });
              if (self.tokenCompanyRegistration) {
                self.$bus.$emit(
                  "global-notification",
                  self.$t("companyRegistrationSuccess"),
                  false
                );
              }
            })
            .catch(function(error) {
              self.screenLocked = false;
              if (error.response.data.message === "TwoFactor") {
                self.$emit(
                  "twoFactorRequested",
                  self.username,
                  self.password,
                  self.tokenCompanyRegistration
                );
              }
              self.message = error.response.data.message;
              self.pageError = true;
            });
        },
        function() {
          self.screenLocked = false;
        }
      );
    },
    passwordRecoverLink: function() {
      if (this.message.includes("_forgetPassword2")) {
        this.message = this.message.replace("_forgetPassword2", " ");
        return "userBlocked";
      } else if (this.message.includes("_forgetPassword")) {
        this.message = this.message.replace("_forgetPassword", " ");
        return "userLoginError";
      }
    },
    setExIdentifyAffiliateKeyId() {
      const exIdentifyAffiliateKeyId = localStorage.getItem(
          "exIdentifyAffiliateKeyId"
      );
      this.exIdentifyAffiliateKeyId = exIdentifyAffiliateKeyId
          ? exIdentifyAffiliateKeyId
          : "";
    },
  },
};
