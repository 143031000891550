<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        {{ $t("recoverYourPassword") }}
      </h5>
      <h5 class="title2">
        {{ $t("components.RecoverPassword.text") }}
      </h5>
    </template>
    <template slot="right">
      <RecoverPasswordRequest
        v-if="email == ''"
        @recoverRequested="recoverRequested"
      />
      <RecoverPasswordResponse
        v-if="email != ''"
        :email="email"
      />
    </template>
  </ModalFullScreenContent>
</template>

<script>
import RecoverPasswordRequest from "@/views/onboarding/recover-password-request.vue";
import RecoverPasswordResponse from "@/views/onboarding/recover-password-response.vue";
import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";

export default {
    components: {
        RecoverPasswordRequest,
        RecoverPasswordResponse,
        ModalFullScreenContent,
    },
    data() {
        return {
            email: "",
        }
    },
    methods: {
        recoverRequested(email) {
            this.email = email;
        },
    },
}
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
</style>
