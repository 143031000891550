<template>
  <div class="main-box-input-date">
    <InputSelect
      v-model="selectedDay"
      :data-source="daysCollection"
      @change="refresh"
    />
        &nbsp;&nbsp;
    <InputSelect
      v-model="selectedMonth"
      :data-source="monthsCollection"
      @change="refresh"
    />
        &nbsp;&nbsp;
    <InputSelect
      v-model="selectedYear"
      :data-source="yearsCollection"
      @change="refresh"
    />
  </div>
</template>

<script>
    import base from "@/components/inputs/base.js";
    import InputSelect from "@/components/inputs/input-select.vue";

    export default {

        components: {
            InputSelect
        },

        mixins: [base],

        data: function () {
            return {

                selectedDay: 0,
                selectedMonth: 0,
                selectedYear: 0
            }
        },

        computed: {
            daysCollection: function () {
                let days = [{ value: 0, text: "00" }];
                let lastDayInMonth = new Date(this.selectedDay, this.selectedMonth, 0).getDate();
                for (var i = 1; i <= lastDayInMonth; i++)
                    days.push({ value: i, text: i.toString().padStart(2, "0") });
                return days;
            },

            monthsCollection: function () {
                return [
                    { value: 0, text: "00" },
                    { value: 1, text: "01" },
                    { value: 2, text: "02" },
                    { value: 3, text: "03" },
                    { value: 4, text: "04" },
                    { value: 5, text: "05" },
                    { value: 6, text: "06" },
                    { value: 7, text: "07" },
                    { value: 8, text: "08" },
                    { value: 9, text: "09" },
                    { value: 10, text: "10" },
                    { value: 11, text: "11" },
                    { value: 12, text: "12" }
                ]
            },

            yearsCollection: function () {
                let years = [{ value: 0, text: "0000" }];
                let lastYear = (new Date()).getFullYear() - 15;
                for (var i = lastYear; i >= lastYear - 84; i--)
                    years.push({ value: i, text: i.toString() });
                return years;
            }
        },

        watch: {
            value: function (/*newVal, oldVal*/) {
                //to-do: update 'selectedDay', 'selectedMonth' and 'selectedYear'.
            }
        },

        methods: {
            refresh: function () {                
                let date = this.selectedYear.toString().padStart(4, "0")
                    + "-"
                    + this.selectedMonth.toString().padStart(2, "0")
                    + "-"
                    + this.selectedDay.toString().padStart(2, "0");
                
                this.updateSelf(date);
            }
        }
    }
</script>

<style lang="less" scoped>
    .main-box-input-date {
        display: inline;
    }

</style>