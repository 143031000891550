export const checkDevice = {

  computed: {
    isMobile() {
      return ["sm", "md"].includes(this.$mq);
    },

    // Check if the device is mobile only
    // feito dessa forma para não ter que alternar a definicao de isMobile
    isMobileOnly() {
      return ["sm"].includes(this.$mq);
    },
  }
}