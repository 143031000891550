import i18n from "@/i18n/i18n-instance";

class InternalServerError extends Error {
  constructor() {
    super(i18n.t("errors.InternalServerError"));
    this.name = "InternalServerError";
  }
}

export default InternalServerError;
