import httpRequest from '@/services/service-providers/httpRequest-service.js';

class ContextManangerService {

    getUserCurrentPicture() {
        return httpRequest.axios.get('ContextMananger/GetUserCurrentPicture', { responseType: "blob" });
    }

    getUserEnvironment(afterLogin) {
        return httpRequest.axios.get('ContextMananger/GetUserEnvironment', {
            params: {
                afterLogin: afterLogin
            }
        });
    }

    impersonate(companyUID) {
        return httpRequest.axios.get('ContextMananger/Impersonate', {
            params: {
                companyUID: companyUID
            },
            supressTokenRenew: true
        });
    }
        
    
}

export default new ContextManangerService();
