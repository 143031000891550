const base = {
  props: {
    value: "",
  },
  methods: {
    updateSelf(value) {
        this.$emit("input", value);
    },
  },
};

export default base;
