<template>
  <div>
    <h2 v-if="$mq === 'sm' || $mq === 'md'">
      {{ $t("recoverYourPassword") }}
    </h2>
    <h4 class="header-text">
      {{ $t("components.RecoverPasswordResponse.text") }}
    </h4>
    <p class="box-valid-info">
      <img
        src="/images/email-envelope.svg"
        alt="email"
      >
      {{ email }}
    </p>
    <p class="email-info-paragraph">
      {{ $t("components.RecoverPasswordResponse.reminder") }}
    </p>
    <a
      v-if="resendVisible"
      class="resend-link"
      href="#"
      :disabled="screenLocked"
      @click.prevent="resend"
    >{{ $t("resendEmail") }}</a>
    <p
      v-if="messageSuccess != ''"
      class="success-message"
    >
      {{ messageSuccess }}
    </p>
    <p
      v-if="messageError != ''"
      class="error-message"
    >
      {{ messageError }}
    </p>
    <Portal to="app-end">
      <div
        id="recaptcha-recover-password-response"
        key="recaptcha-recover-password-response"
      />
    </Portal>
  </div>
</template>

<script>
import googleCaptchaProvider from '@/services/captcha-providers/google.service.js';
import LoginService from '@/services/service-providers/webapp-services/login-service.js';

export default {
    props: {
        email: "",
    },
    data() {
        return {
            resendVisible: false,
            screenLocked: false,
            messageError: "",
            messageSuccess: "",
        }
    },
    mounted() {
        this.blockResend();
        this.$nextTick(() => {
            googleCaptchaProvider.initialize("recaptcha-recover-password-response");
        });
    },
    destroyed() {
        googleCaptchaProvider.dispose();
    },
    methods: {
        blockResend() {
            this.resendVisible = false;
            setTimeout(() => {
                this.resendVisible = true;
                this.messageSuccess = "";
            }, 5000);
        },
        resend() {
            this.messageError = "";
            this.messageSuccess = "";
            this.screenLocked = true;
            googleCaptchaProvider.verify(
                (captchaToken) => {
                    LoginService.recoverPassword(this.email, captchaToken, this.$i18n.locale)
                        .then(() => {
                            this.screenLocked = false;
                            this.messageSuccess = this.$t("emailResent");
                            this.blockResend();
                        })
                        .catch((error) => {
                            this.messageError = error.response.data.message;
                        });
                },
                () => {
                    this.screenLocked = false;
                }
            );
        },
    },
}
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
</style>
