<template>
  <div>
    <impersonateUser
      ref="impersonate"
      class="impersonate-control"
      :disabled="true"
      prefix="Consultar para "
    />

    <div class="section-report">
      <section
        v-if="queryType === 'dossie'"
        id="dossie"
      >
        <div class="container">
          <h1 class="text-center">
            {{ productName }}
          </h1>

          <div
            v-if="errorPage && !is18Minor"
            class="text-center mb-1"
          >
            <Alert
              alert-type="danger"
              :text="`Não foi possível realizar sua consulta, Fique tranquilo(a),
                          <strong>nenhum valor foi descontado</strong> do seu saldo <br> 
                          ${errorMessage ? errorMessage : ''} `"
              class="alert"
              :show-icon="showIconMediumOrLargeScreen"
            />

            <p v-if="showLinkNovaConsulta">
              Você pode aguardar alguns minutos e
              <a @click="consumeProduct()">consultar novamente</a>
            </p>
            <p>
              O problema persiste? <a @click="showChat">Fale com o suporte</a>
            </p>
          </div>

          <div
            v-if="errorPage && is18Minor"
            class="text-center mb-1"
          >
            <Alert
              alert-type="warning"
              :text="`Não foi possível realizar sua consulta, Fique tranquilo(a),
                          <strong>nenhum valor foi descontado</strong> do seu saldo <br> 
                          ${errorMessage ? errorMessage : ''} `"
              class="alert"
              :show-icon="showIconMediumOrLargeScreen"
            />

            <p>
              Se sua empresa tem autorização para essa consulta conforme a LGPD, <a @click="showChat">Fale com o suporte</a>
            </p>
          </div>

          <div v-if="!errorPage">
            <transition name="fade">
              <div
                v-if="pdfUrl"
                class="fade-in text-center"
              >
                <p>A consulta foi realizada com sucesso!</p>

                <div class="button-box">
                  <Button
                    btn-style="primary"
                    btn-size="small"
                    @click="openNewTab(pdfUrl)"
                  >
                    <svg class="icon icon-small icon-save">
                      <use
                        xlink:href="/images/icon-lib.svg#icon-save"
                      /></svg>Baixar Relatório
                  </Button>
                </div>

                <div class="dossie-instruction body-text-1">
                  Você também receberá um <strong>e-mail</strong> com o
                  resultado da consulta.
                </div>
              </div>

              <div v-else>
                <p class="text-center">
                  Sua consulta está em processamento e estará disponível em
                  <strong> até 5 minutos</strong>. <br>
                  Você também receberá um <strong>e-mail </strong> com o
                  resultado da consulta.
                </p>
                <LoaderLocal />
              </div>
            </transition>

            <InfoPage
              v-if="$mq === 'lg' || $mq === 'xl'"
              class="text-center info-page"
            >
              É possível visualizar o resultado da consulta no
              <router-link :to="'/historico-consultas/unitaria'">
                Histórico de Consultas
              </router-link>
            </InfoPage>

            <Alert
              v-if="$mq === 'sm' || $mq === 'md'"
              alert-type="light"
              :text="`Acesse o histórico de consultas pelo <strong>computador</strong> para ver as <strong>consultas realizadas</strong>`"
              :show-icon="false"
              class="alert mt-1-5"
            />
          </div>
        </div>
      </section>
    

      <section
        v-if="queryType != 'dossie'"
        id="query"
      >
        <div class="container">
          <TitleGroup :title="productName">
            <div
              v-if="queryLoaded"
              class="action-page"
            >
              <Button
                btn-style="link"
                btn-size="micro"
                @click="printWindow()"
              >
                <svg class="icon icon-small icon-print">
                  <use xlink:href="/images/icon-lib.svg#icon-print" /></svg>Imprimir
              </Button>
              <Button
                btn-style="link"
                btn-size="micro"
                @click="openNewTab(pdfUrl)"
              >
                <svg class="icon icon-small icon-document">
                  <use xlink:href="/images/icon-lib.svg#icon-document" /></svg>Ver Comprovante
              </Button>
            </div>
          </TitleGroup>

          <Alert
            v-if="errorPage"
            alert-type="danger"
            :text="`Não foi possível realizar sua consulta, Fique tranquilo(a),
                          <strong>nenhum valor foi descontado</strong> do seu saldo <br> 
                          ${errorMessage ? errorMessage : ''} `"
            class="alert"
          />

          <div
            v-if="errorPage"
            class="text-center mb-1"
          >
            <p v-if="showLinkNovaConsulta">
              Você pode aguardar alguns minutos e
              <a @click="consumeProduct()">consultar novamente</a>
            </p>
            <p>
              O problema persiste? <a @click="showChat">Fale com o suporte</a>
            </p>
          </div>

          <div
            v-if="queryLoaded"
            class="content-result fade-in"
          >
            <span>
              <span v-html="resultHtmlDataSourceRiskIndicator" />
              <div class="row-fluid dd-int-ds-result-details">
                <span v-html="resultHtmlHeader" />

                <div
                  v-if="resultMessage"
                  class="col dd-int-ds-return-container"
                >
                  <div class="row">
                    <div class="col-1-icon" />
                    <div class="col-3 dd-int-ds-return-field-label">Mensagem</div>
                    <div class="col dd-int-ds-return-field-value">
                      {{ resultMessage }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="!resultMessage"
                  class="col dd-int-ds-return-container"
                  v-html="resultHtml"
                />
              </div>
            </span>
          </div>

          <transition name="fade">
            <div
              v-if="!queryLoaded && !errorPage"
              class="content-info"
            >
              <LoaderLocal />
            </div>
          </transition>

          <div
            v-if="queryLoaded"
            class="footer-query"
          >
            <div class="body-text-1">
              <svg
                class="
                  icon icon-small icon-with-text icon-notification-info-2
                  text-ColorSecGrey24
                "
              >
                <use
                  xlink:href="/images/icon-lib.svg#icon-notification-info-2"
                /></svg>Emitido exclusivamente para:
              <span v-if="getCurrentCompanyCNPJ">{{ getCurrentCompanyCNPJ | cnpjFormat }} -
                {{ companyName }}</span>
              <span v-else>{{ getUserCpf | cpfFormat }} - {{ userName }}</span>
            </div>

            <div class="action-page action-page-inline-right">
              <Button
                btn-style="link"
                btn-size="micro"
                @click="printWindow()"
              >
                <svg class="icon icon-small icon-print">
                  <use xlink:href="/images/icon-lib.svg#icon-print" /></svg>Imprimir
              </Button>
              <Button
                btn-style="link"
                btn-size="micro"
                @click="openNewTab(pdfUrl)"
              >
                <svg class="icon icon-small icon-document">
                  <use xlink:href="/images/icon-lib.svg#icon-document" /></svg>Ver Comprovante
              </Button>
            </div>
          </div>
        </div>
        <!-- container end -->
      </section>

      <section
        v-if="productName"
        id="query-data"
        class="fade-in"
      >
        <div class="container">
          <div
            class="data-list-icon"
            :class="{ 'justify-content-start': queryType != 'dossie' }"
          >
            <div class="data-list-item">
              <svg class="icon icon-micro icon-with-text-2">
                <use
                  :xlink:href="`/images/icon-lib.svg#${getIconReportType(
                    inputNeeded
                  )}`"
                /></svg><span
                v-for="parameter in getParameters"
                :key="parameter"
              >{{ inputFields[parameter] }}
              </span>
            </div>
            <div class="data-list-item">
              <svg class="icon icon-micro icon-with-text-2">
                <use xlink:href="/images/icon-lib.svg#icon-calendar-2" /></svg>{{ format(new Date(buyDateTime), 'dd/MM/yyyy - HH:mm:ss') }}              
            </div>
            <div class="data-list-item">
              <svg class="icon icon-micro icon-with-text-2">
                <use xlink:href="/images/icon-lib.svg#icon-dossie" /></svg>{{ sourcesCount }} fonte{{
                checkPlural(sourcesCount)
              }}
              consultada{{ checkPlural(sourcesCount) }}
            </div>
            <div class="data-list-item">
              <IdQueryCopy
                :uid="$route.params.uniqueIdentifier"
                :medium="true"
                :small="false"
                :color-icon-turquoise="true"
              />
            </div>
          </div>
        </div>
      </section>
    </div>

    

    <NewQuery
      :product-name="productName"
      :price-to-buy-again="priceToBuyAgain"
      :input-needed="inputNeeded"
      class="new-query"
    />

    <QuestionContainer section-color="#FBFBFB">
      <Accordion>
        <QuestionPasswordReport v-if="queryType == 'dossie'" />
        <QuestionSameCpfCnpj />
        <QuestionUnavaliableSource />
      </Accordion>
    </QuestionContainer>

    <div
      v-if="!errorPage && ($mq === 'sm' || $mq === 'md')"
      class="container mb-2"
    >
      <Alert
        alert-type="info"
        :text="`Acesse o Exato <strong> pelo computador</strong> para realizar todas as consultas.`"
        class="alert"
      />
    </div>

    <SectionCorporate v-if="!userIsPos" />

    <!-- <RelatedProducts
      v-if="$mq === 'lg' || $mq === 'xl'"
      class="other-queries"
    /> -->

    <IndicationPanel
      v-if="($mq === 'lg' || $mq === 'xl') && !userIsPos"
      class="indication"
    />
  </div>
</template>

<script>
import InputCpf from "@/components/inputs/input-cpf.vue";
import InputCnpj from "@/components/inputs/input-cnpj.vue";
import RelatedProducts from "@/components/misc/related-products.vue";
import ProductService from "@/services/service-providers/webapp-services/product-service.js";
import ImpersonateUser from "@/views/dashboard/impersonate-user.vue";
import LoaderLocal from "@/components/misc/loader-local.vue";
// import IntercomService from "@/services/chat-providers/intercom-service.js";
import Button from "@/components/buttons/button.vue";

import { rdConversion } from "@/mixin/rd-conversion.js";
import { checkUserBilling } from "@/mixin/check-user-billing-mixin.js";
import { iconAlert } from "@/mixin/icon-alert-mixin.js";
import { iconReportType } from "@/mixin/icon-report-type-mixin.js";
import { useDateFnsFormat } from "@/mixin/use-date-fns-format-mixin.js";

//Lazy Loading
const InfoPage = () => import("@/components/misc/info-page.vue");
const Alert = () => import("@/components/misc/alert.vue");
const IdQueryCopy = () => import("@/components/misc/id-query-copy.vue");
const TitleGroup = () => import("@/components/misc/title-group.vue");

const NewQuery = () =>
  import(/* webpackChunkName: "NewQuery" */ "@/components/boxes/new-query.vue");

const IndicationPanel = () =>
  import(
    /* webpackChunkName: "IndicationPanel" */ "@/views/edit-profile/indication-panel.vue"
  );

const SectionCorporate = () =>
  import(
    /* webpackChunkName: "SectionCorporate" */ "@/components/section/corporate/section-corporate.vue"
  );

const Accordion = () =>
  import(
    /* webpackChunkName: "Accordion" */ "@/components/accordion/accordion.vue"
  );
const AccordionItem = () =>
  import(
    /* webpackChunkName: "AccordionItem" */ "@/components/accordion/accordion-item.vue"
  );
const QuestionContainer = () =>
  import(
    /* webpackChunkName: "QuestionContainer" */ "@/components/questions/question-container.vue"
  );

const QuestionSameCpfCnpj = () =>
  import(
    /* webpackChunkName: "QuestionSameCpfCnpj" */ "@/components/questions/question-same-cpf-cnpj.vue"
  );
const QuestionUnavaliableSource = () =>
  import(
    /* webpackChunkName: "QuestionUnavaliableSource" */ "@/components/questions/question-unavaliable-source.vue"
  );
const QuestionPasswordReport = () =>
  import(
    /* webpackChunkName: "QuestionPasswordReport" */ "@/components/questions/question-password-report.vue"
  );

export default {
  components: {
    InputCpf,
    InputCnpj,
    RelatedProducts,
    ImpersonateUser,
    LoaderLocal,
    IndicationPanel,
    NewQuery,
    InfoPage,
    Alert,
    IdQueryCopy,
    Button,
    TitleGroup,
    SectionCorporate,
    Accordion,
    AccordionItem,
    QuestionContainer,
    QuestionSameCpfCnpj,
    QuestionUnavaliableSource,
    QuestionPasswordReport,
  },

  filters: {
    uppercase: function (value) {
      if (!value) return "";
      return value.toString().toUpperCase();
    },
  },
  mixins: [rdConversion, checkUserBilling, iconAlert, iconReportType, useDateFnsFormat],

  beforeRouteEnter(to, from, next) {
    //user came from external place or typing the url in browser and trying effectivate a purchase
    if (from.name == null && to.name == "EfetivarCompraProduto") {
      next(this.localePath("LandingPage")); //redirect user to landing page
    } else {
      //allow user to continue
      next((vm) => {
        ProductService.getPurchaseDetails(
          vm.$route.params.externalId,
          vm.$route.params.uniqueIdentifier
        )
          .then(function (response) {
            vm.title = response.data.data.productName;
            if (from.name == "ProductHistory")
              document.title = vm.title + " | Histórico | Exato";
            else document.title = vm.title + " | Resultado | Exato";
          })
          .catch(function (error) {
            vm.$bus.$emit(
              "global-notification",
              error.response.data.message,
              true
            );
          });
      });
    }
  },

  data: function () {
    return {
      inputNeeded: "CPF",
      priceToBuyAgain: 0.0,
      sourcesCount: 0,
      buyDateTime: new Date(),
      productTargetName: "",
      productName: "",
      pdfUrl: "",
      result: "",

      documentToSearch: "",
      inputFields: {},
      tooltipError: {},
      lastSetTimeout: null,

      errorPage: false,
      errorMessage: "",
      showLinkNovaConsulta: true,
      is18Minor: false,

      queryType: "",

      resultHtml: "",
      riskIndicator: "",
      resultHtmlHeader: "",
      queryLoaded: false,
    };
  },

  computed: {
    companyName: function () {
      return this.$store.getters["UserModule/companyName"];
    },

    userName: function () {
      return this.$store.getters["UserModule/userName"];
    },

    userAsCompany: function () {
      return this.$store.getters["UserModule/userAsCompany"];
    },

    getCurrentCompanyCNPJ: function () {
      return this.$store.getters["UserModule/currentCompanyCNPJ"];
    },

    getUserCpf: function () {
      return this.$store.getters["UserModule/userCPF"];
    },

    getParameters: function () {
      return Object.keys(this.inputFields).filter(function (parameter) {
        return parameter !== "uid";
      });
    },
  },

  mounted: function () {
    let self = this;
    this.$bus.$on("global-click-on-page", function () {
      self.tooltipError = {};
    });

    if (this.$route.params.inputFields) {
      this.inputFields = JSON.parse(atob(this.$route.params.inputFields));
    }

    // Quando a página de resultado é recarregada
    if (
      this.getRouteBaseName() == "ExibirProdutoResultado" ||
      this.getRouteBaseName() == "ExibirProdutoResultadoNaoDossie" ||
      this.getRouteBaseName() == "ExibirProdutoUnitaria"
    ) {
      const reloaded = "reload";

      this.queryType =
        this.getRouteBaseName() == "ExibirProdutoResultado" ? "dossie" : "";

      this.retrievePurchaseInformation();
      this.verifyStatusConsumedProduct(reloaded);
    } else {
      this.consumeProduct();
    }
  },

  beforeDestroy: function () {
    if (this.lastSetTimeout) clearTimeout(this.lastSetTimeout);
  },

  methods: {
    consumeProduct: function () {
      let self = this;
      ProductService.consumeProduct(
        this.$route.params.externalId,
        this.inputFields
      )
        .then(function (response) {
          if (response.data.data.isDossie) {
            self.queryType = "dossie";

            self.$router.replace({
              name: "ExibirProdutoResultado",
              params: {
                externalId: self.$route.params.externalId,
                uniqueIdentifier: response.data.data.uniqueIdentifier,
                inputFields: self.$route.params.inputFields,
              },
            });
          } else {
            self.queryType = "NaoDossie";

            self.$router.replace({
              name: "ExibirProdutoResultadoNaoDossie",
              params: {
                externalId: self.$route.params.externalId,
                uniqueIdentifier: response.data.data.uniqueIdentifier,
                inputFields: self.$route.params.inputFields,
              },
            });
          }
          self.retrievePurchaseInformation();
          self.verifyStatusConsumedProduct();
        })
        .catch(function (error) {
          self.$bus.$emit(
            "global-notification",
            error.response.data.message,
            true
          );
          self.errorMessage = error.response.data.message;
          self.errorPage = true;
        });
    },

    retrievePurchaseInformation: function () {
      let self = this;
      ProductService.getPurchaseDetails(
        this.$route.params.externalId,
        this.$route.params.uniqueIdentifier
      )
        .then(function (response) {
          self.productName = response.data.data.productName;
          self.productTargetName = response.data.data.productTargetName;
          self.buyDateTime = response.data.data.buyDateTime;
          self.sourcesCount = response.data.data.sourcesCount;
          self.priceToBuyAgain = response.data.data.priceToBuyAgain;
          self.inputNeeded = response.data.data.inputNeeded;
        })
        .catch(function (error) {
          self.$bus.$emit(
            "global-notification",
            error.response.data.message,
            true
          );
          self.errorMessage = error.response.data.message;
          self.errorPage = true;
        });
    },

    verifyStatusConsumedProduct: function (isReloaded) {
      let self = this;
      ProductService.verifyStatusConsumedProduct(
        this.$route.params.externalId,
        this.$route.params.uniqueIdentifier
      )
        .then(function (response) {
          if (response.data.data.done) {
            self.pdfUrl = response.data.data.pdfUrl;
            self.result = response.data.data.result;
            self.resultMessage = response.data.data.resultMessage;

            //consulta unitária
            self.resultHtml = response.data.data.resultHtml;
            self.resultHtmlHeader = response.data.data.resultHtmlHeader;
            self.resultHtmlDataSourceRiskIndicator =
              response.data.data.resultHtmlDataSourceRiskIndicator;
            self.riskIndicator = response.data.data.riskIndicator;
            self.queryLoaded = true;

            self.errorPage = false;

            self.$refs.impersonate.refreshBalance();
            if (self.$route.params.inputFields && isReloaded !== "reload") {
              self.$gtm.trackEvent({
                event: "consulta-sucesso",
              });
              self.$gtm.trackEvent({
                event: "query_execute_success",
              });
              self.rdConversion(
                "Consulta com Sucesso",
                self.$store.getters["UserModule/userMainEmail"]
              );
            }
          } else self.lastSetTimeout = setTimeout(self.verifyStatusConsumedProduct, 5000);
        })
        .catch(function (error) {
          // Não tem sentido exibir mensagem de erro e no div de alerta a mesma coisa.
          // self.$bus.$emit('global-notification', error.response.data.message, true);
          self.$gtm.trackEvent({
            event: "query_execute_error",
          });
          self.errorMessage = error.response.data.message;
          self.checkErrorType(self.errorMessage);
          self.errorPage = true;
        });
    },

    openNewTab: function (url) {
      window.open(url, "_blank");
    },

    showChat: function () {
      // IntercomService.showNewMessage();
    },

    checkErrorType: function (message) {
      this.is18Minor = false;
      if (message.indexOf("CPF não encontrado") >= 0) {
        this.showLinkNovaConsulta = false;
        return;
      } else if (message.indexOf("pessoa menor de 18 anos") !== -1 || message.indexOf("menor de 18 anos sem informar") !== -1) {
        this.showLinkNovaConsulta = false;
        this.is18Minor = true;
        this.errorMessage = "O CPF informado pertence a um menor de idade.";
        return;
      }
      this.showLinkNovaConsulta = true;
    },
    printWindow: function () {
      window.print();
    },
    checkPlural: function (count) {
      return count > 1 ? "s" : "";
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "base-product.less";

/* classes bootstrap */
// @import "../../styles/bootstrap/bootstrap-grid.less";

/* ícones dossiê */
@import "../../styles/fontawesome/css/all.css";

/* Dossiê */
@import "../../styles/dossier/DossierPrint.less";

/* customizando dossiê */
@import "../../styles/dossier/DossierPrintVariables.less";
@import "../../styles/dossier/DossierPrintIconRisk.less";

// Udado o /deep/ pois o contéudo é injetado pelo serviço e o VUE não reconhece como parte do componente
.dd-int-ds-result-details {
  border-width: 1px;
  margin-top: 0;
  background-color: #ffffff;
  border-radius: 5px;

  /deep/ .dd-int-ds-result-header-container {
    align-items: center;
    padding: 11px 0;
    background: none;
    border-bottom: 1px solid #f5f5f5;
  }

  /deep/ .dd-int-ds-result-risk-indicator {
    border-radius: 5px;
    font-size: 0.7rem;
  }

  /deep/ .dd-int-ds-return-container .row {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  /deep/ .dd-int-ds-return-container .dd-int-ds-return-field-label {
    font-weight: 400;
    font-weight: 500;
  }

  /deep/ .dd-int-ds-return-container {
    .dd-int-ds-return-subheader1 {
      max-width: 98%;
    }
    .dd-int-ds-return-subheader3 {
      max-width: 95%;
    }
    .dd-int-ds-return-field-group-row .row {
      margin-top: 11px;
      margin-bottom: 11px;
    }
  }
}

/deep/ .dd-int-dossier-risk-indicator {
  align-items: center;
  margin: 0 0 2rem 0;
  border-radius: 0.25rem;
}

/deep/ .dd-int-dossier-risk-indicator-icon-container {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/deep/ .dd-int-dossier-risk-indicator-text-container {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/deep/
  .dd-int-dossier-risk-indicator
  /deep/
  .dd-int-dossier-risk-indicator-text-container
  p {
  margin: 0;
  line-height: 1.5rem;
}

/deep/ .dd-int-dossier-risk-indicator-red {
  color: #721c24;
  background-color: #fad6d9;
}
/deep/ .dd-int-dossier-risk-indicator-amber {
  color: #856404;
  background-color: #fff2d6;
}
/deep/ .dd-int-dossier-risk-indicator-green {
  color: #155724;
  background-color: #e5f5d4;
}
/deep/ .dd-int-dossier-risk-indicator-unavailable {
  background-color: #cccccc;
}
/deep/ .dd-int-dossier-risk-indicator-neutral {
  background-color: #ededed;
}

/deep/ .content-result {
  padding-bottom: 1rem;
  // no exato não está definido o border-box. Para o bootstrap é necessário usar
  * {
    box-sizing: border-box;
  }

  p {
    margin-bottom: 1rem;
  }
}

/* Definição do bootstrap usadas no dossiê */
/deep/ hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/deep/ hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.footer-query {
  display: flex;
  align-items: center;

  .action-page {
    margin-left: auto;
  }
}

#dossie {
  min-height: 230px;
  margin-bottom: 1rem;

  .button-box {
    margin: 1.5rem 0;

    @media (min-width: @screenLarge) {
      margin: 2rem 0;
    }
  }
  .content-result {
    padding-bottom: 0;
  }
}

.dossie-instruction {
  max-width: 320px;
  margin: auto;

  @media (min-width: @screenMedium) {
    max-width: 500px;
  }
}

/* checar */
.alert {
  margin-top: 0;
}

@media print {
  .new-query,
  .other-queries,
  .indication,
  .action-page,
  .box-impersonate {
    display: none;
  }
  .container {
    width: auto;
  }
}

// duplicado
.section-content {
  margin-bottom: 2rem;

  @media (min-width: @screenLarge) {
    margin-bottom: 4rem;
  }
}
.info-page,
.alert {
  margin-bottom: 1rem !important;
}

#query {
  margin-bottom: 0.25rem;
}

.data-list-icon {
  padding: 1rem 0;

  @media (min-width: @screenLarge) {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
}

.data-list-item {
  padding-right: 0;
  margin-bottom: 1rem;
  font-size: 0.875rem;

  @media (min-width: @screenLarge) {
    margin-bottom: 0;
    padding-right: 4rem;
  }

  &:last-child {
    padding-right: 0;
  }
  .icon {
    color: @ColorPriTurquoise;
  }
}

.justify-content-start {
  justify-content: start;
}

.section-report {
  min-height: 331px;
  // border: 1px solid red;
}

</style>
