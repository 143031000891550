<template>
  <div>
    <span
      class="close-button"
      @click="close"
    >            
      <svg class="icon icon-small icon-close">
        <use xlink:href="/images/icon-lib.svg#icon-close" />
      </svg>
    </span>



    <slot />
  </div>
</template>

<script>
    export default {

        mounted: function () {
            let body = document.getElementsByTagName("body")[0];
            body.style.overflow = "hidden";
            // close modal on escape
            document.addEventListener("keydown", (e) => {
                if (e.key === "Escape") {
                    this.close();
                }
            });
        },

        destroyed: function () {
            let body = document.getElementsByTagName("body")[0];
            body.style.overflow = "unset";
        },

        methods: {
            close: function () {
                this.$emit("closing");
            }
        }

    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    @import "../../styles/fonts.less";
    @import "../../styles/screen-size.less";

    div {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: @ColorPriWhite;
        z-index: 10;
        //z-index: 1040;
    }

    .close-button {
        position: fixed;
        right: 28px;
        top: 28px;
        height: 16px;
        user-select: none;
        cursor: pointer;        
        z-index: 99;

        transition: .3s all;

        &:hover {       
            opacity: 0.7;
            transform: rotate(180deg);
        }

        @media (max-width: @screenLarge) {
             right: 1rem;   
             top: 1rem;   
        }



    }

    .icon-close {

        @media (max-width: @screenLarge) {
            width: 20px;
            height:20px;     
        }
    }

</style>