export const textScreenRegister = {
  computed: {
    screenText() {
      return {
        default: {
          title2: this.$t("mixins.textScreenRegister.default.title2"),
          headerText: this.$t("mixins.textScreenRegister.default.headerText"),
          ganheTitle: this.$t("mixins.textScreenRegister.default.ganheTitle"),
        },
        whatsAppNotOnbording: {
          title2: this.$t("mixins.textScreenRegister.whatsAppNotOnboarding.title2"),
          headerText: this.$t("mixins.textScreenRegister.whatsAppNotOnboarding.headerText"),
          ganheTitle: this.$t("mixins.textScreenRegister.whatsAppNotOnboarding.ganheTitle"),
        },
      };
    },
  },
}
