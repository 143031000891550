<template>
  <div class="tooltip-icon">
    <svg
      v-if="$mq === 'lg' || $mq === 'xl'"
      v-tooltip="{ content: text }"
      :class="finalIconClass"
    >
      <use :xlink:href="iconPath" />
    </svg>
    <v-popover v-else>
      <svg :class="finalIconClass">
        <use :xlink:href="iconPath" />
      </svg>
      <template #popover>
        <span v-html="text" />
      </template>
    </v-popover>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    iconClass: {
      type: String,
      required: false,
      default: "",
    },
    text: {
      type: String,
      required: true,
    },
    iconPath: {
      type: String,
      default: "/images/icon-lib.svg#icon-notification-info-2",
    },
  },
  computed: {
    finalIconClass() {
      return ["icon", { [this.iconClass]: this.iconClass }];
    },
  },
});
</script>

<style lang="less" scoped>
// test
.tooltip-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 90%;
}

.icon {
  vertical-align: middle;
}

/deep/ .trigger {
  display: flex !important;
  align-items: center;
}
</style>
