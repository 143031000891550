<template>
  <Header
    :user-authenticated="userAuthenticated"
    header-color="#FAFAFA"
    class="header-exato"
  >
    <template #brand />
    <!-- <template #brand="slotProps">
      <a
        href
        class="brand-link"
        @click.prevent="
          !userAuthenticated
            ? [
                $bus.$emit('close-modals'),
                $router.push(localePath('LandingPage')),
                slotProps.closeMenuMobile(),
              ]
            : [$router.push({ name: 'Home' }), slotProps.closeMenuMobile()]
        "
      >
        <LogoExato :logoColor="checkLogoColor" />
      </a>
    </template> -->

    <template #menu-main="slotProps">
      <NavLinkHorizontal
        v-if="!userAuthenticated"
        class="nav-item-button"
      >
        <div
          v-if="!userAuthenticated"
          class="button-box"
        >
          <Button
            v-if="showWinCredits"
            data-intercom="ganheCreditos"
            btn-style="primary-outline"
            :btn-size="checkButtonSize"
            class="no-print mr-md-1-5 mt-md-1"
            @click="showChat"
          >
            <svg class="icon icon-small-2">
              <use xlink:href="/images/icon-lib.svg#icon-money" />
            </svg>{{ $t("earnCredits") }}
          </Button>
          <Button
            v-if="!showWhatsApp"
            btn-style="primary"
            :btn-size="checkButtonSize"
            class="no-print mt-md-1"
            :class="[animeTestButton ? 'tada' : '']"
            @click.native="
              slotProps.closeMenuMobile();
              $bus.$emit('global-register');
            "
          >
            {{ $t("freeTrial") }}
          </Button>
          <a
            v-else
            :href="getUrlQrCode(getRouteBaseName())"
            target="_blank"
            rel="nofollow"
            @click="sendTestWhatsAppAnalytics
            "
          >
            <Button
              btn-style="primary"
              :btn-size="checkButtonSize"
              class="no-print mt-md-1"
              :class="[animeTestButton ? 'tada' : '']"
              @click.native="slotProps.closeMenuMobile()"
            >
              <svg class="icon icon-small-2">
                <use xlink:href="/images/icon-lib.svg#icon-whatsapp" />
              </svg>{{ $t("freeTrial") }}</Button>
          </a>
        </div>
      </NavLinkHorizontal>
    </template>
  </Header>
</template>

<script>
import Header from "@/components/structure/header.vue";
import Button from "@/components/buttons/button.vue";
import ContextManangerService from "@/services/service-providers/webapp-services/context-mananger-service.js";
// import StructureNavigationDropdown from "@/components/structure/exato/header-dropdown.vue";
// // import IntercomService from "@/services/chat-providers/intercom-service.js";
import { goToElement } from "@/mixin/go-to-element-mixin.js";
import { qrCodeUrl } from "@/mixin/qrcode-url-mixin.js";
import { intercom } from "@/mixin/intercom-mixin.js";

const LogoExato = () =>
  import(
    /* webpackChunkName: "LogoExato" */ "@/components/logo/logo-exato.vue"
  );
const NavLinkHorizontal = () =>
  import(
    /* webpackChunkName: "NavLinkHorizontal" */ "@/components/navs/nav-link-horizontal.vue"
  );

export default {

  components: {
    Header,
    Button,
    LogoExato,
    NavLinkHorizontal,
  },
  mixins: [goToElement, qrCodeUrl, intercom],

  data() {
    return {

      animeTestButton: false,
      // menuCollapsed: true, // herdar/atribuir no header
      dropdownSegmento: false,
      dropdownLanguage: false,

      menuResourcesCollapsed: true,
      menuCorporateCollapsed: true,
    };
  },

  computed: {
    // TODO: Esse método e o template relacionado para troca de idioma precisam ser extraídos para um componente próprio.
    availableLocaleConfigs() {
      return this.$i18n.localeConfigs.filter((localeConfig) => {
        // Don't include current locale config
        if (localeConfig.code === this.$i18n.locale) {
          return false;
        }

        // Get path for localized route (if exists)
        const path = this.switchLocalePath(localeConfig.code);

        // Check if path matches the current path (it happens when a localized route doesn't exist)
        const matchesCurrentPath =
          path === this.$route.redirectedFrom || path === this.$route.fullPath;

        // Route exists and differs from the current route (a localized route exists)
        return path && !matchesCurrentPath;
      });
    },
    checkButtonSize() {
      return this.$mq === "lg" || this.$mq === "xl" ? "micro" : "small";
    },
    userAuthenticated() {
      return this.$store.getters["UserModule/userAuthenticated"];
    },
    setHeaderColor() {
      return this.userAuthenticated ? "#FFF" : "#F6F4EC";
    },

    companyName() {
      return this.$store.getters["UserModule/companyName"];
    },

    userName() {
      return this.$store.getters["UserModule/userName"];
    },

    showMenuQueries() {
      const pageWithQueries = [
        "LandingPage",
        "LandingPageRH",
        "LandingPageAutos",
      ];
      return pageWithQueries.includes(this.getRouteBaseName());
    },
    showSegments() {
      const pageWithSegments = [
        "LandingPage",
        "LandingPageDev",
        "LandingPageRH",
        "LandingPageAutos",
      ];
      return pageWithSegments.includes(this.getRouteBaseName());
    },

    showWhatsApp() {
      const pagesWithTestWhatsApp = [
        "LandingTestWhatsApp",
        "LandingTestWhatsAppCpf",
        "LandingTestWhatsAppCnpj",
        "LandingTestWhatsAppTry",
        "LandingWhatsAppPerformance",
        "LandingWhatsAppBranding",
        "LandingWhatsAppVenture",
        "LandingWhatsAppMetro",
        "LandingWhatsAppMetroPerformance",
        "LandingWhatsAppMetroPerformanceAntiFraude",
      ];
      return pagesWithTestWhatsApp.includes(this.getRouteBaseName());
    },

    checkLogoColor() {
      return this.headerFloatMode
        ? null
        : !this.userAuthenticated
          ? "black-exato"
          : null;
    },
    showWinCredits() {
      return "LandingWhatsAppVenture" === this.getRouteBaseName();
    },
  },

  watch: {
    $route(to) {
      const routeBaseName = this.getRouteBaseName(to);

      //Bradesco: Verifica o nome da rota para ativar menu do bradesco
      this.vagasCandidatosSelected = [
        "BradescoVacancy",
        "BradescoCandidates",
        "BradescoCandidatesDetail",
        "BradescoCandidatesBusca",
      ].includes(routeBaseName);

      this.setupVagasSelected = [
        "BradescoSetupVacancy",
        "BradescoSetupVacancySearch",
        "BradescoNewPosition",
        "BradescoEditPosition",
      ].includes(routeBaseName);

      this.batchIntegrationSelected = [
        "BradescoBatchIntegrations",
        "BradescoBatchIntegrationsSearch",
        "BradescoBatchIntegrationsDetails",
      ].includes(routeBaseName);
    },
    userAuthenticated() {
      this.loadUserPicture();
      //this.loadUserEnvironment();
    }
  },

  created() {
    // let self = this;
    // TODO: Isso pode ser movido para o App.vue, pois é um evento global e apenas lida com serviços e estados globais (inclui uso de Vuex).
    // this.$bus.$on(
    //   "global-needReloadUserEnvironment",
    //   function (redirectLogin, afterLogin) {
    //     self.loadUserEnvironment(redirectLogin, afterLogin);
    //   }
    // );
    // this.$bus.$on("global-needReloadUserPicture", function () {
    //   self.loadUserPicture();
    // });    

    document.addEventListener("click", this.documentClickSegmentos);
    document.addEventListener("click", this.checkHideMenu);
  },

  mounted() {
    // window.addEventListener("scroll", this.checkHeaderForFloat);
    this.loadUserPicture();
    // this.loadUserEnvironment();
    this.animateCueTestButton();
  },

  destroyed: function () {
    // window.removeEventListener("scroll", this.checkHeaderForFloat);
    document.removeEventListener("click", this.documentClickSegmentos);
    document.addEventListener("click", this.checkHideMenu);
  },

  methods: {
    goToConsultar: function () {
      this.$router.push("/home");
    },

    goToGerenciar() {
      this.$router.push("/editar-perfil");
    },

    checkHideMenu(e) {
      const target = e.target;
      const dropdownCorporate = this.$refs.dropdowMenuCorporate;
      const dropdownResources = this.$refs.dropdowMenuResources;

      if (dropdownCorporate !== target) {
        this.menuCorporateCollapsed = true;
      }
      if (dropdownResources !== target) {
        this.menuResourcesCollapsed = true;
      }
    },
    closeMenuMobile: function () {
      this.menuCollapsed = !this.menuCollapsed;
    },

    disabledFunctionAlert() {
      this.$bus.$emit("global-modal-disabled-function");
    },




    loadUserPicture: function () {
      this.userimageSrc = "/images/no-user-picture.svg";
      if (this.userAuthenticated) {
        let self = this;
        ContextManangerService.getUserCurrentPicture().then(function (
          response
        ) {
          if (response.status === 204) return;
          let reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = function () {
            self.userimageSrc = reader.result;
          };
        });
      }
    },

    // TODO: Isso pode ser movido para o App.vue, pois apenas lida com serviços globais.
    sendHomeIfPos(currentBillingType) {
      const routeNameNotPosAllowed = ["SaldoExtrato", "ComprarCredito"];
      if (
        currentBillingType === 2 &&
        routeNameNotPosAllowed.includes(this.getRouteBaseName())
      ) {
        this.$router.push({ name: "Home" });
      }
    },

    animateCueTestButton: function () {
      let self = this;
      // executa acada 30 segundos
      setInterval(function () {
        self.animeTestButton = true;
        // para a animação depois de 2 segundos
        setTimeout(function () {
          self.animeTestButton = false;
        }, 2000);
      }, 30000);
    },
    // TODO: Isso pode ser movido para o App.vue, pois apenas lida com serviços globais.
    // sendUserIdAnalytics: function (uid) {
    //   this.$gtm.trackEvent({
    //     userID: uid,
    //   });
    // },

    sendTestWhatsAppAnalytics() {
      this.$gtm.trackEvent({ event: "teste-whatsapp" });
      this.$gtm.trackEvent({ event: "test_button_whatsapp" });
    },

    // o idela é ter uma função genéria que fecha os menus
    documentClickSegmentos(e) {
      if (!this.$refs.segmentos) return;
      let el = this.$refs.segmentos.$el;
      let target = e.target;

      if (el !== target) {
        this.dropdownSegmento = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../styles/sizes.less";
@import "../../../styles/colors.less";
@import "../../../styles/animations.less";
@import "../../../styles/screen-size.less";

/* novo menu mobile */

// .nav-item {
//   position: relative;
//   color: @ColorPriGrey;
// }

.nav-item-principal {
  @media (min-width: @screenLarge) {
    padding-left: 1.5rem;
  }
}

.nav-link-login {
  margin-right: 0;
}

.nav-link-subitem {
  opacity: 0.5;

  @media (min-width: @screenLarge) {
    opacity: 0.8;
  }
}

//TODO - Avaliar: parece que esse trecho não é mais necessário
// .button-box {
//   // padding: 0 2rem;
//   flex-basis: 100%;

//   @media (min-width: @screenLarge) {
//     padding: 0;
//     //margin-left: 2rem;
//   }

//   a {
//     display: inline-block;

//     @media (max-width: @screenMedium) {
//       width: 100%;
//       max-width: 290px;
//       margin: auto !important;
//     }
//     @media (max-width: @screenSmall) {
//       max-width: 100%;;
//     }

//   }
// }

@media (min-width: @screenLarge) {
  .nav-item-separator {
    padding-left: 2rem;
    border-left-width: 1px;
  }
}

//.landing-unified {
// .nav-item {
//   position: relative;
//   color: @ColorPriGrey;

//   // @media (min-width: @screenLarge) {
//   //   display: flex;
//   //   align-items: center;
//   // }
// }
//}


/* end novo mobile */
//.userAuthenticated {
// @media (min-width: @screenLarge) {
//   .nav-item {
//     display: flex;
//     align-items: center;
//     color: @ColorPriGrey;
//   }
// }
//}


.btn-primary-outline {
  border: 1px solid #d7d6cf;
}
</style>
