<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        Valide seu e-mail
      </h5>
      <!-- <h5 class="title2">
                {{
                    pendencyRegister
                        ? 'Validando seu e-mail você terá acesso a consulta selecionada'
                        : 'Validando seu e-mail você terá acesso a mais consultas do Exato'
                }}
            </h5> -->

      <ListFeatureContainer>
        <ListFeature
          ml
          mb
          opacity
          illustration-color="FFF"
          class="text-max-width-none"
        >
          <strong>Ganhe {{ getValeuAfterCompleteRegister }}</strong> em créditos
          para realizar consultas completando o seu cadastro após validar seu
          e-mail
        </ListFeature>
      </ListFeatureContainer>

      <StepIndicatorModal
        v-if="pendencyExecuteQuery !== 'PENDENCY-EMAIL'"
        class="step-modal"
        :step-number="1"
        change-text-for-step-one="Validar E-mail"
        change-text-for-step-two="Completar Cadastro"
        change-text-for-step-three="Cadastrar Empresa"
        :pendency-execute-query="pendencyExecuteQuery"
      />
    </template>
    <template slot="right">
      <h2 v-if="$mq === 'sm' || $mq === 'md'">
        Valide seu e-mail
      </h2>
      <h4 class="header-text">
        Enviamos um e-mail de confirmação para o endereço:
      </h4>
      <p class="box-valid-info">
        <img
          src="/images/email-envelope.svg"
          alt="email"
        >
        {{ email }}
      </p>
      <p>
        Não recebeu o e-mail? Certifique-se de que a mensagem não está na pasta
        "Spam".
      </p>
      <a
        v-if="resendVisible"
        href="#"
        :disabled="screenLocked"
        class="resend-link"
        @click.prevent="resend"
      >Reenviar e-mail</a>
      <p
        v-if="messageSuccess != ''"
        class="success-message"
      >
        {{ messageSuccess }}
      </p>
      <p
        v-if="messageError != ''"
        class="error-message"
      >
        {{ messageError }}
      </p>
    </template>
  </ModalFullScreenContent>
</template>

<script>
import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
import IndividualPersonValidationService from "@/services/service-providers/webapp-services/individual-person-validation-service.js";
import StepIndicatorModal from "@/views/product/step-indicator-modal.vue";
import { valueAfterCompleteRegister } from "@/mixin/value-after-complete-register-mixin.js";
const ListFeatureContainer = () =>
  import(
    /* webpackChunkName: "ListFeatureContainer" */ "@/components/shared/list/feature/list-feature-container.vue"
  );
const ListFeature = () =>
  import(
    /* webpackChunkName: "ListFeature" */ "@/components/shared/list/feature/list-feature.vue"
  );

export default {

  components: {
    ModalFullScreenContent,
    StepIndicatorModal,
    ListFeatureContainer,
    ListFeature
  },
  mixins: [valueAfterCompleteRegister],

  props: {
    pendencyExecuteQuery: {
      type: String,
      required: false,
      default: ""
    }
  },

  data: function() {
    return {
      resendVisible: false,
      screenLocked: false,
      messageError: "",
      messageSuccess: "",
      email: ""
    };
  },
  computed: {
    pendencyRegister: function() {
      return this.pendencyExecuteQuery !== "PENDENCY-EMAIL" ? true : false;
    }
  },

  mounted: function() {
    var self = this;
    IndividualPersonValidationService.getMyMainEmail()
      .then(function(response) {
        self.email = response.data.data;
      })
      .catch(function(error) {
        self.messageError = error.response.data.message;
      })
      .then(function() {
        self.blockResend();
      });
  },

  methods: {
    resend: function() {
      let self = this;
      this.messageError = "";
      this.messageSuccess = "";
      this.screenLocked = true;

      IndividualPersonValidationService.resendEmailConfirmation()
        .then(function() {
          self.screenLocked = false;
          self.messageSuccess = "Email reenviado.";
          self.blockResend();
        })
        .catch(function(error) {
          self.screenLocked = false;
          self.messageError = error.response.data.message;
        });
    },

    blockResend: function() {
      let self = this;
      this.resendVisible = false;
      setTimeout(function() {
        self.resendVisible = true;
        self.messageSuccess = "";
      }, 5000);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
</style>
