
/* global FB */
class FacebookServices {

    constructor() {
        if (this.isAlreadyInitialized()) {
            return;
        }

        window.fbAsyncInit = function () {
            FB.init({
                appId: process.env.VUE_APP_FACEBOOK_APPID,
                cookie: true,                
                xfbml: true,
                version: 'v3.2'
            });
        };
        let js = document.createElement('script');
        js.id = 'facebook-jssdk';
        js.src = 'https://connect.facebook.net/pt_BR/sdk.js';
        let head = document.getElementsByTagName('head')[0];
        head.append(js);
    }

    /**
     * Verify if the Facebook services is already initialized.
     * @returns {boolean} True if already initialized.
     * */
    isAlreadyInitialized() {
        if (document.getElementById('facebook-jssdk')) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Perform the login with Facebook opening a pop-up window asking for a username and password.
     * @param {function} callback If the login was performed with success, this callback will be executed with information about the user (callback parameter).
     */

     login(callback) {
        let instance = this;

        FB.getLoginStatus(function (loginStatusResponse) {
            if (loginStatusResponse.status === 'connected') {
                instance.getActiveUser(callback, loginStatusResponse);
            } else {

                FB.login(function (loginResponse) {                    
                    if (loginResponse.status === 'connected') {
                        instance.getActiveUser(callback, loginResponse);
                    } 
                }, { scope: 'email' });
            }
            
        });
        // https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus/#servers
        // quando usa o parâmetro para não guardar no cache, suge mensagem de erro no console

    }

    /**
     * Retrieve the current active Facebook user.
     * @param {function} callback This callback is always called, delivering the information about the current user (if any) or null if no current user was found.
     */


     getActiveUser(callback, loginStatusResponse) {       
        FB.api('/me', { fields: 'email' }, function (meResponse) {
            if (callback) {
                callback({
                    accessToken: loginStatusResponse.authResponse.accessToken,
                    socialUserId: loginStatusResponse.authResponse.userID,
                    email: meResponse.email
                });
            }
        });
    }

    shareLink(url) {
        FB.ui({
            method: 'share',
            display: 'iframe',
            href: url
        });
    }
}

export default new FacebookServices();
