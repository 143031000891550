<template>
  <div>
    <div
      v-if="isSpecialistContact"
      class="contato-hero"
    >
      <ContactBannerBackground
        ref="contato"
        :section-hero="true"
        class="bg-dark"
      />
    </div>

    <SectionContent
      v-if="!isSpecialistContact"
      class="section-content hero text-center bg-light"
    >
      <div class="container hero-container">
        <SectionHeroSolutions>
          <div class="hero-content-text">
            <SectionHeroSolutionName>Corporate</SectionHeroSolutionName>
            <h1 v-animate="'fadeIn'">
              Background Check
            </h1>
            <p
              v-animate="'fadeIn'"
              class="sub-header"
            >
              Faça a checagem e validação de dados de clientes e fornecedores de
              maneira ágil e automática com relatórios completos para background
              check, due diligence e know your customer (KYC)
            </p>

            <div
              v-animate="'fadeIn'"
              class="button-box"
            >
              <a @click.prevent="goToElementId('contato-content', 'start')">
                <Button
                  class="button"
                  btn-style="primary"
                >Contato Comercial</Button>
              </a>
            </div>
          </div>

          <div
            v-animate="'fadeInRight'"
            class="hero-illustration-content fast"
          >
            <img
              src="/images/corporate/img-background-check.svg"
              class="hero-illustration"
              alt
            >
          </div>

          <div
            v-animate="'fadeIn'"
            class="button-box"
          >
            <a @click.prevent="goToElementId('contato-content', 'start')">
              <Button
                class="button"
                btn-style="primary"
              >Contato Comercial</Button>
            </a>
          </div>
        </SectionHeroSolutions>
        <!-- hero-content end -->

        <Cue
          id-element-to-scroll="feature-content"
          img-path="/images/corporate/icon-lib.svg#icon-arrow"
          cue-color="white"
        />
      </div>
      <!-- container end -->
    </SectionContent>
    <!-- hero end -->
    <div
      v-if="!isSpecialistContact"
      id="feature-content"
      class="scroll-margin-top"
    >
      <SectionContent
        id="feature"
        ref="feature-section"
        ref-section="feature"
        class="text-center bg-dark"
      >
        <div class="container">
          <h2 v-animate="'fadeIn'">
            Automação de processos para Background Check
          </h2>

          <p
            v-animate="'fadeIn'"
            class="introduction"
          >
            Realize a verificação e validação de informações de pessoas físicas
            e jurídicas com agilidade e segurança
          </p>

          <div class="row mb-2">
            <div class="col-lg-4">
              <Card
                v-animate="'fadeInUp'"
                card-theme="dark"
                class="mb-md-1"
              >
                <template #body>
                  <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-exato-dossie" />

                  <div class="card-title">
                    Relatórios em PDF
                  </div>
                  <div class="card-text">
                    Nossa solução permite emitir relatórios completos de pessoas
                    físicas e jurídicas em até 5 minutos por e-mail, portal web,
                    SMS ou WhatsApp e pode ser integrada aos seus sistemas via
                    API
                  </div>
                </template>
              </Card>
            </div>
            <!-- col end -->

            <div class="col-lg-4">
              <Card
                v-animate="'fadeInUp'"
                card-theme="dark"
                class="delay-250ms mb-md-1"
              >
                <template #body>
                  <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-exato-consulta-2" />

                  <div class="card-title">
                    Consultas online
                  </div>
                  <div class="card-text">
                    Realizamos inúmeras consultas em tempo real para garantir a
                    qualidade e precisão das informações. Para uso em grandes
                    volumes, podemos gerar relatórios com consultas e
                    indicadores customizados
                  </div>
                </template>
              </Card>
            </div>
            <!-- col end -->

            <div class="col-lg-4">
              <Card
                v-animate="'fadeInUp'"
                card-theme="dark"
                class="delay-500ms"
              >
                <template #body>
                  <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-exato-lock" />
                  <div class="card-title">
                    Segurança
                  </div>
                  <div class="card-text">
                    Cada relatório emitido possui marca d’água com dados do
                    responsável pela emissão, senha de acesso e assinatura
                    digital. Conta ainda com um link personalizado e QR Code
                    para fácil acesso e compartilhamento
                  </div>
                </template>
              </Card>
            </div>
            <!-- col end -->
          </div>
          <!-- row end -->

          <a @click.prevent="goToElementId('contato-content', 'start')">
            <Button
              class="button mb-md-1"
              btn-style="primary"
            >Contato Comercial</Button>
          </a>

          <Cue
            id-element-to-scroll="relatorios-content"
            img-path="/images/corporate/icon-lib.svg#icon-arrow"
            cue-color="white"
          />
        </div>
      </SectionContent>
    </div>

    <div
      id="relatorios-content"
      class="scroll-margin-top"
    >
      <SectionContent
        id="relatorios"
        ref="relatorios-section"
        ref-section="relatorios"
        class="text-center bg-light"
      >
        <div class="container">
          <h2 v-animate="'fadeIn'">
            Relatórios que o Exato oferece
          </h2>
          <p
            v-animate="'fadeIn'"
            class="introduction"
          >
            Emita relatórios completos em até 5 minutos com informações de
            diversas fontes de dados
          </p>

          <ReportPessoaFisica ref-call-to-action="contato-content" />
          <ReportPessoaJuridica ref-call-to-action="contato-content" />
          <ReportPessoaFisicaCredito ref-call-to-action="contato-content" />
          <ReportPessoaJuridicaCredito ref-call-to-action="contato-content" />

          <Cue
            id-element-to-scroll="precificacao"
            img-path="/images/corporate/icon-lib.svg#icon-arrow"
          />
        </div>
      </SectionContent>
    </div>

    <!--
      https://localhost:1337/corporate/background-check#precificacao
      https://localhost:1337/corporate/background-check/precificacao
    -->
    <div
      id="precificacao"
      class="scroll-margin-top"
    >
      <SectionContent
        id="precos"
        section-color="#FBFBFB"
        class="text-center bg-light"
      >
        <div class="container container-precos">
          <h2 v-animate="'fadeIn'">
            Precificação dos relatórios
          </h2>

          <p
            v-animate="'fadeIn'"
            class="introduction"
          >
            Confira nossa tabela de preços e estime os custos para sua demanda
          </p>

          <Alert
            v-animate="'fadeIn'"
            alert-type="info"
            text="<strong> Valor mínimo de R$ 495,00 mensais,</strong> com descontos progressivos de acordo com o volume mensal de relatórios."
            class="alert delay-300ms"
            icon-path="/images/corporate/icon-lib.svg"
            :show-icon="showIcon"
          />

          <Card
            v-animate="'fadeInUp'"
            class="card-table mb-1"
          >
            <template #body>
              <div class="table-responsive scrollbar scrollbar-horizontal">
                <TableCorporate class="table-price table-mobile-small">
                  <thead class="thead-default">
                    <tr>
                      <th
                        scope="col"
                        class="text-center col-faixa-consumo sticky-col"
                      >
                        Faixa
                      </th>
                      <!-- <th scope="col" class="text-center col-volume sticky-col">
                        Volume de <br />
                        Relatórios
                      </th> -->

                      <th
                        scope="col"
                        class="text-center col-quantidade-inicial sticky-col"
                      >
                        {{ getTextAmount }} <br>
                        inicial
                      </th>
                      <th
                        scope="col"
                        class="text-center col-quantidade-final sticky-col"
                      >
                        {{ getTextAmount }} <br>
                        final
                      </th>
                      <th
                        scope="col"
                        class="text-center col-pessoa-fisica"
                      >
                        Relatório <br>
                        Pessoa Física
                      </th>
                      <th
                        scope="col"
                        class="text-center col-pessoa-juridica"
                      >
                        Relatório <br>
                        Pessoa Jurídica
                      </th>
                      <th
                        scope="col"
                        class="text-center col-pessoa-fisica-credito"
                      >
                        Relatório Pessoa física <br>
                        com Análise de crédito
                      </th>
                      <th
                        scope="col"
                        class="text-center col-pessoa-juridica-credito"
                      >
                        Relatório Pessoa Jurídica <br>
                        com Análise de crédito
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        1
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">100</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        1
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        50
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 19,95
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 19,95
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 24,95
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 29,95
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        2
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">149</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        51
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        100
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 19,04
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 19,04
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 24,04
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 29,04
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        3
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">249</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        101
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        250
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 18,13
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 18,13
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 23,13
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 28,13
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        4
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">499</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        251
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        500
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 17,22
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 17,22
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 22,22
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 27,22
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        5
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">1.499</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        501
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        1.000
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 16,31
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 16,31
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 21,31
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 26,31
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        6
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">2.499</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        1.001
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        2.500
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 15,40
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 15,40
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 20,40
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 25,40
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        7
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">4.999</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        2.501
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        5.000
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 14,49
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 14,49
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 19,49
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 24,49
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        8
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">14.999</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        5.001
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        10.000
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 13,58
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 13,58
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 18,58
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 23,58
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        9
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">24.999</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        10.001
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        25.000
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 12,67
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 12,67
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 17,67
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 22,67
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        10
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">-</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        25.001
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        50.000
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 11,76
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 11,76
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 16,76
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 21,76
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        11
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">-</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        50.001
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        100.000
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 10,85
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 10,85
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 15,85
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 20,85
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center col-faixa-consumo sticky-col"
                        scope="row"
                      >
                        12
                      </td>
                      <!-- <td class="text-center col-volume sticky-col">-</td> -->
                      <td class="text-center col-quantidade-inicial sticky-col">
                        100.001
                      </td>
                      <td class="text-center col-quantidade-final sticky-col">
                        -
                      </td>
                      <td class="text-center col-pessoa-fisica">
                        R$ 9,95
                      </td>
                      <td class="text-center col-pessoa-juridica">
                        R$ 9,95
                      </td>
                      <td class="text-center col-pessoa-fisica-credito">
                        R$ 14,95
                      </td>
                      <td class="text-center col-pessoa-juridica-credito">
                        R$ 19,95
                      </td>
                    </tr>
                  </tbody>
                </TableCorporate>
              </div>
            </template>
          </Card>

          <PrecificacaoDisclaimerContainer class="mb-2">            
            *Faixas não cumulativas, considerando a soma total de qualquer tipo <br><br>
            <PrecificacaoDisclaimer />            
          </PrecificacaoDisclaimerContainer>

          <a @click.prevent="goToElementId('contato-content', 'start')">
            <Button
              class="button mb-md-1"
              btn-style="primary"
            >Contato Comercial</Button>
          </a>

          <Cue
            id-element-to-scroll="beneficios-content"
            img-path="/images/corporate/icon-lib.svg#icon-arrow"
          />
        </div>
      </SectionContent>
    </div>

    <div
      id="beneficios-content"
      class="scroll-margin-top"
    >
      <BenefitsBackgroundCheck />
    </div>

    <SectionContent
      class="bg-light"
      section-color="#FBFBFB"
    >
      <Customers />
    </SectionContent>

    <Investors />

    <ContactBannerBackground
      v-if="!isSpecialistContact"
      ref="contato"
      class="bg-dark"
    />
  </div>
</template>

<script>
import Button from "@/components/buttons/button.vue";
import Cue from "@/components/shared/cue/cue.vue";
import { goToElement } from "@/mixin/go-to-element-mixin.js";
import { sendEventAnalytics } from "@/mixin/send-event-analytics.js";

const Customers = () =>
  import(
    /* webpackChunkName: "Customers" */ "@/components/shared/customers/customers.vue"
  );
const Investors = () =>
  import(
    /* webpackChunkName: "Investors" */
    "@/components/shared/investors/investors.vue"
  );
const Card = () =>
  import(/* webpackChunkName: "Card" */ "@/components/card/card.vue");
const CardIllustration = () =>
  import(
    /* webpackChunkName: "CardIllustration" */ "@/components/card/card-illustration.vue"
  );
const SectionContent = () =>
  import(
    /* webpackChunkName: "SectionContent" */ "@/components/corporate/shared/section/section-content.vue"
  );
const SectionHeroSolutions = () =>
  import(
    /* webpackChunkName: "SectionHeroSolutions" */ "@/components/corporate/shared/section/hero/section-hero-solutions.vue"
  );
const ContactBannerBackground = () =>
  import(
    /* webpackChunkName: "ContactBannerBackground" */ "@/components/corporate/contact/background/contact-banner-background.vue"
  );

const ReportPessoaFisica = () =>
  import(
    /* webpackChunkName: "ReportPessoaFisica" */ "@/components/corporate/report/report-pessoa-fisica.vue"
  );

const ReportPessoaJuridica = () =>
  import(
    /* webpackChunkName: "ReportPessoaJuridica" */ "@/components/corporate/report/report-pessoa-juridica.vue"
  );
const ReportPessoaFisicaCredito = () =>
  import(
    /* webpackChunkName: "ReportPessoaFisicaCredito" */ "@/components/corporate/report/report-pessoa-fisica-credito.vue"
  );
const ReportPessoaJuridicaCredito = () =>
  import(
    /* webpackChunkName: "ReportPessoaJuridicaCredito" */ "@/components/corporate/report/report-pessoa-juridica-credito.vue"
  );
const PessoaFisicaCredito = () =>
  import(
    /* webpackChunkName: "PessoaFisicaCredito" */ "@/components/corporate/report/pessoa-fisica-credito.vue"
  );
const PessoaJuridicaCredito = () =>
  import(
    /* webpackChunkName: "PessoaJuridicaCredito" */ "@/components/corporate/report/pessoa-juridica-credito.vue"
  );

const BenefitsBackgroundCheck = () =>
  import(
    /* webpackChunkName: "BenefitsBackgroundCheck" */ "@/components/corporate/benefits/benefits-background-check.vue"
  );

const TableCorporate = () =>
  import(
    /* webpackChunkName: "TableCorporate" */ "@/components/corporate/table/table-corporate.vue"
  );

const PrecificacaoDisclaimerContainer = () =>
  import(
    /* webpackChunkName: "PrecificacaoDisclaimerContainer" */ "@/components/corporate/precificacao/precificacao-disclaimer-container.vue"
  );
const PrecificacaoDisclaimer = () =>
  import(
    /* webpackChunkName: "PrecificacaoDisclaimer" */ "@/components/corporate/precificacao/precificacao-disclaimer.vue"
  );
const SectionHeroSolutionName = () =>
  import(
    /* webpackChunkName: "SectionHeroSolutionName" */ "@/components/section/hero/section-hero-solution-name.vue"
  );

const Alert = () => import("@/components/misc/alert.vue");

export default {
  components: {
    Button,
    Cue,
    Customers,
    Investors,
    ContactBannerBackground,
    Card,
    CardIllustration,
    SectionContent,
    SectionHeroSolutions,
    ReportPessoaFisica,
    ReportPessoaJuridica,
    ReportPessoaFisicaCredito,
    ReportPessoaJuridicaCredito,
    PessoaFisicaCredito,
    PessoaJuridicaCredito,
    BenefitsBackgroundCheck,
    TableCorporate,
    Alert,
    PrecificacaoDisclaimerContainer,
    SectionHeroSolutionName,
    PrecificacaoDisclaimer,
  },
  mixins: [goToElement, sendEventAnalytics],
  data() {
    return {
      QuodTooltipText: `Somos revendedor autorizado da Quod, bureau de crédito criado a
                partir da união dos cinco maiores bancos em atuação no país para
                impulsionar o Cadastro Positivo no Brasil.`,
      tooltipTextPriceQuodPf: `Valor a ser adicionado no relatório Pessoa Física`,
      tooltipTextPriceQuodPj: `Valor a ser adicionado no relatório Pessoa Jurídica`,
    };
  },
  computed: {
    isSpecialistContact() {
      return this.getRouteBaseName() === "BackgroundCheckContato";
    },
    showIcon() {
      return this.$mq === "xl" || this.$mq === "lg" || this.$mq === "md";
    },
    getTextAmount() {
      return this.$mq === "sm" ? "Qtd." : "Quantidade";
    }
  },
  mounted() {
    if (this.getRouteBaseName() === "BackgroundCheckPrecificacao") {
      setTimeout(() => {
        this.goToElementId("precificacao", "start");
      }, 500);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/screen-size.less";

.contato-hero {
  @media (min-width: @screenExtraLarge) {
    min-height: 724px;
  }
}

#feature-content {
  min-height: 724.1px;
}

#feature {
  background-color: @ColorCorporatePrimary;
  color: @ColorPriWhite;

  .introduction {
    max-width: 660px;
  }
}

.price-container-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: @screenLarge) {
    justify-content: left;
  }
}

.logo-quod {
  margin-left: 1rem;
  margin-bottom: 1rem;
  width: 40px;

  @media (min-width: @screenLarge) {
    width: 49px;
    margin-left: 4rem;
  }
}

.col-faixa-consumo {
  left: 0;
  min-width: 37px;

  @media (min-width: @screenLarge) {
    min-width: 70px;
  }
}

.col-quantidade-inicial {
  left: 37px;
  width: 120px;
  max-width: 120px;
  min-width: 57px;

  @media (min-width: @screenLarge) {
    left: 70px;
    min-width: 120px;
  }
}

.col-quantidade-final {
  left: 94px;
  width: 110px;
  max-width: 110px;
  min-width: 57px;

  @media (min-width: @screenLarge) {
    left: 190px;
    min-width: 110px;
  }
}

.col-volume {
  left: 60px;
  width: 155px;
  max-width: 155px;
  min-width: 100px;

  @media (min-width: @screenLarge) {
    left: 100px;
    min-width: 155px;
  }
}

.col-pessoa-fisica {
  width: 160px;
  max-width: 160px;
  min-width: 105px;

  @media (min-width: @screenLarge) {
    min-width: 160px;
  }
}

.col-pessoa-juridica {
  width: 140px;
  max-width: 140px;
  min-width: 105px;

  @media (min-width: @screenLarge) {
    min-width: 140px;
  }
}

.col-pessoa-juridica-credito,
.col-pessoa-fisica-credito {
  width: 220px;
  max-width: 220px;
  min-width: 160px;

  @media (min-width: @screenLarge) {
    min-width: 200px;
  }
}

.col-pessoa-juridica-credito-consulta,
.col-pessoa-fisica-credito-consulta {
  width: 220px;
  max-width: 220px;
  min-width: 130px;

  @media (min-width: @screenLarge) {
    min-width: 165px;
  }
}

.alert {
  margin-top: 0;
  margin-bottom: 1rem;
}

.tooltip-icon-container {
  .v-popover {
    display: inline-block;
  }

  .icon {
    top: 0.2em;
    position: relative;
    margin-left: 0.125rem;
  }
}

.table-responsive {
  position: relative;
  white-space: nowrap;
}

@media (min-width: @screenLarge) {
  #precificacao {
    min-height: 1113.1px;
  }

  #relatorios-content {
    min-height: 2248px;
  }

  #beneficios-content {
    min-height: 682.1px;
  }
}
</style>
