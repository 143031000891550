<template>
  <button @click="onClick">
    <slot />
  </button>
</template>

<script>
    import base from "@/components/buttons/base.js";

    export default {

        mixins: [base]

    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    @import "base.less";

    button {
        background-color: @ColorPriGrey;
        padding-left: 21px;
        padding-right: 21px;
        height: 56px;
        border-style: none;
        color: @ColorPriWhite;
    }

    button:hover:enabled {
        background-color: @ColorSecBlack;
    }
</style>