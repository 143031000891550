import ContextManangerService from "@/services/service-providers/webapp-services/context-mananger-service.js";

export const impersonateUser = {

    methods: {                
        impersonate: function (companyUID) {
            let self = this;
            //Verificação para o select 
            if (self.localCompanyUID) {
                companyUID = self.localCompanyUID;
            }
            ContextManangerService.impersonate(companyUID)
                .then(function (response) {                        
                    self.$store.commit("UserModule/login", response.data.data);
                    self.$bus.$emit('global-needReloadUserEnvironment');
                    self.setCookieCompanyUid(companyUID);
                    self.$bus.$emit('global-refreshBalance');    
                    // Reload na pagina toda quando o usuario troca para o contexto da Ebanx    
                    if(companyUID == '8cd1aae7-328b-4744-acaf-327e018ad97d') {
                        self.$router.go();
                    }
                        
                })
                .catch(function (error) {
                    self.$bus.$emit('global-notification', error.response.data.message, true);
                });
            this.$emit('closeRequested');
        },
        callShowModalPrice() {
            
            // deveria ser de uma forma melhor
            // o correto seria aguardar o serviço de mudança de contexto executar
            // tempo aumentado para 5 segundos, para evitar que a modal apareça antes do contexto ser alterado
            // não é a solução ideal 
            setTimeout(() => {
                if (this.checkShowModalPrice()) {
                  this.showModalPrice = true;
                }   
            }, "5000"); 
        }

    }
}