<template>
  <button @click="onClick">
    <slot />
  </button>
</template>

<script>
    import base from "@/components/buttons/base.js";

    export default {

        mixins: [base]

    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    @import "base.less";

    button {
        
        border-style: solid;
        color: @ColorPriGrey;
        padding: .6rem 1.1rem;
        border: 2px solid @ColorPriGrey;
        background-color: transparent;
        font-size: 13px;
    }

    button:hover:enabled {
        background-color: darken(@ColorPriYellow, 12%);        
        border: 2px solid darken(@ColorPriYellow, 12%);
    }
</style>