<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        Cadastro de Empresa
      </h5>
      <h5 class="title2">
        Cadastre sua empresa e tenha acesso a consultas e funcionalidades disponíveis exclusivamente para pessoas jurídicas.
      </h5>
            
      <StepIndicatorModal
        v-if="pendencyExecuteQuery !== 'PENDENCY-EMAIL'"
        class="step-modal"
        :step-number="3"
        change-text-for-step-one="Validar E-mail"
        change-text-for-step-two="Completar Cadastro"
        change-text-for-step-three="Cadastrar Empresa"
        :pendency-execute-query="pendencyExecuteQuery"
      />
    </template>

    <template
      v-if="confirmed"
      slot="right"
    >
      <h4 class="header-text">
        {{ message }}
      </h4>
      <p
        v-if="requireDocument"
        class="email-info-paragraph"
      >
        Nosso time fará a verificação das informações fornecidas e retornará o mais breve possível. <br><br> Se tiver alguma dúvida, entre em contato conosco via chat ou pelo email <a href="mailto:fale@exa.to"> fale@exa.to</a>
        <br><br>Enquanto isso, continue navegando no Exato e realizando as consultas disponíveis para pessoas físicas.
      </p>
      <p v-else>
        Agora você poderá realizar consultas e acessar serviços exclusivos para pessoas jurídicas.
      </p>
      <div class="button-box">
        <ButtonLarge
          class="button"
          @click="proceed"
        >
          Continuar
        </ButtonLarge>
      </div>
    </template>

    <template
      v-else
      slot="right"
    >
      <h4 class="header-text">
        Informe os dados da empresa
      </h4>
            
      <div
        v-if="!displayResume"
        class="inputs-box"
      >
        <div class="column-content">
          <InputCNPJ
            id="cnpj"
            v-model="cnpj"
            v-focus
            name="cnpj"
            class="component "
            placeholder="Digite o CNPJ da empresa"
            lazy
          />
          <LoaderLocal 
            v-if="loaderCompany" 
            class="local-loader"
          />

          <p
            v-if="companyName"
            class="display-company"
          >
            Empresa: {{ companyName }}
          </p>
          <p
            v-if="errorMessage"
            class="error-message"
          >
            {{ errorMessage }}
          </p>
        </div>
        <!-- <br/> -->
        <div
          v-show="requireDocument"
          class="column-content"
        >
          <p
            v-show="requireDocument"
            class="display-require-document"
          >
            É necessário enviar o contrato social da empresa em formato pdf, doc ou docx.
          </p>
          <div
            class="upload-component"
            title="Selecione seu arquivo"
          >                        
            <div
              v-if="!filename"
              class="input-container"
            >
              <i class="icon icon-cloud" />
              <a class="space-left-20">Selecione seu arquivo</a>
            </div>                        
            <div
              v-else
              class="input-container"
            >
              <a>{{ filename }}</a>
            </div>
            <input
              id="document"
              ref="fileInput"
              type="file"
              class="input-element"
              multiple
              accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
              @change="uploadFile"
            >
          </div>
        </div>
      </div>

      <div v-else>
        <div class="resume-card">
          <div class="resume-card-header">                    
            <b>{{ companyName }}</b>
            <a
              class="icon-garbage"
              title="Excluir empresa"
              @click="resetEntries"
            >
              <svg class="icon icon-medium icon-trash">
                <use xlink:href="/images/icon-lib.svg#icon-trash" />
              </svg>
            </a>
          </div>
          <p class="text">
            CNPJ: {{ cnpj }}
          </p>
          <p class="text">
            Contrato Social: <span class="text-contract-name">{{ filename }}</span>
          </p>
        </div>
        <p
          v-if="errorMessage"
          class="error-message"
        >
          {{ errorMessage }}
        </p>
      </div>

      <p
        v-if="message"
        class="error-message"
      >
        {{ message }}
      </p>

      <div class="button-box">
        <ButtonLarge
          v-if="companyName"
          class="button"
          :disabled="!canAdvance()"
          @click="validate"
        >
          Continuar
        </ButtonLarge>
      </div>
    </template>
  </ModalFullScreenContent>
</template>

<script>
    import ModalFullscreen from "@/components/boxes/modal-fullscreen.vue";
    import InputCNPJ from "@/components/inputs/input-cnpj.vue";
    import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
    import Validators from "@/utils/validation-helpers.js";
    import ButtonLarge from "@/components/buttons/button-large.vue";
    import QueryService from "@/services/service-providers/webapp-services/query-service.js";
    import ProgressBar from "@/components/misc/progress-bar.vue";
    import ContextManangerService from "@/services/service-providers/webapp-services/context-mananger-service.js";
    import HomeService from "@/services/service-providers/webapp-services/home-service.js";
    import LoaderLocal from "@/components/misc/loader-local.vue";
    import StepIndicatorModal from "@/views/product/step-indicator-modal.vue";
    import { sendEventAnalytics } from "@/mixin/send-event-analytics.js";


    export default {

        components: {
            ModalFullscreen,
            InputCNPJ,
            ButtonLarge,
            ModalFullScreenContent,
            ProgressBar,
            LoaderLocal,
            StepIndicatorModal
        },

        mixins: [sendEventAnalytics],

        props: {

            pendencyExecuteQuery: {
                type: String,
                required: false,
                default: ''
            }
        },

        data: function () {
            return {
                progress: 80,
                companyName: "",
                cnpj: "",
                contract: "",
                requireDocument: false,
                document: null,
                existingCompany: false,
                errorMessage: "",
                message: "",
                filename: null,
                confirmed: false,
                displayResume: false,
                loaderCompany: false
            };
        },
        watch: {
            cnpj: function () {
                this.getCompanyInfo();
            },
        },
        methods: {
            canAdvance(){
                const self = this;
                return self.existingCompany && (!self.requireDocument || self.document) 
            },
            getCompanyInfo() {
                const self = this;

                self.existingCompany = false;
                self.requireDocument = false;
                if (self.cnpj.length < 18) {
                    self.companyName = '';
                    self.errorMessage = '';
                    return;
                }
               
                if (!self.validateCnpj()) {
                    self.companyName = '';
                    return;
                }

                self.loaderCompany = true;

                QueryService.invoke("register/GetCompanyInfo", {cnpj: self.cnpj}).then(function (ret) {
                    self.companyName = ret.data.nomeFantasia ? ret.data.nomeFantasia : ret.data.nomeEmpresarial;
                    self.requireDocument = ret.data.requireDocument;
                    self.existingCompany = true;
                    self.loaderCompany = false;
                }).catch(function (error) {
                    console.log(error.response.data);
                    self.existingCompany = false;
                    self.errorMessage = error.response.data.message;
                    self.loaderCompany = false;
                });
            },
            validateCnpj() {

                

                if (!Validators.validateCNPJ(this.cnpj)) {
                    this.errorMessage = "O CNPJ digitado é inválido.";
                    self.companyName = '';
                    self.hasErrors = true;
                    return false;
                }
                if(!this.hasErrors)
                    this.errorMessage = '';
                return true;
            },
            uploadFile() {
                let self = this;
                let myFile = this.$refs.fileInput.files[0];
                this.filename = myFile.name;
                let reader = new FileReader();
                reader.onloadend = function () {
                    self.document = reader.result;
                };
                reader.readAsDataURL(myFile);
                //reader.readAsBinaryString(myFile);                
                this.displayResume = true;
                this.errorMessage = '';
            },
            resetEntries: function () {
                this.progress = 80;
                this.companyName = "";
                this.cnpj = "";
                this.contract = "";
                this.requireDocument = false;
                this.document = null;
                this.errorMessage = "";
                this.message = "";
                this.filename = null;
                this.confirmed = false;
                this.displayResume = false;
            },
            onFileChange(file) {
                this.document = file;
            },
            validate: function () {
                if (!this.validateCnpj() || this.hasErrors)
                {
                    return;
                }
                var self = this;
                QueryService.invoke('register/registercompany', {
                    cnpj: self.cnpj,
                    binaryString: self.document,
                    fileName: self.filename
                }).then(function (response) {
                    var result = response.data.data;
                    self.errorMessage = !result.isValid ? result.message : "";
                    self.requireDocument = result.requiresDocument;
                    if (result.success) {
                        self.confirmed = true;
                        self.message = result.message;
                        self.progress = 100;

                        // Caso não precise de validação manual, troca para o contexto da empresa cadastrada
                        if(!self.requireDocument){
                            self.impersonate(result.companyUid);
                            self.sendEventAnalytics(
                            'cadastro-empresa-sucesso',
                            'Fluxo',
                            'Cadastro de Empresa com Sucesso',
                            'Cadastro de Empresa com Sucesso'
                            );
                        }
                        // self.$bus.$emit('global-needReloadUserEnvironment');
                        // self.$bus.$emit('global-reloadAccountScreen');
                    }
                    else {
                        self.sendEventAnalytics(
                            'cadastro-empresa-erro',
                            'Fluxo', 
                            'Cadastro de Empresa com Erro',
                            'Cadastro de Empresa com Erro');
                    }
                    if (self.requireDocument) {
                        self.sendEventAnalytics(
                            'cadastro-empresa-avaliacao',
                            'Fluxo', 
                            'Cadastro de Empresa em Avaliação',
                            'Cadastro de Empresa em Avaliação');
                    }
                })
            },
            //TODO: tanto impersonate como refresh balance foram copiados de outros componentes
            // estes caras deveriam ser globais para o contexto da aplicação.
            impersonate: function (localCompanyUID) {
                let self = this;
                ContextManangerService.impersonate(localCompanyUID)
                    .then(function (response) {
                        self.$store.commit("UserModule/login", response.data.data);
                        self.$bus.$emit('global-needReloadUserEnvironment');
                        self.refreshBalance();
                    })
                    .catch(function (error) {
                        self.$bus.$emit('global-notification', error.response.data.message, true);
                    });
            },
            refreshBalance: function () {
                let self = this;
                HomeService.getUserBalance()
                    .then(function (response) {
                        console.log(response.data.data);
                        self.balanceCondition = response.data.data.balanceCondition;
                        self.$store.commit("UserModule/userBalance", response.data.data.balance);
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.$bus.$emit('global-notification', error.response.data.message, true);
                    });
            },

            proceed: function () {
                this.$emit("onboardEnd");
            }

        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/fonts.less";
    @import "../../styles/colors.less";
    @import "../../styles/base-modal.less";

    .resume-card {
        /* width: 515px;
        height: 154px; */
        object-fit: contain;

        padding: 1.5rem;
        margin-top: 2rem;

        /* padding-top: 26px;
        padding-left: 31px;
        padding-right: 31px; */
        border: solid 1px #dfe3e6;
        background-color: #ffffff;
        border-radius: 10px;
    }

    .resume-card {

        .resume-card-header {
            display: flex;
            justify-content: space-between;
        }

        .text {
            padding-top: 25px;
            font-family: Saira;        
            color: #5f5c5c;
            word-wrap: break-word;
        }

        .text-contract-name {
            color:@ColorPriTurquoise;
        }
    }
    

    .display-company {
        margin-top: 10px;
    }

    .display-require-document {
        margin-bottom: 10px;
    }

    a.icon {
        cursor: pointer;
    }

    .icon-garbage {
        margin-left: 1rem;
        color: @ColorPriGrey;

        &:hover {
            color: @ColorPriYellow;
        }
    }

    .icon-cloud {
        content: url("/images/cloud-computing.svg");
        width: 44.3px;
        height: 35px;
    }

    .upload-component {
        min-height: 100%;
        border: 2px dashed @ColorPriTurquoise;
        background: white;
        border-radius: 10px;
    }

    .upload-component {
        height: 80px;
        position: relative;
    }

    .input-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .input-container a {
        font-size: 18px
    }

    .input-element {
        width: 100%;
        height: 100%;
        margin: 0;
        background: antiquewhite;
        opacity: 0;
        cursor: pointer;
    }

    .space-left-20 {
        padding-left: 20px;
    }

    .align-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .font-upload {
        font-size: 18px;
    }

</style>

<style lang="less" scoped>
    @import "../../styles/fonts.less";
    @import "../../styles/colors.less";

    .header-position {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: 0px;
    }

    .upload-component {
        height: 80px;
    }

    .row {
        display: flex;
        margin-top: 50px;
    }

    .row.column-content {
        flex-direction: column;
    }

    .small-description {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 300;        
    }

    .component {
        width: 100%;
    }

    .upload-document {
        width: 100%;
        height: 80px;
    }

    .success-message {
        margin-top: 1rem 0;
        color: @ColorOk;
        font-size: 24px;
    }
    .local-loader {
        margin-top: 1rem;
    }

    .icon-medium {
        width: 20px;
        height: 20px;
        
    }


    .email-info-paragraph{
        margin-top: 2rem;
    }



</style>
