<template>
  <transition name="fade3">
    <ModalFullscreen
      v-if="showModal"
      @closing="closeModal"
    >
      <Login
        v-if="currentStep === 'LOGIN'"
        @loginSuccessful="loginSuccessful"
        @recoverPassRequested="recoverPassword"
        @socialRegistrationRequested="socialRegistration"
        @closeModalRequested="closeModal"
        @twoFactorRequested="twoFactor"
        @socialLoginRequested="socialLogin"
      />
      <transition name="fade3">
        <RecoverPassword v-if="currentStep === 'RECOVER'" />
      </transition>
      <transition name="fade3">
        <RegisterSocialNetwork
          v-if="currentStep === 'SOCIALREG'"
          :social-registration="socialRegistrationData"
          @loginSuccessful="loginSuccessful"
          @closeModalRequested="closeModal"
          @showPrivacyPolicy="closeModal"
          @showTermsConditions="closeModal"
        />
      </transition>
      <transition name="fade3">
        <TwoFactor
          v-if="currentStep === 'TWOFACTOR'"
          :username="username"
          :password="password"
          :token-company-registration="tokenCompanyRegistration"
          :social-login-data="socialLoginData"
          @loginSuccessful="loginSuccessful"
        />
      </transition>
    </ModalFullscreen>
  </transition>
</template>

<script>
import ModalFullscreen from "@/components/boxes/modal-fullscreen.vue";
import Login from "@/views/onboarding/login.vue";
import RecoverPassword from "@/views/onboarding/recover-password.vue";
import RegisterSocialNetwork from "@/views/onboarding/register-social-network.vue";
import RegisterService from "@/services/service-providers/webapp-services/register-service.js";
import TwoFactor from "@/views/onboarding/two-factor.vue";
import { checkReport } from "@/mixin/check-report-mixin.js.js";
import { checkWhatsAppText } from "@/mixin/whatsapp-param-text-mixin.js";

export default {
    components: {
        ModalFullscreen,
        Login,
        RecoverPassword,
        RegisterSocialNetwork,
        TwoFactor,
    },
    mixins: [checkReport, checkWhatsAppText],
    data() {
        return {
            showModal: false,
            currentStep: "LOGIN",
            socialRegistrationData: {},
            socialLoginData: {},
            urlToRedirectWhenLoginSuccessful: "",
            username: "",
            password: "",
            tokenCompanyRegistration: "",
        };
    },
    computed: {
        userAuthenticated() {
            return this.$store.getters["UserModule/userAuthenticated"];
        },
    },
    created() {
        let self = this;
        this.$bus.$on("global-redirectUserAfterLoginSuccessful", function () {
          self.redirectUserAfterLoginSuccessful();
        });
    },
    mounted() {
        if (this.$route.params.validateEmailRequestUID) {
            this.runEmailValidation(this.$route.params.validateEmailRequestUID);
        }
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
        loginSuccessful() {
            //this.showModal = false;

            this.$bus.$emit("global-needReloadUserEnvironment", true, true);
            //this.redirectUserAfterLoginSuccessful();
        },
        twoFactor(username, password, tokenCompanyRegistration) {
            // fluxo duplo fator se necessário
            this.username = username;
            this.password = password;
            if(tokenCompanyRegistration === undefined) {
                this.tokenCompanyRegistration = null;
            } else {
                this.tokenCompanyRegistration = tokenCompanyRegistration;
            }
            this.currentStep = "TWOFACTOR";

            //this.redirectUserAfterLoginSuccessful()
        },
        init(redirectToUrl) {
            this.urlToRedirectWhenLoginSuccessful = redirectToUrl;
            this.currentStep = "LOGIN";
            this.showModal = true;
        },
        recoverPassword() {
            this.currentStep = "RECOVER";
            this.showModal = true;
        },
        socialLogin(value) {
            this.socialLoginData = value;
            this.currentStep = "TWOFACTOR";
        },
        socialRegistration(value) {
            this.socialRegistrationData = value;
            this.currentStep = "SOCIALREG";
            this.showModal = true;
        },
        removeStorageExIdentifyAffiliateKeyId() {
            localStorage.removeItem("exIdentifyAffiliateKeyId");
        },
        redirectWithTypeAndDocument(documentType, inputFields) {
            const externalId = documentType === "CNPJ"
                ? "16d4da51-febf-4303-b19c-91bf0e107238"
                : "e73b8677-effa-4dbe-a7a9-0f40cc2cfd7f"
            this.$router.push(`/confirmar-compra/${externalId}/input/${inputFields}`);
        },
        redirectUserAfterLoginSuccessful() {
            //this.$bus.$emit('global-needReloadUserEnvironment');
            //this.$store.commit("UserModule/emailValidated", true);

            // remove dados do storage
            this.removeAccountJoinedData();
            this.removeParamFromWhatsApp();

            // TODO - avaliar chamar este onboard aqui para evitar que o comprar e home abram sempre
            // movido para a home - havia problemas no recapcha ao abrir completar cadastro
            // const paramFromWhatsApp = this.$store.getters["UserModule/cameFromChatbot"];
            // se parametros do whatsapp chama onboard
            // if (paramFromWhatsApp) {
            //     this.$bus.$emit('global-onboardDepdendsOnUserState');
            // }

            // a URL é passada ao clicar no card
            if (this.urlToRedirectWhenLoginSuccessful) {
                //Redireciona para o primeiro passo da compra da consulta
                this.$router.push(this.urlToRedirectWhenLoginSuccessful);
                this.urlToRedirectWhenLoginSuccessful = "";
            }
            else {
                let externalId = this.$route.params.externalId;
                const inputFields = this.$route.params.inputFields;
                const documentType = this.$route.params.documentType;

                // Chamadas para emissão de relatório: afiliado ou flashcheck
                // A chave do afiliado sendo gravada no storage no index.js (router)
                if (documentType  && inputFields) {
                    this.redirectWithTypeAndDocument(documentType, inputFields);
                } else {
                    // verifica se externalId está como parâmetro. Usada no cadastro do teste. O mesmo parâmetro também está em comprar-produto
                    if (externalId) {
                        // TODO - serviço para identificar a pendência de cadastro
                        if (this.isReportPessoaJuridica(externalId)) {
                            this.$router.push(this.localePath({
                                name: "ComprarProduto",
                                params: { externalId: externalId },
                            }));
                        }
                        else if (this.isReportPessoaFisica(externalId)) {
                            this.$bus.$emit("global-initOnboard", `/comprar-produto-validando-onboard/${externalId}`, "PENDENCY-COMPLETE-REGISTER");
                            this.$router.push("/home");
                        }
                        else {
                            // se não for os externalIds fixados acima
                            this.$router.push("/home");
                        }
                    } else {
                        this.$router.push("/home");
                    }
                }
            }

            this.removeStorageExIdentifyAffiliateKeyId();
            this.setStorageUserLoggedIn();
            this.$bus.$emit("close-modals");
        },
        // usado para verificar se o usuário já fez login
        setStorageUserLoggedIn() {
            localStorage.setItem("exUserLoggedIn", "1");
        },
        runEmailValidation(validateEmailRequestUID) {
            if (!this.userAuthenticated) {
                this.init();
            }

            let self = this;
            RegisterService.validateEmail(validateEmailRequestUID)
                .then(function (response) {
                    if (response.data.data.status === 1) {
                        //self.$bus.$emit('global-notification', "E-mail validado com sucesso!");
                        // self.$store.commit("UserModule/emailValidated");
                        let editarPerfil = "/editar-perfil"
                        self.urlToRedirectWhenLoginSuccessful = response.data.data.redirectToUrl;

                        // Abre o modal se não tiver a URL - URL passada no card
                        if (!self.urlToRedirectWhenLoginSuccessful)  {
                            self.$bus.$emit("global-success-email");
                        }

                        if(self.urlToRedirectWhenLoginSuccessful == editarPerfil)   {
                            self.$bus.$emit("global-notification", "E-mail validado com sucesso.", false);
                        }

                        if (self.userAuthenticated) {
                            self.$bus.$emit("global-needReloadUserEnvironment");
                            self.redirectUserAfterLoginSuccessful();
                        }
                    }
                })
                .catch(function (error) {
                    self.$bus.$emit("global-notification", error.response.data.message, true);
                });
        },
    },
};
</script>

<style lang="less" scoped>
@import "../../styles/animations.less";
</style>
