export const loginGoogle = {
  methods: {
    initializeGoogleGsi() {
      // https://developers.google.com/identity/gsi/web/guides/migration#popup-mode_1
      // https://stackoverflow.com/questions/71757310/sign-in-with-google-gis-on-vue-js-application
      // chamada para o js está no index.html
      let self = this;
      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENTID,
        callback: self.handleGoogleCredentialResponse,
      });
    }
  },
};