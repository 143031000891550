export const goToElement = {

    methods: {

        //https://www.bram.us/2020/03/01/prevent-content-from-being-hidden-underneath-a-fixed-header-by-using-scroll-margin-top/
        
        // position:    "start" | "center" | "end" | "nearest"        
        goToElement(refObj, position) {
            refObj.scrollIntoView({ behavior: "smooth", block: position });            
        },
        goToElementId (id, position){
            document.getElementById(id).scrollIntoView({ behavior: "smooth", block: position});
        }
    }
}