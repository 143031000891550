/**
 * @typedef {import("vue-router").Location} Location
 * @typedef {import("vue-router").RawLocation} RawLocation
 * @typedef {import("vue-router").Route} Route
 * @typedef {import("vue-router").default} Router
 */

import getRouteLocalizedName from "@/i18n/get-route-localized-name";
import defaultLocaleCode from "@/i18n/default-locale-code";
import { withoutTrailingSlash } from "ufo"
import getRouteBaseName from "@/i18n/get-route-base-name";

/**
 * @typedef {ReturnType<import("vue-router").default["resolve"]> | undefined} ResolveRouteReturn
 * @typedef {(router: Router, currentRoute: Route, route: RawLocation, localeCode: string) => ResolveRouteReturn} ResolveRoute
 */

/** @type {ResolveRoute} */
const resolveRoute = (router, currentRoute, route, localeCode) => {
    // Abort if empty route or empty locale code
    if (!route || !localeCode) {
        return
    }

    // If route parameter is a string, check if it's a path or name of route.
    if (typeof route === "string") {
        if (route[0] === "/") {
            // If route parameter is a path, create route object with path.
            route = { path: route }
        } else {
            // Else use it as route name.
            route = { name: route }
        }
    }

    let localizedRoute = Object.assign({}, /** @type {Location} */ route)

    if (localizedRoute.path && !localizedRoute.name) {
        const resolvedRoute = router.resolve(localizedRoute).route
        const resolvedRouteName = getRouteBaseName(resolvedRoute)
        if (resolvedRouteName) {
            localizedRoute = {
                name: getRouteLocalizedName(resolvedRouteName, localeCode),
                params: resolvedRoute.params,
                query: resolvedRoute.query,
                hash: resolvedRoute.hash
            }
        } else {
            const isDefaultLocale = localeCode === defaultLocaleCode
            // if route has a path defined but no name, resolve full route using the path
            const isPrefixed = !isDefaultLocale // don't prefix default locale
            if (isPrefixed) {
                localizedRoute.path = `/${localeCode}${localizedRoute.path}`
            }
            localizedRoute.path = withoutTrailingSlash(localizedRoute.path, true)
        }
    } else {
        if (!localizedRoute.name && !localizedRoute.path) {
            localizedRoute.name = getRouteBaseName(currentRoute)
        }

        localizedRoute.name = getRouteLocalizedName(localizedRoute.name, localeCode)

        const { params } = localizedRoute
        if (params && params["0"] === undefined && params.pathMatch) {
            params["0"] = params.pathMatch
        }
    }

    const resolvedRoute = router.resolve(localizedRoute)
    if (resolvedRoute.route.name) {
        return resolvedRoute
    }

    // If didn't resolve to an existing route then just return resolved route based on original input.
    return router.resolve(route)
}

export default resolveRoute
