// Code adapted from https://github.com/nuxt-modules/i18n/blob/v7.3.1/src/templates/utils-common.js

import localizedRouteNamePattern from "@/i18n/localized-route-name-pattern";
import localizedRoutePathPattern from "@/i18n/localized-route-path-pattern";

/**
 * Extract locale code from given route:
 * - If route has a name, try to extract locale from it
 * - Otherwise, fall back to using the routes' path
 * @param  {import("vue-router").Route} route
 * @return {string} Locale code found if any
 */
const getRouteLocaleCode = (route) => {
  // Extract from route name
  if (route.name) {
    const matches = route.name.match(localizedRouteNamePattern)
    if (matches && matches.length > 1) {
      return matches[1]
    }
  } else if (route.path) {
    // Extract from path
    const matches = route.path.match(localizedRoutePathPattern)
    if (matches && matches.length > 1) {
      return matches[1]
    }
  }
  return ""
}

export default getRouteLocaleCode
