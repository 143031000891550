import Vue from "vue";
import Router from "vue-router";
import VueGtm from "vue-gtm";
import VueScrollTo from "vue-scrollto";
import { AuthenticationContext } from "vue-adal";
import VerificationStatusEnum from "@/constants/verification-status/verification-status-enum";
import $store from "@/state";
import exatoRoutes from "@/router/exato/exato-routes";
import corporateRoutes from "@/router/corporate/corporate-routes";
import sharedRoutes from "@/router/shared/shared-routes";
import bradescoRoutes from "@/router/bradesco/bradesco-routes";
import flashCheckRoutes from "@/router/flashcheck/flashcheck-routes";
import executiveCheckRoutes from "@/router/executivecheck/executivecheck-routes";
import stoneRoutes from "@/router/stone/stone-routes";
import docCheckRoutes from "@/router/doc-check/doc-check-routes";
import i18n from "@/i18n/i18n-instance";
import defaultLocaleCode from "@/i18n/default-locale-code";
import createRouteConfigs from "@/i18n/create-route-configs";
import i18nNavigationGuard from "@/i18n/i18n-navigation-guard";
import localePath from "@/i18n/locale-path";
import RouterRedirectService from "@/services/service-providers/webapp-services/router-redirect-service";
// import IntercomService from "@/services/chat-providers/intercom-service";
import CookieHelpers from "@/utils/cookie-helpers";

Vue.use(Router);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 400,
  easing: "ease-out",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

// Se existir um token de usuário logado, seta o estado no UserModule
// Chamado toda vez quando é feito por exemplo um refresh na página F5
const token = CookieHelpers.getCookie("token");
if (token) {
  $store.commit("UserModule/login", token);
}

const router = new Router({
  mode: "history",
  routes: [
    ...exatoRoutes,
    ...corporateRoutes,
    ...sharedRoutes,
    ...bradescoRoutes,
    ...flashCheckRoutes,
    ...executiveCheckRoutes,
    ...stoneRoutes,
    ...docCheckRoutes,

    // Always use non-prefixed paths for the default locale
    {
      path: `/${defaultLocaleCode}/:nonPrefixedPath*`, redirect: (to) => {
        const nonPrefixedPath = to.params.nonPrefixedPath
        if (nonPrefixedPath) {
          return to.params.nonPrefixedPath
        }
        return "/"
      }
    },

    {
      path: "/maintenance",
      name: "Maintenance",
      meta: { 
        title: "Estamos em manutenção | Exato",
        robots: "noindex" 
      },
      component: () =>
        import(/* webpackChunkName: "Maintenance" */ "@/views/maintenance/maintenance-page.vue"),
    },

    // Catch all / 404 routes
    ...createRouteConfigs({
      baseConfig: {
        path: "*",
        name: "NotFound404",
        component: () => import(
                    /* webpackChunkName: "PageNotFound" */ "@/views/PageNotFound.vue"
        ),
        meta: { requiresAuth: false }
      },
      localeConfigs: [
        { code: "en-US", meta: { title: "Page not found | Exato" } },
        { code: "pt-BR", meta: { title: "Página não encontrada | Exato" } },
      ],
    }),

    /* {
        path: "/confirmar-compra-lote/:productExternalId/arquivo/:batchFileId",
        name: "BusinessConsulting",
        component: ConfirmBuyBatch,
        meta: { requiresAuth: true },
        props: {
            productExternalId: {
                type: String,
                required: true
            },
            batchFileId: {
                type: String,
                required: true
            }
        }
    }, */

    /* {
        path: "/batch-query-cancellation",
        name: "BatchQueryCancellation",
        component: BatchQueryCancellation,
        meta: { requiresAuth: false }
    }, */
    /*         {
                path: "/adicionar-usuario-empresa/:email?/:userUid?",
                name: "AddNewUser",
                component: AddNewUser,
                meta: { requiresAuth: false },
                props: {
                    email: {
                        type: Number,
                        required: false
                    },
                    userUid: {
                        type: Number,
                        required: false
                    }
                }
            }, */
    /* {
        path: "/usuarios-vinculados-empresa",
        name: "UserLinkedToTheCompany",
        component: UserLinkedToTheCompany,
        meta: { requiresAuth: false }
    }, */
    /* {
        path: "/add-new-phone-verification-sms",
        name: "AddNewPhoneVerificationSms",
        component: AddNewPhoneVerificationSms,
        meta: { requiresAuth: false }
    }, */
    /* {
        path: "/add-new-phone",
        name: "AddNewPhone",
        component: AddNewPhone,
        meta: { requiresAuth: false }
    }, */
    /*         {
                path: "/add-new-email",
                name: "AddNewEmail",
                component: AddNewEmail,
                meta: { requiresAuth: false }
            }, */
    /* {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: { requiresAuth: false }
    }, */
    /* {
        path: "/add-new-tax-address",
        name: "AddNewTaxAddress",
        component: AddNewTaxAddress,
        meta: { requiresAuth: false }
    }, */

    /*         {
                path: "/editar-perfil-empresa",
                name: "EditProfileCompany",
                component: EditProfileCompany,
                meta: { requiresAuth: false }
            }, */

    /* {
        path: "/buscar/:query",
        name: "Result",
        component: Result,
        meta: { requiresAuth: false },
        props: {
            query: {
                type: String,
                required: true
            }
        }
    }, */
    /* {
        path: "/chave-acesso/:accessKeyId?",
        name: "AccessKey",
        component: AccessKey,
        meta: { requiresAuth: true },
        props: {
            accessKeyId: {
                type: String,
                required: false
            }
        }
    }, */

    /* {
        path: "/entidade-busca/:entityId",
        name: "ExibirOpcoes",
        component: EntityResult,
        meta: { requiresAuth: false },
        props: {
            entityId: {
                type: String,
                required: true
            }
        }
    }, */


    /* {
        path: "/diretorio-empresas",
        name: "LegalPersonOpen",
        component: LegalPersonOpen,
        meta: { requiresAuth: false }
    }, */
    /* {
        path: "/diretorio-empresas/:uf",
        name: "LegalPersonByUf",
        component: LegalPersonByUf,
        meta: { requiresAuth: false },
        props: {
            uf: {
                type: String,
                required: true
            }
        }
    }, */

  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {

      // parece que o scrollTo não encontra o id para fazer o scroll
      // acompanhar para ver se o timeout resolve
      // https://stackoverflow.com/a/64654535/18956116
      setTimeout(() => {
        this.app.$scrollTo(to.hash);
      }, 500);

      return {
        selector: to.hash,
        // avaliar comportamento
        behavior: "smooth"
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      //When the route changes, the page should scroll back to the top.
      this.app.$scrollTo("#app");
      return { x: 0, y: 0 }
    }
  }
});


//
// Gerenciamento de i18n
//

router.beforeEach(
  (to, from, next) => i18nNavigationGuard(router, to, from, next)
);


//
// Gerenciamento de SEO
//

router.beforeEach((to, _from, next) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  const DEFAULT_TITLE = "Consulte pessoas e empresas em até 5 minutos | Exato";
  const DEFAULT_DESCRIPTION = "Simplifique o background check para verificar seus clientes, fornecedores, colaboradores e candidatos de maneira rápida e acessível. Teste grátis!";
  const DEFAULT_ROBOTS = "index"
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
    document.querySelector('meta[name="description"]').setAttribute("content", to.meta.description || DEFAULT_DESCRIPTION);
    document.querySelector('meta[name="robots"]').setAttribute("content", to.meta.robots || DEFAULT_ROBOTS);
  });
  next();
});


//
// Gerenciamento de scroll
//

router.beforeEach((to, from, next) => {
  if (to.hash && to.path !== from.path) {
    Vue.nextTick(() => {
      VueScrollTo.scrollTo(to.hash)
    });
  }
  next();
});


//
// Gerenciamento de afiliados
//

function isAffiliated(toParams) {
  return toParams.exIdentifyAffiliateKeyId;
}
function setStorageExIdentifyAffiliateKeyId(exIdentifyAffiliateKeyId) {
  localStorage.setItem("exIdentifyAffiliateKeyId", exIdentifyAffiliateKeyId);
}
router.beforeEach((to, from, next) => {
  if (isAffiliated(to.params)) {
    setStorageExIdentifyAffiliateKeyId(to.params.exIdentifyAffiliateKeyId);
  }
  next();
});


//
// Gerenciamento de autenticação e autorização, redirecionamento para última
// página autenticada que usuário tentou acessar, unificação de cadastros vindos
// do WhatsApp e modo manutenção
//

let entryUrl = null;
function saveTargetUrl(to) {
  entryUrl = to.path;
}

function isWhatsAppParams(toQuery) {
  return toQuery.user_uid || toQuery.organization_uid;
}
function setStorageWhatsAppParams(
  accountUserExternalId,
  accountOrganizationExternalId
) {
  const whatsAppParams = {};
  whatsAppParams.accountUserExternalId = accountUserExternalId;
  whatsAppParams.accountOrganizationExternalId = accountOrganizationExternalId;
  sessionStorage.setItem("whatsAppParams", JSON.stringify(whatsAppParams));
}

function logout() {
  $store.commit("UserModule/logout");
  // IntercomService.reset();
  if (AuthenticationContext != null) {
    AuthenticationContext.logout();
  }
}

router.beforeEach(function (to, from, next) {
  // if in maintenance mode, redirect to maintenance page
  if (
    process.env.VUE_APP_MAINTENANCE === "true" &&
    to.path !== "/maintenance"
  ) {
    next("/maintenance");
  } else {
    //require authentication
    if (to.meta.requiresAuth) {
      //user is authenticated
      if ($store.getters["UserModule/userAuthenticated"]) {
        // se veio do whatsapp o usuário precisa passar no login ou cadastro
        // se logado e houver parametros do whatsapp: guarda parâmetros e
        // desloga
        if (isWhatsAppParams(to.query)) {
          setStorageWhatsAppParams(
            to.query.user_uid,
            to.query.organization_uid
          );
          saveTargetUrl(to);
          logout();
          next(localePath(router, "Login", i18n.locale))
        }
        // rota exige claim, mas pessoa não possui nenhuma ou não possui a claim
        // necessária
        else if (
          to.meta.extraClaims != null &&
          !$store.getters["UserModule/extraClaims"].includes(
            to.meta.extraClaims
          )
        ) {
          next("/home");
        }
        // verificar se há url guardada e direciona se necessário
        else if (entryUrl) {
          const url = entryUrl;
          entryUrl = null;
          next(url); // goto stored url
        }
        // permitir acesso à rota
        else {
          next(); // all is fine
        }
      }
      // user isn't authenticated
      else {
        //user came from external place or typing the url in browser
        saveTargetUrl(to);
        if (entryUrl.includes("/relatorio/")) {
          let x = entryUrl.split("/");
          let relatorioUID = x.slice(2).join("/").trim();
          next({
            path: "login/ad",
            query: { relatorioUID: relatorioUID },
          });
        }
        else {
          //////////////
          // Anderson - sem cadastro completo
          // https://localhost:1337/completar-cadastro?user_uid=226d077e-719c-418b-b6ed-4fbbe43a5b0d&organization_uid=f9ee6205-489a-41e7-9248-fb77cb498f20
  
          // https://localhost:1337/comprar-credito?user_uid=226d077e-719c-418b-b6ed-4fbbe43a5b0d&organization_uid=f9ee6205-489a-41e7-9248-fb77cb498f20
  
          ////////////
  
          // parâmentro nos links whatsapp
          // verificar se ainda não foi feita a unificação
          if (isWhatsAppParams(to.query)) {
            RouterRedirectService.verifyUserIsMerged(
              to.query.user_uid,
              to.query.organization_uid
            )
              .then(function (response) {
                setStorageWhatsAppParams(
                  to.query.user_uid,
                  to.query.organization_uid
                );
                sessionStorage.setItem(
                  "whatsAppAccountJoined",
                  JSON.stringify(response.data)
                );
                sessionStorage.setItem(
                  "userMainEmail",
                  response.data.accountEmail
                );
                if (response.data.accountExists) {
                  next(localePath(router, "Login", i18n.locale))
                } else {
                  next(localePath(router, "Cadastro", i18n.locale));
                }
              })
              .catch(function (error) {
                console.log(error)
                console.error(error.response.data.message);
                next(localePath(router, "Login", i18n.locale))
              })
          } else {
            next(localePath(router, "Login", i18n.locale))
          }
          // next("/login");
        }
        // next({ path: "login", query: { entryUrl: entryUrl } });
        // router.app.$bus.$emit("global-login"); //show the login page
  
        // if (from.name == null)
        //     next("/"); //redirect user to landing page
        // //user came from any page
        // else {
        //     next(false); //stop the navigation
        //     router.app.$bus.$emit("global-login"); //show the login page
        // }
      }
    }
    //do not require authentication
    else {
      next();//let the user be happy
    }
  }
});


//
// Redirecionamento para áreas Bradesco e DocCheck ao entrar na área logada
//

router.beforeResolve((to, _from, next) => {
  // Aplicar redirecionamento apenas na Home
  if (to.name === "Home") {
    // Verificar existência de extra claims
    if ($store.getters["UserModule/hasExtraClaims"]) {
      // Redirecionar para área Bradesco apenas se não tiver claim da Exato
      if (
        !$store.getters["UserModule/hasExatoClaim"] &&
        $store.getters["UserModule/hasBradescoClaim"]
      ) {
        next("/bradesco/vagas");
      }
      // Redirecionar para área DocCheck apenas se não tiver claim da Exato
      else if (
        !$store.getters["UserModule/hasExatoClaim"] &&
        $store.getters["UserModule/hasDocCheckClaim"]
      ) {
        next(localePath(router, {
          name: "DocCheckVerification",
          query: {
            status: VerificationStatusEnum.ManualReview,
          },
        }, i18n.locale));
      }
      // Claim não reconhecida ou tem a claim da Exato: permite o acesso
      else {
        next();
      }
    }
    // Usuário sem extra claims: permite o acesso
    else {
      next();
    }
  }
  // Seguir em frente se não for a Home
  else {
    next();
  }
});


//
// Gerenciamento de analytics
//

Vue.use(VueGtm, {
  //id: "GTM-KL37B4K", // Your GTM single container ID or array of container ids ["GTM-xxxxxxx", "GTM-yyyyyyy"]
  id: "GTM-KL37B4K", // Your GTM single container ID or array of container ids ["GTM-xxxxxxx", "GTM-yyyyyyy"]
  /*     queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
        gtm_auth:"AB7cDEf3GHIjkl-MnOP8qr",
        gtm_preview:"env-4",
        gtm_cookies_win:"x"
      }, */
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ["homepage"] // If router, you can exclude some routes name (case insensitive) (optional)
});


export default router;
