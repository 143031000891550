import httpRequest from '@/services/service-providers/httpRequest-service.js';

class TwoFactorAuthenticationService {

    sendVerificationCode(email, type) {
        return httpRequest.axios.post('TwoFactorAuthentication/SendVerificationCode', 
        {
            Email: email,
            Type: type
        },
        );
    }

    checkVerificationCode(phoneOrEmail, code, type) {
        return httpRequest.axios.post('TwoFactorAuthentication/CheckVerificationCode',
        {
            PhoneOrEmail: phoneOrEmail,
            Code: code,
            Type: type
        });
    }
}

export default new TwoFactorAuthenticationService();
