/**
 * Code adapted from the following sources:
 * https://github.com/nuxt-modules/i18n/blob/v7.3.1/src/templates/plugin.main.js#L250
 * */

/**
 * @typedef {import("vue-router").Route} Route
 */

import redirectStrategy from "@/i18n/redirect-strategy";
import redirectStrategyOptions from "@/i18n/redirect-strategy-options";
import shouldAlwaysRedirectToTranslatedRoute from "@/i18n/should-always-redirect-to-translated-route";
import localizedRoutePathPattern from "@/i18n/localized-route-path-pattern";
import shouldUseLocaleCodeInLocalStorage from "@/i18n/should-use-locale-code-in-local-storage";
import getLocaleCodeInLocalStorage from "@/i18n/get-locale-code-in-local-storage";
import getBrowserLocaleCode from "@/i18n/get-browser-locale-code";
import i18n from "@/i18n/i18n-instance";

/**
 * @typedef {(to: Route) => string} DetectBrowserLocaleCode
 */

/** @type {DetectBrowserLocaleCode} */
const detectBrowserLocaleCode = (to) => {
  if (redirectStrategy === redirectStrategyOptions.root) {
    if (to.path !== "/") {
      return ""
    }
  } else if (redirectStrategy === redirectStrategyOptions.noPrefix) {
    if (!shouldAlwaysRedirectToTranslatedRoute && to.path.match(localizedRoutePathPattern)) {
      return ""
    }
  }

  let matchedLocaleCode

  if (shouldUseLocaleCodeInLocalStorage && (matchedLocaleCode = getLocaleCodeInLocalStorage())) {
    // Get preferred language from local storage if present and enabled
  } else {
    // Try to get locale from navigator detection
    matchedLocaleCode = getBrowserLocaleCode()
  }

  const finalLocaleCode = matchedLocaleCode

  // Handle local storage option to prevent multiple redirections
  if (finalLocaleCode && (!shouldUseLocaleCodeInLocalStorage || shouldAlwaysRedirectToTranslatedRoute || !getLocaleCodeInLocalStorage())) {
    if (finalLocaleCode !== i18n.locale) {
      return finalLocaleCode
    }
  }

  return ""
}

export default detectBrowserLocaleCode
