<template>  
  <div
    class="alert"
    :class="[
      smallText ? 'small-text' : null,
      smallIcon ? 'small-icon' : null,
      topIcon ? 'top-icon' : null,
      getAlertType.class
    ]"
  >
    <div
      v-if="showIcon"
      class="alert-icon"
    >
      <svg
        class="icon icon-big"
        :class="loading
          ? 'icon-loading rotate'
          : iconName != null
            ? iconName
            : getAlertType.icon
        "
      >
        <use
          :xlink:href="`${iconPath}#${loading
            ? 'icon-loading'
            : iconName != null
              ? iconName
              : getAlertType.icon
          }`"
        />
      </svg>
    </div>
    <div>
      <span v-html="text" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alertType: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    showIcon: {
      type: Boolean,      
      default: true,
    },
    smallText: {
      type: Boolean,
      default: false,
    },
    iconPath: {
      type: String,
      default: "/images/icon-lib.svg",
    },
    iconName: {
      type: String,
      required: false,
      default: null,
    },
    smallIcon: {
      type: Boolean,      
      default: false,
    },
    topIcon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      alertTypes: [
        {
          type: "info",
          class: "alert-info",
          icon: "icon-notification-info",
        },
        {
          type: "danger",
          class: "alert-danger",
          icon: "icon-notification-error",
        },
        {
          type: "success",
          class: "alert-success",
          icon: "icon-notification-check",
        },
        {
          type: "light",
          class: "alert-light",
          icon: "icon-notification-info",
        },
        {
          type: "warning",
          class: "alert-warning",
          icon: "icon-notification-alert",
        },
      ],
    };
  },
  computed: {
    getAlertType() {
      if (!this.alertType) {
        return "";
      }
      let alertType = this.alertType;
      return this.alertTypes.find(function (alert) {
        return alert.type == alertType;
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "../../styles/colors.less";

.alert {
  position: relative;
  display: flex;
  align-items: center;
  margin: 2rem 0;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-align: left;

  font-size: 0.875rem;

  @media (min-width: 992px) {
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }

  .icon {
    display: block;
  }
}

.alert-icon {
  margin-right: 0.75rem;

  @media (min-width: 992px) {
    margin-right: 1.25rem;
  }
}

.alert-success {
  /* color: @success-900;
    background-color: #d4edda;     */
  color: @success-900;
  background-color: @success-100;

  .alert-icon {
    color: @success-500;
  }
}

.alert-danger {
  /*     color: @danger-900;
    background-color: #f8d7da;    */
  color: @danger-900;
  background-color: @danger-100;

  .alert-icon {
    color: @danger-500;
  }
}

.alert-warning {
  color: @warning-900;
  background-color: @warning-100;

  .alert-icon {
    color: @warning-500;
  }
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;

  .alert-icon {
    color: @blueExato;
  }

  a {
    color: #0c5460;

    &:hover {
      opacity: 0.7;
    }
  }

}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-light {
  color: @ColorPriGrey;
  background-color: @light100;

  .alert-icon {
    color: #818182;
  }
}

.alert-warning {
  color: @warning-900;
  background-color: @warning-100;

  .alert-icon {
    color: @warning-500;
  }
}

.small-text {
  font-size: 0.75rem;
}

.small-icon {
  .alert-icon {
    margin-right: .75rem
  }

  .icon {
    width: 1.25em;
    height: 1.25em;
  }
}

.top-icon {
  align-items: flex-start;

  .alert-icon {
    margin-top: 0.125rem;
  }
}
</style>
