import httpRequest from '@/services/service-providers/httpRequest-service.js';

class QueryService {

    invoke(url, data, config) {
        return httpRequest.axios.post(url, data, config);
    }

    get(url, data) {
        return httpRequest.axios.get(url, data);
    }
}

export default new QueryService();
