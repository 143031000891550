<template>
  <div role="dialog">
    <div
      ref="modal"
      class="modal"
    >
      <section class="modal-body">
        <span
          class="btn-close"
          aria-label="Fechar"
          @click="close"
        >x</span>
        <h3>{{ modalTitle }}</h3>
        <p v-if="text">
          {{ text }}
        </p>
        <div v-if="buttons != null && buttons.button1">
          <p class="modal-buttons">                        
            <ButtonLarge
              class="button-yes"
              @click="close"
            >
              OK
            </ButtonLarge>
          </p>
        </div>
        <div v-else>
          <p class="modal-buttons">
            <ButtonRegularDark
              class="button-no"
              @click="close"
            >
              Não
            </ButtonRegularDark>
            <ButtonLarge
              class="button-yes"
              @click="confirm"
            >
              Sim
            </ButtonLarge>
          </p>
        </div>
        <slot />
      </section>
    </div>
  </div>
</template>

<script>
    import ButtonRegularDark from '@/components/buttons/button-regular-dark.vue';
    import ButtonLarge from "@/components/buttons/button-large.vue";

    export default {

        components: {
            ButtonLarge,
            ButtonRegularDark
        },

        props: {
            modalTitle: {
                type: String,
                required: true
            },
             text: {
                type: String,
                required: false
            },
            buttons: {
                type: Object,
                required: false
            }
        },

        mounted() {

            let self = this;
            window.addEventListener('keyup', function(event) {            
            if (event.keyCode === 27) { 
                 self.$emit("closing");
                }
            });
        },

        methods: {
            close: function () {
                this.$emit("closing");
            },
            confirm: function () {
                this.$emit("confirming");
            }
        }

    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    .modal {
        background: #ffffff;
        box-shadow: 2px 2px 20px 1px #5f5c5c;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        z-index: 10;
        text-align: center;
        max-width: 70%;
        min-width: 480px;

        color: #5f5c5c;
    }

    h3{
        margin-bottom: 1rem;
    }
        
    .modal-body {
        position: relative;
        padding: 20px;
    }

    .modal-buttons{
        padding:2rem 1rem 0 1rem;
    }

    .btn-close {
        border: none;
        font-size: 15px;
        float: right;
        cursor: pointer;
        font-weight: bold;
        color: @ColorPriGrey;
        background: transparent;
        text-align: right;
        padding-right:10px;

        display: none;
    }


    .button-yes {
        width: 150px;
        margin-left: 10px;
    }

    .button-no {
        width: 150px;
        margin-right: 10px;
    }


</style>