/**
 * Code adapted from the following sources:
 * https://github.com/nuxt-modules/i18n/blob/v7.3.1/src/templates/utils-common.js#L171
 */

import shouldUseLocaleCodeInLocalStorage from "@/i18n/should-use-locale-code-in-local-storage";
import localeCodes from "@/i18n/locale-codes";

/**
 * @typedef {() => string | undefined} GetLocaleCodeInLocalStorage
 */

/** @type {GetLocaleCodeInLocalStorage} */
const getLocaleCodeInLocalStorage = () => {
  if (shouldUseLocaleCodeInLocalStorage) {
    const localeCode = window.localStorage.getItem("exI18nRedirected")

    if (localeCode && localeCodes.includes(localeCode)) {
      return localeCode
    }
  }
}

export default getLocaleCodeInLocalStorage
