/**
 * Code adapted from the following sources:
 * https://github.com/nuxt-modules/i18n/blob/v7.3.1/src/templates/plugin.main.js#L66
 * */

/**
 * @typedef {import("vue-router").default} Router
 * @typedef {import("vue-router").NavigationGuard} NavigationGuard
 * @typedef {import("./types").LocaleConfig} LocaleConfig
 */

import i18n from "./i18n-instance"
import getRouteLocaleCode from "./get-route-locale-code"
import Vue from "vue";
import {klona} from "klona/full";
import getLocaleConfig from "@/i18n/get-locale-config";
import setLocaleCodeInLocalStorage from "@/i18n/set-locale-code-in-local-storage";
import shouldUseLocaleCodeInLocalStorage from "@/i18n/should-use-locale-code-in-local-storage";
import detectBrowserLocaleCode from "@/i18n/detect-browser-locale-code";
import localePath from "@/i18n/locale-path";
import {isEqual} from "ufo";

/**
 * @typedef {(
 *   router: Router,
 *   to: Parameters<NavigationGuard>[0],
 *   from: Parameters<NavigationGuard>[1],
 *   next: Parameters<NavigationGuard>[2]
 * ) => void} I18nNavigationGuard
 */

/** @type {I18nNavigationGuard} */
const i18nNavigationGuard = (router, to, from, next) => {
  /**
   * The next route's locale code may be empty when:
   * 1. the route does not have neither a name, nor a locale code prefix in its path (unsupported scenario);
   * 2. the route does not have a name and has an unsupported locale code prefix in its path (unsupported scenario);
   * 3. the route has a name, but it does not have a locale code suffix (unsupported scenario);
   * 4. the route has a name, but it has an unsupported locale code suffix (unsupported scenario).
   *
   * When it happens, fallback to the current active locale.
   */
  const toRouteLocaleCode = getRouteLocaleCode(to) || i18n.locale

  // Retrieve possible new locale code from all possible sources
  const newLocaleCode = detectBrowserLocaleCode(to) || toRouteLocaleCode

  // Check if locale code changed
  const oldLocaleCode = i18n.locale
  if (newLocaleCode === oldLocaleCode) {
    next()
    return
  }

  if (shouldUseLocaleCodeInLocalStorage) {
    setLocaleCodeInLocalStorage(newLocaleCode)
  }

  // Set new app locale code
  i18n.locale = newLocaleCode

  // Set properties for new locale
  /** @type {LocaleConfig} */
  const newLocaleConfig = getLocaleConfig(newLocaleCode)
  // In case certain locale has more properties than another, reset all the properties
  for (const key of Object.keys(i18n.localeConfig)) {
    i18n.localeConfig[key] = undefined
  }
  // Copy properties of the new locale
  for (const [key, value] of Object.entries(newLocaleConfig)) {
    Vue.set(i18n.localeConfig, key, klona(value))
  }

  // Skip if already on the correct route for the detected browser locale code
  if (toRouteLocaleCode !== newLocaleCode) {
    // Attempt to find translated route for the detected browser locale code using the next route's full path
    const path = localePath(router, to.fullPath, newLocaleCode)
    if (path && !isEqual(path, to.fullPath) && !path.startsWith("//")) {
      next(path)
      return
    }
  }

  next()
}

export default i18nNavigationGuard
