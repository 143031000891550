const loadingModule = {
    namespaced: true,

    state: {
        isLoading: 0
    },

    mutations: {

        startLoading: function (state) {
            state.isLoading++;
        },

        endLoading: function (state) {
            if (state.isLoading > 0)
                state.isLoading--;
        }

    },

    getters: {
        isLoading: function (state) {
            return state.isLoading ? true : false;
        }
    }
};

export default loadingModule;