/**
 * @typedef {import("./types").LocaleConfig} LocaleConfig
 */

import localeConfigs from "@/i18n/locale-configs";

/**
 * @typedef {(localeCode: string) => LocaleConfig | undefined} GetLocaleConfig
 */

/** @type {GetLocaleConfig} */
const getLocaleConfig = (localeCode) => {
  return localeConfigs.find((localeConfig) => localeConfig.code === localeCode)
}

export default getLocaleConfig
