const HomeFlashCheck = () =>
  import(
    /* webpackChunkName: "HomeFlashCheck" */
    "@/views/flashcheck/home-flashcheck.vue"
  );

const routes = [
  {
    path: "/flashcheck",
    name: "HomeFlashCheck",
    meta: {
      requiresAuth: false,
      title: "Soluções para Background Check, KYC e Analytics | Exato Corporate",
    },
    component: HomeFlashCheck,
  },
  {
    path: "/flashcheck/experimente",
    name: "FlashcheckExperimente",
    meta: {
      requiresAuth: false,
      title: "Soluções para Background Check, KYC e Analytics | Exato Corporate",
    },
    component: HomeFlashCheck,
  },
];

export default routes;
