export const qrCodeUrl = {
  methods: {
    getUrlQrCode: function (pageName) {
      const domain = "https://exa.to/";
      const pages = [
        {
          name: "LandingWhatsAppPerformance",
          path: "lp",
        },
        {
          name: "LandingWhatsAppBranding",
          path: "lb",
        },
        {
          name: "LandingWhatsAppVenture",
          path: "lv",
        },
        {
          name: "LandingWhatsAppMetro",
          path: "lm",
        },
        {
          name: "LandingWhatsAppMetroPerformance",
          path: "lr",
        },
        {
          name: "LandingWhatsAppMetroPerformanceAntiFraude",
          path: "lr",
        },
      ];

      let objPage = pages.find((page) => page.name === pageName);
      return objPage
        ? `${domain}${objPage.path}`
        : `${domain}q`
    },
  },
}
