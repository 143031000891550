<template>
  <input
    type="text"
    :value="value"
    @paste="onCpfPaste"
    @keyup="updateSelf($event.target.value)"
  >
</template>

<script>
    import Cleave from 'cleave.js';
    import base from "@/components/inputs/base.js";

    export default {

        mixins: [base],

        mounted: function () {
            new Cleave(this.$el, {
                delimiters: [".", ".", "-"],
                blocks: [3, 3, 3, 2],
                numericOnly: true
            });
        },
        
        methods: {
            onCpfPaste: function (evt) {
                let cpf = (evt.clipboardData || window.clipboardData).getData('text');
                var cpfOnlyNumbers = cpf.replace(/\D+/g, '');
                cpfOnlyNumbers = cpfOnlyNumbers.padStart(11, '0');
                this.$emit('input', this.formatCpf(cpfOnlyNumbers));
            },

            formatCpf: function (cpf) {
                let firstBlock = cpf.substring(0, 3);
                let secondBlock = cpf.substring(3, 6);
                let thirdBlock = cpf.substring(6, 9);
                let forthBlock = cpf.substring(9, 11);
                
                return firstBlock + "." + secondBlock + "." + thirdBlock + "-" + forthBlock;        
            }
        }

    }
</script>

<style lang="less" scoped>
    @import "base.less";
</style>