const routes = [
  {
    path: "/executivecheck",
    name: "ExecutiveCheck",
    meta: {
      requiresAuth: false,
      title:
        "Verifique candidatos e contrate os melhores talentos | ExecutiveCheck",
    },
    component: () => import(
      /* webpackChunkName: "ExecutiveCheck" */
      "@/views/executivecheck/home-executivecheck.vue"
    ),
  },
];

export default routes;
