import httpRequest from '@/services/service-providers/httpRequest-service.js';

class ProductService {

    listSources() {
        return httpRequest.axios.get('Product/ListSources');
    }

    listQueryTargets() {
        return httpRequest.axios.get('Product/ListQueryTargets');
    }

    searchProducts(queryType, sourceId, orderBy, limitResult) {
        return httpRequest.axios.get('Product/SearchProducts', {
            params: {
                queryType: queryType,
                sourceId: sourceId,
                orderBy: orderBy,
                limitResult: limitResult
            },
            supressTokenRenew: true
        });
    }

    detailProduct(externalId, language) {
        return httpRequest.axios.get('Product/DetailProduct', {
            params: {
                externalId: externalId,
                language: language
            }
        });
    }

    checkProductAvailability(externalId) {
        return httpRequest.axios.get('Product/CheckProductAvailability', {
            params: {
                externalId: externalId
            }
        });
    }

    confirmBuyDetails(externalId, entityId) {
        return httpRequest.axios.get('Product/ConfirmBuyDetails', {
            params: {
                externalId: externalId,
                entityId: entityId
            }
        });
    }

    getPopularProducts() {
        return httpRequest.axios.get('Product/GetPopularProducts', {
            supressTokenRenew: true
        });
    }

    getRelatedProducts() {
        return httpRequest.axios.get('Product/GetRelatedProducts');
    }

    consumeProduct(externalId, parameters) {
        return httpRequest.axios.post('Product/ConsumeProduct', {
            externalId: externalId,
            parameters: parameters            
        });
    }

    verifyStatusConsumedProduct(externalId, uniqueIdentifier) {
        return httpRequest.axios.get('Product/VerifyStatusConsumedProduct', {
            params: {
                externalId: externalId,
                uniqueIdentifier: uniqueIdentifier
            }
        });
    }

    getPurchaseDetails(externalId, uniqueIdentifier) {
        return httpRequest.axios.get('Product/GetPurchaseDetails', {
            params: {
                externalId: externalId,
                uniqueIdentifier: uniqueIdentifier
            }
        });
    }

    addProductAutoConfirmation(externalId) {
        return httpRequest.axios.post('Product/AddProductAutoConfirmation', null, {
            params: {
                externalId: externalId
            }
        });
    }
}

export default new ProductService();
