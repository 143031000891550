<template>
  <select
    :value="value"
    @change="updateSelf($event.target.value); $emit('change');"
    @blur="compressWidth"
    @focus="restoreWidth"
  >
    <option
      v-for="(item, index) in dataSource"
      :key="index"
      :value="item.value"
      :selected="item.value == value"
    >
      {{ item.text }}
    </option>
  </select>
</template>

<script>
    import base from "@/components/inputs/base.js";

    export default {

        mixins: [base],

        props: {
            dataSource: {},
            autoWidth: false
        },

        watch: {
            dataSource: function () {
                let self = this;
                window.setTimeout(function () {
                    self.compressWidth();
                }, 0);
            },

            value: function () {
                let self = this;
                window.setTimeout(function () {
                    self.restoreAndCompress();
                }, 0);
            }
        },

        mounted: function () {
            let self = this;
            window.setTimeout(function () {
                self.compressWidth();
            }, 0);
        },

        methods: {
            compressWidth: function () {
                if (!this.autoWidth)
                    return;
                var sel = this.$el;
                for (var i = 0; i < sel.options.length; i++) {
                    if (sel.options[i].innerHTML.trim())
                        sel.options[i].title = sel.options[i].innerHTML.trim();
                    // if (!sel.options[i].selected && sel.options[i].title)
                    //     sel.options[i].innerHTML = '';
                }
                sel.blur();
            },

            restoreWidth: function () {
                if (!this.autoWidth)
                    return;
                var sel = this.$el;
                for (var i = 0; i < sel.options.length; i++) {
                    if (sel.options[i].title)
                        sel.options[i].innerHTML = sel.options[i].title;
                }
            },

            restoreAndCompress: function () {
                this.restoreWidth();
                this.compressWidth();
            }
        }

    }
</script>

<style lang="less" scoped>
    @import "base.less";

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-repeat: no-repeat;
        background-image: url('/images/shape-down.svg');
        background-position: right center;        
        padding: 5px 18px 5px 5px;
        //border-bottom: 1px solid @ColorSecGrey24;

        option {
            font-size: 1rem;
        }
    }

    select[disabled='disabled'] {
        border-bottom-style: none;
        background-image: none;
    }

    select::-ms-expand {
        display: none;
    }
</style>