// import IntercomService from "@/services/chat-providers/intercom-service.js";

import HubSpotChat from "@/services/chat-providers/hubspot-chat.js";

export const intercom = {
  methods: {
    showChat: function () {
      // IntercomService.show();
      HubSpotChat.open();
    },
  }
};
