<template>
  <div>
    <input
      type="hidden"
      :name="name"
      :value="+value"
    >
    <input
      :id="id"
      type="checkbox"
      :checked="value"
      class="hidden"
      @change="updateSelf($event.target.checked)"
      @click="click"
    >
    <label
      :for="id"
      :class="[classFontSize ? classFontSize : '']"
      :style="sizeLabel ? sizeLabel : '' "
    ><slot /></label>
  </div>
</template>

<script>
    import base from "@/components/inputs/base.js";
    import cuid from 'cuid';

    export default {

        mixins: [base],

        props: {
            name: "",
            classFontSize: "",
            sizeLabel: ""
        },

        data: function () {
            return {
                id: ""
            }
        },

        created: function () {
            this.id = cuid();
        },

        methods: {
            click: function () {
                this.$emit("click");
            }
        }
        
    }
</script>

<style lang="less" scoped>
    @import "base.less";
    @import "../../styles/fonts.less";
    @import "../../styles/colors.less";

    div {
        display: inline;
        vertical-align: middle;
    }
    a {
       // font-weight: 300;
    }

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox'] + label {
        background-image: url('/images/check-unmarked.svg');
        background-position: left 2px;
        background-size: auto 15px;
        // width: 36px;
        width: 100%;
        //height: 23px;
        background-repeat: no-repeat;
        padding-left: 22px;
        user-select: none;
        //font-size: 16px;        
        //font-weight: 300;
        color: @ColorPriGrey;        
    }

    input[type='checkbox']:checked + label {
        background-image: url('/images/check-marked.svg');
    }

    .body-text-1{
        font-size: 0.875rem !important; //14px
    }
</style>