<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        E-mail validado
      </h5>

      <StepIndicatorModal
        v-if="pendencyExecuteQuery !== 'PENDENCY-EMAIL'"
        class="step-modal"
        :step-number="checkStepNumber"
        change-text-for-step-one="Validar E-mail"
        change-text-for-step-two="Completar Cadastro"
        change-text-for-step-three="Cadastrar Empresa"
        :pendency-execute-query="pendencyExecuteQuery"
      />
    </template>
    <template slot="right">
      <h2 v-if="$mq === 'sm' || $mq === 'md'">
        E-mail validado
      </h2>
      <h4 class="header-text">
        O seu e-mail foi validado com sucesso
      </h4>
      <p>
        <!-- texto genérico, incentivando o cadastro -->
        Agora complete o seu cadastro para
        <strong>ganhar {{ getValeuAfterCompleteRegister }}</strong> em créditos
        para poder realizar mais consultas.

        <!-- retirada a verificação de texto para deixar o conteúdo genérico, incentivando o cadastro -->
        <!-- <span v-if="pendencyRegister"><strong>Complete o seu cadastro</strong> para fazer a consulta selecionada.</span>
                <span v-else>Agora você pode realizar consultas que são disponíveis apenas para e-mails validados.</span> -->
      </p>
      <div class="button-box">
        <ButtonLarge
          class="button"
          @click="$emit('successEmailNextStep')"
        >
          Completar Cadastro
        </ButtonLarge>

        <!-- retirada a verificação de texto para deixar o conteúdo genérico, incentivando o cadastro -->
        <!-- <ButtonLarge v-if="!pendencyRegister" class="button" @click="$emit('hideModal')">Continuar</ButtonLarge>
                <ButtonLarge v-else class="button" @click="$emit('successEmailNextStep')">Completar Cadastro</ButtonLarge> -->
      </div>
    </template>
  </ModalFullScreenContent>
</template>

<script>
import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
import ProgressBar from "@/components/misc/progress-bar.vue";
import ButtonLarge from "@/components/buttons/button-large.vue";
import StepIndicatorModal from "@/views/product/step-indicator-modal.vue";
import { valueAfterCompleteRegister } from "@/mixin/value-after-complete-register-mixin.js";

export default {

  components: {
    ModalFullScreenContent,
    ProgressBar,
    ButtonLarge,
    StepIndicatorModal
  },
  mixins: [valueAfterCompleteRegister],

  props: {
    msgLeft: "",
    msgRight1: "",
    msgRight2: "",

    pendencyExecuteQuery: {
      type: String,
      required: false,
      default: ""
    }
  },

  computed: {
    checkStepNumber: function() {
      return this.$store.getters["UserModule/emailValidated"] ? 1 : 2;
    },
    // pendencyRegister: function () {
    //     return this.pendencyExecuteQuery !== 'PENDENCY-EMAIL' ? true : false;
    // }
    pendencyRegister: function() {
      if (!this.pendencyExecuteQuery || this.pendencyExecuteQuery === "") {
        return false;
      } else {
        return this.pendencyExecuteQuery !== "PENDENCY-EMAIL" ? true : false;
      }
    }
  },

  methods: {
    proceed: function() {
      this.$emit("onboardEnd");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";

.email-info-paragraph {
  margin-top: 2rem;
}
</style>
