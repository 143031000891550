import routeNameSeparator from "@/i18n/route-name-separator";

/**
 * @param {string | undefined} routeName
 * @param {string} locale
 */
function getRouteLocalizedName(routeName, locale) {
    return `${routeName}${routeNameSeparator}${locale}`
}

export default getRouteLocalizedName
