// Referência: https://stackoverflow.com/a/64687300/9475897
const VerificationStatusEnum = /** @type {const} */({
  Rejected: "REPROVADO",
  Expired: "VALIDACAO_EXPIRADA",
  NotFound: "NOT_FOUND",
  InProgress: "EM_VALIDACAO",
  ManualReview: "REQUER_VALIDACAO_MANUAL",
  Approved: "APROVADO",
  AutomaticallyApproved: "APROVACAO_AUTOMATICA",
  AutomaticallyRejected: "REJEICAO_AUTOMATICA",
  ManuallyApproved: "APROVACAO_MANUAL",
  ManuallyRejected: "REJEICAO_MANUAL",
  SerproApproved: "APROVADO_SERPRO",
  SerproRejected: "REPROVADO_SERPRO",
});

export default VerificationStatusEnum;
