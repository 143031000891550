import createRouteConfigs from "@/i18n/create-route-configs";
import i18n from "@/i18n/i18n-instance";

const LgpdEnUs = () =>
  import(
    /* webpackChunkName: "Lgpd.en-US" */
    "@/views/legal/lgpd.en-US.vue"
  );
const LgpdPtBr = () =>
  import(
    /* webpackChunkName: "Lgpd.pt-BR" */
    "@/views/legal/lgpd.pt-BR.vue"
  );
const CookiePolicyPtBr = () =>
  import(
    /* webpackChunkName: "CookiePolicyPtBr" */
    "@/views/legal/cookie-policy.pt-BR.vue"
  );
const CookiePolicyEnUs = () =>
  import(
    /* webpackChunkName: "CookiePolicyEnUs" */
    "@/views/legal/cookie-policy.en-US.vue"
  );

const routes = [
  ...createRouteConfigs({
    baseConfig: {
      path: "/lgpd",
      name: "Lgpd",
      meta: { requiresAuth: false },
    },
    localeConfigs: [
      {
        code: "en-US",
        component: LgpdEnUs,
        meta: { title: i18n.t("pages.LGPD.meta.title", "en-US") },
      },
      {
        code: "pt-BR",
        component: LgpdPtBr,
        meta: { title: i18n.t("pages.LGPD.meta.title", "pt-BR") },
      },
    ],
  }),
  ...createRouteConfigs({
    baseConfig: {
      path: "/corporate/lgpd",
      name: "LgpdCorporate",
      meta: { requiresAuth: false },
    },
    localeConfigs: [
      {
        code: "en-US",
        component: LgpdEnUs,
        meta: { title: i18n.t("pages.LGPDCorporate.meta.title", "en-US") },
      },
      {
        code: "pt-BR",
        component: LgpdPtBr,
        meta: { title: i18n.t("pages.LGPDCorporate.meta.title", "pt-BR") },
      },
    ],
  }),
  ...createRouteConfigs({
    baseConfig: {
      path: "/executivecheck/lgpd",
      name: "LgpdExecutiveCheck",
      meta: { requiresAuth: false },
    },
    localeConfigs: [
      {
        code: "en-US",
        component: LgpdEnUs,
        meta: { title: i18n.t("pages.LGPDExecutiveCheck.meta.title", "en-US") },
      },
      {
        code: "pt-BR",
        component: LgpdPtBr,
        meta: { title: i18n.t("pages.LGPDExecutiveCheck.meta.title", "pt-BR") },
      },
    ],
  }),
  ...createRouteConfigs({
    baseConfig: {
      path: "/flashcheck/lgpd",
      name: "LgpdFlashCheck",
      meta: { requiresAuth: false },
    },
    localeConfigs: [
      {
        code: "en-US",
        component: LgpdEnUs,
        meta: { title: i18n.t("pages.LGPDExecutiveCheck.meta.title", "en-US") },
      },
      {
        code: "pt-BR",
        component: LgpdPtBr,
        meta: { title: i18n.t("pages.LGPDExecutiveCheck.meta.title", "pt-BR") },
      },
    ],
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "CookiePolicy",
      meta: { requiresAuth: false },
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/cookie-policy",
        component: CookiePolicyEnUs,
        meta: { title: i18n.t("pages.CookiePolicy.meta.title", "en-US") },
      },
      {
        code: "pt-BR",
        path: "/politica-cookie",
        component: CookiePolicyPtBr,
        meta: { title: i18n.t("pages.CookiePolicy.meta.title", "pt-BR") },
      },
    ],
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "CookiePolicyCorporate",
      meta: { requiresAuth: false },
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/corporate/cookie-policy",
        component: CookiePolicyEnUs,
        meta: {
          title: i18n.t("pages.CookiePolicyCorporate.meta.title", "en-US"),
        },
      },
      {
        code: "pt-BR",
        path: "/corporate/politica-cookie",
        component: CookiePolicyPtBr,
        meta: {
          title: i18n.t("pages.CookiePolicyCorporate.meta.title", "pt-BR"),
        },
      },
    ],
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "CookiePolicyExecutiveCheck",
      meta: { requiresAuth: false },
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/executivecheck/cookie-policy",
        component: CookiePolicyEnUs,
        meta: {
          title: i18n.t("pages.CookiePolicyExecutiveCheck.meta.title", "en-US"),
        },
      },
      {
        code: "pt-BR",
        path: "/executivecheck/politica-cookie",
        component: CookiePolicyPtBr,
        meta: {
          title: i18n.t("pages.CookiePolicyExecutiveCheck.meta.title", "pt-BR"),
        },
      },
    ],
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "CookiePolicyFlashCheck",
      meta: { requiresAuth: false },
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/flashcheck/cookie-policy",
        component: CookiePolicyEnUs,
        meta: {
          title: i18n.t("pages.CookiePolicyFlashCheck.meta.title", "en-US"),
        },
      },
      {
        code: "pt-BR",
        path: "/flashcheck/politica-cookie",
        component: CookiePolicyPtBr,
        meta: {
          title: i18n.t("pages.CookiePolicyFlashCheck.meta.title", "pt-BR"),
        },
      },
    ],
  }),
];

export default routes;
