import httpRequest from '@/services/service-providers/httpRequest-service.js';

class HomeService {

    getContentForDashboard(language) {
        return httpRequest.axios.get('Home/GetContentForDashboard',{
            params: {
                language: language
            }
        });
    }

    getUserBalance() {
        return httpRequest.axios.get('Home/GetUserBalance');
    }

    listProducts(language) {
        return httpRequest.axios.get('Home/ListProducts', {
            params: {
                language: language
            },
            supressTokenRenew: true
        });
    }

    listAlerts() {
        return httpRequest.axios.get('Home/ListAlerts');
    }
}

export default new HomeService();
