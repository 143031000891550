export const checkWhatsAppText = {

  data() {
    return {
      footerIsParamFromWhatsApp: null,
      accountUserExternalId: "",
      accountOrganizationExternalId: "",
    }
  },

  computed: {    
    getScreenText: function () {
      if (!this.getIsParamFromWhatsApp) {
        return this.screenText.default;
      } else if (!this.isAccountOnboardComplete) {
        return this.screenText.whatsAppNotOnbording;
      } else {
        return this.screenText.default;        
      }
    },
    getAccountJoinedData: function () {
      return JSON.parse(sessionStorage.getItem('whatsAppAccountJoined'));      
    },

    getIsParamFromWhatsApp: function () {
      return JSON.parse(sessionStorage.getItem('whatsAppParams'));      
    },

    isAccountOnboardComplete: function () {
      let accountOnboardComplete = false;
      if (this.getAccountJoinedData) {
        return this.getAccountJoinedData.accountOnboardComplete;
      }
      return accountOnboardComplete;
    }
  },
  methods: {
    // o storage não é reativo, então não atualiza no footer
    // método chamado via evento da landing genérica
    updateFooterParamWhatsapp: function () {
      this.footerIsParamFromWhatsApp = this.isAccountOnboardComplete;      
    },
    removeAccountJoinedData: function () {
      sessionStorage.removeItem('whatsAppAccountJoined');      
    },
    removeParamFromWhatsApp: function () {
      sessionStorage.removeItem('whatsAppParams');      
    },

    setDataWhatsAppParamToPost: function () {
      if (this.getIsParamFromWhatsApp) {
        this.accountUserExternalId = this.getIsParamFromWhatsApp.accountUserExternalId;
        this.accountOrganizationExternalId = this.getIsParamFromWhatsApp.accountOrganizationExternalId;
      }
    }

  },
}
