<template>
  <button @click="onClick">
    <svg
      v-if="loading"
      class="icon icon-small icon-with-text icon-loading rotate"
    >
      <use xlink:href="/images/icon-lib.svg#icon-loading" />
    </svg>
    <slot />
  </button>
</template>

<script>
    import base from "@/components/buttons/base.js";

    export default {

        mixins: [base],

        props: {
            loading :{
                type: Boolean,
                required: false,
            }
        }

    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    @import "base.less";
    @import "../../styles/screen-size.less";

    button {
        background-color: @ColorPriYellow;        
        // color: @ColorSecGrey84;    
        color: @ColorPriGrey;    
        font-size: 15px;

        @media (max-width: @screenSmall) {
            width: 100%;
            max-width: 290px;
        }
    }

    button:hover:enabled {
        background-color: darken(@ColorPriYellow, 12%);
    }
</style>