export const inputFocus = {

  methods: {
    //por algum motivo via refs, não funcionou
    //https://stackoverflow.com/questions/52088691/vue-js-put-focus-on-input
    focusInput(inputId) {
      this.$nextTick(function () {
        const input = document.getElementById(inputId);
        if (input) {
          input.focus();
        }
      })
    },
  }
}