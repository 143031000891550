/**
 * @typedef {import("vue-i18n").NumberFormats} NumberFormats
 */

/** @type {NumberFormats} */
const numberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
    },
  },
  "pt-BR": {
    currency: {
      style: "currency",
      currency: "BRL",
    },
  },
}

export default numberFormats
