<template>    
  <transition name="fade3">
    <ModalFullscreen
      v-if="showRegister"
      @closing="closeRegister"
    >
      <Register 
        v-if="registerCurrentStep == 'REG'"
        :pre-input-email="preInputEmail"
        :pre-input-promo-code="preInputPromoCode"
        @showPrivacyPolicy="closeRegister"
        @showTermsConditions="closeRegister"
        @loginSuccessful="closeRegister"
        @socialRegistrationRequested="socialRegistrationRequested"
        @closeRegisterRequest="closeRegister" 
      />
      <transition name="fade3">
        <RegisterSocialNetwork
          v-if="registerCurrentStep == 'SOCIALREG'"
          :social-registration="socialRegistrationData"
          @loginSuccessful="closeRegister"
          @closeRegisterRequest="closeRegister"
        />
      </transition>
    </ModalFullscreen>
  </transition>    
</template>

<script>
    import ModalFullscreen from "@/components/boxes/modal-fullscreen.vue";
    import Register from "@/views/onboarding/register.vue";
    import RegisterSocialNetwork from "@/views/onboarding/register-social-network.vue";

    export default {

        components: {
            ModalFullscreen,
            Register,
            RegisterSocialNetwork,
        },

        data: function () {
            return {
                showRegister: false,
                registerCurrentStep: "REG",
                preInputEmail: "",
                preInputPromoCode: "",
                socialRegistrationData: {}
            }
        },

        methods: {

            openRegistration: function (preInputEmail) {                
                this.preInputEmail = preInputEmail;
                this.registerCurrentStep = "REG";
                this.showRegister = true;
            },

            socialRegistrationRequested: function (value) {
                this.socialRegistrationData = value;
                this.registerCurrentStep = "SOCIALREG";
                this.showRegister = true;
            },

            closePasswordRecover: function () {
                this.showPasswordRecover = false;
            },

            closeRegister: function () {
                this.showRegister = false;
            }

        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/animations.less";
</style>