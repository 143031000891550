import RdService from "@/services/service-providers/webapp-services/rd-service.js";

export const rdConversion = {

    methods: {
        
        // ConversionIdentifier, email, name, PersonalPhone, CompanyName, ClientTrackingId

        rdConversion: function (...theArgs) {

            RdService.conversionEvent(...theArgs).then(function () {                    
            })
            .catch(function (error) {
                console.log(error, 'Erro conversão RD');
            });
        }
    }
}