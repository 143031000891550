<template>
  <!-- <div
    class="cue"
    :class="checkVueThemeColor"
    v-scroll-to="{
      el: idElementToScroll,
      duration: duration,
    }"
  > -->
  <div
    class="cue"
    :class="checkVueThemeColor"    
    @click="goToElementId(idElementToScroll, positionElementToScroll)"
  >
    <svg class="icon icon-medium">
      <use :xlink:href="`${imgPath}`" />
    </svg>
  </div>
</template>

<script>
import { goToElement } from "@/mixin/go-to-element-mixin.js";


export default {
  
  mixins: [goToElement],
  
  props: {
    imgPath: {
      type: String,
      default: "/images/icon-lib.svg#icon-arrow",
    },
    idElementToScroll: {
      type: String,
      required: true,
    },
    positionElementToScroll: {
      type: String,
      default: "start",
    },
    duration: {
      type: Number,
      default: 600
    },
    cueColor: {
      type: String,
      default: "gray"
    }
  },

  computed: {
    checkVueThemeColor() {
      return {
        "cue-white": this.cueColor === "white",
        "" : this.cueColor === "gray",
      } 
    }
  },

};
</script>

<style lang="less" scoped>
@import "../../../styles/screen-size.less";
@import "../../../styles/colors.less";

.cue {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  color: lighten(@ColorSecGrey24, 18%);
  cursor: pointer;
  transition: 0.3s all; 

  &:hover {
    opacity: 0.7;
    color: @ColorPriYellow;
  }

  @media (min-width: @screenLarge) {
    transform: translate(-50%, -100%);
  }
  svg {
    vertical-align: bottom;
  }
}
.cue-light {
  opacity: .35;
}

.cue-white {
  color: #FFF !important;
  opacity: .35 !important;

  &:hover {    
    color: @ColorPriYellow !important;
  }
}

</style>