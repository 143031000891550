<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        {{ $t("signup") }}
      </h5>
      <h5
        class="title2"
        v-html="getScreenText.title2"
      />
    </template>
    <template slot="right">
      <h2 v-if="$mq === 'sm' || $mq === 'md'">
        {{ $t("signup") }}
      </h2>

      <h4
        class="header-text"
        v-html="getScreenText.headerText"
      />

      <Alert
        v-if="message"
        alert-type="danger"
        :show-icon="false"
        :small-text="true"
        class="alert mt-1-5"
      >
        <slot>{{ message }}</slot>
      </Alert>

      <form
        class="form-register"
        @submit.prevent
      >
        <!-- https://community.hubspot.com/t5/Lead-Capture-Tools/Ignore-a-specific-non-Hubspot-form-from-being-collected/m-p/246957 -->
        <label
          for="amex"
          style="display:none"
        >AMEX</label>
        <input
          id="amex"
          type="hidden"
          name="amex"
          required=""
          value=""
        >
        <div class="inputs-box">
          <div>
            <InputEmail
              id="emailInput"
              v-model="email"
              maxlength="100"
              :disabled="screenLocked || emailLocked"
              :placeholder="$t('enterYourEmail')"
              @keyup.enter.native="register"
              @input.once="sendEventStartForm"
            />
          </div>
          <RegisterInputCreatePassword
            :screen-locked="screenLocked"
            IdInputPassword="inputPasswordRegister"
            @sendActionForm="register"
            @updatePassword="updatePassword"
          />
        </div>
        <div class="accept-contract-box">
          <InputCheckbox
            v-model="acceptContract"
            class-font-size="body-text-2"
            @click="verifyMark"
          >
            {{ $t("acceptContract[0]") }}
            <router-link
              class="list-group-item"
              :to="localePath('PrivacyPolicy')"
              target="_blank"
              tabindex="-1"
            >
              {{ $t("acceptContract[1]") }}
            </router-link>
            {{ $t("acceptContract[2]") }}
            <router-link
              class="list-group-item"
              :to="localePath('TermsConditions')"
              target="_blank"
              tabindex="-1"
            >
              {{ $t("acceptContract[3]") }}
            </router-link>
            {{ $t("acceptContract[4]") }}
          </InputCheckbox>
        </div>
        <div class="content-invite-code">
          <RegisterInviteCode
            :screen-locked="screenLocked"
            @updatePromotionalCode="updatePromotionalCode"
          />
        </div>
      </form>

      <div class="button-box">
        <Button
          class="button"
          type="button"
          btn-style="primary"
          :disabled="screenLocked"
          :loading="screenLocked"
          @click="register"
        >
          {{ $t("signup") }}
        </Button>
      </div>
      <SocialLogin2
        font-color="text-ColorSecGrey47"
        bg-color-divider="bg-ColorSecGrey03"
        border-color="bd-ColorSecGrey47"
        @socialLoginStarted="socialLoginStarted"
        @loginSuccessful="loginSuccessful"
        @socialRegistrationRequested="socialRegistrationRequested"
        @closeRegister="loginSuccessful"
      />
      <div class="action-navigation">
        {{ $t("components.Register.existentAccount") }}
        <router-link
          to
          @click.native="$bus.$emit('global-login')"
        >
          {{
            $t("login")
          }}
        </router-link>
      </div>

      <Portal to="app-end">
        <div
          id="recaptcha-register"
          key="recaptcha-register"
        />
      </Portal>
    </template>
  </ModalFullScreenContent>
</template>

<script>
import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
import InputEmail from "@/components/inputs/input-email.vue";
import InputText from "@/components/inputs/input-text.vue";
import InputCheckbox from "@/components/inputs/input-checkbox.vue";
import Button from "@/components/buttons/button.vue";
import RegisterService from "@/services/service-providers/webapp-services/register-service.js";
import SocialLogin2 from "@/components/misc/social-login-2.vue";
import Alert from "@/components/misc/alert.vue";
import googleCaptchaProvider from "@/services/captcha-providers/google.service.js";
import UserCompanyService from "@/services/service-providers/webapp-services/user-company-service.js";
import { rdConversion } from "@/mixin/rd-conversion.js";
import { registerInviteCode } from "@/mixin/register-invite-code-mixin.js";
import { registerInputCreatePassword } from "@/mixin/register-input-create-password-mixin.js";
import { inputFocus } from "@/mixin/input-focus-mixin.js";
import { checkReport } from "@/mixin/check-report-mixin.js.js";
import { checkWhatsAppText } from "@/mixin/whatsapp-param-text-mixin.js";
import { textScreenRegister } from "@/mixin/text/text-screen-register-mixin.js";

const RegisterInviteCode = () =>
  import(
    /* webpackChunkName: "RegisterInviteCode" */ "@/components/register/register-invite-code.vue"
  );
const RegisterInputCreatePassword = () =>
  import(
    /* webpackChunkName: "RegisterInputCreatePassword" */ "@/components/register/register-input-create-password.vue"
  );

export default {
  components: {
    ModalFullScreenContent,
    InputEmail,
    InputCheckbox,
    Button,
    SocialLogin2,
    InputText,
    UserCompanyService,
    RegisterInviteCode,
    RegisterInputCreatePassword,
    Alert
  },
  mixins: [
    rdConversion,
    registerInviteCode,
    registerInputCreatePassword,
    inputFocus,
    checkReport,
    checkWhatsAppText,
    textScreenRegister
  ],
  props: {
    socialRegistration: {},
    preInputEmail: ""
  },
  data() {
    return {
      screenLocked: false,
      email: "",
      emailLocked: false,
      password: "",
      acceptContract: false,
      message: "",
      tokenCompanyRegistration: "",
      sendEmailValidation: true,
      exIdentifyAffiliateKeyId: ""
    };
  },
  mounted() {
    this.email = this.preInputEmail;
    if (this.preInputEmail) {
      this.focusInput("inputPasswordRegister");
    } else {
      this.focusInput("emailInput");
    }
    this.tokenCompanyRegistration = this.$route.params.tokenCompanyRegistration;

    if (this.getIsParamFromWhatsApp) {
      this.$gtm.trackEvent({
        event: "cadastro-abertura-modal-whatsapp"
      });
    }

    this.$nextTick(function() {
      googleCaptchaProvider.initialize("recaptcha-register");
    });
  },
  destroyed() {
    googleCaptchaProvider.dispose();
  },
  methods: {
    register() {
      if (!this.email) {
        this.message = this.$t("emptyEmailError");
        return;
      }

      if (
          !document.querySelector(".inputs-box input[type='email']").validity
              .valid
      ) {
        this.message = this.$t("invalidEmailError");
        return;
      }

      if (!this.password) {
        this.message = this.$t("emptyPasswordError");
        return;
      }

      if (!this.acceptContract) {
        this.message = this.$t("refusedContractError");
        return;
      }

      this.sendEmailValidation = this.checkSendEmailValidation(
          this.$route.params.externalId
      );

      // TODO - se teaser e consultas que precisam de completar o cadastro não mandar o email de boas vindas

      this.message = "";
      this.screenLocked = true;
      let self = this;

      self.setDataWhatsAppParamToPost();
      self.setExIdentifyAffiliateKeyId();

      googleCaptchaProvider.verify(
          function(captchaToken) {
            RegisterService.registerUser(
                self.email,
                self.password,
                self.promotionalCode,
                captchaToken,
                self.tokenCompanyRegistration,
                self.sendEmailValidation,
                self.accountUserExternalId,
                self.accountOrganizationExternalId,
                self.exIdentifyAffiliateKeyId
                // TODO - verificar com o andre o que será passado para rastrear o usuário
                // estou considerando o exIdentifyAffiliateKeyId
                // não necessariamente o parceiro será do flashcheck
            )
                .then(function(response) {
                  self.rdConversion("Formulário Cadastro - Modal", self.email);

                  self.screenLocked = false;
                  self.$store.commit("UserModule/login", response.data.data);
                  self.$emit("loginSuccessful");
                  if (self.tokenCompanyRegistration) {
                    self.$bus.$emit(
                        "global-notification",
                        self.$t("companyRegistrationSuccess"),
                        false
                    );
                  }
                  if (self.$store.getters["UserModule/userAuthenticated"]) {
                    self.$bus.$emit("global-needReloadUserEnvironment", true);
                  }

                  if (self.getIsParamFromWhatsApp) {
                    self.sendEventAnalyticsRegisterSuccessWhatsApp();
                  } else {
                    self.sendEventAnalyticsRegisterSuccess();
                  }
                })
                .catch(function(error) {
                  self.screenLocked = false;
                  self.message = error.response.data.message;
                });
          },
          function() {
            self.screenLocked = false;
          }
      );
    },
    verifyMark() {
      if (this.acceptContract) this.message = "";
    },
    socialLoginStarted() {
      this.screenLocked = true;
    },
    loginSuccessful() {
      this.$emit("loginSuccessful");
      // this.$router.push('/home');
    },
    socialRegistrationRequested(value) {
      this.$emit("socialRegistrationRequested", value);
    },
    // showPrivacyPolicy() {
    //     this.$emit('showPrivacyPolicy');
    //     this.$router.push('/politica-privacidade');
    // },
    // showTermsConditions() {
    //     this.$emit("showTermsConditions");
    //     this.$router.push('/termos-condicoes');
    // },
    sendEventAnalyticsRegisterSuccess() {
      this.$gtm.trackEvent({
        event: "cadastro-sucesso"
      });
      this.$gtm.trackEvent({
        event: "sign_up",
        signUpMethod: "email",
        partner_key_id: this.exIdentifyAffiliateKeyId
      });
    },
    sendEventAnalyticsRegisterSuccessWhatsApp() {
      this.$gtm.trackEvent({
        event: "cadastro-sucesso-whatsapp"
      });
      this.$gtm.trackEvent({
        event: "sign_up",
        signUpMethod: "origem whatsapp",
        partner_key_id: this.exIdentifyAffiliateKeyId
      });
    },
    sendEventStartForm() {
      this.$gtm.trackEvent({
        event: "inicio-form-cadastro"
      });
    },
    checkSendEmailValidation(externalId) {
      return externalId && this.isReportPessoaFisica(externalId);
    },
    setExIdentifyAffiliateKeyId() {
      const exIdentifyAffiliateKeyId = localStorage.getItem(
          "exIdentifyAffiliateKeyId"
      );
      this.exIdentifyAffiliateKeyId = exIdentifyAffiliateKeyId
          ? exIdentifyAffiliateKeyId
          : "";
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
@import "../../styles/input-group.less";
@import "../../styles/screen-size.less";

.accept-contract-box {
  max-width: 408px;
  margin-top: 1.75rem;

  @media (min-width: @screenLarge) {
    margin-top: 1.5rem;
  }
}

.action-navigation {
  margin-top: 1.25rem;
  font-weight: 300;
  text-align: center;

  a {
    font-weight: 400;
    text-transform: uppercase;
  }
}

.disabled {
  cursor: auto;
  color: #5f5c5c;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
}

//evitar layout shift
.inputs-box {
  min-height: 90px;

  @media (min-width: @screenLarge) {
    min-height: 94px;
  }
}

.content-invite-code {
  min-height: 16px;
}
</style>
