export const passwordVisualHelper = {
  data: function () {
    return {
      passwordFieldType: 'password',
    }
  },
  methods: {
    switchVisibility: function () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
  }
}