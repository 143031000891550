/**
 * @typedef {import("./types").LocaleConfig} LocaleConfig
 */

/** @type {LocaleConfig[]} */
const localeConfigs = [
  {
    code: "en-US",
    name: "English",
    iconPath: "/images/shared/flag-circle-en-US.svg",
  },
  {
    code: "pt-BR",
    name: "Português",
    iconPath: "/images/shared/flag-circle-pt-BR.svg",
  },
]

export default localeConfigs
