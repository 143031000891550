import Vue from 'vue';
import App from '@/App.vue';
import Router from '@/router/index.js';
import State from '@/state/index.js';
import VTooltip from 'v-tooltip'
// import VueMoment from 'vue-moment';
// @ts-ignore
import VueMq from 'vue-mq'
// @ts-ignore
import VueAnimate from 'vue-animate-scroll'
// @ts-ignore
import checkView from 'vue-check-view'
import PortalVue from 'portal-vue'
import vueDebounce from 'vue-debounce'
import i18n from "./i18n/i18n-instance"

Vue.config.productionTip = (process.env.NODE_ENV === "production");

const EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
    $bus: {
        get: function () {
            return EventBus
        }
    }
});

Vue.use(VTooltip, {
  defaultBoundariesElement: 'body',
});
// Vue.use(VueMoment);
Vue.use(PortalVue);
Vue.use(vueDebounce);


// TODO: verificar se essas diretivas devem ficar concentradas em outro local
// Registra a diretiva personalizada global chamada `v-focus`
Vue.directive('focus', {
    // Quando o elemento vinculado é inserido no DOM...
    inserted: function (el) {
      // Coloque o foco no elemento
      el.focus()
    }
  });

Vue.directive('uppercase', {
    bind(el) {
        el.addEventListener('keyup', () => {
            el.value = el.value.toUpperCase();
        })
    }
});


// TO DO : Colocar filtros em um arquivo separado
Vue.filter('trim', function (value) {
  const formated = value ? value.trim() : '';
  return formated;
});

Vue.filter('cpfFormat', function(value){
  const formated = value
    ? value
        .toString()
        .padStart(11, "0")
        .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
    : "";
  return formated;
});

Vue.filter('cnpjFormat', function(value){
  const formated = value
    ? value
        .toString()
        .padStart(14, "0")
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
    : "";
  return formated;
});

Vue.filter('cpfCnpjFormat', function(value){
  if (!value) {
    return ''
  }
  if (value.length > 11) {
    return value.toString()
    .padStart(14, '0')
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  } else {
    return value
      .toString()
      .padStart(11, "0")
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }
});

Vue.filter('coalesce', function(value){
  return !value ? '-' : value;
});

//TODO - verificar se há uma maneira melhor
Vue.filter('convertToBrDate', function (strDate) {
  let date=new Date(strDate);
  let _dd = "";
  let _mm="";
  let _yy = date.getFullYear();

  date.getDate() <10 ? (_dd='0'+date.getDate()):(_dd=date.getDate());
  (date.getMonth()+1) <10?(_mm='0'+(date.getMonth()+1)):(_mm = date.getDate()+1);
  let dateFormated = _dd + '/' +_mm+ '/' +_yy;

  return dateFormated
});


/* Vue.filter('formatMoney', function(value, currency){
  let formated = value.toFixed(2).replace('.', ',');
  return currency ? currency + formated : formated;
}); */

//no momento não consigo testar o histórico e saldo, por isso foi criado este
Vue.filter("formatMoney", function(value, currency) {
  let formated = value.toFixed(2).split(".");
  formated[0] = currency
    ? currency + formated[0].split(/(?=(?:...)*$)/).join(".")
    : formated[0].split(/(?=(?:...)*$)/).join(".");
  return formated.join(",");
});

Vue.filter("formatMilliseconds", function(value) {
  return value.toFixed(3).replace(".", ",") + " seg";
});

/*
'sm' => 0 > screenWidth < 768
'md' => 768 >= screenWidth < 992
'lg' => 992 >= screenWidth < 1200
'xl' => screenWidth >= 1200
*/
Vue.use(VueMq, {
  breakpoints: {
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity
  },
  defaultBreakpoint: ""
})

Vue.use(VueAnimate);

Vue.use(checkView);

// export const msalConfig = {
//   auth: {
//     clientId: process.env.VUE_APP_ACTIVE_DIRECTORY_CLIENT_ID,
//     authority: 'https://login.microsoftonline.com/common',
//     redirectUri: process.env.VUE_APP_ACTIVE_DIRECTORY_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
//     //postLogoutRedirectUri: 'https://localhost:1337/bradesco/login' // Must be registered as a SPA redirectURI on your app registration
//   },
//   graph: {
//     url: "https://graph.microsoft.com/v1.0/me",
//     scopes: ["openid", "profile", "email"]
//     //graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages"
//   },
//   cache: {
//     cacheLocation: 'localStorage'
//   },
//   mode: "popup"
// }
//
// Vue.use(msalPlugin, msalConfig);


new Vue({
    render: h => h(App),
    router: Router,
    store: State,
    i18n,
    $bus: new Vue()
})
.$mount('#app');
