import httpRequest from '@/services/service-providers/httpRequest-service.js';

class RdService {

    // Não foram todos os campos que foram colocados no nosso back end
    //https://developers.rdstation.com/pt-BR/reference/events#conversionEventPostDetails

    conversionEvent(ConversionIdentifier, email, name, PersonalPhone, CompanyName, ClientTrackingId ) {

        return httpRequest.axios.post('RDStation/RDsStationConversion',
            {
                ConversionIdentifier: ConversionIdentifier,
                email: email,
                name: name,
                PersonalPhone: PersonalPhone,
                CompanyName: CompanyName,
                ClientTrackingId: ClientTrackingId
            },
            {
                supressTokenRenew: true
            });
    }

}

export default new RdService();
