export const checkReport = {
  methods: {
    isReportPessoaJuridica(externalId) {
      return externalId === "16d4da51-febf-4303-b19c-91bf0e107238";
    },
    isReportPessoaFisica(externalId) {
      return externalId === "e73b8677-effa-4dbe-a7a9-0f40cc2cfd7f";
    },
  },
}
