<template>
  <div>
    <SectionContent class="section-content hero text-center bg-light fade-in">
      <div class="container hero-container">
        <SectionHeroSolutions>
          <div class="hero-content-text">
            <SectionHeroSolutionName>Corporate</SectionHeroSolutionName>
            <h1 v-animate="'fadeIn'">
              Automação de Consultas
            </h1>
            <p
              v-animate="'fadeIn'"
              class="sub-header"
            >
              Automatize o processo de consulta e verificação de CPFs e CNPJs em
              inúmeras fontes com nossas APIs de fácil integração, projetadas
              para manipular grandes volumes de dados e realizar transações em
              tempo real
            </p>

            <div
              v-animate="'fadeIn'"
              class="button-box"
            >
              <a @click.prevent="goToElementId('contato-content', 'start')">
                <Button
                  class="button"
                  btn-style="primary"
                >Contato Comercial</Button>
              </a>
            </div>
          </div>

          <div
            v-animate="'fadeInRight'"
            class="hero-illustration-content fast"
          >
            <img
              src="/images/corporate/img-automacao-consultas.svg"
              class="hero-illustration"
              alt
            >
          </div>

          <div
            v-animate="'fadeIn'"
            class="button-box"
          >
            <a @click.prevent="goToElementId('contato-content', 'start')">
              <Button
                class="button"
                btn-style="primary"
              >Contato Comercial</Button>
            </a>
          </div>
        </SectionHeroSolutions>
        <!-- hero-content end -->

        <Cue
          id-element-to-scroll="feature-content"
          img-path="/images/corporate/icon-lib.svg#icon-arrow"
          cue-color="white"
        />
      </div>
      <!-- container end -->
    </SectionContent>
    <!-- hero end -->

    <!-- por algum motivo, o scroll-margin-top, não funciona se o id estiver no componente -->

    <div
      id="feature-content"
      class="scroll-margin-top"
    >
      <FeaturesAutomacaoConsultas
        section-theme="dark"
        id-element-to-scroll="consultas"
      />
    </div>

    <div
      id="consultas"
      class="scroll-margin-top"
    >
      <SectionContent
        ref="consultas-section"
        ref-section="consultas"
        class="text-center bg-light"
      >
        <div class="container">
          <h2 v-animate="'fadeIn'">
            Consultas que o Exato oferece
          </h2>
          <p
            v-animate="'fadeIn'"
            class="introduction"
          >
            Automatize o processo de validação de dados nas principais fontes,
            como Receita Federal, Sintegra, Polícia Federal, CGU, Quod e muito
            mais
          </p>
        </div>

        <div class="container-fluid container-lg">
          <section
            id="consultas-card"
            class="consultas-content"
          >
            <hooper :settings="hooperSettings">
              <HooperSlide :index="0">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-user-pattern" />
                    <div class="card-title">
                      Situação do CPF
                    </div>
                    <div class="card-title-sub">
                      Receita Federal
                    </div>
                    <div class="card-text">
                      Prevenção de fraudes financeiras e mitigação de riscos,
                      atualização de cadastros, validação de dados de clientes,
                      verificação de óbito
                    </div>
                  </template>
                </Card>
              </HooperSlide>

              <HooperSlide :index="1">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-situacao-cnpj" />
                    <div class="card-title">
                      Situação do CNPJ
                    </div>
                    <div class="card-title-sub">
                      Receita Federal
                    </div>
                    <div class="card-text">
                      Checagem de dados de clientes e fornecedores, validação e
                      atualização de dados cadastrais, situação da empresa, QSA
                      e CNAE
                    </div>
                  </template>
                </Card>
              </HooperSlide>

              <HooperSlide :index="2">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-analise-credito" />
                    <div class="card-title">
                      Análise de Crédito
                    </div>
                    <div class="card-title-sub">
                      Quod
                    </div>
                    <div class="card-text">
                      Prevenção à fraude e inadimplência, recuperação de
                      dívidas, concessão de crédito
                    </div>
                  </template>
                </Card>
              </HooperSlide>

              <HooperSlide :index="3">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-inscricao-estadual" />
                    <div class="card-title">
                      Inscrição Estadual
                    </div>
                    <div class="card-title-sub">
                      Sintegra Unificado
                    </div>
                    <div class="card-text">
                      Verificação de Inscrição Estadual e situação do CNPJ nos
                      Sintegras Estaduais, validação de dados para gestão fiscal
                      e financeira
                    </div>
                  </template>
                </Card>
              </HooperSlide>

              <HooperSlide :index="4">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-certidao-debitos" />
                    <div class="card-title">
                      Certidão Conjunta de Débitos
                    </div>
                    <div class="card-title-sub">
                      Receita Federal
                    </div>
                    <div class="card-text">
                      Gestão fiscal e tributária, checagem de dados de empresas,
                      prevenção à fraude, mitigação de riscos, KYS, KYC,
                      compliance
                    </div>
                  </template>
                </Card>
              </HooperSlide>

              <HooperSlide :index="5">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-processos" />
                    <div class="card-title">
                      Processos
                    </div>
                    <div class="card-title-sub">
                      Exato Digital
                    </div>
                    <div class="card-text">
                      Identificação e prevenção à fraude, KYE, KYS, checagem de
                      dados de clientes e fornecedores, mitigação de riscos
                    </div>
                  </template>
                </Card>
              </HooperSlide>

              <HooperSlide :index="6">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-sanctions-list-search-pattern" />
                    <div class="card-title">
                      Sanctions List Search
                    </div>
                    <div class="card-title-sub">
                      OFAC
                    </div>
                    <div class="card-text">
                      Validação de identidade, prevenção à fraude, background
                      check, KYC, KYE, análise e concessão de crédito,
                      financiamento, compliance
                    </div>
                  </template>
                </Card>
              </HooperSlide>

              <HooperSlide :index="7">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration
                      card-img-path="/images/corporate/icon-lib.svg#icon-pessoa-exposta-politicamente-pattern"
                    />
                    <div class="card-title">
                      PEP - Pessoa Exposta Politicamente
                    </div>
                    <div class="card-title-sub">
                      CGU
                    </div>
                    <div class="card-text">
                      Mitigação de riscos, prevenção à fraude, KYC, compliance,
                      gestão fiscal, checagem de dados de clientes e
                      fornecedores
                    </div>
                  </template>
                </Card>
              </HooperSlide>

              <!-- <HooperSlide :index="8">
                <Card
                  v-animate="'fadeInLeft'"
                  cardSize="medium"
                  class="h-100"
                >
                  <template v-slot:body>
                    <CardIllustration cardImgPath="/images/corporate/icon-lib.svg#icon-protestos-cartorio">
                    </CardIllustration>
                    <div class="card-title">Protestos em Cartório</div>
                    <div class="card-title-sub">IEPTB</div>
                    <div class="card-text">
                      Checagem de dados de clientes e fornecedores, prevenção de
                      fraudes financeiras, KYS, KYC, gestão de riscos,
                      compliance
                    </div>
                  </template>
                </Card>
              </HooperSlide> -->

              <HooperSlide :index="9">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-optantes-simples" />
                    <div class="card-title">
                      Optantes pelo Simples Nacional
                    </div>
                    <div class="card-title-sub">
                      Receita Federal
                    </div>
                    <div class="card-text">
                      Validação e atualização de dados de clientes e
                      fornecedores, KYS, gestão fiscal, compliance, checagem de
                      dados de empresa
                    </div>
                  </template>
                </Card>
              </HooperSlide>
              <HooperSlide :index="10">
                <Card
                  v-animate="'fadeInLeft'"
                  card-size="medium"
                  class="h-100"
                >
                  <template #body>
                    <CardIllustration card-img-path="/images/corporate/icon-lib.svg#icon-exato-consulta" />
                    <div class="card-title">
                      Sua consulta
                    </div>
                    <div class="card-title-sub">
                      Fonte de dados
                    </div>
                    <div class="card-text">
                      Não encontrou a consulta que procurava? Entre em contato
                      com nosso time comercial
                    </div>

                    <div class="card-action">
                      <router-link
                        to
                        class="card-action-button"
                        @click.native="
                          goToElementId('contato-content', 'start')
                        "
                      >
                        Solicite sua Consulta
                      </router-link>
                    </div>
                  </template>
                </Card>
              </HooperSlide>
              <template #hooper-addons>
                <hooper-navigation slot="hooper-addons">
                  <template #hooper-prev>
                    <ArrowIcon />
                  </template>

                  <template #hooper-next>
                    <ArrowIcon class="rotate180" />
                  </template>
                </hooper-navigation>
              </template>
            </hooper>
            <div class="container">
              <Button
                v-animate="'fadeIn'"
                data-intercom="queries-offer"
                btn-size="small"
                btn-style="secondary"
                class="button-dexter mb-1"
                @click="showChat"
              >
                <svg class="icon icon-small icon-talk">
                  <use xlink:href="/images/corporate/icon-lib.svg#icon-talk" />
                </svg>Quero Testar
              </Button>
            </div>
          </section>
        </div>

        <div class="container">
          <AvailableQueries />
        </div>

        <Cue
          id-element-to-scroll="precificacao"
          img-path="/images/corporate/icon-lib.svg#icon-arrow"
          cue-color="white"
        />

        <!-- container end -->
      </SectionContent>
    </div>

    <div
      id="precificacao"
      class="scroll-margin-top"
    >
      <Precificacao class="text-center bg-light" />
    </div>

    <SectionContent
      class="bg-light"
      section-color="#FBFBFB"
    >
      <Customers />
    </SectionContent>

    <Investors />

    <ContactBannerForm
      ref="contato"
      class="bg-dark"
      img-background-color="rgba(40, 70, 79, 0.85)"
    >
      <h2>
        Automação de consultas para
        <span class="text-ColorPriYellow">redução de fraudes</span> e custos
      </h2>
      <ListFeatureContainer>
        <ListFeature
          ml
          mb
          opacity
        >
          Reduza riscos de fraudes financeiras, fiscais e de identidade
        </ListFeature>

        <ListFeature
          ml
          mb
          opacity
        >
          Elimine erros causados por trabalho manual e ganhe produtividade
        </ListFeature>

        <ListFeature
          ml
          mb
          opacity
        >
          Integre aos seus sistemas internos via APIs com facilidade
        </ListFeature>

        <ListFeature
          ml
          mb
          opacity
        >
          Valide informações em diversas fontes como Receita Federal, CGU e
          Sintegra
        </ListFeature>
      </ListFeatureContainer>
    </ContactBannerForm>
  </div>
</template>

<script>
import Button from "@/components/buttons/button.vue";
import { goToElement } from "@/mixin/go-to-element-mixin.js";
import { intercom } from "@/mixin/intercom-mixin.js";
import Cue from "@/components/shared/cue/cue.vue";
import { HooperCarousel } from "@/mixin/hooper-carousel-mixin.js";

const Customers = () =>
  import(
    /* webpackChunkName: "Customers" */ "@/components/shared/customers/customers.vue"
  );
const Investors = () =>
  import(
    /* webpackChunkName: "Investors" */
    "@/components/shared/investors/investors.vue"
  );
const ContactBannerForm = () =>
  import(
    /* webpackChunkName: "ContactBannerForm" */ "@/components/contact/contact-banner-form.vue"
  );
const ListFeatureContainer = () =>
  import(
    /* webpackChunkName: "ListFeatureContainer" */ "@/components/shared/list/feature/list-feature-container.vue"
  );
const ListFeature = () =>
  import(
    /* webpackChunkName: "ListFeature" */ "@/components/shared/list/feature/list-feature.vue"
  );
const Card = () =>
  import(/* webpackChunkName: "Card" */ "@/components/card/card.vue");

const CardIllustration = () =>
  import(
    /* webpackChunkName: "CardIllustration" */ "@/components/card/card-illustration.vue"
  );
const SectionContent = () =>
  import(
    /* webpackChunkName: "SectionContent" */ "@/components/corporate/shared/section/section-content.vue"
  );
const SectionHeroSolutions = () =>
  import(
    /* webpackChunkName: "SectionHeroSolutions" */ "@/components/corporate/shared/section/hero/section-hero-solutions.vue"
  );
const AvailableQueries = () =>
  import(
    /* webpackChunkName: "AvailableQueries" */ "@/components/corporate/queries/available-queries.vue"
  );
const FeaturesAutomacaoConsultas = () =>
  import(
    /* webpackChunkName: "FeaturesAutomacaoConsultas" */ "@/components/corporate/features/features-automacao-consultas.vue"
  );
const Precificacao = () =>
  import(
    /* webpackChunkName: "Precificacao" */ "@/components/corporate/precificacao/precificacao.vue"
  );
const SectionHeroSolutionName = () =>
  import(
    /* webpackChunkName: "SectionHeroSolutionName" */ "@/components/section/hero/section-hero-solution-name.vue"
  );
const ArrowIcon = () =>
  import(
    /* webpackChunkName: "ArrowIcon" */ "@/components/shared/icons/arrow-icon.vue"
  );

export default {

  components: {
    Button,
    Customers,
    Investors,
    ContactBannerForm,
    ListFeatureContainer,
    ListFeature,
    Card,
    CardIllustration,
    SectionContent,
    SectionHeroSolutions,
    AvailableQueries,
    Cue,
    FeaturesAutomacaoConsultas,
    Precificacao,
    SectionHeroSolutionName,
    ArrowIcon,
  },
  mixins: [goToElement, intercom, HooperCarousel],

  data() {
    return {
      hooperSettings: {
        itemsToShow: 1.18,
        trimWhiteSpace: true,
        centerMode: true,
        initialSlide: 0,
        wheelControl: false,
        infiniteScroll: false,
        breakpoints: {
          768: {
            centerMode: false,
            itemsToShow: 3,
          },
          1200: {
            centerMode: false,
            itemsToShow: 4,
          },
        },
      },
    };
  },
  mounted() {
    if (this.getRouteBaseName() === "AutomacaoConsultasPrecificacao" ||
      this.getRouteBaseName() === "AutomacaoConsultasPrecificacaoFullPath"
    ) {
      setTimeout(() => {
        this.goToElementId("precificacao", "start");
      }, 500);
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/screen-size.less";
@import "../../styles/mixin/mixin.less";

h3 {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;

  @media (min-width: @screenLarge) {
    font-size: 1.5rem;
  }
}

// global
.container-fluid,
.container-lg {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-lg {
  @media (min-width: @screenExtraLarge) {
    max-width: 1140px;
  }
}

.container-fluid {
  @media (max-width: 767px) {
    padding: 0;
  }
}

#consultas {
  .scroll-margin-top;

  background-color: #e8eceb;

  h2 {
    max-width: 268px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: @screenLarge) {
      max-width: none;
      margin-bottom: 1.25rem;
    }
  }

  /* Avaliar colocar global */

  /* Carousel */

  /deep/ .hooper-slide {
    padding: 8px;
    //filter: blur(.5px);

    @media (min-width: @screenLarge) {
      padding: 8px 12px;
    }
  }

  .hooper {
    height: 370px;
    margin-bottom: 1.25rem;

  }

}

#consultas-card {
  margin-bottom: 0.75rem;

  @media (min-width: @screenLarge) {
    margin-bottom: 1.75rem;
  }
}

/* usar componente de botão */
.card-action {
  margin-top: 1.5rem;
}

.card-action-button {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  font-weight: 500 !important;
  font-size: 0.75rem;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid @ColorPriTurquoise;
  border-radius: 15px;

  &:hover {
    color: @ColorSecGrey74;
    background-color: @ColorPriYellow;
    border: 1px solid @ColorPriYellow;
  }
}

.button-dexter {
  @media (min-width: @screenLarge) {
    //width: 220px;
  }
}
</style>
