/*
 * Code adapted from the following sources:
 * https://phrase.com/blog/posts/vue-2-localization/
 * https://github.com/nuxt-modules/i18n/blob/dd764d74f3e21640904889c981eb8eb8b4874e4a/src/templates/plugin.main.js#L295
 */

import Vue from "vue"
import VueI18n from "vue-i18n"
import defaultLocaleCode from "./default-locale-code"
import fallbackLocaleCode from "./fallback-locale-code"
import i18nPlugin from "./i18n-plugin"
import localeConfigs from "@/i18n/locale-configs";
import localeCodes from "@/i18n/locale-codes";
import {klona} from "klona/full";
import getLocaleConfig from "@/i18n/get-locale-config";
import numberFormats from "@/i18n/number-formats";

Vue.use(VueI18n)
Vue.use(i18nPlugin)

const loadLocaleMessages = () => {
  const locales = require.context(
    "./messages",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: defaultLocaleCode,
  fallbackLocale: fallbackLocaleCode,
  numberFormats,
  messages: loadLocaleMessages(),
})

/** @type {import("./types").LocaleCode[]} */
i18n.localeCodes = localeCodes
/** @type {import("./types").LocaleConfig[]} */
i18n.localeConfigs = localeConfigs
/** @type {import("./types").LocaleConfig} */
i18n.localeConfig = Vue.observable(klona(getLocaleConfig(i18n.locale)))

export default /** @type {typeof VueI18n & { localeConfigs: import("./types").LocaleConfig[]; localeCodes: import("./types").LocaleCode[]; localeConfig: import("./types").LocaleConfig }} */ i18n
