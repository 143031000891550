<template>
  <div>
    <transition name="fade3">
      <div
        v-if="showDiv"
        class="notification-box"
        :class="isError ? 'notification-box-error' : 'notification-box-ok'"
      >
        {{ message }}
      </div>
    </transition>
  </div>    
</template>

<script>

    export default {

        data: function () {
            return {
                showDiv: false,
                timeoutHandler: null,
                message: '',
                isError: false
            }
        },

        methods: {
            show: function (message, isError) {
                if (this.timeoutHandler)
                    clearTimeout(this.timeoutHandler);

                this.message = message;
                this.isError = isError;
                this.showDiv = true;

                let self = this;
                this.timeoutHandler = setTimeout(function () {
                    self.showDiv = false;
                    self.timeoutHandler = null;
                }, 6000);
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    @import "../../styles/animations.less";

    .notification-box {
        position: fixed;
        top: 0px;
        text-align: center;
        padding: 20px;
        width: 100%;
        z-index: 1031;
        color: @ColorPriWhite;
        font-size: 16px;
        opacity: 0.9;
    }

    .notification-box-error {
        background-color: @ColorError;
    }

    .notification-box-ok {
        background-color: @ColorOk;
    }
</style>