<template>
  <div
    v-show="show"
    class="loading-progress"
  >
    <div class="indeterminate" />
  </div>
</template>

<script>

    export default {

        computed: {
            show: function () {
                return this.$store.getters["LoadingModule/isLoading"];
            }
        }

    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";

    .loading-progress {
        position: fixed;
        height: 4px;
        display: block;
        width: 100%;
        background-color: @ColorPriTurquoise;
        border-radius: 2px;
        background-clip: padding-box;
        overflow: hidden;
        z-index: 999999;
    }

    .loading-progress .indeterminate {
        background-color: @ColorPriYellow;
    }

    .loading-progress .indeterminate:before {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    .loading-progress .indeterminate:after {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
        animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
    }

    @keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }

        60% {
            left: 100%;
            right: -90%;
        }

        100% {
            left: 100%;
            right: -90%;
        }
    }

    @keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }

        60% {
            left: 107%;
            right: -8%;
        }

        100% {
            left: 107%;
            right: -8%;
        }
    }
</style>