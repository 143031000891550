const routes = [
  {
    path: "/stone",
    name: "StoneMachineHome",
    meta: {
      requiresAuth: false,
      title: "Maquininha Stone | Exato Digital",
      robots: "noindex"
    },
    component: () => import(
      /* webpackChunkName: "StoneMachineHome" */ "@/views/stone/stone-machine-home.vue"
    ),
  },
  {
    path: "/stone/aplicativos",
    name: "StoneMachineApps",
    meta: {
      requiresAuth: false,
      title: "Maquininha Stone - Aplicativos | Exato Digital",
      robots: "noindex"
    },
    component: () => import(
      /* webpackChunkName: "StoneMachineApps" */ "@/views/stone/stone-machine-apps.vue"
    ),
  },
  {
    path: "/stone/tipo-relatorio",
    name: "StoneMachineReportType",
    meta: {
      requiresAuth: false,
      title: "Maquininha Stone - Selecionar Tipo de Relatório | Exato Digital",
      robots: "noindex"
    },
    component: () => import(
      /* webpackChunkName: "StoneMachineReportType" */ "@/views/stone/stone-machine-report-type.vue"
    ),
  },
  {
    path: "/stone/tipo-relatorio/pessoa-fisica",
    name: "StoneMachinePerson",
    meta: {
      requiresAuth: false,
      title: "Maquininha Stone - Informar CPF | Exato Digital",
      robots: "noindex"
    },
    component: () => import(
      /* webpackChunkName: "StoneMachinePerson" */ "@/views/stone/stone-machine-pessoa-fisica.vue"
    ),
  },
  {
    path: "/stone/local-receber-relatorio",
    name: "StoneMachineReceivePlace",
    meta: {
      requiresAuth: false,
      title: "Maquininha Stone - Local para Receber Relatório | Exato Digital",
      robots: "noindex"
    },
    component: () => import(
      /* webpackChunkName: "StoneMachineReceivePlace" */ "@/views/stone/stone-machine-receive-place.vue"
    ),
  },
  {
    path: "/stone/local-receber-relatorio/whatsapp",
    name: "StoneMachineReceivePlaceWhatsApp",
    meta: {
      requiresAuth: false,
      title: "Maquininha Stone - Receber Relatório no WhatsApp | Exato Digital",
      robots: "noindex"
    },
    component: () => import(
      /* webpackChunkName: "StoneMachineReceivePlaceWhatsApp" */ "@/views/stone/stone-machine-receive-place-phone.vue"
    ),
  },
  {
    path: "/stone/corfirmar-relatorio",
    name: "StoneMachineReportConfirm",
    meta: {
      requiresAuth: false,
      title: "Maquininha Stone - Confirmar Relatório | Exato Digital",
      robots: "noindex"
    },
    component: () => import(
      /* webpackChunkName: "StoneMachineReportConfirm" */ "@/views/stone/stone-machine-report-confirm.vue"
    ),
  },
  {
    path: "/stone/processando-relatorio",
    name: "StoneMachineReportProcessing",
    meta: {
      requiresAuth: false,
      title: "Maquininha Stone - Processando Relatório | Exato Digital",
      robots: "noindex"
    },
    component: () => import(
      /* webpackChunkName: "StoneMachineReportProcessing" */ "@/views/stone/stone-machine-report-processing.vue"
    ),
  },
];

export default routes;
