import httpRequest from '@/services/service-providers/httpRequest-service.js';

class IndividualPersonOnboarding {

    start(redirectToUrl) {
        return httpRequest.axios.get('IndividualPersonValidation/Start', {
            params: {
                redirectToUrl: redirectToUrl
            }
        });
    }

    registerIndividualPerson(fullName, cpf, birthday, phoneNumber, captchaToken) {
        return httpRequest.axios.post('IndividualPersonValidation/RegisterIndividualPerson',
            {
                FullName: fullName,
                CPF: cpf,
                Birthday: birthday,
                PhoneNumber: phoneNumber,
                CaptchaToken: captchaToken
            });
    }

    getMyMainEmail() {
        return httpRequest.axios.get('IndividualPersonValidation/GetMyMainEmail');
    }

    resendEmailConfirmation() {
        return httpRequest.axios.get('IndividualPersonValidation/ResendEmailConfirmation');
    }

    resendSMSCode(requestUID) {
        return httpRequest.axios.get('IndividualPersonValidation/ResendSMSCode', {
            params: {
                requestUID: requestUID
            }
        });
    }

    verifySMSCode(requestUID, smsCode, onboardingRequestUID, quizRequiredAfterSms) {
        return httpRequest.axios.post('IndividualPersonValidation/VerifySMSCode', {
            requestUID: requestUID,
            smsCode: smsCode,
            onboardingRequestUID: onboardingRequestUID,
            quizRequiredAfterSms: quizRequiredAfterSms
        });
    }

    sendSMSFromPhoneChallenge(maskedPhone, onboardingRequestUID) {
        return httpRequest.axios.get('IndividualPersonValidation/SendSMSFromPhoneChallenge', {
            params: {
                maskedPhone: maskedPhone,
                onboardingRequestUID: onboardingRequestUID
            }
        });
    }

    getQuiz(onboardingRequestUID) {
        return httpRequest.axios.get('IndividualPersonValidation/GetQuiz', {
            params: {
                onboardingRequestUID: onboardingRequestUID
            }
        });
    }

    answerQuiz(quizIdentifier, answers, onboardingRequestUID) {
        return httpRequest.axios.post('IndividualPersonValidation/AnswerQuiz', {
            QuizIdentifier: quizIdentifier,
            Answers: answers,
            OnboardingRequestUID: onboardingRequestUID
        });
    }
    
}

export default new IndividualPersonOnboarding();
