<template>
  <div>
    <p class="label">
      {{ percent }}% do cadastro concluído
    </p> 
    <div class="outside">
      <div
        class="inside"
        :style="{ width: percent + '%' }"
      />
    </div>
  </div>    
</template>

<script>
    export default {

        props: {
            percent: 0
        }
        
    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";

    .label {
        font-size: 16px;
        margin-bottom: .5rem;
    }
    .outside {        
        background-color: rgba(245, 247, 247, 0.5);
        border-radius: 5px;
        height: 15px;
        width: inherit;
    }

    .inside {
        background-color: @ColorPriWhite;
        border-radius: 5px;
        height: 100%;
    }
</style>