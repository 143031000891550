<template>
  <!-- <div v-if="!pendencyExecuteQuery == ''" class="step-component content" :class="darkMode ? 'dark-mode' : ''"> -->
  <div
    class="step-component content"
    :class="darkMode ? 'dark-mode' : ''"
  >
    <div class="fundo">
      <div
        class="line line1"
        :class="stepNumber > 1 ? 'line-selected' : ''"
      />
      <div
        class="line line2"
        :class="stepNumber > 2 ? 'line-selected' : ''"
      />
    </div>
    <div class="up">
      <div
        class="bullets bullets-1"
        :class="classItem(0, emailValidated)"
      >
        <div class="circle">
          <span v-if="!emailValidated">1</span>
          <svg
            v-else 
            class="icon icon-micro icon-check"
          >
            <use xlink:href="/images/icon-lib.svg#icon-check" />
          </svg>
        </div>
        <label class="description">{{ changeTextForStepOne }}</label>
      </div>
      <div
        class="bullets bullets-2"
        :class="classItem(1, userOnboarded)"
      >
        <div class="circle">
          <span v-if="!userOnboarded">2</span>
          <svg
            v-else 
            class="icon icon-micro icon-check"
          >
            <use xlink:href="/images/icon-lib.svg#icon-check" />
          </svg>
        </div>
        <label class="description">{{ changeTextForStepTwo }}</label>
      </div>
      <div
        v-if="pendencyExecuteQuery === 'PENDENCY-COMPANY'"
        class="bullets bullets-3"
        :class="classItem(2, currentCompanyOnboarded)"
      >
        <div class="circle">
          <span v-if="!currentCompanyOnboarded">3</span>
          <svg
            v-else 
            class="icon icon-micro icon-check"
          >
            <use xlink:href="/images/icon-lib.svg#icon-check" />
          </svg>
        </div>
        <label class="description">{{ changeTextForStepThree }}</label>
      </div>
    </div>
  </div>
</template>

<script>
    export default {

        props: {
            stepNumber: {
                type: Number,
                required: false,
                default: 1
            },
            darkMode: {
                type: Boolean,
                required: false,
                default: false
            },
            changeTextForStepOne: {
                type: String,
                required: false,
                default: "Preenchimento"
            },
            changeTextForStepTwo: {
                type: String,
                required: false,
                default: "Confirmação"
            },
            changeTextForStepThree: {
                type: String,
                required: false,
                default: "Resultado"
            },
            pendencyExecuteQuery: {
                type: String,
                required: false,
                default: ''
            }
        },

        computed : {
            emailValidated: function () {
                return this.$store.getters['UserModule/emailValidated'];
            },
            userOnboarded: function () {
                return this.$store.getters['UserModule/userOnboarded'];
            },
            currentCompanyOnboarded: function () {
                return this.$store.getters['UserModule/currentCompanyOnboarded'];
            }
        },

        methods : {
            classItem : function (stepItem, itemValidated) {
                return {
                    'status-active': this.stepNumber > stepItem,
                    'status-done': itemValidated                    
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    @import "base-step-indicator.less";
    
</style>