// https://localhost:1337/corporate/background-check/precificacao
import BackgroundCheck from "@/views/corporate/background-check.vue";

// TODO - A rota com hash não estava funcionando
//tirado do lazy load para funcionar a rota com hash
//https://localhost:1337/corporate/automacao-consultas#precificacao
import AutomacaoConsultas from "@/views/corporate/automacao-consultas.vue";

const HomeCorporate = () =>
  import(
    /* webpackChunkName: "HomeCorporate" */
    "@/views/corporate/home-corporate.vue"
  );

const ApiVerificacaoCpf = () =>
  import(
    /* webpackChunkName: "ApiVerificacaoCpf" */ "@/views/corporate/api-verificacao-cpf.vue"
  );

const routes = [
  {
    path: "/corporate",
    name: "HomeCorporate",
    component: HomeCorporate,
    meta: {
      requiresAuth: false,
      title: "Soluções para Background Check, KYC e Analytics | Exato Corporate"
    }
  },
  {
    path: "/corporate/afiliados",
    name: "Afiliados",
    component: HomeCorporate,
    meta: {
      requiresAuth: false,
      title: "Soluções para Background Check, KYC e Analytics | Exato Corporate"
    }
  },
  {
    path: "/corporate/flashcheck",
    name: "Flashcheck",
    component: HomeCorporate,
    meta: {
      requiresAuth: false,
      title: "Soluções para Background Check, KYC e Analytics | Exato Corporate"
    }
  },
  {
    path: "/corporate/background-check",
    name: "BackgroundCheck",
    component: BackgroundCheck,
    meta: {
      requiresAuth: false,
      title: "Background Check | Exato Corporate"
    }
  },
  // https://localhost:1337/corporate/background-check/precificacao
  {
    path: "/corporate/background-check/precificacao",
    name: "BackgroundCheckPrecificacao",
    component: BackgroundCheck,
    meta: {
      requiresAuth: false,
      title: "Background Check | Exato Corporate"
    }
  },
  {
    path: "/corporate/contato-especialista-background-check",
    name: "BackgroundCheckContato",
    component: BackgroundCheck,
    meta: {
      requiresAuth: false,
      title: "Contato especialista Background Check | Exato Corporate"
    }
  },
  {
    path: "/corporate/automacao-consultas",
    name: "AutomacaoConsultas",
    component: AutomacaoConsultas,
    meta: {
      requiresAuth: false,
      title: "Automação de Consultas | Exato Corporate"
    }
  },

  //rota focando em precificação na automação de consultas
  {
    path: "/corporate/precificacao",
    name: "AutomacaoConsultasPrecificacao",
    component: AutomacaoConsultas,
    meta: {
      requiresAuth: false,
      title: "Automação de Consultas | Exato Corporate"
    }
  },
  {
    path: "/corporate/automacao-consultas/precificacao",
    name: "AutomacaoConsultasPrecificacaoFullPath",
    component: AutomacaoConsultas,
    meta: {
      requiresAuth: false,
      title: "Automação de Consultas | Exato Corporate"
    }
  },

  {
    path: "/corporate/sobre-nos",
    name: "AboutCorporate",
    component: () => import(
      /* webpackChunkName: "AboutCorporate" */ "@/views/corporate/about.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Sobre Nós | Exato Corporate"
    }
  },
  {
    path: "/corporate/contato",
    name: "ContactCorporate",
    component: () => import(
      /* webpackChunkName: "ContactCorporate" */ "@/views/corporate/contact.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Contato Comercial | Exato Corporate"
    }
  },
  {
    path: "/corporate/inteligencia-mercado",
    name: "InteligenciaMercado",
    component: () => import(
      /* webpackChunkName: "InteligenciaMercado" */ "@/views/corporate/inteligencia-mercado.vue"
    ),
    meta: {
      requiresAuth: false,
      title: " Inteligência de Mercado | Exato Corporate"
    }
  },
  {
    path: "/corporate/onboarding-digital",
    name: "OnboardingDigital",
    component: () => import(
      /* webpackChunkName: "OnboardingDigital" */ "@/views/corporate/onboarding-digital.vue"
    ),
    meta: {
      requiresAuth: false,
      title: " Onboarding Digital | Exato Corporate"
    }
  },
  {
    path: "/corporate/background-check-recrutamento-selecao",
    name: "BackgroundCheckRecrutamentoSelecao",
    component: () => import(
      /* webpackChunkName: "BackgroundCheckRecrutamentoSelecao" */ "@/views/corporate/recrutamento-selecao.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Background Check para Recrutamento de Candidatos | Exato Corporate"
    }
  },
  {
    path: "/rh",
    name: "BackgroundCheckRecrutamentoSelecaoRh",
    component: () => import(
      /* webpackChunkName: "BackgroundCheckRecrutamentoSelecao" */ "@/views/corporate/recrutamento-selecao.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Background Check para Recrutamento de Candidatos | Exato Corporate"
    }
  },
  {
    path: "/corporate/background-check-prevencao-lavagem-dinheiro",
    name: "BackgroundCheckPrevencaoLavagemDinheiro",
    component: () => import(
      /* webpackChunkName: "BackgroundCheckPrevencaoLavagemDinheiro" */ "@/views/corporate/prevencao-lavagem-dinheiro.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Background Check para Prevenção à Lavagem de Dinheiro | Exato Corporate"
    }
  },
  {
    path: "/corporate/background-check-verificacao-fornecedores",
    name: "BackgroundCheckVerificacaoFornecedores",
    component: () => import(
      /* webpackChunkName: "BackgroundCheckVerificacaoFornecedores" */ "@/views/corporate/verificacao-fornecedores.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Background Check para Verificação de Fornecedores - | Exato Corporate",
      // description: "teste"
    }
  },
  {
    path: "/corporate/background-check-compliance",
    name: "BackgroundCheckCompliance",
    component: () => import(
      /* webpackChunkName: "BackgroundCheckCompliance" */ "@/views/corporate/verificacoes-compliance.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Background Check para Compliance | Exato Corporate",
      // description: "teste"
    }
  },
  {
    path: "/compliance",
    name: "Compliance",
    component: () => import(
      /* webpackChunkName: "Compliance" */ "@/views/corporate/verificacoes-compliance.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Automatize verificações para compliance | Exato Corporate",
      // description: "teste"
    }
  },
  {
    path: "/corporate/api-verificacao-cpf",
    name: "ApiVerificacaoCpf",
    component: ApiVerificacaoCpf,
    meta: {
      requiresAuth: false,
      title: "API de Verificação de CPF | Exato Corporate",
    }
  },
  // rota criada para o evento fraude day - Pedido do Marketing 
  // https://3.basecamp.com/5723137/buckets/36714987/card_tables/cards/7888712923
  {
    path: "/kyc",
    name: "KycApiVerificacaoCpf",
    component: ApiVerificacaoCpf,
    meta: {
      requiresAuth: false,
      title: "API de Verificação de CPF | Exato Corporate",
    }
  },
  {
    path: "/corporate/validacao-identidade-apostadores",
    name: "ValidacaoIdentidadeApostadores",
    component: () => import(
      /* webpackChunkName: "ValidacaoIdentidadeApostadores" */ "@/views/corporate/landing/validacao-identidade-apostadores.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Valide a identidade dos apostadores | Exato Corporate",
    }
  },
  {
    path: "/igaming",
    name: "ValidacaoIdentidadeApostadoresIgaming",
    component: () => import(
      /* webpackChunkName: "ValidacaoIdentidadeApostadoresIgaming" */ "@/views/corporate/landing/validacao-identidade-apostadores.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Economize até 30% na validação de CPF dos apostadores | Exato Corporate",
    }
  },

  {
    path: "/corporate/testar-relatorio-pessoa-juridica",
    name: "TestReportPessoaJuridica",
    component: () => import(
      /* webpackChunkName: "TestReportPessoaJuridica" */ "@/views/corporate/test/test-report-pessoa-juridica.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Teste Grátis o Relatório Pessoa Jurídica | Exato Corporate"
    }
  },
  {
    path: "/corporate/testar-relatorio-pessoa-fisica",
    name: "TestReportPessoaFisica",
    component: () => import(
      /* webpackChunkName: "TestReportPessoaFisica" */ "@/views/corporate/test/test-report-pessoa-fisica.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Teste Grátis o Relatório Pessoa Física | Exato Corporate"
    }
  },
  {
    path: "/corporate/planos-sla",
    name: "PlanosSLA",
    component: () => import(
      /* webpackChunkName: "PlanosSLA" */ "@/views/corporate/planos-sla.vue"
    ),
    meta: {
      requiresAuth: false,
      title: "Planos SLA | Exato Corporate",
      robots: "noindex"
    }
  },
];

export default routes;
