<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        {{ $t("signup") }}
      </h5>
      <h5
        class="title2"
        v-html="getScreenText.title2"
      />
    </template>
    <template slot="right">
      <h2 v-if="$mq === 'sm' || $mq === 'md'">
        {{ $t("signup") }}
      </h2>

      <h4 class="header-text">
        {{ $t("components.RegisterSocialNetwork.headerText") }}
      </h4>
      <div class="inputs-box">
        <RegisterInviteCode
          :screen-locked="screenLocked"
          @updatePromotionalCode="updatePromotionalCode"
        />

        <div class="accept-contract-box">
          <InputCheckbox
            v-model="acceptContract"
            class-font-size="body-text-2"
            @click="verifyMark"
          >
            {{ $t("acceptContract[0]") }}
            <router-link
              class="list-group-item"
              :to="localePath('PrivacyPolicy')"
              target="_blank"
            >
              {{ $t("acceptContract[1]") }}
            </router-link>
            {{ $t("acceptContract[2]") }}
            <router-link
              class="list-group-item"
              :to="localePath('TermsConditions')"
              target="_blank"
            >
              {{ $t("acceptContract[3]") }}
            </router-link>
            {{ $t("acceptContract[4]") }}
          </InputCheckbox>
        </div>
        <p
          v-if="message"
          class="error-message"
        >
          {{ message }}&nbsp;
        </p>
      </div>
      <div class="button-box">
        <ButtonLarge
          class="button"
          :disabled="screenLocked"
          @click="register"
        >
          {{ $t("signup") }}
        </ButtonLarge>
      </div>
      <Portal to="app-end">
        <div
          id="recaptcha-register-network"
          key="recaptcha-register-network"
        />
      </Portal>
    </template>
  </ModalFullScreenContent>
</template>

<script>
import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
import InputText from "@/components/inputs/input-text.vue";
import InputCheckbox from "@/components/inputs/input-checkbox.vue";
import ButtonLarge from "@/components/buttons/button-large.vue";
import RegisterService from "@/services/service-providers/webapp-services/register-service.js";
import { registerInviteCode } from "@/mixin/register-invite-code-mixin.js";
import { checkWhatsAppText } from "@/mixin/whatsapp-param-text-mixin.js";
import { textScreenRegister } from "@/mixin/text/text-screen-register-mixin.js";
import googleCaptchaProvider from "@/services/captcha-providers/google.service";
const RegisterInviteCode = () =>
  import(
    /* webpackChunkName: "RegisterInviteCode" */ "@/components/register/register-invite-code.vue"
  );

export default {
  components: {
    ModalFullScreenContent,
    InputText,
    InputCheckbox,
    ButtonLarge,
    RegisterInviteCode
  },
  mixins: [registerInviteCode, checkWhatsAppText, textScreenRegister],
  props: {
    socialRegistration: {}
  },
  data() {
    return {
      screenLocked: false,
      promotionalCode: "",
      acceptContract: false,
      message: ""
    };
  },
  mounted() {
    this.$nextTick(function() {
      googleCaptchaProvider.initialize("recaptcha-register-network");
    });
  },
  destroyed() {
    googleCaptchaProvider.dispose();
  },
  methods: {
    register() {
      if (!this.acceptContract) {
        this.message = this.$t("refusedContractError");
        return;
      }

      this.message = "";
      this.screenLocked = true;
      let self = this;

      self.setDataWhatsAppParamToPost();

      RegisterService.registerUserFromSocialNetwork(
        this.socialRegistration.socialNetwork,
        this.socialRegistration.accessToken,
        this.promotionalCode,
        self.accountUserExternalId,
        self.accountOrganizationExternalId
      )
        .then(function(response) {
          self.screenLocked = false;

          self.$store.commit("UserModule/login", response.data.data);

          self.$emit("closeRegisterRequest");
          self.$bus.$emit("global-needReloadUserEnvironment", true);
        })
        .catch(function(error) {
          self.screenLocked = false;
          self.message = error.response.data.message;
        });
    },
    verifyMark() {
      if (this.acceptContract) this.message = "";
    }
    // showPrivacyPolicy() {
    //     this.$emit('closeRegisterRequest');
    //     this.$router.push('/politica-privacidade');
    // },
    // showTermsConditions() {
    //     this.$emit('closeRegisterRequest');
    //     this.$router.push('/termos-condicoes');
    // },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
@import "../../styles/input-group.less";

.disabled {
  cursor: auto;
  color: #5f5c5c;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
}

.icon-help {
  width: 16px;
  height: 16px;
}

/deep/ .invite-code {
  text-align: left !important;
}
</style>
