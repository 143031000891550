<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        Validação do celular
      </h5>
      <h5 class="title2">
        Validando seu celular você terá acesso a mais serviços de consulta na plataforma.
      </h5>

      <StepIndicatorModal
        v-if="pendencyExecuteQuery !== 'PENDENCY-EMAIL'"
        class="step-modal"
        :step-number="2"
        change-text-for-step-one="Validar E-mail"
        change-text-for-step-two="Completar Cadastro"
        change-text-for-step-three="Cadastrar Empresa"
        :pendency-execute-query="pendencyExecuteQuery"
      />
    </template>
    <template slot="right">
      <h4 class="header-text">
        Enviamos um SMS de confirmação para o número:
      </h4>

      <p class="box-valid-info">
        <img
          src="/images/phone.svg"
          alt="phone"
        >
        {{ maskedPhone }}
      </p>

      <div class="inputs-box">
        <div>
          <InputText
            v-model="smsCode"
            placeholder="Digite o codigo recebido via SMS"
            maxlength="10"
            @keyup.enter.native="verify"
          />
        </div>
      </div>

      <p class="phone-info-paragraph">
        Não recebeu o SMS?
        <a
          v-if="resendVisible"
          href="#"
          :disabled="screenLocked"
          @click.prevent="resend"
        >Reenviar SMS</a>
        <span
          v-if="messageSuccess != ''"
          class="success-message"
        >{{ messageSuccess }}</span>
      </p>            
      <p
        v-if="messageError != ''"
        class="error-message"
      >
        {{ messageError }}&nbsp;
      </p>
      <div class="button-box">
        <ButtonLarge
          :disabled="screenLocked"
          class="button"
          @click="verify"
        >
          Continuar
        </ButtonLarge>
      </div>
    </template>
  </ModalFullScreenContent>
</template>


<script>
    import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
    import ProgressBar from "@/components/misc/progress-bar.vue";
    import IndividualPersonValidationService from "@/services/service-providers/webapp-services/individual-person-validation-service.js";
    import InputText from "@/components/inputs/input-text.vue";
    import ButtonLarge from "@/components/buttons/button-large.vue";
    import StepIndicatorModal from "@/views/product/step-indicator-modal.vue";
    import { rdConversion } from "@/mixin/rd-conversion.js";    

    export default {
        components: {
            ModalFullScreenContent,
            ProgressBar,
            InputText,
            ButtonLarge,
            StepIndicatorModal
        },

        mixins: [rdConversion],

        props: {
            maskedPhone: "",
            requestUID: "",
            onboardingRequestUID: "",
            quizRequiredAfterSms: {
                type: Boolean,
                required: false,
                default: false
            },
            
            pendencyExecuteQuery: {
                type: String,
                required: false,
                default: ''
            }
            
        },

        data: function () {
            return {
                resendVisible: false,
                screenLocked: false,
                smsCode: "",
                messageError: '',
                messageSuccess: ''
            }
        },

        mounted: function () {
            this.blockResend();
        },

        methods: {

            resend: function () {

                let self = this;
                this.messageError = '';
                this.messageSuccess = '';
                this.screenLocked = true;

                IndividualPersonValidationService.resendSMSCode(this.requestUID)
                    .then(function (response) {
                        self.screenLocked = false;
                        self.messageSuccess = "SMS reenviado.";
                        self.blockResend();
                        self.requestUID = response.data.data.requestUID;
                    })
                    .catch(function (error) {
                        self.screenLocked = false;
                        self.messageError = error.response.data.message;
                    });
            },

            verify: function () {

                if (this.smsCode == "") {
                    this.messageError = "Preencha o código recebido via SMS."
                    return;
                }

                let self = this;
                this.messageError = '';
                this.messageSuccess = '';
                this.screenLocked = true;

                IndividualPersonValidationService.verifySMSCode(this.requestUID, this.smsCode, this.onboardingRequestUID, this.quizRequiredAfterSms)
                    .then(function () {
                        self.screenLocked = false;
                        if(self.quizRequiredAfterSms)
                            self.$emit("startQuiz", self);
                        else
                            self.$emit("validationSuccessful");
                    })
                    .catch(function (error) {
                        self.screenLocked = false;
                        self.messageError = error.response.data.message;
                    });
            },

            blockResend: function () {
                let self = this;
                this.resendVisible = false;
                setTimeout(function () {
                    self.resendVisible = true;
                    self.messageSuccess = "";
                }, 5000);
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    @import "../../styles/base-modal.less";

     
    .phone-info-paragraph {
        margin-top: 2rem;
        font-weight: 300;
    }
    
</style>