import defaultLocaleCode from "./default-locale-code";
import isLocaleSupported from "./is-locale-supported";
import routeNameSeparator from "./route-name-separator";

/** @type {(name?: string, localeCode?: string) => string} */
const createRouteName = (name, localeCode) => name
  ? (localeCode ? `${name}${routeNameSeparator}${localeCode}` : name)
  : "";

/** @type {(path: string, localeCode?: string) => string} */
const createRoutePath = (path, localeCode) =>
  !localeCode || localeCode === defaultLocaleCode
    ? path
    : `/${localeCode}${path}`;

/**
 * @param {import("./types").RouteConfigTemplate} routeConfigTemplate
 * @returns {import("vue-router").RouteConfig[]}
 */
const createRouteConfigs = ({ baseConfig, localeConfigs }) => {
  if (baseConfig == null) {
    return [];
  }

  if (localeConfigs == null) {
    return [baseConfig];
  }

  const validLocaleConfigs = localeConfigs
    .filter((localeConfig) => isLocaleSupported(localeConfig.code));

  if (!validLocaleConfigs.length) {
    return [baseConfig];
  }

  const routeConfigs = validLocaleConfigs.map(localeConfig => ({
    ...baseConfig,
    path: createRoutePath(
      localeConfig.path || baseConfig.path,
      localeConfig.code
    ),
    name: createRouteName(baseConfig.name, localeConfig.code) || undefined,
    component: localeConfig.component || baseConfig.component || undefined,
    alias: localeConfig.alias
      ? Array.isArray(localeConfig.alias)
        ? localeConfig.alias.map(alias =>
            createRoutePath(alias, localeConfig.code)
          )
        : createRoutePath(localeConfig.alias, localeConfig.code)
      : baseConfig.alias
      ? Array.isArray(baseConfig.alias)
        ? baseConfig.alias.map(alias =>
            createRoutePath(alias, localeConfig.code)
          )
        : createRoutePath(baseConfig.alias, localeConfig.code)
      : undefined,
    meta: {
      ...baseConfig.meta,
      title:
        (localeConfig.meta && localeConfig.meta.title) ||
        (baseConfig.meta && baseConfig.meta.title) ||
        undefined,
      description:
        (localeConfig.meta && localeConfig.meta.description) ||
        (baseConfig.meta && baseConfig.meta.description) ||
        undefined,
    },
  }));

  return routeConfigs;
}

export default createRouteConfigs;
