import routeNameSeparator from "@/i18n/route-name-separator";

/**
 * @typedef {import("vue-router").Route} Route
 * @typedef {(route?: Route) => string | undefined} GetRouteBaseName
 */

/** @type {GetRouteBaseName} */
const getRouteBaseName = (route) => {
    if (!route || !route.name) {
        return
    }
    return route.name.split(routeNameSeparator)[0]
}

export default getRouteBaseName
