<template>
  <div
    id="app"
    :mode="vueMode"
  >
    <NotificationBar ref="notificationBar" />
    <IndeterminateLoadingBar />
    <header v-if="!isPrototipoStone">
      <HeaderExecutiveCheck
        v-if="isExecutiveCheck"
        ref="headerExecutiveCheck"
      />
      <HeaderCorporate
        v-else-if="isCorporate"
        ref="headerCorporate"
      />
      <HeaderFlashCheck
        v-else-if="isFlashCheck"
        ref="headerFlashCheck"
      />

      <HeaderDocCheck
        v-else-if="isDocCheck"
        ref="headerDocCheck"
      />

      <HeaderDocCheckPortaria
        v-else-if="isDocCheckPortaria"
        ref="headerDocCheckPortaria"
      />

      <HeaderMaintenance
        v-else-if="isMaintenance"
        ref="HeaderMaintenance"
      />

      <HeaderExato v-else />
    </header>
    <main>
      <transition
        name="pagina"
        mode="out-in"
      >
        <router-view />
      </transition>
    </main>

    <footer v-if="!isPrototipoStone">
      <FooterComun
        v-if="isExato"
        show-disclaimer-lgpd
      />
      <FooterClean
        v-else-if="isFooterClean"
        maintenance
      />
      <FooterComun v-else />
    </footer>

    <IndividualPersonValidationFlow ref="onboardingFlow" />
    <BuyCreditPixFlow ref="buyCreditPixFlow" />
    <AddPhoneFlow ref="addPhoneFlow" />
    <AddAddressFlow ref="addAddressFlow" />

    <LoginFlow ref="loginFlow" />
    <PasswordRecoveryFlow ref="passwordRecoveryFlow" />
    <BasicRegistrationFlow ref="basicRegistrationFlow" />
    <UserRegistrationCompanyFlow ref="userRegistrationCompanyFlow" />

    <!-- comentado, pois não foi encontrado o uso -->
    <!-- <ModalCenter ref="modalCenter" v-if="showModal" >
            <ModalCenterContentNoButton :title="title" :image="image" :text="text" @closing="closeModal" />
        </ModalCenter>
        <transition name="fade3">
            <ModalCenter v-if="showModalDisabledFeature">
                <ModalCenterContent
                    class="center"
                    modalTitle="Função não disponível"
                    text="Esta funcionalidade será disponibilizada em breve"
                    :buttons=" {
                        button1: {
                            label: 'ok'
                        }
                    }"
                    @closing="showModalDisabledFeature = false"
                />
            </ModalCenter>
        </transition> -->

    <PortalTarget
      name="app-end"
      multiple
    />
  </div>
</template>

<script>
import HeaderExato from "@/components/structure/exato/header-exato.vue";
import IndeterminateLoadingBar from "@/components/misc/indeterminate-loading-bar.vue";
// // import IntercomService from "@/services/chat-providers/intercom-service.js";
import NotificationBar from "@/components/misc/notification-bar.vue";
import IndividualPersonValidationFlow from "@/views/onboarding/individual-person-validation-flow.vue";
import LoginFlow from "@/views/onboarding/login-flow.vue";
import PasswordRecoveryFlow from "@/views/onboarding/password-recovery-flow.vue";
import BasicRegistrationFlow from "@/views/onboarding/basic-registration-flow.vue";
import UserRegistrationCompanyFlow from "@/views/edit-profile/user-registration-company-flow.vue";
import ModalCenterContentNoButton from "@/components/boxes/modal-center-content-no-button.vue";
import ModalCenter from '@/components/boxes/modal-center.vue';
import HttpRequest from '@/services/service-providers/httpRequest-service.js';
import ModalCenterContent from '@/components/boxes/modal-center-content.vue';
import LoginService from "@/services/service-providers/webapp-services/login-service.js";
import { AuthenticationContext } from "vue-adal";

const HeaderCorporate = () => import(
    /* webpackChunkName: "HeaderCorporate" */ '@/components/structure/corporate/header-corporate.vue'
);
const HeaderExecutiveCheck = () => import(
    /* webpackChunkName: "HeaderExecutiveCheck" */ '@/components/structure/executivecheck/header-executivecheck.vue'
);
const HeaderFlashCheck = () => import(
    /* webpackChunkName: "HeaderFlashCheck" */ '@/components/structure/flashcheck/header-flashcheck.vue'
);
const HeaderDocCheck = () => import(
    /* webpackChunkName: "HeaderDocCheck" */ '@/components/structure/doccheck/header-doccheck.vue'
);
const HeaderDocCheckPortaria = () => import(
    /* webpackChunkName: "HeaderDocCheckPortaria" */ '@/components/structure/doccheck/header-doccheck-portaria.vue'
);

const HeaderMaintenance = () => import(
    /* webpackChunkName: "HeaderMaintenance" */ '@/components/structure/maintenance/header-maintenance.vue'
);

const FooterComun = () => import(
    /* webpackChunkName: "FooterComun" */ '@/components/structure/footer-comun.vue'
);

const FooterClean = () => import(
    /* webpackChunkName: "FooterClean" */ '@/components/structure/footer-clean.vue'
);

const BuyCreditPixFlow = () => import(
    /* webpackChunkName: "BuyCreditPixFlow" */ '@/views/credit/buy-credit-pix-flow.vue'
);
const AddPhoneFlow = () => import(
    /* webpackChunkName: "AddPhoneFlow" */ '@/views/edit-profile/add-phone/add-phone-flow.vue'
);

const AddAddressFlow = () => import(
    /* webpackChunkName: "AddAddressFlow" */ '@/views/credit/add-address/add-addess-flow.vue'
);

export default {
    name: 'App',
    components: {
        HeaderExato,
        IndeterminateLoadingBar,
        NotificationBar,
        IndividualPersonValidationFlow,
        LoginFlow,
        PasswordRecoveryFlow,
        BasicRegistrationFlow,
        UserRegistrationCompanyFlow,
        ModalCenter,
        ModalCenterContentNoButton,
        ModalCenterContent,
        HeaderCorporate,
        HeaderExecutiveCheck,
        FooterClean,
        HeaderFlashCheck,
        BuyCreditPixFlow,
        AddPhoneFlow,
        AddAddressFlow,
        FooterComun,
        HeaderDocCheck,
        HeaderDocCheckPortaria,
        HeaderMaintenance,
    },
    data() {
        return {
            isExato: false,
            isCorporate: false,
            isExecutiveCheck: false,
            isFlashCheck: false,
            isDocCheck: false,
            isDocCheckPortaria: false,
            isMaintenance: false,
            isPrototipoStone: false,
            isFooterClean: false,
            title: '',
            text: '',
            image: '',
            showModal: false,
            showModalDisabledFeature: false,
        }
    },
    computed: {
        vueMode() {
            return process.env.VUE_APP_MODE;
        },
    },
    watch: {
        $route(route) {
            this.setActiveProduct(route);
        }
    },
    created() {
        let self = this;

        this.setActiveProduct(this.$route);

        this.$bus.$on('global-notification', function (message, isError) {
            self.$refs.notificationBar.show(message, isError);
        });
        this.$bus.$on('global-initOnboard', function (redirectToUrl, pendencyExecuteQuery) {
            self.$refs.onboardingFlow.init(redirectToUrl, pendencyExecuteQuery);
        });

        this.$bus.$on('global-onboardDepdendsOnUserState', function () {
            self.$refs.onboardingFlow.onboardDepdendsOnUserState();
        });

        this.$bus.$on('global-addPhone', function (currentModalOpen, phoneValidationPayload, packgeToBuyPayload) {
            self.$refs.addPhoneFlow.init(currentModalOpen, phoneValidationPayload, packgeToBuyPayload);
        });

        this.$bus.$on('global-addAddress', function (packgeToBuyPayload) {
            self.$refs.addAddressFlow.init(packgeToBuyPayload);
        });

        this.$bus.$on('global-buyCreditPix', function (currentModalOpen, packgeToBuyPayload) {
            self.$refs.buyCreditPixFlow.init(currentModalOpen, packgeToBuyPayload);
        });


        this.$bus.$on('global-success-email', function (pendencyExecuteQuery) {
            self.$refs.onboardingFlow.successEmail(pendencyExecuteQuery);
        });
        this.$bus.$on('global-login', function (redirectToUrl) {
            self.closeModalNotAuthenticated();
            self.$refs.loginFlow.init(redirectToUrl);
        });
        this.$bus.$on('global-recover-password', function () {
            self.$refs.loginFlow.recoverPassword();
        });

        // criado para fechar os modais no mobile web não logado
        // funciona quando temos somente uma landing mobile web
        this.$bus.$on('close-modals', function () {
            self.closeModalNotAuthenticated();
        });

        this.$bus.$on('global-register', function (preInputEmail) {
            self.closeModalNotAuthenticated();
            self.$refs.basicRegistrationFlow.openRegistration(preInputEmail);
        });

        this.$bus.$on('global-register-social', function (value) {
            self.$refs.basicRegistrationFlow.socialRegistrationRequested(value);
        });

        this.$bus.$on('global-logout', function () {
            self.logout();
        });
        this.$bus.$on('global-modal-msg', function (title, text, image) {
            self.title = title;
            self.text = text;
            self.image = image;
            self.showModal = true;
        });
        this.$bus.$on('global-modal-close', function () {
            self.showModal = false;
        });
        this.$bus.$on('global-modal-disabled-function', function () {
            self.showModalDisabledFeature = true;
        });

        this.$bus.$on('global-validate-email', function (validateEmailRequestUID) {
            self.$refs.loginFlow.runEmailValidation(validateEmailRequestUID);
        });

        this.$bus.$on('global-header-light', function (value, bgColor) {
            if (self.$refs.headerCorporate) {
                self.$refs.headerCorporate.navBarLight = value;
                self.$refs.headerCorporate.navBarBgColor = bgColor;
            }
            if (self.$refs.headerExecutiveCheck) {
                self.$refs.headerExecutiveCheck.navBarLight = value;
                self.$refs.headerExecutiveCheck.navBarBgColor = bgColor;
            }
        });
        // o storage não é reativo, então não atualiza no footer
        // criado este envento que é chamando na landing genérica
        this.$bus.$on('global-update-footer-param-whatsapp', function () {
        });
    },
    mounted() {
        if (process.env.VUE_APP_MODE !== 'development') {
            //Salva parametros do googleAds
            this.$router.onReady(()=>{
              const googleAdsParams = {};
              googleAdsParams.utm_source = this.$route.query.utm_source;
              googleAdsParams.utm_medium = this.$route.query.utm_medium;
              googleAdsParams.utm_campaign = this.$route.query.utm_campaign;
              googleAdsParams.gclid = this.$route.query.gclid;
              sessionStorage.setItem("googleAdsParams", JSON.stringify(googleAdsParams));
            })
            // IntercomService.boot();
        } else if (this.vueMode === 'development' && process.env.VUE_APP_LOGIN_AS && process.env.VUE_APP_LOGIN_AS !== "") {
            this.autologin();
        }

        let self = this;
        HttpRequest.sessionEndCallback = function () {
            self.logout();
            self.$bus.$emit('global-login');
            window.setTimeout(function () { alert("Sua sessão expirou!"); }, 2000);
        };
    },
    methods: {
        setActiveProduct() {

            // se não setar como false, o link do footer nao atualiza o header
            this.isExato = false;
            this.isCorporate = false;
            this.isExecutiveCheck = false;
            this.isFlashCheck = false;
            this.isDocCheck = false;
            this.isDocCheckPortaria = false;
            this.isMaintenance = false;
            this.isPrototipoStone = false;
            this.isFooterClean = false;

            if (this.isUrlStartPath("/executivecheck")) {
                this.isExecutiveCheck = true;
            } else if (this.isUrlStartPath("/corporate") || this.isUrlStartPath("/igaming") || this.isUrlStartPath("/compliance")) {
                this.isCorporate = true;
            } else if (this.isUrlStartPath("/flashcheck")) {
                this.isFlashCheck = true;
            } else if (
                this.getRouteBaseName() === "DocCheckLanding"
                || this.getRouteBaseName() === "DocCheckDocs"
                || this.getRouteBaseName() === "DocCheckDocument"
            ) {
                this.isDocCheck = true;
            } else if (this.getRouteBaseName() === "DocChecklandingPortaria") {
                this.isDocCheckPortaria = true;
                this.isFooterClean = true;
            } else if (this.getRouteBaseName() === "Maintenance") {
                this.isMaintenance = true;
                this.isFooterClean = true;
            } else if (this.isUrlStartPath("/stone")) {
                this.isPrototipoStone = true;
            } else {
                this.isExato = true;
            }
        },

        isUrlStartPath(path) {
            return this.getRouteBasePath().toLowerCase().startsWith(path);
        },

        logout() {
            this.$store.commit("UserModule/logout");
            // IntercomService.reset();
            if (AuthenticationContext != null)
                AuthenticationContext.logout();
            if (this.getRouteBaseName() === "LandingPage")
                location.href = this.localePath("LandingPage");
            else
                this.$router.push(this.localePath("LandingPage"));

            sessionStorage.removeItem("userMainEmail");
        },
        autologin() {
            let self = this;
            let alreadyIn = self.$store.getters["UserModule/userAuthenticated"];
            if (alreadyIn)
                return;
            LoginService.login(process.env.VUE_APP_LOGIN_AS, 123123123, null, null).then(function (response) {
                self.$store.commit("UserModule/login", response.data.data);
                self.$emit('loginSuccessful');
                self.$router.push('/home');
            });
        },
        closeModal() {
            this.showModal = false;
        },
        closeModalNotAuthenticated() {
            this.$refs.loginFlow.closeModal();
            this.$refs.basicRegistrationFlow.closeRegister();
            this.$refs.passwordRecoveryFlow.closePasswordRecover();
        }
    },
}
</script>

<style lang="less">
@import "styles/global.less";
@import "styles/sizes.less";
@import "styles/bootstrap/bootstrap-grid-responsive.less";

#app {
    display: flex;
    flex-direction: column;
    height: inherit;

    //AVALIAR SE VAI QUEBRAR O SITE EM ALGUM LOCAL
    // - Está quebrando resoluções menores que 1320px
    //min-width: @PageWidth + 40px;

    main {
        position: relative;
        flex: 1 0 auto;
    }

    >*:last-child {
        flex-shrink: 0;
    }
}

.pagina-enter-active,
.pagina-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-out;
}

.pagina-enter,
.pagina-leave-active {
    opacity: 0;
    transition-timing-function: ease-in;
}

@media print {
    #app {
        min-width: auto;
    }
}
</style>
