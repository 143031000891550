/* global grecaptcha */
class GoogleServices {
    constructor() {
        window.grecaptchaHasLoaded = window.grecaptchaHasLoaded || false;
        window.grecaptchaIsReady = window.grecaptchaIsReady || false;
        this.scriptId = "google-api";
        this.widgetContainerElementOrId = null;
        this.widgetId = null;
        this.verifyCallback = null;
        this.errorCallback = null;
    }

    getScriptElement() {
        return document.querySelector(`#${this.scriptId}`);
    }

    createWidget() {
        return this.widgetId = grecaptcha.render(this.widgetContainerElementOrId, {
            sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
            size: "invisible",
            callback: (token) => {
                if (this.verifyCallback) {
                    this.verifyCallback(token);
                    grecaptcha.reset(this.widgetId);
                }
            },
            "expired-callback": () => {
                if (this.errorCallback) {
                    this.errorCallback();
                }
            },
            "error-callback": () => {
                if (this.errorCallback) {
                    this.errorCallback();
                }
            }
        });
    }

    initialize(widgetContainerElementOrId) {
        this.widgetContainerElementOrId = widgetContainerElementOrId;
        return new Promise((resolve) => {
            if (window.grecaptchaIsReady) {
                resolve(this.createWidget());
            } else if (window.grecaptchaHasLoaded) {
                grecaptcha.ready(() => {
                    window.grecaptchaIsReady = true;
                    resolve(this.createWidget());
                });
            } else {
                let script = this.getScriptElement();
                if (script) {
                    script.addEventListener("load", () => {
                        window.grecaptchaHasLoaded = true;
                        grecaptcha.ready(() => {
                            window.grecaptchaIsReady = true;
                            resolve(this.createWidget());
                        });
                    })
                } else {
                    let newScript = document.createElement("script");
                    newScript.async = true;
                    newScript.defer = true;
                    newScript.id = this.scriptId;
                    newScript.src = "https://www.google.com/recaptcha/api.js?render=explicit";
                    newScript.addEventListener("load", () => {
                        window.grecaptchaHasLoaded = true;
                        grecaptcha.ready(() => {
                            window.grecaptchaIsReady = true;
                            resolve(this.createWidget());
                        });
                    });
                    let head = document.querySelector("head");
                    head.append(newScript);
                }
            }
        });
    }

    /**
     * Verify if the user sounds like a robot, showing a challenge if your behavior is suspicious.
     * If the user does not like a robot, no challenge will be displayed.
     * @param {function} verifyCallback After comproving that the user is not a robot (with challenge needed or not) this callback will be executed.
     * This callback will not be executed if the user do not complete the challenge.
     * A string token will be present in an input parameter in the callback. 
     */
    verify(verifyCallback, errorCallback) {
        this.verifyCallback = verifyCallback;
        this.errorCallback = errorCallback;
        grecaptcha.execute(this.widgetId)
            .then(() => {
                let watcher = setInterval(() => {
                    let iframe = document.querySelector('iframe[src^="https://www.google.com/recaptcha"][title="recaptcha challenge"]');
                    if (iframe) {
                        let container = iframe.parentNode.parentNode;
                        if (container && container.style.visibility === "visible") {
                            container.scrollIntoView();
                            let observer = new MutationObserver(() => {
                                if (container && container.style.visibility === "hidden") {
                                    if (this.errorCallback) {
                                        this.errorCallback();
                                    }
                                    observer.disconnect();
                                }
                            });
                            observer.observe(container, {
                                attributes: true,
                                attributeFilter: ["style"],
                            });
                            clearInterval(watcher);
                        }
                    }
                }, 200);
            })
            .catch(() => {
                grecaptcha.reset(this.widgetId);
            });
    }

    /**
     * Release all objects in memory from recaptcha.
     * */
    dispose() {
        grecaptcha.reset(this.widgetId);
    }
}

export default new GoogleServices();
