<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        Complete o cadastro
      </h5>
      <!-- <h5 class="title2">Completando seu cadastro você terá acesso a mais consultas disponíveis na plataforma</h5>  -->

      <BenefitsRegister />

      <StepIndicatorModal
        class="step-modal"
        :step-number="2"
        change-text-for-step-one="Validar E-mail"
        change-text-for-step-two="Completar Cadastro"
        change-text-for-step-three="Cadastrar Empresa"
        :pendency-execute-query="pendencyExecuteQuery"
      />
      <!-- <StepIndicatorModal
                v-if="pendencyExecuteQuery !== 'PENDENCY-EMAIL'"
                class="step-modal"
                :stepNumber="2"
                changeTextForStepOne="Validar E-mail"
                changeTextForStepTwo="Completar Cadastro"
                changeTextForStepThree="Cadastrar Empresa"
                :pendencyExecuteQuery="pendencyExecuteQuery"
            /> -->
    </template>
    <template slot="right">
      <h2 v-if="$mq === 'sm' || $mq === 'md'">
        Complete o cadastro
      </h2>
      <h4 class="header-text">
        Preencha os dados abaixo:
      </h4>
      <div class="inputs-box">
        <div class="input-icon">
          <InputCPF
            v-model="cpf"
            v-focus
            maxlength="14"
            :disabled="screenLocked"
            placeholder="Digite seu CPF"
            @keyup.enter.native="validate"
            @input.once="sendStartCompleteIndividualRegistration"
          />

          <svg
            v-if="$mq === 'lg' || $mq === 'xl'"
            v-tooltip="{
              content:
                'Seu CPF é fundamental para que possamos validar a sua identidade e garantir um cadastro único em seu nome, evitando fraudes.'
            }"
            class="icon icon-medium icon-help input-icon-prepend"
            tabindex="-1"
          >
            <use xlink:href="/images/icon-lib.svg#icon-help" />
          </svg>

          <v-popover v-else>
            <svg
              class="icon icon-medium icon-help input-icon-popover"
              tabindex="-1"
            >
              <use xlink:href="/images/icon-lib.svg#icon-help" />
            </svg>
            <template slot="popover">
              Seu CPF é fundamental para que possamos validar a sua identidade e
              garantir um cadastro único em seu nome, evitando fraudes.
            </template>
          </v-popover>
        </div>
        <div>
          <p class="small-description">
            Data de Nascimento
          </p>
          <InputDate v-model="birthday" />
        </div>
        <div>
          <InputText
            v-model="fullName"
            v-uppercase
            maxlength="100"
            :disabled="screenLocked"
            placeholder="Digite seu nome completo"
            @keyup.enter.native="validate"
          />
          <div class="form-text">
            Informe seu nome <strong>como consta no CPF</strong>
          </div>
        </div>
      </div>

      <span v-if="!userHasPhone">
        <h3 class="header-text-sub">
          <svg
            class="icon icon-small icon-with-text-2 text-ColorPriTurquoise"
            tabindex="-1"
          >
            <use xlink:href="/images/icon-lib.svg#icon-gift" /></svg>Ganhe um bônus de mais R$ 10,00 para realizar consultas!
        </h3>
        <div class="inputs-box">
          <div class="mt-1">
            <InputPhone
              v-model="phone"
              maxlength="14"
              :disabled="screenLocked"
              placeholder="Digite seu celular com DDD (opcional)"
              @keyup.enter.native="validate"
            />
            <div
              id="phoneHelp"
              class="form-text"
            >
              Confirme seu celular e <strong>receba mais R$ 10,00</strong> em
              créditos para realizar consultas
            </div>
          </div>
          <!-- <p class="small-description">Campos obrigatórios</p> -->
        </div>
      </span>
      <p
        v-if="message"
        class="error-message"
      >
        {{ message }}&nbsp;
      </p>
      <div class="button-box">
        <Button
          btn-style="primary"
          btn-size="small"
          :disabled="screenLocked"
          :loading="screenLocked"
          class="button"
          @click="validate"
        >
          Continuar
        </Button>
      </div>
      <Portal to="app-end">
        <div
          id="recaptcha-individual-person-onboarding"
          key="recaptcha-individual-person-onboarding"
        />
      </Portal>
    </template>
  </ModalFullScreenContent>
</template>

<script>
import googleCaptchaProvider from "@/services/captcha-providers/google.service.js";
import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
import InputCPF from "@/components/inputs/input-cpf.vue";
import InputText from "@/components/inputs/input-text.vue";
import InputPhone from "@/components/inputs/input-phone.vue";
import InputDate from "@/components/inputs/input-date-splited.vue";
import Button from "@/components/buttons/button.vue";
import ValidationHelpers from "@/utils/validation-helpers.js";
import IndividualPersonValidationService from "@/services/service-providers/webapp-services/individual-person-validation-service.js";
import StepIndicatorModal from "@/views/product/step-indicator-modal.vue";
import { useDateFnsDifferenceInYears } from "@/mixin/use-date-fns-difference-in-years-mixin.js";

const BenefitsRegister = () =>
  import(
    /* webpackChunkName: "BenefitsRegister" */ "@/components/onboarding/benefits/benefits-register.vue"
  );

export default {

  components: {
    ModalFullScreenContent,
    InputCPF,
    InputText,
    InputPhone,
    InputDate,
    Button,
    StepIndicatorModal,
    BenefitsRegister
  },
  mixins: [useDateFnsDifferenceInYears],

  props: {
    pendencyExecuteQuery: {
      type: String,
      required: false,
      default: ""
    }
  },

  data: function() {
    return {
      screenLocked: false,
      message: "",
      cpf: "",
      fullName: "",
      phone: "",
      birthday: ""
      // differenceInYears,
    };
  },

  computed: {
    userHasPhone: function() {
      return this.$store.getters["UserModule/userPhone"];
    }
  },

  mounted: function() {
    this.$nextTick(function() {
      googleCaptchaProvider.initialize(
        "recaptcha-individual-person-onboarding"
      );
    });
  },

  destroyed: function() {
    googleCaptchaProvider.dispose();
  },

  methods: {
    validate: function() {
      this.message = "";

      if (this.cpf == "") {
        this.message = "Preencha o CPF.";
        return;
      }

      if (this.cpf.length != 14 || !ValidationHelpers.validateCPF(this.cpf)) {
        this.message = "CPF inválido.";
        return;
      }

      if (this.birthday == "") {
        this.message = "Preencha a data de nascimento.";
        return;
      }

      if (this.birthday.includes("-00") || this.birthday.includes("0000")) {
        this.message = "Data de nascimento inválida.";
        return;
      }

      if (this.getAge() < 18) {
        this.message = "Você precisa ter 18 anos para se cadastrar.";
        return;
      }

      if (this.fullName == "") {
        this.message = "Preencha o seu nome completo.";
        return;
      }

      if (this.phone.length > 0 && this.phone.length < 13) {
        this.message = "Telefone inválido.";
        return;
      }

      let self = this;
      this.screenLocked = true;
      googleCaptchaProvider.verify(
        function(captchaToken) {
          IndividualPersonValidationService.registerIndividualPerson(
            self.fullName,
            self.cpf.replace(/\D/g, ""),
            self.birthday,
            self.phone.replace(/\D/g, ""),
            captchaToken
          )
            .then(function(response) {
              self.screenLocked = false;
              self.$store.commit(
                "UserModule/login",
                response.data.data.renewedToken
              );
              self.$store.commit("UserModule/userName", self.fullName);
              self.$store.commit("UserModule/userPhone", self.phone);
              self.$emit("registerEnded", response.data.data);
              self.sendSuccessIndividualRegistration();
            })
            .catch(function(error) {
              self.screenLocked = false;
              self.message = error.response.data.message;
              self.sendErrorIndividualRegistration();
            });
        },
        function() {
          self.screenLocked = false;
        }
      );
    },

    dropErrorMessage: function(message) {
      this.message = message;
    },

    getAge: function() {
      return this.differenceInYears(new Date(), new Date(this.birthday));
    },
    sendStartCompleteIndividualRegistration: function() {
      this.$gtm.trackEvent({
        event: "inicio-form-cadastro-completo-pf",
        category: "Fluxo",
        action: "Cadastro PF Início do Preenchimento do Formulário",
        label: "Cadastro Completo PF Início"
      });
    },
    sendSuccessIndividualRegistration: function() {
      this.$gtm.trackEvent({
        event: "sucesso-primeira-parte-form-cadastro-pf",
        category: "Fluxo",
        action: "Cadastro PF Primeiro Formulário com Sucesso",
        label: "Primeiro Formulário PF com Sucesso"
      });
    },
    sendErrorIndividualRegistration: function() {
      this.$gtm.trackEvent({
        event: "erro-primeira-parte-form-cadastro-pf",
        category: "Fluxo",
        action: "Cadastro PF Primeiro Formulário Erro ",
        label: "Primeiro Formulário PF com Erro"
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/fonts.less";
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
@import "../../styles/input-group.less";

.header-text-sub {
  margin-top: 2.5rem;
  font-size: 1.125rem;
}

input {
  text-transform: uppercase;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}
</style>
