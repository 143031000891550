<template>
  <div>
    <transition name="fade3">
      <ModalFullscreen
        v-if="showPasswordRecover"
        @closing="closePasswordRecover"
      >
        <ResetPassword
          :request-u-i-d="$route.params.recoverPassRequestUID"
          @resetSuccess="closePasswordRecover"
        />
      </ModalFullscreen>
    </transition>
  </div>    
</template>

<script>
    import ModalFullscreen from "@/components/boxes/modal-fullscreen.vue";
    import ResetPassword from "@/views/onboarding/reset-password.vue";

    export default {

        components: {
            ModalFullscreen,
            ResetPassword
        },

        data: function () {
            return {
                showPasswordRecover: false
            }
        },

        created: function () {
            if (this.$route.params.recoverPassRequestUID)
                this.showPasswordRecover = true;
        },

        methods: {

            closePasswordRecover: function () {
                this.showPasswordRecover = false;
            },

            closeRegister: function () {
                this.showRegister = false;
            }

        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/animations.less";
</style>