<template>
  <div
    class="box-container-navbar"
    :class="{ 'isNavbar2': showNavbar2DocCheck }"
  >
    <div
      class="container-navbar"
      :class="[
        headerFloatMode ? 'nav-float-mode fade-in-down' : 'fade-in',
        !userAuthenticated ? 'landing-unified' : null,
        userAuthenticated ? 'user-authenticated' : null,
      ]"
      :style="`background-color: ${headerColor}`"
    >
      <div class="container">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light"
          :class="{
            userAuthenticated: userAuthenticated,
            'navbar-active-directory': checkLoginActiveDirectoy,
          }"
        >
          <slot
            name="brand"
            :close-menu-mobile="closeMenuMobile"
          >
            <a
              href
              class="brand-link"
              :class="{ 'cursor-no-action': maintenance }"
              @click.prevent="getLinkBrand()"
            >
              <LogoExato v-if="!headerFloatMode || isMobile" />

              <img
                v-if="headerFloatMode && !isMobile"
                src="/images/exato-shield.svg"
                alt="Exato Digital"
                class="brand-shield zoomIn"
              >

            </a>
          </slot>

          <button
            v-if="!maintenance"
            class="navbar-toggler"
            type="button"
            :class="menuCollapsed ? 'collapsed' : ''"
            @click="menuCollapsed = !menuCollapsed"
          >
            <span class="icon-bar top-bar" />
            <span class="icon-bar middle-bar" />
            <span class="icon-bar bottom-bar" />
          </button>

          <div
            v-if="!checkLoginActiveDirectoy"
            id="navbarMenuPrincipal"
            class="collapse navbar-collapse"
            :class="menuCollapsed ? '' : 'show'"
          >
            <NavLinkHorizontalContainer>
              <NavLinkHorizontal
                v-if="checkShowItemMenu && !maintenance && !isDoccheckDashboard"
                class="nav-item-corporate dropdown-large"
              >
                <div class="dropdown dropdown-large">
                  <a
                    ref="dropdowMenuCorporate"
                    class="nav-link nav-link-dropdown"
                    :class="{
                      'menu-selected': isSelectedMenuCorporate,
                      'menu-open': isOpenMenuCorporate,
                    }"
                    @click="menuCorporateCollapsed = !menuCorporateCollapsed"
                  >
                    <div class="dropdown-label click-ignore">
                      {{ $t("corporate") }}
                    </div>
                    <div class="dropdown-indicator click-ignore">
                      <svg class="icon icon-small icon-arrow">
                        <use xlink:href="/images/icon-lib.svg#icon-arrow" />
                      </svg>
                    </div>
                  </a>
                  <transition name="slide-fade-down">
                    <div
                      v-if="!menuCorporateCollapsed"
                      class="dropdown-menu dropdown-menu-large"
                    >
                      <div class="container">
                        <div class="row">
                          <div class="col-12 col-lg-4 dropdown-group">
                            <router-link
                              :to="{ name: 'HomeCorporate' }"
                              class="dropdown-group-item pb-0-25"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <div class="dropdown-group-label">
                                {{ $t("ourSolutions") }}
                              </div>
                              <div class="dropdown-group-indicator">
                                <svg class="icon icon-small icon-arrow">
                                  <use xlink:href="/images/icon-lib.svg#icon-arrow" />
                                </svg>
                              </div>
                            </router-link>

                            <div class="dropdown-group-text">
                              {{ $t("components.Header.corporateText") }}
                            </div>
                            <router-link
                              :to="{ name: 'BackgroundCheck' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-background-check" />
                              </svg>Background Check
                            </router-link>

                            <router-link
                              :to="{ name: 'ExecutiveCheck' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/icon-lib.svg#icon-user-tie" />
                              </svg>ExecutiveCheck
                            </router-link>

                            <router-link
                              :to="{ name: 'HomeFlashCheck' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/icon-lib.svg#icon-flashcheck" />
                              </svg>FlashCheck
                            </router-link>
                            <router-link
                              :to="{ name: 'DocCheckLanding' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-face-match" />
                              </svg>DocCheck
                            </router-link>
                            <router-link
                              :to="{ name: 'OnboardingDigital' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-onboarding" />
                              </svg>{{ $t("digitalOnboarding") }}
                            </router-link>

                            <router-link
                              :to="{ name: 'AutomacaoConsultas' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-automacao" />
                              </svg>{{ $t("queryAutomation") }}
                            </router-link>

                            <router-link
                              :to="{ name: 'InteligenciaMercado' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-inteligencia" />
                              </svg>{{ $t("marketIntelligence") }}
                            </router-link>
                          </div>
                          <div class="col-12 col-lg-4 dropdown-group md-separator-top">
                            <div class="dropdown-group-item pb-0-25">
                              <div class="dropdown-group-label">
                                {{ $t("segments") }}
                              </div>
                            </div>

                            <div class="dropdown-group-text">
                              {{ $t("components.Header.segmentsText") }}
                            </div>
                            <router-link
                              :to="{
                                name: 'BackgroundCheckRecrutamentoSelecao',
                              }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-hire" />
                              </svg>{{ $t("employmentSelection") }}
                            </router-link>
                            <router-link
                              :to="{
                                name: 'BackgroundCheckPrevencaoLavagemDinheiro',
                              }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-money-laundry" />
                              </svg>{{ $t("antiMoneyLaundering") }}
                            </router-link>
                            <router-link
                              :to="{
                                name: 'BackgroundCheckVerificacaoFornecedores',
                              }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-supplier" />
                              </svg>{{
                                $t("supplierVerification")
                              }}
                            </router-link>
                            <router-link
                              :to="{ name: 'BackgroundCheckCompliance' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-compliance" />
                              </svg>Compliance
                            </router-link>
                            <router-link
                              :to="{ name: 'ApiVerificacaoCpf' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-user-validation" />
                              </svg>{{ $t("userVerification") }}
                            </router-link>
                            <router-link
                              :to="{ name: 'ValidacaoIdentidadeApostadores' }"
                              class="dropdown-item dropdown-item-large"
                              @click.native="
                                closeMenuMobile();
                                emitCloseModals();
                              "
                            >
                              <svg class="icon icon-small icon-with-text zoomIn">
                                <use xlink:href="/images/corporate/icon-lib.svg#icon-sport-bet" />
                              </svg>{{ $t("iGamingPlatform") }}
                            </router-link>
                          </div>
                          <div class="col-12 col-lg-4 dropdown-group">
                            <div class="dropdown-group-itens">
                              <a
                                class="dropdown-group-item"
                                @click.prevent="
                                  redirectToIdOrHash(
                                    'HomeCorporate',
                                    'cases-content'
                                  );
                                  closeMenuMobile();
                                  emitCloseModals();
                                "
                              >{{ $t("successCases") }}
                              </a>
                              <router-link
                                :to="{ name: 'PlanosSLA' }"
                                class="dropdown-group-item"
                                @click.native="
                                  closeMenuMobile();
                                  emitCloseModals();
                                "
                              >
                                {{ $t("plansSla") }}
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="checkShowItemMenu && !maintenance && !isDoccheckDashboard"
                class="nav-item-resources dropdown-large"
              >
                <div class="dropdown dropdown-large">
                  <a
                    ref="dropdowMenuResources"
                    class="nav-link nav-link-dropdown"
                    :class="{
                      'menu-selected': !menuResourcesCollapsed,
                      'menu-open': !menuResourcesCollapsed,
                    }"
                    @click="menuResourcesCollapsed = !menuResourcesCollapsed"
                  >
                    <div class="dropdown-label click-ignore">
                      {{ $t("resources") }}
                    </div>
                    <div class="dropdown-indicator click-ignore">
                      <svg class="icon icon-small icon-arrow">
                        <use xlink:href="/images/icon-lib.svg#icon-arrow" />
                      </svg>
                    </div>
                  </a>

                  <transition name="slide-fade-down">
                    <div
                      v-if="!menuResourcesCollapsed"
                      class="dropdown-menu dropdown-menu-large"
                    >
                      <div class="container">
                        <div class="row">
                          <div class="col-12 col-lg-4 dropdown-group">
                            <div class="dropdown-group-item">
                              <div class="dropdown-group-label">
                                {{ $t("integrations") }}
                              </div>
                            </div>
                            <div class="dropdown-group-text">
                              {{ $t("components.Header.integrationsText") }}
                            </div>
                            <a
                              href="https://api.exato.digital/swagger-ui/"
                              class="dropdown-item dropdown-item-large"
                            >
                              {{ $t("apiDocumentation") }}
                            </a>
                            <a
                              href="https://help.exato.digital/pt/articles/5860451-documentacao-da-api"
                              target="_blank"
                              class="dropdown-item dropdown-item-large"
                            >
                              {{ $t("integrationGuide") }}
                            </a>
                            <a
                              class="dropdown-item dropdown-item-large"
                              data-intercom="tokenAcesso"
                              @click.prevent="showChat"
                            >
                              {{ $t("accessToken") }}
                            </a>
                          </div>
                          <div class="col-12 col-lg-4 dropdown-group">
                            <div class="dropdown-group-itens">
                              <a
                                href="https://blog.exato.digital/"
                                class="dropdown-group-item"
                              >Blog
                              </a>

                              <a
                                href="https://help.exato.digital/"
                                target="_blank"
                                class="dropdown-group-item"
                              >
                                {{ $t("helpCenter") }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="!userAuthenticated && checkShowItemMenu && !maintenance"
                class="nav-item-login nav-link-separator-left"
              >
                <router-link
                  to
                  class="nav-link nav-link-login nav-link-md-separator-top mt-md-0-25"
                  @click.native="
                    closeMenuMobile();
                    $bus.$emit('global-login');
                  "
                >
                  <svg class="icon icon-small-2 icon-user icon-with-text">
                    <use xlink:href="/images/icon-lib.svg#icon-user" />
                  </svg>{{ $t("login") }}
                </router-link>
              </NavLinkHorizontal>

              <slot
                name="menu-main"
                :close-menu-mobile="closeMenuMobile"
              />

              <NavLinkHorizontal
                v-if="showBuyCredits && !maintenance"
                class="nav-item-buy"
              >
                <router-link
                  :to="{ name: 'ComprarCredito' }"
                  class="nav-link"
                  :class="{ 'menu-selected': BuySelected }"
                  @click.native="closeMenuMobile()"
                >
                  {{ $t("components.Header.buyCredits") }}
                </router-link>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="userAuthenticated && hasDocCheckClaim && !maintenance"
                class="nav-item-query"
                :class="{ 'nav-link-separator-left': !isDoccheckDashboard }"
              >
                <router-link
                  :to="localePath({
                    name: 'DocCheckVerification',
                    query: { status: VerificationStatusEnum.ManualReview },
                  })"
                  class="nav-link nav-link-md-separator-top mt-md-0-25"
                  :class="{ 'menu-selected': isDocCheck }"
                  @click.native="closeMenuMobile()"
                >
                  DocCheck
                </router-link>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="userAuthenticated && canAccessExato && !maintenance && !isDoccheckDashboard"
                class="nav-item-query nav-link-separator-left"
              >
                <router-link
                  :to="{ name: 'Home' }"
                  class="nav-link nav-link-md-separator-top mt-md-0-25"
                  :class="{ 'menu-selected': consultarSelected }"
                  @click.native="closeMenuMobile()"
                >
                  {{ $t("components.Header.query") }}
                </router-link>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="userAuthenticated && !isMobile && canAccessExato && !maintenance && !isDoccheckDashboard"
                class="nav-item-history"
              >
                <router-link
                  :to="{ name: 'ProductHistory', params: { mode: 'unitaria' } }"
                  class="nav-link"
                  :class="{ 'menu-selected': historicoSelected }"
                  @click.native="closeMenuMobile()"
                >
                  {{ $t("components.Header.history") }}
                </router-link>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="
                  userAuthenticated &&
                    !isMobile &&
                    !userIsPos &&
                    canAccessExato &&
                    !maintenance &&
                    !isDoccheckDashboard
                "
                class="nav-item-balance"
              >
                <router-link
                  :to="{ name: 'SaldoExtrato' }"
                  class="nav-link"
                  :class="{ 'menu-selected': saldoSelected }"
                  @click.native="closeMenuMobile()"
                >
                  {{ $t("components.Header.statement") }}
                </router-link>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="userAuthenticated && isMobile && !maintenance"
                class="nav-item-logout"
              >
                <router-link
                  to
                  class="nav-link nav-link-md-separator-top pt-md-1-25 mt-md-0-25"
                  @click.native="
                    closeMenuMobile();
                    logout();
                  "
                >
                  <svg class="icon icon-small icon-logout icon-with-text zoomIn">
                    <use xlink:href="/images/icon-lib.svg#icon-logout" />
                  </svg>
                  {{ $t("logout") }}
                </router-link>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="userAuthenticated && !isMobile && !maintenance"
                class="nav-item active nav-link-user"
              >
                <a
                  ref="boxUser"
                  class="box-user"
                  :class="{ 'menu-selected': dropdownOpen }"
                  @click="dropdownOpen = !dropdownOpen"
                >
                  <div
                    ref="userImageBox"
                    class="user-image-box click-ignore"
                  >
                    <img
                      :src="userimageSrc"
                      alt="user"
                      class="click-ignore"
                    >
                  </div>
                  <div
                    class="user-name-container click-ignore"
                    :class="{ 'body-text-2': companyName }"
                  >
                    <div class="user-name click-ignore limited-content">
                      {{ userName }}
                    </div>
                    <div
                      v-if="companyName"
                      class="user-name-company click-ignore limited-content"
                    >
                      <svg class="icon icon-micro click-ignore icon-with-text-2">
                        <use xlink:href="/images/icon-lib.svg#icon-build" />
                      </svg>{{ companyName }}
                    </div>
                  </div>
                  <svg class="icon icon-small icon-arrow click-ignore">
                    <use xlink:href="/images/icon-lib.svg#icon-arrow" />
                  </svg>
                </a>
                <transition name="fade">
                  <MenuUserDropdown
                    v-if="dropdownOpen"
                    @closeRequested="dropdownOpen = false"
                    @openChangeAccount="openModalChangeAccount"
                  />
                </transition>
              </NavLinkHorizontal>

              <NavLinkHorizontal
                v-if="availableLocaleConfigs.length"
                class="nav-item-locale"
              >
                <div class="dropdown">
                  <Button
                    ref="language"
                    class="nav-link pr-0"
                    :class="{ 'menu-selected': dropdownLanguage }"
                    @click="dropdownLanguage = !dropdownLanguage"
                  >
                    <div class="click-ignore">
                      <div class="dropdown-item-container">
                        <div
                          v-if="$i18n.localeConfig.iconPath"
                          class="dropdown-icon"
                        >
                          <img
                            class="flag"
                            :src="$i18n.localeConfig.iconPath"
                            alt=""
                          >
                        </div>
                        <div
                          v-if="isMobile"
                          class="dropdown-item-label"
                        >
                          {{ $i18n.localeConfig.name }}
                        </div>
                        <div class="dropdown-indicator">
                          <svg class="icon icon-small icon-arrow">
                            <use xlink:href="/images/icon-lib.svg#icon-arrow" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Button>
                  <transition name="slide-fade-down">
                    <div
                      v-if="dropdownLanguage"
                      class="dropdown-menu"
                    >
                      <router-link
                        v-for="availableLocaleConfig in availableLocaleConfigs"
                        :key="availableLocaleConfig.code"
                        class="dropdown-item"
                        :to="switchLocalePath(availableLocaleConfig.code)"
                        replace
                        @click.native="
                          dropdownLanguage = !dropdownLanguage;
                          closeMenuMobile();
                        "
                      >
                        <div class="dropdown-item-container">
                          <div
                            v-if="availableLocaleConfig.iconPath"
                            class="dropdown-icon"
                          >
                            <img
                              class="flag"
                              :src="availableLocaleConfig.iconPath"
                              alt=""
                            >
                          </div>
                          <div class="dropdown-item-label">
                            {{ availableLocaleConfig.name }}
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </transition>
                </div>
              </NavLinkHorizontal>
            </NavLinkHorizontalContainer>
          </div>
        </nav>
      </div>

      <!-- Menu Bradesco - vagas -->
      <div
        v-if="checkBradesco && !maintenance"
        class="nav-positions-container"
      >
        <div class="container">
          <ul class="navbar2-nav">
            <li class="navbar2-nav-item">
              <router-link
                :to="{ name: 'BradescoVacancy' }"
                class="navbar2-nav-link"
                :class="{ active: vagasCandidatosSelected }"
                @click.native="removeAllStorageListBradesco"
              >
                <svg class="icon icon-micro icon-user-2 icon-with-text">
                  <use xlink:href="/images/icon-lib.svg#icon-user-2" />
                </svg>Vagas e Candidatos
              </router-link>
            </li>
            <li class="navbar2-nav-item">
              <router-link
                :to="{ name: 'BradescoSetupVacancy' }"
                class="navbar2-nav-link"
                :class="{ active: setupVagasSelected }"
                @click.native="removeAllStorageListBradesco"
              >
                <svg class="icon icon-micro icon-setup icon-with-text">
                  <use xlink:href="/images/bradesco/icon-lib.svg#icon-setup" />
                </svg>Setup de Vagas
              </router-link>
            </li>
            <li class="navbar2-nav-item">
              <router-link
                :to="{ name: 'BradescoBatchIntegrations' }"
                class="navbar2-nav-link"
                :class="{ active: batchIntegrationSelected }"
                @click.native="removeAllStorageListBradesco"
              >
                <svg class="icon icon-micro icon-integration icon-with-text">
                  <use xlink:href="/images/bradesco/icon-lib.svg#icon-integration" />
                </svg>Lotes e Integrações
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- Menu Bradesco end -->

      <!-- Menu doccheck -->
      <div
        v-if="isDocCheck && userAuthenticated && !maintenance && !isDoccheckDashboard"
        class="navbar2-container"
      >
        <div class="container container-flex">
          <ul class="navbar2-nav">
            <NavLinkHorizontal
              v-if="($mq === 'lg' || $mq === 'xl')"
              class="nav-item-history"
              size="small"
            >
              <router-link
                :to="localePath({
                  name: 'DocCheckVerification',
                  query: { status: VerificationStatusEnum.ManualReview },
                })"
                class="nav-link"
                @click.native="closeMenuMobile()"
              >
                {{ $t("components.Header.verifications") }}
              </router-link>
            </NavLinkHorizontal>
            <!-- <NavLinkHorizontal
              v-if="($mq === 'lg' || $mq === 'xl')"
              class="nav-item-history"
              size="small"
            >
              <router-link
                :to="{ name: 'DocCheckUser' }"
                @click.native="closeMenuMobile()"
                class="nav-link"                
              >{{ $t("components.Header.users") }}</router-link>
            </NavLinkHorizontal>
            <NavLinkHorizontal
              v-if="($mq === 'lg' || $mq === 'xl')"
              class="nav-item-history"
              size="small"
            >
              <router-link
                :to="{ name: 'DocCheckIntegration' }"
                @click.native="closeMenuMobile()"
                class="nav-link"
              >{{ $t("integrations") }}</router-link>
            </NavLinkHorizontal> -->
          </ul>

          <div
            v-if="hasDocCheckDashboardClaim"
            class="navbar2-nav-action"
          >
            <ul class="navbar2-nav">
              <NavLinkHorizontal
                v-if="($mq === 'lg' || $mq === 'xl')"
                class="nav-item-history"
                size="small"
              >
                <router-link
                  :to="localePath({
                    name: 'DocCheckDashboard',
                  })"
                  class="nav-link nav-link-turquoise pr-0"
                  @click.native="closeMenuMobile()"
                >
                  Dashboard <svg class="icon icon-small icon-with-text ml-0-25 mr-0">
                    <use xlink:href="/images/icon-lib.svg#icon-external-link" />
                  </svg>
                </router-link>
              </NavLinkHorizontal>
            </ul>
          </div>
        </div>
      </div>
      <!-- Menu doccheck end -->
    </div>


    <Portal to="app-end">
      <transition name="fade3">
        <ModalChangeAccount
          v-if="showModalChangeAccount"
          :width="modalChangeAccoutWidthDesktop"
          hide-footer
          @close="closeModalChangeAccount"
          @openChangeAccount="openModalChangeAccount"
        />
      </transition>
    </Portal>
  </div>
</template>

<script>
import VerificationStatusEnum from "@/constants/verification-status/verification-status-enum";
import Button from "@/components/buttons/button.vue";
import ContextManangerService from "@/services/service-providers/webapp-services/context-mananger-service";
// import IntercomService from "@/services/chat-providers/intercom-service";
import { isUrlStartPathMixin } from "@/mixin/is-url-start-path-mixin";
import { checkDevice } from "@/mixin/check-device-mixin";
import { redirectIdHash } from "@/mixin/redirect-id-hash-mixin";
import { goToElement } from "@/mixin/go-to-element-mixin";
import { intercom } from "@/mixin/intercom-mixin";
import { checkUserBilling } from "@/mixin/check-user-billing-mixin";
import { getUserAuthenticated } from "@/mixin/get-user-authenticated-mixin";
import extraClaimsMixin from "@/mixin/extra-claims-mixin";
import { modalChangeAccount } from "@/mixin/modal-change-account-mixin";


const NavLinkHorizontalContainer = () =>
  import(
    /* webpackChunkName: "NavLinkHorizontalContainer" */ "@/components/navs/nav-link-horizontal-container.vue"
  );
const NavLinkHorizontal = () =>
  import(
    /* webpackChunkName: "NavLinkHorizontal" */ "@/components/navs/nav-link-horizontal.vue"
  );
const LogoExato = () =>
  import(
    /* webpackChunkName: "LogoExato" */ "@/components/logo/logo-exato.vue"
  );
const MenuUserDropdown = () =>
  import(
    /* webpackChunkName: "MenuUserDropdown" */ "@/components/structure/exato/menu-user-dropdown.vue"
  );
const ModalChangeAccount = () =>
  import(
    /* webpackChunkName: "ModalChangeAccount" */ "@/components/account/modal-change-account.vue"
  );

export default {

  components: {
    Button,
    NavLinkHorizontalContainer,
    NavLinkHorizontal,
    LogoExato,
    MenuUserDropdown,
    ModalChangeAccount,
  },
  mixins: [
    isUrlStartPathMixin,
    checkDevice,
    redirectIdHash,
    goToElement,
    intercom,
    checkUserBilling,
    getUserAuthenticated,
    extraClaimsMixin,
    modalChangeAccount
  ],

  props: {
    // userAuthenticated: {
    //   type: Boolean,
    //   default: false,
    // },
    headerColor: {
      type: String,
      default: null,
    },
    marketingCampaign: {
      type: Boolean,
      default: false,
    },
    maintenance: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      saldoSelected: false,
      consultarSelected: false,
      historicoSelected: false,
      BuySelected: false,

      // docCheckSelected: false,
      // verificationSelected: false,


      headerFloatMode: false,
      vagasCandidatosSelected: false,
      setupVagasSelected: false,
      batchIntegrationSelected: false,
      menuCollapsed: true,

      dropdownLanguage: false,
      menuResourcesCollapsed: true,
      menuCorporateCollapsed: true,

      userimageSrc: "/images/no-user-picture.svg",
      dropdownOpen: false,

    };
  },

  computed: {
    VerificationStatusEnum() {
      return VerificationStatusEnum;
    },


    isDoccheckDashboard() {
      return this.getRouteBaseName() === "DocCheckDashboard";
    },


    // Exibe se não for camapnha de marketing ou se for campanha de marketing e o usuário estiver autenticado
    checkShowItemMenu() {
      if (!this.marketingCampaign) {
        return true;
      } else if (this.marketingCampaign && this.userAuthenticated) {
        return true;
      } else {
        return false;
      }
    },

    availableLocaleConfigs() {
      return this.$i18n.localeConfigs.filter((localeConfig) => {
        // Don't include current locale config
        if (localeConfig.code === this.$i18n.locale) {
          return false;
        }

        // Get path for localized route (if exists)
        const path = this.switchLocalePath(localeConfig.code);

        // Check if path matches the current path (it happens when a localized route doesn't exist)
        const matchesCurrentPath =
          path === this.$route.redirectedFrom || path === this.$route.fullPath;

        // Route exists and differs from the current route (a localized route exists)
        return path && !matchesCurrentPath;
      });
    },

    showBuyCredits() {
      return (
        this.userAuthenticated &&
        this.canAccessExato &&
        !this.userIsPos &&
        this.isMobile
      );
    },
    checkBradesco: function () {
      if (this.$route.path.toLowerCase().indexOf("/bradesco") !== -1)
        return true;
      else return false;
    },
    // checa se o login está sendo feito por active directory
    checkLoginActiveDirectoy: function () {
      if (this.getRouteBaseName() === "ActiveDirectoryLogin") return true;
      else return false;
    },
    isOpenMenuCorporate() {
      return !this.menuCorporateCollapsed

      // return (
      //   !this.menuCorporateCollapsed || (this.isMobile && this.isCorporate)
      // );
    },

    isSelectedMenuCorporate() {
      return !this.menuCorporateCollapsed || this.isCorporate;
    },

    // isSelectedMenuCorporate() {
    //   return this.isCorporate;
    // },

    isOpendMenuCorporate() {
      return !this.menuCorporateCollapsed;
    },

    isCorporate() {
      return (
        this.isUrlStartPath("/corporate") ||
        this.isUrlStartPath("/flashcheck") ||
        this.isUrlStartPath("/executivecheck")
      );
    },
    isDocCheck() {
      return (
        this.isUrlStartPath("/doccheck") &&
        this.getRouteBaseName() !== "DocCheckTry" &&
        this.getRouteBaseName() !== "DocCheckLanding"
      );
    },

    showNavbar2DocCheck() {
      return this.isDocCheck && this.userAuthenticated && !this.isDoccheckDashboard;
    },

    companyName() {
      return this.$store.getters["UserModule/companyName"];
    },

    userName() {
      return this.$store.getters["UserModule/userName"];
    },

  },

  watch: {
    $route(to) {
      const routeBaseName = this.getRouteBaseName(to);

      this.consultarSelected = [
        "Home",
        "ComprarProduto",
        "ConfirmarCompraEntidadeExplicita",
        "ConfirmarCompraManualInput",
        "EfetivarCompraProduto",
        "ExibirProdutoResultado",
        "ExibirProdutoResultadoNaoDossie",
        "ExibirProdutoUnitaria",
      ].includes(routeBaseName);

      this.saldoSelected = ["SaldoExtrato"].includes(routeBaseName);

      this.historicoSelected = [
        "ProductHistory",
        "ProductHistorySearch",
      ].includes(routeBaseName);

      this.BuySelected = [
        "ComprarCredito",
        "ConfirmarCompraPackageUID",
        "ConfirmarCompraValor",
        "BuyCreditResult",
        "BuyCreditResultRedirect",
      ].includes(routeBaseName);


      /*******************/
      /* Bradesco:  */
      /*******************/

      //Páginas que devem guardar o total de itens na listagem de vagas e candidatos
      this.pagesKeepListPositionsRecords = [
        "BradescoVacancy",
        "BradescoVacancySearch",
        "BradescoCandidates",
      ].includes(routeBaseName);

      if (!this.pagesKeepListPositionsRecords) {
        this.removeStorageTotalRecords("listPositionsRecords");
      }

      //Páginas que devem guardar o total de itens na listagem de candidatos
      this.pagesKeepListCandidateTotalRecords = [
        "BradescoCandidates",
        "BradescoCandidatesDetail",
        "BradescoCandidatesBusca",
      ].includes(routeBaseName);

      if (!this.pagesKeepListCandidateTotalRecords) {
        this.removeStorageTotalRecords("listCandidateTotalRecords");
      }

      //Páginas que devem guardar o total de itens na listagem de vagas no setup de vagas
      this.pagesKeepListPositionsSetupTotalRecords = [
        "BradescoSetupVacancy",
        "BradescoSetupVacancySearch",
        "BradescoEditPosition",
      ].includes(routeBaseName);

      if (!this.pagesKeepListPositionsSetupTotalRecords) {
        this.removeStorageTotalRecords("listPositionsSetupTotalRecords");
      }

      //Páginas que devem guardar o total de itens na listagem de vagas no setup de vagas
      this.pagesKeepListBatchRunsTotalRecords = [
        "BradescoBatchIntegrations",
        "BradescoBatchIntegrationsSearch",
        "BradescoBatchIntegrationsDetails",
      ].includes(routeBaseName);

      if (!this.pagesKeepListBatchRunsTotalRecords) {
        this.removeStorageTotalRecords("listBatchRunsTotalRecords");
      }
    },
  },

  created: function () {
    let self = this;
    this.$bus.$on(
      "global-needReloadUserEnvironment",
      function (redirectLogin, afterLogin) {
        self.loadUserEnvironment(redirectLogin, afterLogin);
      }
    );
    this.$bus.$on("global-needReloadUserPicture", function () {
      self.loadUserPicture();
    });


    this.consultarSelected = this.$route.path === "/home";

    document.addEventListener("click", this.checkHideMenu);
    document.addEventListener("click", this.documentClickLanguage);
    document.addEventListener("click", this.documentClick);
  },

  mounted: function () {
    this.loadUserEnvironment();
    window.addEventListener("scroll", this.checkHeaderForFloat);
  },

  destroyed: function () {
    window.removeEventListener("scroll", this.checkHeaderForFloat);
    document.addEventListener("click", this.checkHideMenu);
    document.removeEventListener("click", this.documentClickLanguage);
    document.removeEventListener("click", this.documentClick);

  },

  methods: {

    getLinkBrand() {

      this.closeMenuMobile();

      if (this.maintenance) {
        return null;
      }

      if (!this.userAuthenticated) {
        return this.$bus.$emit('close-modals'),
          this.$router.push(this.localePath('LandingPage'));

      } else {
        return this.$router.push({ name: 'Home' });
      }
    },

    loadUserEnvironment: function (redirectLogin, afterLogin) {
      if (this.userAuthenticated) {
        let self = this;
        ContextManangerService.getUserEnvironment(afterLogin).then(function (
          response
        ) {
          let userCompanies = response.data.data.userCompanies;
          userCompanies.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
          userCompanies.unshift({
            companyUID: "",
            name: response.data.data.userFullName,
            onboarded: false,
          });

          self.sendHomeIfPos(response.data.data.currentBillingType);

          self.$store.commit("UserModule/setUserInfo", {
            userName: response.data.data.userFullName,
            companyName: response.data.data.impersonatedCompanyName,
            userCompanies: userCompanies,
            emailValidated: response.data.data.emailValidated,
            userOnboarded: response.data.data.userOnboarded,
            userMainEmail: response.data.data.userMainEmail,
            indicationCode: response.data.data.indicationCode,
            currentCompanyCNPJ: response.data.data.currentCompanyCNPJ,
            userCPF: response.data.data.userCPF,
            extraClaims: response.data.data.extraClaims,
            currentBillingType: response.data.data.currentBillingType,
            userPhone: response.data.data.userPhone,
            cameFromChatbot: response.data.data.cameFromChatbot,
            haveAddress: response.data.data.haveAddress,
            impersonatedCompanyUid: response.data.data.impersonatedCompanyUid, // UID da empresa que está sendo acessada
          });

          sessionStorage.setItem(
            "userMainEmail",
            response.data.data.userMainEmail
          );

          if (process.env.VUE_APP_MODE !== "development")
            // IntercomService.update(
            //   self.$store.getters["UserModule/userName"],
            //   self.$store.getters["UserModule/userMainEmail"],
            //   self.$store.getters["UserModule/userUID"]
            // );

          self.sendUserIdAnalytics(self.$store.getters["UserModule/userUID"]);

          if (redirectLogin) {
            self.$bus.$emit("global-redirectUserAfterLoginSuccessful", true);
          }
        });
      }
    },
    // Duplicado - Já está no header-dropdown
    logout() {
      this.$bus.$emit("global-logout");
      //this.$emit('closeRequested');
    },
    sendHomeIfPos: function (currentBillingType) {
      const routeNameNotPosAllowed = ["SaldoExtrato", "ComprarCredito"];
      if (
        currentBillingType === 2 &&
        routeNameNotPosAllowed.includes(this.getRouteBaseName())
      ) {
        this.$router.push({ name: "Home" });
      }
    },
    sendUserIdAnalytics: function (uid) {
      this.$gtm.trackEvent({
        userID: uid,
      });
    },

    checkHideMenu(e) {
      const target = e.target;
      const dropdownCorporate = this.$refs.dropdowMenuCorporate;
      const dropdownResources = this.$refs.dropdowMenuResources;

      if (dropdownCorporate !== target) {
        this.menuCorporateCollapsed = true;
      }
      if (dropdownResources !== target) {
        this.menuResourcesCollapsed = true;
      }
    },
    documentClickLanguage(e) {
      if (!this.$refs.language) return;
      let el = this.$refs.language.$el;
      let target = e.target;

      if (el !== target) {
        this.dropdownLanguage = false;
      }
    },
    documentClick(e) {
      let el = this.$refs.boxUser;
      let target = e.target;

      if (el !== target && this.$refs.usernameParagraph !== target) {
        this.dropdownOpen = false;
      }
    },
    closeMenuMobile() {
      this.menuCollapsed = true;
    },
    emitCloseModals() {
      this.$bus.$emit("close-modals");
    },


    checkHeaderForFloat: function () {

      const header = document.querySelector('header');
      const headerHeight = header.offsetHeight;

      let top = window.pageYOffset || document.scrollTop || 0;

      let posScrollDown = headerHeight;
      let posScrollUp = headerHeight;

      // if (this.$mq === "sm" || this.$mq === "md") {
      //   posScrollDown = 60;
      //   posScrollUp = 60;
      // }

      if (top > posScrollDown && !this.headerFloatMode)
        this.headerFloatMode = true;
      else if (top <= posScrollUp && this.headerFloatMode)
        this.headerFloatMode = false;
    },

    removeStorageTotalRecords: function (itemStorage) {
      localStorage.removeItem(itemStorage);
    },

    removeAllStorageListBradesco: function () {
      let listStorage = [
        "listPositionsRecords",
        "listCandidateTotalRecords",
        "listPositionsSetupTotalRecords",
        "listBatchRunsTotalRecords",
      ];
      listStorage.forEach((item) => this.removeStorageTotalRecords(item));
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/sizes.less";
@import "../../styles/colors.less";
@import "../../styles/animations.less";
@import "../../styles/screen-size.less";

@fullHeight: 70px;
@compactHeight: 65px;

@media print {

  .box-menus,
  .box-user,
  .separator-line,
  .icon,
  .super-search,
  .brand-shield {
    display: none !important;
  }

  .brand {
    display: block !important;
  }

  .nav-float-mode {
    box-shadow: none !important;
  }
}

// .nav-item {
//   position: relative;
//   color: @ColorPriGrey;

//   @media (min-width: @screenLarge) {
//     display: flex;
//     align-items: center;
//   }
// }

// .nav-item-corporate {
//   order: 1;
// }
// .nav-item-resources {
//   order: 2;
// }
// .nav-item-login {
//   order: 3;
// }

// @media (max-width: @screenMedium) {
//   .nav-item-corporate {
//     order: 2;
//   }

//   .nav-item-resources {
//     order: 3;
//   }

//   .nav-item-login {
//     order: 4;
//   }
// }

/* novo menu mobile */

// evitar que o conteúdo "salte" no scroll
.box-container-navbar {
  height: 60px;

  @media (min-width: @screenLarge) {
    height: 70px;

    &.isNavbar2 {
      height: 110px;
    }
  }


}

.container-navbar {
  background-color: #fff;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  transition: all 0.3s;

  @media (min-width: @screenLarge) {
    //ficar abaixo dos modais
    z-index: 9;
  }
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.063rem 0;
  //padding: 0;
  margin-right: auto;
  margin-left: auto;

  a {
    text-decoration: none;
  }

  @media (min-width: @screenLarge) {
    position: relative;
    padding: 0;
    //padding: 0.7815rem 0;
  }
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;

  @media (min-width: @screenLarge) {
    padding-top: 0;
  }
}

#navbarMenuPrincipal {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 0;
  max-height: calc(100vh - 60px);
  background-color: @ColorSecGrey03;
  z-index: 1000;

  //transition: all 0.3s ease-in-out;
  transition: all 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);

  overflow: auto;

  @media (min-width: @screenLarge) {
    position: relative;
    top: 0;
    height: auto;
    background-color: transparent;

    overflow: initial;
  }

  &.show {
    height: 100vh;

    @media (min-width: @screenLarge) {
      height: auto;
    }
  }
}

@media (min-width: @screenLarge) {
  .navbar-expand-lg {
    flex-flow: row nowrap;

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
  }
}

.navbar-toggler {
  margin-left: auto;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent !important;
  color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0.25rem;

  &:focus {
    outline: none;
  }

  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }

  .middle-bar {
    opacity: 0;
  }

  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }

  &.collapsed {
    .top-bar {
      transform: rotate(0);
    }

    .middle-bar {
      opacity: 0.75;
    }

    .bottom-bar {
      transform: rotate(0);
    }
  }
}

.icon-bar {
  width: 22px;
  height: 2px;
  background-color: @ColorPriGrey;
  display: block;
  transition: all 0.2s;
  margin-top: 4px;
  opacity: 0.75;
}

@media (min-width: @screenLarge) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

// soma com a alura do logo para completar 70px
.brand-link {
  @media (min-width: @screenLarge) {
    padding: 1.5rem 0;
  }
}


.nav-float-mode {
  position: fixed;
  top: 0px;
  z-index: 1030;
  // background-color: #fff !important;
  box-shadow: 0px 1px 14px 0px rgba(50, 50, 50, 0.2);

  .icon-bar {
    background-color: @ColorPriGrey;
  }

  //TODO - rever esse trecho
  @media (min-width: @screenLarge) {
    //ficar abaixo dos modais
    z-index: 9;

    // .nav-item {
    //   color: @ColorPriGrey;
    // }
    // .nav-item-separator {
    //   border-left: 1px solid @ColorSecGrey05;
    // }
    // /deep/ .brand-text {
    //   display: none;
    // }

    // /deep/ .brand {
    //   width: 244px;
    //   height: 33px;
    // }

    // soma com a alura do logo para completar 70px
    .brand-link {
      padding: 1.188rem 0;
    }
  }



  // .nav-link:hover {
  //   color: @ColorPriYellow;
  // }
}

.brand-shield {
  display: block;
  width: 22px;
  height: 28px;
  margin-top: 0.25rem;
}

// // TODO classes nav-link poderiam ficar no nav-link-horizontal
// .nav-link.menu-selected {
//   color: @ColorPriYellow;

//   .dropdown-indicator {
//     transform: rotate(180deg);
//   }

//   &::before {
//     content: "";
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 2px;
//     background-color: @ColorPriYellow;
//   }

//   .dropdown-caret {
//     &:before {
//       border-color: @ColorPriYellow;
//     }
//   }
// }

.dropdown {
  position: relative;
}

.dropdown-label {
  margin-right: 0.5rem;
}

.dropdown-icon {
  margin-right: 0.625rem;
}

.dropdown-indicator {
  margin-left: auto;
}

.dropdown-label,
.dropdown-indicator {
  transition: 0.3s all;
}

/* end novo mobile */

// Classes bradesco
.navbar2-container {
  background-color: #f4f4f4;
  border-top: 1px solid @ColorSecGrey03;
}

.navbar2-nav-item {
  margin: 0 2rem;

  &:first-of-type {
    margin-left: 0;
  }
}

.navbar2-nav {
  display: flex;
}

.navbar2-nav-link {
  display: block;
  padding: 0.65rem 0rem;
  color: @ColorSecGrey47;
  font-size: 13px;
  text-decoration: none;

  &:hover {
    color: @yellow-600 !important;
  }
}

.navbar2-nav-link.active {
  color: @ColorPriTurquoise;
}

.nav-positions {
  .icon-micro {
    width: 13px;
    height: 13px;
  }
}

.navbar-active-directory {
  height: 70px;
}

.dropdown-menu {
  @media (min-width: @screenLarge) {
    position: absolute;
    top: 100%;
    right: 0;
    // right: 2rem;
    z-index: 1000;
    min-width: 10rem;
    padding: 1.5rem 2rem;
    margin: 0;
    font-size: 1rem;
    text-align: left;
    background-color: #f4f4f4;
    background-clip: padding-box;
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  }
}

.dropdown-large {
  position: static !important;
}

.dropdown-menu-large {
  @media (min-width: @screenLarge) {
    width: 930px;
    max-width: 930px;
    padding: 2rem 0;
    font-size: 0.875rem;
  }

  @media (min-width: @screenExtraLarge) {
    width: 1110px;
    max-width: 1110px;
  }
}

.dropdown-group {
  // padding: 1rem 0 .5rem 0;
  padding: 1rem 0;
  // border-bottom: 1px solid @light100;

  @media (min-width: @screenLarge) {
    // border-bottom:0;
    padding: 0 2.5rem;

    &:not(:last-child) {
      border-right: 1px solid @light100;
    }
  }
}

.dropdown-group-text {
  margin-bottom: 0.5rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  border: 0;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;

  .icon {
    top: 0.2em;
    margin-right: 0.875rem;
    transition: 0.3s all;
  }

  @media (min-width: @screenLarge) {
    padding: 0.5rem 0rem;
    font-size: 0.875rem;
  }
}

.dropdown-group {
  .dropdown-item:last-child {
    padding-bottom: 0;
  }
}

.dropdown-group-item {
  width: 100%;
  padding: 0.5rem 0;
  font-weight: 500;
  text-transform: uppercase;

  &:first-of-type {
    padding-top: 0;
  }
}

.dropdown-item,
a.dropdown-group-item {
  display: flex;
  color: @ColorPriGrey;

  @media (min-width: @screenLarge) {

    &:focus,
    &:hover {
      color: @yellow-600;

      .icon {
        color: @yellow-600;
      }
    }
  }

}

.dropdown-group-indicator {
  margin-left: auto;
  transform: rotate(270deg);
}

.dropdown-item-large {
  padding-left: 0;
  padding-right: 0;

  .icon {
    // top: 0.2em;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 0.375rem;
    color: @turquesa-500;

    @media (min-width: @screenLarge) {
      width: 16px;
      height: 16px;
    }
  }
}

.dropdown-group-item.router-link-exact-active,
.dropdown-item.router-link-exact-active {
  color: @yellow-600;
  cursor: default;

  .icon {
    color: @yellow-600;
    cursor: default;
  }
}

// .icon-small-2 {
//   width: 16px;
//   height: 16px;

//   @media (min-width: @screenLarge) {
//     width: 14px;
//     height: 14px;
//   }

//   // &.icon-with-text {
//   //   top: 0.3em;
//   //   margin-right: 0.25rem;
//   // }
// }

.icon-arrow {
  width: 12px;
  height: 12px;

  @media (min-width: @screenLarge) {
    width: 8px;
    height: 8px;
  }
}

.flag {
  display: block;
  width: 24px;
  height: 24px;
}

// .dropdown-menu {
//   .flag {
//     width: 16px;
//     height: 16px;
//   }
// }

.user-name-container {
  margin: 0 12px 0 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  user-select: none;
}

.dropdown-item-container {
  display: flex;
  align-items: center;
}

.box-user {
  padding-left: 0.5rem;
  color: @ColorPriGrey;
  cursor: pointer;
  transition: all 0s;

  &:hover,
  &:focus {
    color: @yellow-600;

    .user-name,
    .icon-micro {
      color: @yellow-600 !important;
    }
  }

  &.menu-selected {
    color: @yellow-600;

    .icon-arrow {
      transform: rotate(180deg);
    }
  }

  >* {
    display: inline-block;
    vertical-align: middle;
  }

  .user-name-company {
    font-weight: 500;
  }

  .icon-with-text-2 {
    margin-right: 0.25rem;
  }

  .icon-arrow {
    width: 8px;
    height: 8px;
  }


  .user-name,
  .user-name-company,
  .icon-with-text-2,
  .icon-arrow {
    transition: all 0.3s;
  }
}

.user-image-box {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: inherit;
    height: inherit;
  }
}

@media (max-width: @screenMedium) {
  .md-separator-top {
    border-top: 1px solid #e3e5e5;
  }
}


.container-flex {
  display: flex;
}

.navbar2-nav-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
