<template>
  <div>
    <h2 v-if="$mq === 'sm' || $mq === 'md'">
      {{ $t("recoverYourPassword") }}
    </h2>
    <h4 class="header-text">
      {{ $t("components.RecoverPasswordRequest.text") }}
    </h4>
    <Alert
      v-if="message"
      class="alert"
      :text="message"
      alert-type="danger"
      :show-icon="false"
      small-text
    />
    <form
      class="inputs-box"
      @submit.prevent
    >
      <div>
        <InputEmail
          v-model="email"
          :placeholder="$t('enterYourEmail')"
          maxlength="100"
          :disabled="screenLocked"
          @keyup.enter.native="recover"
        />
      </div>
      <!--<p v-if="message" class="error-message">{{ message }}&nbsp;</p> -->
    </form>
    <div class="button-box">
      <ButtonLarge
        class="button"
        type="button"
        :disabled="screenLocked"
        @click="recover"
      >
        {{ $t("toContinue") }}
      </ButtonLarge>
    </div>
    <Portal to="app-end">
      <div
        id="recaptcha-recover-password-request"
        key="recaptcha-recover-password-request"
      />
    </Portal>
  </div>
</template>

<script>
import InputEmail from "@/components/inputs/input-email.vue";
import ButtonLarge from "@/components/buttons/button-large.vue";
import googleCaptchaProvider from '@/services/captcha-providers/google.service.js';
import loginService from '@/services/service-providers/webapp-services/login-service.js';
import Alert from "@/components/misc/alert.vue";

export default {
    components: {
        InputEmail,
        ButtonLarge,
        Alert,
    },
    data() {
        return {
            email: "",
            message: "",
            screenLocked: false,
        }
    },
    mounted() {
        this.$nextTick(() => {
            googleCaptchaProvider.initialize("recaptcha-recover-password-request");
        });
    },
    destroyed() {
        googleCaptchaProvider.dispose();
    },
    methods: {
        recover() {
            if (!this.email) {
                this.message = this.$t("emptyEmailError");
                return;
            }

            if (!document.querySelector(".inputs-box input[type='email']").validity.valid) {
                this.message = this.$t("invalidEmailError");
                return;
            }

            this.message = "";
            this.screenLocked = true;
            googleCaptchaProvider.verify(
                (captchaToken) => {
                    loginService.recoverPassword(this.email, captchaToken, this.$i18n.locale)
                        .then(() => {
                            this.screenLocked = false;
                            this.$emit("recoverRequested", this.email);
                        })
                        .catch((error) => {
                            this.screenLocked = false;
                            this.message = error.response.data.message;
                        });
                },
                () => {
                    this.screenLocked = false;
                }
            );
        },
    },
}
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
</style>
