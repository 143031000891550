/**
 * @typedef {import("vue-router").Location} Location
 * @typedef {import("vue-router").RawLocation} RawLocation
 * @typedef {import("vue-router").Route} Route
 * @typedef {import("vue-router").default} Router
 */

import resolveRoute from "@/i18n/resolve-route";

/** @type {(router: Router, currentRoute: Route, route: RawLocation, localeCode: string) => Location | undefined} */
const localeLocation = (router, route, localeCode) => {
    const resolvedRoute = resolveRoute(router, router.currentRoute, route, localeCode)
    return resolvedRoute ? resolvedRoute.location : undefined
}

export default localeLocation
