<template>
  <div>
    <transition name="fade3">
      <ModalFullscreen
        v-if="showModal"
        @closing="showModal = false"
      >
        <transition name="fade3">
          <EmailValidationRequired
            v-if="currentStep === 'EMAIL'"
            :pendency-execute-query="pendencyExecuteQuery"
          />
        </transition>
        <transition name="fade3">
          <EmailValidationSuccess
            v-if="currentStep === 'EMAIL_SUCESS'"
            :msg-left="endingMsgLeft"
            :msg-right1="endingMsgRight1"
            :msg-right2="endingMsgRight2"
            :pendency-execute-query="pendencyExecuteQuery"
            @successEmailNextStep="successEmailNextStep"
            @hideModal="hideModal"
          />
        </transition>
        <transition name="fade3">
          <IndividualPersonOnboarding
            v-show="currentStep === 'ONBOARD'"
            ref="IndividualPersonOnboarding"
            :pendency-execute-query="pendencyExecuteQuery"
            @registerEnded="handleRegisterResponse"
          />
        </transition>
        <transition name="fade3">
          <EndingOnboarding
            v-if="currentStep === 'END'"
            :msg-left="endingMsgLeft"
            :msg-right1="endingMsgRight1"
            :msg-right2="endingMsgRight2"
            :pendency-execute-query="pendencyExecuteQuery"
            @onboardEnd="endOnboard"
          />
        </transition>
        <transition name="fade3">
          <EnterSMSCode
            v-if="currentStep === 'SMS'"
            :masked-phone="maskedPhone"
            :request-u-i-d="requestUID"
            :onboarding-request-u-i-d="onboardingRequestUID"
            :pendency-execute-query="pendencyExecuteQuery"
            :quiz-required-after-sms="quizRequiredAfterSms"
            @validationSuccessful="success"
            @startQuiz="quizRequired"
          />
        </transition>
        <transition name="fade3">
          <ChooseOption
            v-if="currentStep === 'QUIZ'"
            :options="quizCurrentOptions"
            :question="quizCurrentQuestion"
            :percent="percent"
            :pendency-execute-query="pendencyExecuteQuery"
            @optionChosen="quizFeedback"
          />
        </transition>
        <transition name="fade3">
          <ChooseOption
            v-if="currentStep === 'CHOOSE_PHONE'"
            :options="quizCurrentOptions"
            :question="quizCurrentQuestion"
            :percent="percent"
            :pendency-execute-query="pendencyExecuteQuery"
            @optionChosen="phoneChallengeFeedback"
          />
        </transition>
        <transition name="fade3">
          <LegalPersonOnboarding
            v-if="currentStep === 'COMPANY'"
            :pendency-execute-query="pendencyExecuteQuery"
            @registerEnded="handleRegisterResponse"
            @onboardEnd="endOnboard"
          />
        </transition>
      </ModalFullscreen>
    </transition>
  </div>    
</template>

<script>
    import ModalFullscreen from "@/components/boxes/modal-fullscreen.vue";
    import IndividualPersonOnboarding from "@/views/onboarding/individual-person-onboarding.vue";
    import LegalPersonOnboarding from "@/views/onboarding/legal-person-onboarding.vue";
    import EmailValidationRequired from "@/views/onboarding/email-validation-required.vue";
    import EndingOnboarding from "@/views/onboarding/ending-onboarding.vue";
    import IndividualPersonValidationService from "@/services/service-providers/webapp-services/individual-person-validation-service.js";
    import EnterSMSCode from "@/views/onboarding/enter-sms-code.vue";
    import ChooseOption from "@/views/onboarding/choose-option.vue";
    import EmailValidationSuccess from "@/views/onboarding/email-validation-success.vue";
    import { rdConversion } from "@/mixin/rd-conversion.js";    

    export default {
        components: {
            ModalFullscreen,
            IndividualPersonOnboarding,
            EmailValidationRequired,
            LegalPersonOnboarding,
            EndingOnboarding,
            EnterSMSCode,
            ChooseOption,
            EmailValidationSuccess
        },

        mixins: [rdConversion],

        data: function () {
            return {
                showModal: false,
                currentStep: "",
                pendencyExecuteQuery: "",

                endingMsgLeft: "",
                endingMsgRight1: "",
                endingMsgRight2: "",

                //SMS challenge
                maskedPhone: "",
                requestUID: "",

                //Quiz or PhoneChallenge
                quizIdentifier: "",
                quizCollection: [],
                quizAnswers: [],
                quizCurrentQuestion: "",
                quizCurrentOptions: [],

                onboardingRequestUID: "",
                redirectToUrlOnSuccess: "",

                percent: 0
            }
        },

        mounted: function () {

            /* console.log(this.getRouteBaseName())

            // não estava funcuionado
            if (this.getRouteBaseName() === 'CompletarCadastro') {
                // Se a rota é completar cadastro, inicia o fluxo de onboarding
                // A rota completar cadastro somente irá funcionar se o usuário estiver logado
                // Segue o fluxo de onboarding conforme a pendência de cadastro do usuário[validar email, completar cadastro, cadastrar empresa]
                this.init();
                console.log('completar cadastro')
            } */
        },

        methods: {
            init: function (redirectToUrl, pendencyExecuteQuery) {
                this.redirectToUrlOnSuccess = redirectToUrl;
                this.pendencyExecuteQuery = pendencyExecuteQuery;
                let self = this;                
                IndividualPersonValidationService.start(redirectToUrl)
                    .then(function (response) {
                        if (response.data.data === 0)//ReadyToStart
                        {
                            self.currentStep = "ONBOARD";
                            self.showModal = true;
                        }
                        else if (response.data.data === 1) {//AlreadyValidated
                            self.$bus.$emit('global-notification', "Seu cadastro de pessoa física já está concluído.", true);
                        }
                        else if (response.data.data === 2) {//ManualValidation
                            self.$bus.$emit('global-notification', "Seu cadastro está em avaliação manual. Aguarde.", true);
                        }
                        else if (response.data.data === 3) {//EmailValidationNeeded
                            self.currentStep = "EMAIL";
                            self.showModal = true;
                        } else if (response.data.data === 4) {//CompanyValidation
                            self.currentStep = "COMPANY";
                            self.showModal = true;
                        }


                    })
                    .catch(function (error) {
                        self.$refs.IndividualPersonOnboarding.dropErrorMessage(error.response.data.message);
                        self.currentStep = "ONBOARD";
                        self.sendErrorInitPersonalOnboard();
                    });
            },

            onboardDepdendsOnUserState: function () {
                if (!this.$store.getters["UserModule/emailValidated"]) {
                    this.currentStep = "EMAIL";
                    this.showModal = true;
                } else if (!this.$store.getters["UserModule/userOnboarded"]) {
                    this.currentStep = "ONBOARD";
                    this.showModal = true;
                }
            },

            handleRegisterResponse: function (data) {
                this.onboardingRequestUID = data.onboardingRequestUID;
                
                if (data.situation === 0) {//ValidateByPhoneChallengeRequired
                    this.validateByPhoneChallengeRequired(data);
                }
                else if (data.situation === 1) {//ManualValidationRequired
                    this.manualValidationRequired();
                }
                else if (data.situation === 2) {//QuizRequired
                    this.quizRequired(data);
                }
                else if (data.situation === 3) {//Success
                    this.success();
                }
                else if (data.situation === 4) {//ValidateBySentSMSRequired
                    this.validateBySentSMSRequired(data);
                }
                else if (data.situation === 5){//ValidateBySMSAndQuizRequired
                    this.validateBySentSMSRequired(data, true);
                }
            },

            validateByPhoneChallengeRequired: function (data) {
                this.quizCurrentQuestion = "Qual número de celular você reconhece?";
                let options = [];
                for (let i = 0; i < data.continuation.options.length; i++)
                    options.push({
                        text: data.continuation.options[i].maskedPhone,
                        value: data.continuation.options[i].maskedPhone
                    });
                options.push({
                    text: "Não reconheço",
                    value: "0"
                });
                this.quizCurrentOptions = options;
                this.percent = 40;
                this.currentStep = "CHOOSE_PHONE";
                this.sendPhoneValidationStart();
            },

            phoneChallengeFeedback: function (option) {
                if (option.value === "0") {
                    this.queryForQuiz(this.onboardingRequestUID);
                }
                else {
                    let self = this;
                    IndividualPersonValidationService.sendSMSFromPhoneChallenge(option.value, this.onboardingRequestUID)
                        .then(function (response) {
                            self.maskedPhone = option.text;
                            self.requestUID = response.data.data.requestUID;
                            self.currentStep = "SMS";
                            self.sendCompletedPhoneValidation();
                        })
                        .catch(function (error) {
                            self.$refs.IndividualPersonOnboarding.dropErrorMessage(error.response.data.message);
                            self.currentStep = "ONBOARD";
                            self.sendErrorPhoneValidation();
                        });
                }
            },

            quizFeedback: function (option) {

                this.quizAnswers.push(option.value);

                if (this.quizCollection.length > this.quizAnswers.length) {
                    this.mountQuiz(this.quizCollection[this.quizAnswers.length]);
                }
                else {
                    let self = this;
                    IndividualPersonValidationService.answerQuiz(this.quizIdentifier, this.quizAnswers, this.onboardingRequestUID)
                        .then(function (response) {
                            if (response.data.data.quizOK) {
                                self.success();
                                self.sendQuizSuccess();
                                return;
                            }
                            else {
                                self.manualValidationRequired();
                                return;
                            }
                        })
                        .catch(function (error) {
                            self.$refs.IndividualPersonOnboarding.dropErrorMessage(error.response.data.message);
                            self.currentStep = "ONBOARD";
                            self.sendQuizError();
                        });
                }
            },

            queryForQuiz: function (onboardingRequestUID) {
                let self = this;
                IndividualPersonValidationService.getQuiz(onboardingRequestUID)
                    .then(function (response) {
                        self.quizCollection = response.data.data.questions;
                        self.quizIdentifier = response.data.data.identifier;
                        self.quizAnswers = [];
                        self.mountQuiz(self.quizCollection[0]);
                        self.sendQuizStart();
                    })
                    .catch(function (error) {
                        console.log(error)
                        self.manualValidationRequired();
                        return;
                        // self.$refs.IndividualPersonOnboarding.dropErrorMessage(error.response.data.message);
                        // self.currentStep = "ONBOARD";
                        // self.sendQuizError();
                    });
            },

            mountQuiz: function (quizQuestion) {
                this.quizCurrentQuestion = quizQuestion.description;
                let options = [];
                for (let i = 0; i < quizQuestion.answers.length; i++)
                    options.push({
                        text: quizQuestion.answers[i],
                        value: quizQuestion.answers[i]
                    });
                
                this.quizCurrentOptions = options;
                this.percent = 80;
                this.currentStep = "QUIZ";
            },

            manualValidationRequired: function () {
                this.endingMsgLeft = "Seu cadastro já está completo, agora é com a gente!";
                this.endingMsgRight1 = "Estamos validando seu cadastro para garantir a autenticidade dos dados.";
                this.endingMsgRight2 = "Você receberá uma notificação por e-mail assim que a validação for concluída.";
                this.sendManualValidationNecessary();
                this.currentStep = "END";
            },

            quizRequired: function (data) {
                this.queryForQuiz(data.onboardingRequestUID);
            },

            success: function () {
                this.$gtm.trackEvent({
                    event: 'cadastro-completo-pf'
                });
                this.rdConversion(
                    'Cadastro Completo PF',
                    this.$store.getters["UserModule/userMainEmail"],
                    this.$store.getters["UserModule/userName"],
                    this.$store.getters["UserModule/userPhone"]
                );
                this.$store.commit("UserModule/userOnboarded", true);
                this.endingMsgLeft = "Cadastro completo";
                this.endingMsgRight1 = "O seu cadastro foi validado com sucesso";
                // this.endingMsgRight2 = "Agora você pode realizar consultas que são disponíveis apenas para cadastros validados.";
                this.endingMsgRight2 = "Parabéns! O seu <strong>bônus em créditos</strong> já está disponível.  Agora você pode realizar mais consultas na plataforma.";
                this.currentStep = "END";                                
                if (this.redirectToUrlOnSuccess) {
                    this.$router.push(this.redirectToUrlOnSuccess);
                }
                this.$bus.$emit('global-refreshBalance');
            },

            validateBySentSMSRequired: function (data, quizRequiredAfterSms) {
                this.maskedPhone = data.continuation.maskedPhone;
                this.requestUID = data.continuation.requestUID;
                this.quizRequiredAfterSms = quizRequiredAfterSms;
                this.currentStep = "SMS";
                this.sendCompletedPhoneValidation();
            },

            // Finaliza o completar cadastro
            endOnboard: function () {
                this.$bus.$emit('global-needReloadUserEnvironment');
                this.showModal = false;

                // Se a pendência de cadastro para efetivar a consulta for o cadastro de empresa
                if (this.pendencyExecuteQuery === 'PENDENCY-COMPANY') {
                    this.currentStep = "COMPANY";
                    this.showModal = true;
                }
            },

            successEmail : function (pendencyExecuteQuery) {
                this.pendencyExecuteQuery = pendencyExecuteQuery;
                this.currentStep = 'EMAIL_SUCESS';
                this.showModal = true;
            },

            successEmailNextStep : function () {
                this.$bus.$emit('global-needReloadUserEnvironment');
                this.showModal = false;

                // Modal completar cadastro 
                this.currentStep = "ONBOARD";
                this.showModal = true;
            },

            hideModal : function () {
                this.showModal = false;
            },
            sendPhoneValidationStart: function () {
                this.$gtm.trackEvent({
                    event: 'inicio-validação-celular',
                    category: 'Fluxo',
                    action: 'Cadastro PF Início Confirmação Celular',
                    label: 'Início Confirmação Celular'
                });
            },
            sendCompletedPhoneValidation: function () {
                this.$gtm.trackEvent({
                    event: 'sucesso-validação-celular',
                    category: 'Fluxo',
                    action: 'Cadastro PF Celular Confirmado',
                    label: 'Celular Confirmado'
                });
            },
            sendQuizStart: function () {
                this.$gtm.trackEvent({
                    event: 'inicio-quiz',
                    category: 'Fluxo',
                    action: 'Cadastro PF Início Quiz',
                    label: 'Início Quiz'
                });
            },
            sendQuizSuccess: function () {
                this.$gtm.trackEvent({
                    event: 'sucesso-quiz',
                    category: 'Fluxo',
                    action: 'Cadastro PF Quiz com Sucesso',
                    label: 'Quiz Finalizado Com Sucesso'
                });
            },
            sendManualValidationNecessary: function () {
                this.$gtm.trackEvent({
                    event: 'ação-manual-necessária',
                    category: 'Fluxo',
                    action: 'Cadastro PF Validação Manual',
                    label: 'Validação Manual'
                });
            },
            sendErrorPhoneValidation: function () {
                this.$gtm.trackEvent({
                    event: 'erro-celular-step-cadastro-completo-pf',
                    category: 'Fluxo',
                    action: 'Cadastro PF Erro Celular',
                    label: 'Cadastro PF Celular Erro'
                });
            },
            sendQuizError: function () {
                this.$gtm.trackEvent({
                    event: 'erro-quiz-step-cadastro-completo-pf',
                    category: 'Fluxo',
                    action: 'Cadastro PF Erro Quiz',
                    label: 'Cadastro PF Erro Quiz'
                });
            },
            sendErrorInitPersonalOnboard: function () { 
                this.$gtm.trackEvent({
                    event: 'erro-iniciar-cadastro-completo-pf',
                    category: 'Fluxo',
                    action: 'Cadastro PF Erro ao Iniciar',
                    label: 'Cadastro PF Erro no Início' 
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/animations.less";
</style>