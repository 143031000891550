<template>
  <button
    :class="classObject"
    @click="onClick"
  >
    <svg
      v-if="loading"
      class="icon icon-small icon-loading rotate"
    >
      <use xlink:href="/images/icon-lib.svg#icon-loading" />
    </svg>
    <svg
      v-else-if="iconPath"
      class="icon icon-small"
      :class="iconClass"
    >
      <use :xlink:href="iconPath" />
    </svg>
    <slot />
  </button>
</template>

<script>
import base from "@/components/buttons/base.js";

export default {
  mixins: [base],
  props: {
    btnStyle: String,
    btnSize: String,
    btnType: String,
    iconPath: {
      type: String,
      required: false,
      default: "",
    },
    iconClass: {
      type: [String, Object, Array],
      required: false,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classObject: function () {
      return {
        "btn": true,
        "btn-primary": this.btnStyle === "primary",
        "btn-primary-outline": this.btnStyle === "primary-outline",
        "btn-secondary": this.btnStyle === "secondary",
        "btn-secondary-outline": this.btnStyle === "secondary-outline",
        "btn-tertiary": this.btnStyle === "tertiary",
        "btn-tertiary-outline": this.btnStyle === "tertiary-outline",
        "btn-success": this.btnStyle === "success",
        "btn-danger": this.btnStyle === "danger",
        "btn-light-outline": this.btnStyle === "light-outline",
        "btn-outline-dark": this.btnStyle === "outline-dark",
        "btn-outline-light": this.btnStyle === "outline-light",
        "btn-link": this.btnStyle === "link",
        "btn-link-light": this.btnStyle === "link-light",
        "btn-icon": this.btnStyle === "icon",
        "btn-small": this.btnSize === "small",
        "btn-micro": this.btnSize === "micro",
        "btn-square": this.btnType === "square",
      };
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/fonts.less";
@import "../../styles/screen-size.less";

/* Tratar status dos botões:  hover, focus, active, disable  */

button {
  position: relative;
  display: inline-flex; // checar se irá gerar algum problema
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 0.863rem 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
  font-size: 0.9375rem;
  border-style: none;
  border-radius: 1.75rem;
  white-space: nowrap;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;
  user-select: none;
  background: transparent;
  transition: all 0.3s;

  @media (min-width: @screenLarge) {
    //padding: .969rem 1.8rem;
  }

  @media (max-width: @screenMedium) {
    width: 100%;
    max-width: 290px;
    margin: auto;
  }

  @media (max-width: @screenSmall) {
    max-width: 100%;
  }
}

button:disabled {
  cursor: not-allowed;
  color: lighten(@ColorSecGrey47, 7%);
  background-color: lighten(@ColorSecGrey24, 12%) !important;
}

/* button:hover:enabled {
  background-color: darken(@ColorCorporatePrimary, 8%);
} */

.btn-primary {
  background-color: @ColorPriYellow;
  color: @ColorSecGrey74;

  &:hover:enabled,
  &:focus:enabled {
    background-color: darken(@ColorPriYellow, 18%);
  }
}

.btn-secondary {
  background-color: @ColorPriTurquoise;
  color: @ColorPriWhite;
  border: 1px solid transparent;

  &:hover:enabled:not(.active),
  &:focus:enabled:not(.active) {
    background-color: @turquesa-700 !important;
  }
}

.btn-tertiary {
  background-color: @ColorCorporatePrimary;
  color: @ColorPriWhite;

  &:hover:enabled,
  &:focus:enabled {
    background-color: @ColorPriYellow;
    color: @ColorSecGrey74;
  }
}

.btn-success {
  background-color: @success-500;
  color: @ColorPriWhite;

  &:hover:enabled,
  &:focus:enabled {
    background-color: darken(@success-500, 18%);
  }
}

.btn-danger {
  background-color: @danger-500;
  color: @ColorPriWhite;

  &:hover:enabled,
  &:focus:enabled {
    background-color: darken(@danger-500, 18%);
  }
}


.btn-link,
.btn-link-light {
  width: auto !important;
  padding: 0.863rem 1rem;
  color: @ColorPriTurquoise;

  &:hover:enabled,
  &:focus:enabled {
    color: @ColorPriYellow;
  }

  &:disabled {
    color: #918F8F;
    background-color: transparent !important;
  }
}

.btn-link-light {
  color: #918F8F;
}

.btn-icon {
  width: auto !important;
  padding: 0.5rem;
  color: #918F8F;

  &:hover:enabled,
  &:focus:enabled {
    color: @ColorPriYellow;
  }

  &:disabled {
    color: #918F8F;
    background-color: transparent !important;
  }
}


.btn-small {
  padding: 0.788rem 1.8rem;
  font-size: 0.875rem;
}

//este botão deveria ser o small
.btn-micro {
  padding: 0.5rem 1.5rem;
  font-size: 0.75rem;
}

.btn-primary-outline {
  border: 1px solid #707070;
  color: #4A4A4A;

  &:hover:enabled,
  &:focus:enabled {
    background-color: darken(@ColorPriYellow, 18%);
    border: 1px solid darken(@ColorPriYellow, 18%);
  }
}

.btn-outline-dark {
  border: 1px solid @ColorSecGrey74;
  color: @ColorSecGrey74;

  &:hover:enabled,
  &:focus:enabled {
    background-color: @ColorSecGrey74;
    color: #FFF;
  }
}

.btn-outline-light {
  border: 1px solid @ColorSecGrey24;
  color: @ColorSecGrey24;

  &:hover:enabled,
  &:focus:enabled {
    border: 1px solid @ColorSecGrey63;
    background-color: @ColorSecGrey63;
    color: #FFF;
  }
}

.btn-secondary-outline {
  border: 1px solid @ColorPriTurquoise;
  color: @ColorPriTurquoise;

  &:hover:enabled,
  &:focus:enabled {
    background-color: @ColorPriTurquoise;
    color: @ColorPriWhite;
  }

  &:disabled {
    background-color: transparent !important;
    border: 1px solid lighten(@ColorSecGrey24, 12%);
  }
}

.btn-tertiary-outline {
  border: 1px solid @ColorCorporatePrimary;
}


.btn-light-outline {
  background-color: #fbfbfb;
  color: #757575;
  border: 1px solid #dee2e6;
}

.btn-square {
  border-radius: 0.25rem;
}

.active {
  cursor: auto;

  /* &:hover {
    background-color: currentcolor;
  } */
}


.button-filter {

  width: 100%;
  padding: 0.595rem .75rem 0.555rem .75rem;
  font-size: 0.6875rem;
  white-space: nowrap;
  border-radius: .25rem;

  .icon-arrow {
    position: relative;
    height: 10px;
    width: 10px;
    margin-left: 0.25rem;
    transform: rotate(0deg);
    transition: 0.3s all;
  }

  svg {
    color: #9e9e9e;
  }


  &.active {
    background-color: #c2c2c2;
    color: #fff;
    cursor: pointer;

    .icon-arrow {
      color: #fff;
      transform: rotate(180deg);
    }

    .icon-filter {
      color: #fff;
    }
  }

}
</style>
