import httpRequest from '@/services/service-providers/httpRequest-service.js';

class ApiRegisterServices {

    registerUserFromSocialNetwork(socialNetwork, accessToken, promotionalCode, accountUserExternalId, accountOrganizationExternalId) {
        return httpRequest.axios.post('Register/RegisterUserFromSocialNetwork',
            {
                SocialNetwork: socialNetwork,
                AccessToken: accessToken,
                PromotionalCode: promotionalCode,
                accountUserExternalId: accountUserExternalId,
                accountOrganizationExternalId: accountOrganizationExternalId
            },
            {
                supressTokenRenew: true
            });
    }

    // TODO: Mensagens de erro desse endpoint precisam levar em consideração o idioma atual escolhido pela pessoa.
    registerUser(email, password, promotionalCode, captchaToken, tokenCompanyRegistration, sendEmailValidation, accountUserExternalId, accountOrganizationExternalId, exIdentifyAffiliateKeyId) {
        return httpRequest.axios.post('Register/RegisterUser',
            {
                Email: email,
                Password: password,
                PromotionalCode: promotionalCode,
                CaptchaToken: captchaToken,
                tokenCompanyRegistration,
                sendEmailValidation: sendEmailValidation,
                accountUserExternalId: accountUserExternalId,
                accountOrganizationExternalId: accountOrganizationExternalId,
                partnerKeyId: exIdentifyAffiliateKeyId
            },
            {
                supressTokenRenew: true
            });
    }

    validateEmail(requestUID) {
        return httpRequest.axios.post('Register/ValidateEmail',
            {
                RequestUID: requestUID
            },
            {
                supressTokenRenew: true
            });
    }

    sendInvitationEmail(email) {
        return httpRequest.axios.post('Register/SendInvitationEmail',
            {
                email: email
            });
    }
}

export default new ApiRegisterServices();
