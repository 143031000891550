<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        {{ $t("components.ResetPassword.title") }}
      </h5>
      <h5 class="title2">
        {{ $t("components.ResetPassword.text") }}
      </h5>
    </template>
    <template slot="right">
      <h2 v-if="$mq === 'sm' || $mq === 'md'">
        {{ $t("components.ResetPassword.title") }}
      </h2>
      <h4
        v-if="!success"
        class="header-text"
      >
        {{ $t("components.ResetPassword.instruction") }}
      </h4>
      <div v-if="success">
        <h4 class="success-reset-message">
          {{ $t("passwordChangeSuccess") }}
        </h4>
        <!-- <p class="redirect-message">Você será redirecionado em {{ counterRedirect }}...</p> -->
        <ButtonLarge
          class="button"
          :disabled="screenLocked"
          @click="userToLogin"
        >
          {{ $t("login") }}
        </ButtonLarge>
      </div>
      <div v-else>
        <div class="inputs-box">
          <RegisterInputCreatePassword
            :screen-locked="screenLocked"
            @updatePassword="updatePassword"
            @sendActionForm="resetPass"
          />
          <div class="input-icon">
            <InputPassword
              v-model="repeatPassword"
              :type="passwordFieldType"
              :placeholder="$t('components.ResetPassword.repeatPassword')"
              maxlength="30"
              :disabled="screenLocked"
            />
            <svg
              v-if="passwordFieldType == 'password'"
              class="icon icon-medium icon-eye input-icon-prepend"
              tabindex="-1"
              @click="switchVisibility"
            >
              <use xlink:href="/images/icon-lib.svg#icon-eye" />
            </svg>
            <svg
              v-if="passwordFieldType == 'text'"
              class="icon icon-medium icon-eye input-icon-prepend"
              tabindex="-1"
              @click="switchVisibility"
            >
              <use xlink:href="/images/icon-lib.svg#icon-eye-closed" />
            </svg>
          </div>
        </div>
        <div class="Support">
          <p class="doubt-text">
            <!-- O Id "fale-suporte-esqueci-senha" esta amarrado com o Intercom, favor não alterar -->
            {{ $t("components.ResetPassword.cantResetPassword") }}
            <a
              id="fale-suporte-esqueci-senha"
              class="support-link-text"
              href="#"
              onclick="return false;"
            >{{ $t("contactSupport") }}</a>
          </p>
        </div>
        <p
          v-if="errorMessage"
          class="error-message"
        >
          {{ errorMessage }}&nbsp;
        </p>
        <div class="button-box">
          <ButtonLarge
            class="button"
            :disabled="screenLocked"
            @click="resetPass"
          >
            {{ $t("toConfirm") }}
          </ButtonLarge>
        </div>
      </div>
    </template>
  </ModalFullScreenContent>
</template>

<script>
import loginService from '@/services/service-providers/webapp-services/login-service.js';
import InputPassword from "@/components/inputs/input-password.vue";
import ButtonLarge from "@/components/buttons/button-large.vue";
import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
import { passwordVisualHelper } from "@/mixin/password-visual-helper-mixin.js";
import { registerInputCreatePassword } from "@/mixin/register-input-create-password-mixin.js";

const RegisterInputCreatePassword = () =>
    import(
        /* webpackChunkName: "RegisterInputCreatePassword" */ "@/components/register/register-input-create-password.vue"
    );

export default {
    components: {
        InputPassword,
        ButtonLarge,
        ModalFullScreenContent,
        RegisterInputCreatePassword,
    },
    mixins: [passwordVisualHelper, registerInputCreatePassword],
    props: {
        requestUID: "",
    },
    data() {
        return {
            screenLocked: true,
            success: false,
            counterRedirect: 5,
            password: "",
            repeatPassword: "",
            errorMessage: "",
        }
    },
    mounted() {
      loginService.verifyRecoverPasswordRequest(this.requestUID, this.$i18n.locale)
          .then(() => {
              this.screenLocked = false;
          })
          .catch((error) => {
              this.errorMessage = error.response.data.message;
          });
    },
    methods: {
        resetPass() {
            if (this.password != this.repeatPassword) {
                this.errorMessage = this.$t("components.ResetPassword.repeatPasswordError");
                return;
            }

            this.screenLocked = true;
            loginService.setNewPassword(this.password, this.repeatPassword, this.requestUID, this.$i18n.locale)
                .then(() => {
                    this.screenLocked = false;
                    this.success = true;
                    // let interval = null;
                    // interval = setInterval(() => {
                    //     this.counterRedirect -= 1;
                    //     if (this.counterRedirect == 0) {
                    //         this.$router.push(this.localePath("LandingPage"));
                    //         this.$emit("resetSuccess");
                    //         clearInterval(interval);
                    //     }
                    // }, 1000);
                })
                .catch((error) => {
                    this.screenLocked = false;
                    this.errorMessage = error.response.data.message;
                });
        },
        userToLogin() {
            this.$bus.$emit("global-login");
            this.$emit("resetSuccess");
        },
    },
}
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
@import "../../styles/input-group.less";

.success-reset-message {
    margin: 1rem 0 2rem 0;
    // font-size: 16px;
}
</style>
