import Vue from 'vue';
import Vuex from 'vuex';
import LoadingModule from '@/state/loading-module.js';
import UserModule from '@/state/user-module.js';

Vue.use(Vuex);

const vuexStore = new Vuex.Store({
    modules: {
        LoadingModule,
        UserModule
    }
});

export default vuexStore;