<template>
  <div />
</template>

<script>
    import UserCompanyService from "@/services/service-providers/webapp-services/user-company-service.js";
    export default {
        mounted: function () {
            if (this.$route.params.tokenCompanyRegistration) {
                if (this.$store.getters["UserModule/userAuthenticated"]) {
                    let self = this;
                    UserCompanyService.addUserToCompany(this.$route.params.tokenCompanyRegistration)
                        .then(function () {
                            self.$bus.$emit('global-notification', self.$t("companyRegistrationSuccess"), false);
                            self.$router.push('/home');
                        })
                        .catch(function (error) {
                            self.$bus.$emit('global-notification', error.response.data.message, true);
                        });
                }
                else {
                    let self = this;
                    UserCompanyService.getEmailFromCompanyInvitation(this.$route.params.tokenCompanyRegistration)
                        .then(function (response) {
                            if (response.data.data.userAlreadyRegistered)
                                self.$bus.$emit('global-login');
                            else
                                self.$bus.$emit('global-register', response.data.data.email);
                        })
                        .catch(function (error) {
                            self.$bus.$emit('global-notification', error.response.data.message, true);
                        });
                }
            }
        }
    }
</script>
