import httpRequest from '@/services/service-providers/httpRequest-service.js';

class UserCompanyService {

    addRequestToUserCompany(email,userRole,userUID) {
        return httpRequest.axios.post('UserCompanyManager/AddRequestToUserCompany',
            {
                Email: email,
                UserRole: userRole,
                UserUID: userUID
            });
    }

    addUserToCompany(token) {
        return httpRequest.axios.post('UserCompanyManager/AddUserToCompany',
            {
                Token: token
            });
    }

    updateUserToCompany(email, userRole, userUID) {
        return httpRequest.axios.post('UserCompanyManager/UpdateUserToCompany',
            {
                Email: email,
                UserRole: userRole,
                UserUID: userUID
            });
    }

    getListUsersByCompany() {
        return httpRequest.axios.get('UserCompanyManager/GetListUsersByCompany');
    }

    getProfilesRoles() {
        return httpRequest.axios.get('UserCompanyManager/GetProfilesRoles');
    }

    getUsersCompanyListFilter(textFilter, userRole, status) {
        return httpRequest.axios.get('UserCompanyManager/GetUsersCompanyListFilter',
            {
                params: {
                    textFilter: textFilter,
                    userRole: userRole,
                    status: status
                }
            });
    }

    getUserCompanyDetails(email, userUid) {
        return httpRequest.axios.get('UserCompanyManager/GetUserCompanyDetails',
            {
                params: {
                    email: email,
                    userUid: userUid
                }
            });
    }

    getSendRequestAgain(email) {
        return httpRequest.axios.get('UserCompanyManager/GetSendRequestAgain',
            {
                params: {
                    email: email
                }
            });
    }

    deleteUserToCompany(email, userUid) {
        return httpRequest.axios.delete('UserCompanyManager/DeleteUserToCompany',
            {
                params: {
                    email: email,
                    userUid: userUid
                }
            });
    }

    getEmailFromCompanyInvitation(companyRequestToken) {
        return httpRequest.axios.get('UserCompanyManager/GetEmailFromCompanyInvitation', {
                params: {
                    companyRequestToken: companyRequestToken
                }
            });
    }
}

export default new UserCompanyService();