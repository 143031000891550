<template>
  <section
    id="produtos-relacionados"
    class="section-content"
    :style="`background-color:${sectionColor}`"
  >
    <div class="container container-slider">
      <h3 class="text-center">
        {{ titleText }}
      </h3>

      <div class="cards-slider">
        <div v-if="localLoader">
          <LoaderLocal />
        </div>

        <ProductShowcase
          v-if="!localLoader"
          :products="relatedProducts"
          class="cards-slider"
          show-pagination
        />
      </div>
    </div>
  </section>
</template>

<script>
const ProductShowcase = () =>
  import(
    /* webpackChunkName: "ProductShowcase" */ "@/views/dashboard/product-showcase.vue"
  );
import ProductService from "@/services/service-providers/webapp-services/product-service.js";
import LoaderLocal from "@/components/misc/loader-local.vue";

export default {

  components: {
    ProductShowcase,
    LoaderLocal,
  },
  props: {
    titleText: {
      type: String,
      required: false,
      default: "Consultas relacionadas",
    },
    sectionColor: {
        type: String,
        default: null,
    },
  },

  data: function () {
    return {
      relatedProducts: [],
      localLoader: true,
    };
  },

  mounted: function () {
    let self = this;
    this.localLoader = true;
    ProductService.getRelatedProducts()
      .then(function (response) {
        self.relatedProducts = response.data.data;
        self.localLoader = false;
      })
      .catch(function (error) {
        self.$bus.$emit(
          "global-notification",
          error.response.data.message,
          true
        );
        self.localLoader = false;
      });
  },
};
</script>

<style scoped lang="less">
@import "../../styles/screen-size.less";

// duplicado
.section-content {
  padding: 2rem 0;

  @media (min-width: @screenLarge) {
    padding: 4rem 0;
  }
}
.cards-slider {
  min-height: 380px;
}
</style>