import cookieHelpers from "@/utils/cookie-helpers.js";
export const companyUidCookie = {

    methods: {        
        setCookieCompanyUid: function (companyUID) {
            let d = new Date();
            d.setTime(d.getTime() + (1080*24*60*60*1000));
            cookieHelpers.setCookie('localCompanyUID', companyUID, d);              
        },
        getCookieCompanyUid: function () {
            return this.$store.getters["UserModule/impersonatedCompanyUid"];
        },
        
        setCookieTutorialContexto: function (TutorialContexto) {
            let d = new Date();
            d.setTime(d.getTime() + (1080*24*60*60*1000));
            cookieHelpers.setCookie('localTutorialContexto', TutorialContexto, d);              
        },
        getCookieTutorialContexto: function () {
            return cookieHelpers.getCookie('localTutorialContexto');
        },
        setCookiePriceChange: function (PriceChange) {
            let d = new Date();
            d.setTime(d.getTime() + (1080*24*60*60*1000));
            cookieHelpers.setCookie('localPriceChange', PriceChange, d);              
        },        
        getCookiePriceChange: function () {
            return cookieHelpers.getCookie('localPriceChange');
        },

        // criado outro cookie para armazenar o valor de 2025
        // não foi usado o anterior, pois é possível que o cookie ainda exista no browser
        setCookiePriceChange2025: function (PriceChange) {
            let d = new Date();
            d.setTime(d.getTime() + (1080*24*60*60*1000));
            cookieHelpers.setCookie('localPriceChange2025', PriceChange, d);              
        },
        getCookiePriceChange2025: function () {            
            return cookieHelpers.getCookie('localPriceChange2025');
        },
    }
}