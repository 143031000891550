export const modalChangeAccount = {
  data: function () {
    return {
      showModalChangeAccount: false,
      modalChangeAccoutWidthDesktop: "550px",
    };
  },

  methods: {
    openModalChangeAccount() {
      this.showModalChangeAccount = true;
    },    
    closeModalChangeAccount() {
      this.showModalChangeAccount = false;
    }
  },
}