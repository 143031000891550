<template>
  <div role="dialog">
    <div
      ref="modal"
      class="modal"
    >
      <span
        class="btn-close"
        aria-label="Fechar"
        @click="close"
      >x</span>
      <section class="modal-body">
        <p v-if="image">
          <img :src="image">
        </p>
        <h4
          v-if="title"
          class="text-config"
        >
          {{ title }}
        </h4>
        <p v-if="text">
          {{ text }}
        </p>
        <slot />
      </section>
    </div>
  </div>
</template>

<script>
    import ButtonRegularDark from '@/components/buttons/button-regular-dark.vue';
    import ButtonLarge from "@/components/buttons/button-large.vue";

    export default {

        components: {
            ButtonLarge,
            ButtonRegularDark
        },

        props: {
            title: {
                type: String,
                required: true
            },
            image: {
                type: String,
                required: false
            },
            text: {
                type: String,
                required: false
            }
        },
        methods: {
            close: function () {
                this.$emit("closing");
            }
        }

    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";

    .text-config{
        text-align:center;
    }

    img {
        height: 80px;
    }

    p{
        text-align:center;
    }

    h4 {
        font-size: 50px;
        padding-bottom: 24px;
        padding-top: 20px;
        
    }
    
    .modal {
       background: #ffffff;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        z-index: 10;
        max-width: 829px;
    }

    .modal-body {
        position: relative;
        padding: 20px;
        margin: 40px 74px 70px 74px;
    }

    .btn-close {
        position: relative;
        cursor: pointer;
        margin-top: 10px;
        margin-right: 20px;
        right: 0;
        text-align:right;
    }
</style>