class CookieHelpers {

  // a expiração máxima dos cookies está em 400 dias
  // https://developer.chrome.com/blog/cookie-max-age-expires?hl=pt-br
  setCookie(name, value, expirationDate) {
    var expires = "expires=" + new Date(expirationDate).toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  eraseCookie(name) {
    document.cookie = name + "=;-1;path=/";
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}

export default new CookieHelpers();
