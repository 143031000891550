/**
 * @typedef {import("vue-router").Route} Route
 */

import localeCodes from "@/i18n/locale-codes";

/** @type {(route?: Route) => string | undefined} */
const getRouteBasePath = (route) => {
    if (!route) {
        return
    }

    const slicedPath = route.path.split("/").slice(1)
    const isLocalePrefix = localeCodes.includes(slicedPath[0])
    if (isLocalePrefix) {
        return `/${slicedPath.slice(1).join("/")}`
    } else {
        return route.path
    }
}

export default getRouteBasePath
