export const registerInviteCode = {

  data: function () {
    return {
      promotionalCode: "",
    }
  },

  methods: {
    updatePromotionalCode: function (promotionalCode) {
      this.promotionalCode = promotionalCode;
    },
  }
}
