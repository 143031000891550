<template>
  <!-- MELHORAR COMPONENTE - Talvez exista a necessidade de customizar o tamanho e cores  -->
  <div 
    class="loader-container fade-in"
    :class="{ micro: sizeMicro }"
  >
    <div 
      id="loader-local" 
      class="d-block"                    
    />
  </div>
</template>

<script>    

    export default {
        props: {            
          sizeMicro: ''
        }
    }
</script>


<style lang="less" scoped>

@import "../../styles/colors.less";

.loader-container{
    position: relative;

    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 1rem


}

/* Center the loader */
#loader-local {
  
  /* position: absolute;
  left: 50%;
  top: 17px; */

  z-index: 1;
  /* display: none; */

  //transform: translate(-50%, -50%);

 /*  margin-left: -23px;
  margin-top: -10px; */

  border: 2px solid @ColorPriYellow;
  border-radius: 50%;  
  border-top: 2px solid #f3f3f3;
  width: 34px;
  height: 34px;
  //margin: auto;  
  animation: spin .7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container.micro {
  padding: 0;
  margin-right: 6px;

  #loader-local {    
    width: 14px;
    height: 14px;
    border-width: 1px;
  }
}


.loader-more {
  padding: 0 0 1rem;
}

</style>
