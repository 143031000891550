// Code adapted from https://github.com/nuxt-modules/i18n/blob/v7.3.1/src/templates/plugin.routing.js

import getRouteBaseName from "@/i18n/get-route-base-name";
import localeLocation from "@/i18n/locale-location";
import localeRoute from "@/i18n/locale-route";
import localePath from "@/i18n/locale-path";
import switchLocalePath from "@/i18n/switch-locale-path";
import getRouteBasePath from "@/i18n/get-route-base-path";

/**
 * @typedef {import("vue-router").Location} Location
 * @typedef {import("vue-router").RawLocation} RawLocation
 * @typedef {import("vue-router").Route} Route
 * @typedef {ReturnType<import("vue-router").default["resolve"]> | undefined} ResolveRouteReturn
 *
 * @typedef I18nPluginApi
 * @prop {(route?: Route) => string | undefined} getRouteBasePath
 * @prop {(route?: Route) => string | undefined} getRouteBaseName
 * @prop {(route: RawLocation, locale?: string) => string} localePath
 * @prop {(route: RawLocation, locale?: string) => Route | undefined} localeRoute
 * @prop {(route: RawLocation, locale?: string) => Location | undefined} localeLocation
 * @prop {(locale: string) => string} switchLocalePath
 *
 * @typedef PluginProxy
 * @prop {I18nPluginApi["getRouteBasePath"]} getRouteBasePath
 * @prop {I18nPluginApi["getRouteBaseName"]} getRouteBaseName
 * @prop {Vue["$i18n"]} i18n
 * @prop {I18nPluginApi["localePath"]} localePath
 * @prop {I18nPluginApi["localeRoute"]} localeRoute
 * @prop {I18nPluginApi["localeLocation"]} localeLocation
 * @prop {Vue["$route"]} route
 * @prop {Vue["$router"]} router
 * @prop {Vue["$store"]} store
 */

/**
 * @this {PluginProxy}
 * @type {PluginProxy["getRouteBasePath"]}
 */
function getRouteBasePathFromVueInstance(givenRoute) {
  return getRouteBasePath(givenRoute !== undefined ? givenRoute : this.route)
}

/**
 * @this {PluginProxy}
 * @type {PluginProxy["getRouteBaseName"]}
 */
function getRouteBaseNameFromVueInstance(givenRoute) {
  return getRouteBaseName(givenRoute !== undefined ? givenRoute : this.route)
}

/**
 * @this {PluginProxy}
 * @type {I18nPluginApi["localeLocation"]}
 */
function localeLocationFromVueInstance(route, locale) {
  return localeLocation(this.router, route, locale || this.i18n.locale)
}

/**
 * @this {PluginProxy}
 * @type {I18nPluginApi["localeRoute"]}
 */
function localeRouteFromVueInstance(route, locale) {
  return localeRoute(this.router, route, locale || this.i18n.locale)
}

/**
 * @this {PluginProxy}
 * @type {I18nPluginApi["localePath"]}
 */
function localePathFromVueInstance(route, locale) {
  return localePath(this.router, route, locale || this.i18n.locale)
}

/**
 * @this {PluginProxy}
 * @type {I18nPluginApi["switchLocalePath"]}
 */
function switchLocalePathFromVueInstance(locale) {
  return switchLocalePath(this.router, locale || this.i18n.locale)
}

/**
 * @template {(...args: any[]) => any} T
 * @param {T} targetFunction
 * @return {(this: Vue, ...args: Parameters<T>) => ReturnType<T>}
 */
const VueInstanceProxy = function (targetFunction) {
  return function () {
    /** @type {PluginProxy} */
    const proxy = {
      getRouteBasePath: this.getRouteBasePath,
      getRouteBaseName: this.getRouteBaseName,
      i18n: this.$i18n,
      localePath: this.localePath,
      localeRoute: this.localeRoute,
      localeLocation: this.localeLocation,
      route: this.$route,
      router: this.$router,
      store: this.$store
    }

    return targetFunction.call(proxy, ...arguments)
  }
}

/** @type {import("vue").PluginObject<void>} */
const i18nPlugin = {
  install(Vue) {
    Vue.mixin({
      methods: {
        getRouteBasePath: VueInstanceProxy(getRouteBasePathFromVueInstance),
        getRouteBaseName: VueInstanceProxy(getRouteBaseNameFromVueInstance),
        localeLocation: VueInstanceProxy(localeLocationFromVueInstance),
        localeRoute: VueInstanceProxy(localeRouteFromVueInstance),
        localePath: VueInstanceProxy(localePathFromVueInstance),
        switchLocalePath: VueInstanceProxy(switchLocalePathFromVueInstance),
      }
    })
  }
}

export default i18nPlugin
