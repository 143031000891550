import { render, staticRenderFns } from "./email-validation-success.vue?vue&type=template&id=7973fef2&scoped=true"
import script from "./email-validation-success.vue?vue&type=script&lang=js"
export * from "./email-validation-success.vue?vue&type=script&lang=js"
import style0 from "./email-validation-success.vue?vue&type=style&index=0&id=7973fef2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7973fef2",
  null
  
)

export default component.exports