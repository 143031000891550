<template>
  <input
    :type="passwordFieldType"
    :value="value"
    @input="updateSelf($event.target.value)"
    @focus="$emit('focus')"
  >
</template>
<script>
    import base from "@/components/inputs/base.js";

    export default {

        mixins: [base],

        props: {
            passwordFieldType: {
                default: 'password',
                type: String
            }
        }

    }
</script>
<style lang="less" scoped>

    @import "base.less";
</style>