/**
 * @typedef {import("vue-router").RawLocation} RawLocation
 * @typedef {import("vue-router").Route} Route
 * @typedef {import("vue-router").default} Router
 */

import resolveRoute from "@/i18n/resolve-route";

/** @type {(router: Router, route: RawLocation, localeCode: string) => Route | undefined} */
const localeRoute = (router, route, localeCode) => {
    const resolvedRoute = resolveRoute(router, router.currentRoute, route, localeCode)
    return resolvedRoute ? resolvedRoute.route : undefined
}

export default localeRoute
