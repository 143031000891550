import httpRequest from '@/services/service-providers/httpRequest-service.js';

class ApiLoginServices {

    // TODO: Traduzir mensagens de erro desse endpoint e respeitar idioma selecionado pela pessoa.
    login(username, password, captchaToken, tokenCompanyRegistration, twoFactorVerified, accountUserExternalId, accountOrganizationExternalId) {
        return httpRequest.axios.post('Login/Login',
            {
                Login: username,
                Password: password,
                CaptchaToken: captchaToken,
                tokenCompanyRegistration: tokenCompanyRegistration,
                TwoFactorVerified: twoFactorVerified,
                accountUserExternalId: accountUserExternalId,
                accountOrganizationExternalId: accountOrganizationExternalId
            },
            {
                supressTokenRenew: true
            });
    }

    socialLogin(socialNetwork, accessToken, socialUserId, twoFactorVerified, accountUserExternalId, accountOrganizationExternalId) {
        return httpRequest.axios.post('Login/SocialLogin',
            {
                SocialNetwork: socialNetwork,
                AccessToken: accessToken,
                SocialUserId: socialUserId,
                TwoFactorVerified: twoFactorVerified,
                accountUserExternalId: accountUserExternalId,
                accountOrganizationExternalId: accountOrganizationExternalId
            },
            {
                supressTokenRenew: true
            });
    }

    // TODO: Traduzir mensagens de erro desse endpoint e respeitar idioma selecionado pela pessoa.
    recoverPassword(email, captchaToken) {
        return httpRequest.axios.post('Login/RecoverPassword',
            {
                Email: email,
                CaptchaToken: captchaToken
            },
            {
                supressTokenRenew: true
            });
    }

    // TODO: Traduzir mensagens de erro desse endpoint e respeitar idioma selecionado pela pessoa.
    verifyRecoverPasswordRequest(requestUID) {
        return httpRequest.axios.post('Login/VerifyRecoverPasswordRequest',
            {
                RequestUID: requestUID
            },
            {
                supressTokenRenew: true
            });
    }

    // TODO: Traduzir mensagens de erro desse endpoint e respeitar idioma selecionado pela pessoa.
    setNewPassword(newPassword, repeatPassword, requestUID) {
        return httpRequest.axios.post('Login/SetNewPassword',
            {
                RequestUID: requestUID,
                NewPassword: newPassword,
                RepeatPassword: repeatPassword
            },
            {
                supressTokenRenew: true
            });
    }

    activeDirectoryLogin(email) {
        return httpRequest.axios.post('Login/LoginWithActiveDirectory',
        {
            Email: email
        },
        {
            supressTokenRenew: true
        });
    }
}

export default new ApiLoginServices();
