<template>
  <div>
    <div class="hero-h-100-vh">
      <SectionHero
        id-element-to-scroll="feature"
        section-color="#F6F4EC"
        is-bg-light
      >
        <template #hero-content>
          <h1 v-animate="'fadeIn'">
            <i18n
              path="pages.LandingGeneric.hero.title[0]"
              :tag="false"
            >
              <span class="text-ColorPriTurquoise">{{
                $t("pages.LandingGeneric.hero.title[1]")
              }}</span>
            </i18n>
          </h1>
          <p
            v-animate="'fadeIn'"
            class="sub-header"
          >
            <i18n
              path="pages.LandingGeneric.hero.subtitle[0]"
              :tag="false"
            >
              <strong>{{ $t("pages.LandingGeneric.hero.subtitle[1]") }}</strong>
              <strong>{{ $t("pages.LandingGeneric.hero.subtitle[2]") }}</strong>
            </i18n>
          </p>
        </template>

        <template #hero-illustration>
          <SectionHeroIllustrationContainer>
            <SectionHeroIllustration img-path="/images/img-home-generica-min.png" />
            <FeatureCard
              v-if="$mq !== 'sm'"
              icon-path="/images/icon-lib.svg#icon-time"
              feature-position-top="157px"
              feature-position-left="-70px"
              :feature-behind-photo="true"
            >
              {{ $t("pages.LandingGeneric.hero.estimate") }}
            </FeatureCard>
            <FeatureCard
              v-if="$mq !== 'sm'"
              icon-path="/images/icon-lib.svg#icon-dollar"
              feature-position-top="40px"
              feature-position-left="68%"
            >
              {{ $t("pages.LandingGeneric.hero.price") }}
            </FeatureCard>
            <FeatureCard
              v-if="$mq !== 'sm'"
              icon-path="/images/icon-lib.svg#icon-fact-check"
              feature-position-top="79.5%"
              feature-position-left="-19px"
            >
              {{ $t("pages.LandingGeneric.hero.kyc") }}
            </FeatureCard>
            <FeatureCard
              v-if="$mq !== 'sm'"
              icon-path="/images/icon-lib.svg#icon-dossie"
              feature-position-top="54%"
              feature-position-left="61%"
            >
              {{ $t("pages.LandingGeneric.hero.sources[0]") }}<br>
              {{ $t("pages.LandingGeneric.hero.sources[1]") }}
            </FeatureCard>
          </SectionHeroIllustrationContainer>
        </template>

        <template #hero-action>
          <form @submit.prevent>
            <RegisterEmail v-animate="'fadeIn'" />
          </form>
        </template>
      </SectionHero>
    </div>

    <section
      id="feature"
      ref="feature"
      class="section-content text-center"
    >
      <div class="container">
        <h2>{{ $t("pages.LandingGeneric.features.title") }}</h2>

        <p class="introduction">
          {{ $t("pages.LandingGeneric.features.subtitle") }}
        </p>

        <div class="row mb-2">
          <div class="col-lg-4">
            <Card
              v-animate="'fadeInUp'"
              class="mb-md-1"
            >
              <template #body>
                <CardIllustration
                  card-img-path="/images/icon-lib.svg#icon-dollar-2"
                  card-img-size="medium"
                />
                <div class="card-title">
                  {{ $t("pages.LandingGeneric.features.simplicity.title") }}
                </div>
                <div class="card-text">
                  {{ $t("pages.LandingGeneric.features.simplicity.text") }}
                </div>
              </template>
            </Card>
          </div>
          <!-- col end -->
          <div class="col-lg-4">
            <Card
              v-animate="'fadeInUp'"
              class="delay-250ms mb-md-1"
            >
              <template #body>
                <CardIllustration
                  card-img-path="/images/icon-lib.svg#icon-time-2"
                  card-img-size="medium"
                />
                <div class="card-title">
                  {{ $t("pages.LandingGeneric.features.agility.title") }}
                </div>
                <div class="card-text">
                  {{ $t("pages.LandingGeneric.features.agility.text") }}
                </div>
              </template>
            </Card>
          </div>
          <!-- col end -->
          <div class="col-lg-4">
            <Card
              v-animate="'fadeInUp'"
              class="delay-250ms"
            >
              <template #body>
                <CardIllustration
                  card-img-path="/images/icon-lib.svg#icon-exato-check"
                  card-img-size="medium"
                />
                <div class="card-title">
                  {{ $t("pages.LandingGeneric.features.risks.title") }}
                </div>
                <div class="card-text">
                  {{ $t("pages.LandingGeneric.features.risks.text") }}
                </div>
              </template>
            </Card>
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->

        <div class="button-box">
          <Button
            class="button"
            btn-style="primary"
            :disabled="screenLocked"
            @click="$bus.$emit('global-register')"
          >
            {{
              $t("freeTrial") }}
          </Button>
        </div>

        <Cue
          id-element-to-scroll="about-features"
          img-path="/images/icon-lib.svg#icon-arrow"
        />
      </div>
      <!-- container end -->
    </section>

    <ReportFeatures />

    <section
      id="queries"
      ref="queries"
      class="section-content text-center"
    >
      <div class="container">
        <h2>{{ $t("pages.LandingGeneric.queries.title") }}</h2>

        <p class="introduction">
          {{ $t("pages.LandingGeneric.queries.subtitle") }}
        </p>
      </div>

      <div class="container container-slider">
        <div
          v-animate="'fadeIn'"
          class="queries-showcase-container"
        >
          <ProductShowcase
            v-if="data.marketingProductInfo"
            :products="data.marketingProductInfo.marketingProducts"
            :hide-navigation="true"
            class="queries-showcase"
          />
        </div>
      </div>

      <!-- <div v-if="localLoader">
          <LoaderLocal />
        </div> -->

      <div
        v-animate="'fadeIn'"
        class="sources-content"
      >
        <div class="container">
          <p class="introduction">
            {{ $t("pages.LandingGeneric.queries.sources") }}
          </p>
        </div>

        <div class="container container-slider">
          <SourcesCarousel
            v-if="data.marketingSourceInfo"
            :sources="data.marketingSourceInfo.marketingSources"
            class="sources"
            show-pagination
          />
        </div>
      </div>

      <div class="container">
        <Button
          class="button"
          btn-style="primary"
          :disabled="screenLocked"
          @click="$bus.$emit('global-register')"
        >
          {{
            $t("freeTrial") }}
        </Button>
      </div>
    </section>

    <SectionBulletsAction
      section-color="#fcc52c"
      img-background-color="RGBA(252,197,44,0.1)"
      class="text-warning-1200"
    >
      <template #content>
        <h2
          class="mb-1"
          v-html="getScreenText.ganheTitle"
        />
        <p class="mb-2">
          {{ $t("pages.LandingGeneric.contact.subtitle") }}
        </p>
        <ListFeatureContainer>
          <ListFeature
            ml
            mb
            opacity
            illustration-color="#D87A00"
          >
            {{ $t("pages.LandingGeneric.contact.automation") }}
          </ListFeature>
          <ListFeature
            ml
            mb
            opacity
            illustration-color="#D87A00"
          >
            {{ $t("pages.LandingGeneric.contact.security") }}
          </ListFeature>
          <ListFeature
            ml
            mb
            opacity
            illustration-color="#D87A00"
          >
            {{ $t("pages.LandingGeneric.contact.fraud") }}
          </ListFeature>
        </ListFeatureContainer>
      </template>
      <template #content-action>
        <div class="ganhe-container">
          <RegisterBox
            class="box-formulario"
            rd-conversion-identifier="Formulário Cadastro - Landing Otimizada"
            card-header-theme="yellow"
            :google-captcha-provider="googleCaptchaProviderAsProp"
          />
        </div>
      </template>
    </SectionBulletsAction>
    <Customers
      ref="customers"
      class="section-content"
    />
    <Investors bg-color="#FBFBFB" />
    <Portal to="app-end">
      <div
        id="recaptcha-landing-generica"
        key="recaptcha-landing-generica"
      />
    </Portal>
  </div>
</template>

<script>
import HomeService from "@/services/service-providers/webapp-services/home-service.js";
import LoaderLocal from "@/components/misc/loader-local.vue";
import Button from "@/components/buttons/button.vue";
import Cue from "@/components/shared/cue/cue.vue";
import googleCaptchaProvider from "@/services/captcha-providers/google.service.js";
import { goToElement } from "@/mixin/go-to-element-mixin.js";
import { checkWhatsAppText } from "@/mixin/whatsapp-param-text-mixin.js";
import { textScreenRegister } from "@/mixin/text/text-screen-register-mixin.js";
import { intercom } from "@/mixin/intercom-mixin.js";

const RegisterBox = () =>
  import(
    /* webpackChunkName: "RegisterBox" */ "@/components/boxes/register-landing-box.vue"
  );

const Customers = () =>
  import(
    /* webpackChunkName: "Customers" */ "@/components/shared/customers/customers.vue"
  );

const Investors = () =>
  import(
    /* webpackChunkName: "Investors" */
    "@/components/shared/investors/investors.vue"
  );

const SourcesCarousel = () =>
  import(
    /* webpackChunkName: "SourcesCarousel" */ "@/components/shared/sources-carousel.vue"
  );
const ProductShowcase = () =>
  import(
    /* webpackChunkName: "ProductShowcase" */ "@/views/dashboard/product-showcase.vue"
  );

const Card = () =>
  import(/* webpackChunkName: "Card" */ "@/components/card/card.vue");
const CardIllustration = () =>
  import(
    /* webpackChunkName: "CardIllustration" */ "@/components/card/card-illustration.vue"
  );

const SolutionFeature = () =>
  import(
    /* webpackChunkName: "SolutionFeature" */ "@/components/corporate/home/solution-feature.vue"
  );

const FeatureCard = () =>
  import(
    /* webpackChunkName: "FeatureCard" */ "@/components/shared/feature/feature-card.vue"
  );

const RegisterEmail = () =>
  import(
    /* webpackChunkName: "RegisterEmail" */ "@/components/register/register-email.vue"
  );

const ReportFeatures = () =>
  import(
    /* webpackChunkName: "ReportFeatures" */ "@/components/landing/report-features.vue"
  );
const SectionBulletsAction = () =>
  import(
    /* webpackChunkName: "SectionBulletsAction" */ "@/components/section/section-bullets-action.vue"
  );
const ListFeatureContainer = () =>
  import(
    /* webpackChunkName: "ListFeatureContainer" */ "@/components/shared/list/feature/list-feature-container.vue"
  );
const ListFeature = () =>
  import(
    /* webpackChunkName: "ListFeature" */ "@/components/shared/list/feature/list-feature.vue"
  );

const SectionHero = () =>
  import(
    /* webpackChunkName: "SectionHero" */ "@/components/section/hero/section-hero.vue"
  );
const SectionHeroIllustrationContainer = () =>
  import(
    /* webpackChunkName: "SectionHeroIllustrationContainer" */ "@/components/section/hero/section-hero-illustration-container.vue"
  );
const SectionHeroIllustration = () =>
  import(
    /* webpackChunkName: "SectionHeroIllustration" */ "@/components/section/hero/section-hero-illustration.vue"
  );

// import IntercomService from "@/services/chat-providers/intercom-service.js";

export default {
  components: {
    Button,
    Customers,
    Investors,
    LoaderLocal,
    RegisterBox,
    ProductShowcase,
    SourcesCarousel,
    Card,
    CardIllustration,
    SolutionFeature,
    FeatureCard,
    RegisterEmail,
    ReportFeatures,
    Cue,
    SectionBulletsAction,
    ListFeatureContainer,
    ListFeature,
    SectionHero,
    SectionHeroIllustrationContainer,
    SectionHeroIllustration
  },
  mixins: [goToElement, checkWhatsAppText, textScreenRegister, intercom],
  data() {
    return {
      data: {},
      animeCue: false,
      localLoader: true,
      screenLocked: false,
      message: "",
      emailRegistration: ""
    };
  },
  computed: {
    googleCaptchaProviderAsProp() {
      return googleCaptchaProvider;
    }
  },
  watch: {
    "$i18n.locale": {
      handler: "listProducts",
      immediate: true
    }
  },
  mounted() {
    this.animateCue();

    const routeName = this.getRouteBaseName();
    this.checkOpenDexter(routeName);
    this.checkOpenRegister(routeName);
    this.checkOpenLogin(routeName);

    this.$bus.$emit("global-update-footer-param-whatsapp");

    this.$nextTick(function () {
      googleCaptchaProvider.initialize("recaptcha-landing-generica");
    });
  },
  destroyed() {
    googleCaptchaProvider.dispose();
  },
  methods: {
    async listProducts() {
      try {
        const response = await HomeService.listProducts(this.$i18n.locale);
        this.data = response.data.data;
        this.localLoader = false;
      } catch (error) {
        this.$bus.$emit(
          "global-notification",
          error.response.data.message,
          true
        );
        this.localLoader = false;
      }
    },
    animateCue() {
      let self = this;
      // executa acada 10 segundo
      setInterval(function () {
        self.animeCue = true;
        // para a animação depois de 2 segundos
        setTimeout(function () {
          self.animeCue = false;
        }, 2000);
      }, 10000);
    },
    showRegistrationWithEmail() {
      this.$bus.$emit("global-register", this.emailRegistration);
    },
    checkOpenRegister(routeName) {
      const isRegisterToEmitReport =
        routeName === "CadastroEmitirRelatorio" ||
        routeName === "CadastroEmitirRelatorioAfiliado";

      if (
        routeName === "Cadastro" ||
        routeName === "CadastroTeste" ||
        routeName === "CadastroEmitirRelatorio" ||
        routeName === "CadastroEmitirRelatorioAfiliado"
      ) {
        //Já fez login e veio da rota para emitir relatório
        if (this.getStorageUserLoggedIn() === "1" && isRegisterToEmitReport) {
          this.$bus.$emit("global-login");
        } else {
          this.$bus.$emit("global-register");
        }
      }
    },
    getStorageUserLoggedIn() {
      return localStorage.getItem("exUserLoggedIn");
    },
    checkOpenLogin(routeName) {
      if (routeName === "Login") {
        this.$bus.$emit("global-login");
      }
    },
    checkOpenDexter(routeName) {
      if (routeName === "Dexter") {

        let self = this

        // não é a melhor opção
        // ideal configurar a abertura automática no próprio hubspot
        setTimeout(function () {          
          self.showChat()
        }, 3000);

      }
    },

  }
};
</script>

<style scoped lang="less">
@import "../../styles/colors.less";
@import "../../styles/sizes.less";
@import "../../styles/screen-size.less";
@import "../../styles/mixin/mixin.less";

@import "base.less";

.icon-big {
  width: 40px;
  height: 40px;
}

/deep/ .grecaptcha-badge {
  z-index: 100;
}

/deep/ .VueCarousel {
  margin-bottom: 2rem;
}

.button {
  min-width: 244px;
}

/* ********************* */

.section-content {
  position: relative;

  .scroll-margin-top;

  padding: 2rem 0 2rem 0;

  @media (min-width: @screenLarge) {
    padding: 4.5rem 0 4.5rem 0;
  }

  @media (min-width: @screenExtraLargeFullHD) {
    padding: 6rem 0 6rem 0;
  }

  @media (min-width: @screenExtraLarge2K) {
    padding: 9rem 0 9rem 0;
  }
}

h2 {
  margin-bottom: 0.5rem;
  font-family: "Saira";
  font-weight: 500;
  font-size: 1.25rem;

  @media (min-width: @screenLarge) {
    font-size: 2.1875rem;
  }
}

p {
  margin-bottom: 1rem;
}

.queries-showcase-container {
  min-height: 380px;
  margin-bottom: 2rem;
}

.introduction {
  margin-bottom: 2rem;
  font-size: 0.875rem;

  @media (min-width: @screenLarge) {
    font-size: 1rem;
  }

  @media (min-width: @screenExtraLarge) {
    font-size: 1.125rem;
  }
}

.sources {
  margin-bottom: 2rem;
}

.ganhe-container {
  position: relative;
  padding: 1.5rem 0.9375rem;
  background-color: RGBA(243, 178, 33, 0.5);
  border-radius: 0.375rem;

  @media (min-width: @screenLarge) {
    padding: 1.875rem;
  }
}
</style>
