<template>
  <div
    ref="modalContainer"
    class="modal-container"
    :class="{ 'w-100': isMobileOnly }"
  >
    <slot />
  </div>
</template>

<script>
import { checkDevice } from "@/mixin/check-device-mixin.js";
export default {

  mixins: [checkDevice],

  props: {
    notCloseEscClick: {
      type: Boolean,
      default: false
    },
  },

  created: function () {
    if (!this.notCloseEscClick) {
      document.addEventListener("click", this.closeModalOverlay);
      document.addEventListener("keyup", this.closeModalEscKey);
    }
  },
  destroyed: function () {
    if (!this.notCloseEscClick) {
      document.removeEventListener("click", this.closeModalOverlay);    
      document.removeEventListener("keyup", this.closeModalEscKey);
    }
  },
  methods: {
    close: function () {
      if (this.$children.length > 0) {
        if (this.$children[0].close) {
          this.$children[0].close();
        }
      }
    },

    closeModalOverlay: function (e) {
      let el = this.$refs.modalContainer;
      let target = e.target;
      if (el === target) {
        this.closeModal()
      }

    },

    //TODO -  avaliar e usar o mesmo processo com o closeModalOverlay
    // nos testes feitos essa função não funciona. O modal não fecha ao clicar no overlay
    // documentClickCloseModal: function(e) {
    //     if (this.$children.length > 0) {                    
    //         let el = this.$children[0].$refs.modalContainer;
    //         let target = e.target;
    //         if (el === target) {
    //             this.$children[0].close();
    //         }                    
    //     }                                
    // },

    closeModalEscKey() {
      if (event.keyCode === 27) {
        this.closeModal()
      }
    },

    closeModal() {
      this.$emit("closing");
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/fonts.less";

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1030;
}
</style>
