/**
 * @typedef {import("vue-router").RouteConfig} RouteConfig
 * @typedef {import("@/i18n/types").RouteConfigTemplate} RouteConfigTemplate
 */

import i18n from "@/i18n/i18n-instance";
import createRouteConfigs from "@/i18n/create-route-configs";
import DocCheckClaimsEnum from "@/router/doc-check/doc-check-claims-enum";
// import $store from "@/state";

/** @type {RouteConfig["meta"]} */
const authenticatedRouteConfigMetaProperties = {
  requiresAuth: true,
  extraClaims: DocCheckClaimsEnum.Default,
};

// apenas usuários com a claim "doccheck_dashboard" podem acessar a rota
// a verificação para acesso ao /doccheck está no router/index.js
// const isDocCheckDashboard = (to, from, next) => {  
//   if (!$store.getters["UserModule/extraClaims"].includes("doccheck_dashboard")) {
//     next("/doccheck/verification");
//   } else {
//     next();
//   }
// }

const routes = [
  //
  // Área pública
  //
  {
    path: "/doccheck",
    name: "DocCheckLanding",
    meta: { title: "Valide usuários com liveness em tempo real | Exato DocCheck" },
    component: () =>
      import(/* webpackChunkName: "DocCheckLanding" */ "@/views/doc-check/doc-check-landing.vue"),
  },
  {
    path: "/doccheck/try",
    name: "DocCheckTry",
    meta: { title: "Valide usuários com liveness em tempo real | Exato DocCheck" },
    component: () => import(/* webpackChunkName: "DocCheckTry" */ "@/views/doc-check/doc-check-try.vue"),
  },
  ...createRouteConfigs({
    baseConfig: {
      path: "/doccheck/docs",
      name: "DocCheckDocs",
      meta: { robots: "noindex" },
    },
    localeConfigs: [
      {
        code: "en-US",
        component: () =>
          import(/* webpackChunkName: "DocCheckDocsEnUS" */ "@/views/doc-check/doc-check-document.en-US.vue"),
        meta: { title: i18n.t("pages.LGPD.meta.title", "en-US") },
      },
      {
        code: "pt-BR",
        component: () =>
          import(/* webpackChunkName: "DocCheckDocsPtBR" */ "@/views/doc-check/doc-check-document.pt-BR.vue"),
        meta: { title: i18n.t("pages.LGPD.meta.title", "pt-BR") },
      },
    ],
  }),

  ...createRouteConfigs({
    baseConfig: {
      path: "/regulamentacao-bet",
      name: "DocChecklandingPortaria",
      component: () =>
        import(/* webpackChunkName: "DocChecklandingPortaria" */ "@/views/doc-check/doc-check-landing-portaria.vue"),
    },
    localeConfigs: [
      {
        code: "en-US",
        meta: { title: i18n.t("pages.DocChecklandingPortaria.meta.title", "en-US") },
      },
      {
        code: "pt-BR",
        meta: { title: i18n.t("pages.DocChecklandingPortaria.meta.title", "pt-BR") },
      },
    ],
  }),



  //
  // Área privada
  //

  ...createRouteConfigs({
    baseConfig: {
      path: "/doccheck/dashboard",
      name: "DocCheckDashboard",
      meta: authenticatedRouteConfigMetaProperties,
      component: () =>
        import(
          /* webpackChunkName: "DocCheckDashboard" */
          "@/views/doc-check/doc-check-dashboard.vue"
        ),
      // beforeEnter: isDocCheckDashboard
    },
    localeConfigs: [
      {
        code: "en-US",
        meta: {
          title: i18n.t("pages.DocCheckDashboard.meta.title", "en-US"),
        },
      },
      {
        code: "pt-BR",
        meta: {
          title: i18n.t("pages.DocCheckDashboard.meta.title", "pt-BR"),
        },
      },
    ],
  }),


  ...createRouteConfigs({
    baseConfig: {
      path: "/doccheck/verification",
      name: "DocCheckVerification",
      meta: authenticatedRouteConfigMetaProperties,
      component: () =>
        import(
          /* webpackChunkName: "DocCheckVerification" */
          "@/views/doc-check/doc-check-verification.vue"
        ),
    },
    localeConfigs: [
      {
        code: "en-US",
        meta: {
          title: i18n.t("pages.DocCheckVerification.meta.title", "en-US"),
        },
      },
      {
        code: "pt-BR",
        meta: {
          title: i18n.t("pages.DocCheckVerification.meta.title", "pt-BR"),
        },
      },
    ],
  }),
  ...createRouteConfigs({
    baseConfig: {
      path: "/doccheck/verification/:organizationExternalId/:uid",
      name: "DocCheckVerificationDetail",
      meta: authenticatedRouteConfigMetaProperties,
      props: {
        uid: {
          type: String,
          required: true,
        },
        organizationExternalId: {
          type: String,
          required: true,
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "DocCheckVerificationDetail" */
          "@/views/doc-check/doc-check-verification-detail.vue"
        ),
    },
    localeConfigs: [
      {
        code: "en-US",
        meta: {
          title: i18n.t("pages.DocCheckVerificationDetail.meta.title", "en-US"),
        },
      },
      {
        code: "pt-BR",
        meta: {
          title: i18n.t("pages.DocCheckVerificationDetail.meta.title", "pt-BR"),
        },
      },
    ],
  }),
  {
    path: "/doccheck/integration",
    name: "DocCheckIntegration",
    meta: {
      ...authenticatedRouteConfigMetaProperties,
      title: "Integrações | Exato DocCheck",
    },
    component: () =>
      import(/* webpackChunkName: "DocCheckIntegration" */ "@/views/doc-check/doc-check-integration.vue"),
  },
  {
    path: "/doccheck/integration/:uid",
    name: "DocCheckIntegrationDetail",
    meta: {
      ...authenticatedRouteConfigMetaProperties,
      title: "Visualizar Integração | Exato DocCheck",
    },
    props: {
      uid: {
        type: String,
        required: true,
      },
    },
    component: () =>
      import(/* webpackChunkName: "DocCheckIntegrationDetail" */ "@/views/doc-check/doc-check-integration-detail.vue"),
  },
  {
    path: "/doccheck/user",
    name: "DocCheckUser",
    meta: {
      ...authenticatedRouteConfigMetaProperties,
      title: "Usuários | Exato DocCheck",
    },
    component: () => import(/* webpackChunkName: "DocCheckUser" */ "@/views/doc-check/doc-check-user.vue"),
  },
  {
    path: "/doccheck/user/new",
    name: "DocCheckUserNew",
    meta: {
      ...authenticatedRouteConfigMetaProperties,
      title: "Criar Usuário | Exato DocCheck",
    },
    component: () =>
      import(/* webpackChunkName: "DocCheckUserNew" */ "@/views/doc-check/doc-check-user-new.vue"),
  },
  {
    path: "/doccheck/user/:uid",
    name: "DocCheckUserDetail",
    meta: {
      ...authenticatedRouteConfigMetaProperties,
      title: "Visualizar Usuário | Exato DocCheck",
    },
    props: {
      uid: {
        type: String,
        required: true,
      },
    },
    component: () =>
      import(/* webpackChunkName: "DocCheckUserDetail" */ "@/views/doc-check/doc-check-user-detail.vue"),
  },
];

export default routes;
