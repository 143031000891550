<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        Verificação em duas etapas
      </h5>
      <h5 class="title2">
        Camada adicional de validação para manter a sua conta segura
      </h5>
    </template>
    <template slot="right">
      <transition
        name="fade"
        mode="out-in"
      >
        <TwoFactorEmail
          v-if="twoFactorMethod === 'email'"
          :user-email="phoneOrEmail"
          :error-message="message"
          :page-error="pageError"
          :user-has-phone="userHasPhone"
          :screen-locked="screenLocked"
          :login-error-type="messageError"
          @check-code="checkCode"
          @change-two-factor-method="changeTwoFactorMethod"
          @send-verification-code="sendVerificationCode"
        />
        <TwoFactorSms
          v-else-if="twoFactorMethod === 'sms'"
          :user-phone="phoneOrEmail"
          :error-message="message"
          :page-error="pageError"
          :screen-locked="screenLocked"
          :login-error-type="messageError"
          @check-code="checkCode"
          @change-two-factor-method="changeTwoFactorMethod"
          @send-verification-code="sendVerificationCode"
        />
        <Portal to="app-end">
          <div
            id="recaptcha-two-factor"
            key="recaptcha-two-factor"
          />
        </Portal>
      </transition>
    </template>
  </ModalFullScreenContent>
</template>

<script>
import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
import TwoFactorEmail from "@/views/onboarding/two-factor-email.vue";
import TwoFactorSms from "@/views/onboarding/two-factor-sms.vue";
import googleCaptchaProvider from "@/services/captcha-providers/google.service.js";
import LoginService from "@/services/service-providers/webapp-services/login-service.js";
import TwoFactor from "@/services/service-providers/webapp-services/two-factor-authentication-service.js";
import { login } from "@/mixin/login-mixin.js";

export default {

  components: {
    ModalFullScreenContent,
    TwoFactorEmail,
    TwoFactorSms,
  },
  mixins: [login],

  props: {
    username: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
    tokenCompanyRegistration: {
      type: String,
      required: false,
      default: "",
    },
    socialLoginData: {
      type: Object,
      required: false,
    },
  },

  data: function () {
    return {
      twoFactorMethod: "email",
      message: "",
      pageError: false,
      code: "",
      twoFactorVerified: false,
      phoneOrEmail: "Carregando...",
      userHasPhone: false,
      screenLocked: false
    };
  },

  computed: {
    googleCaptchaProviderForMixin() {
      return googleCaptchaProvider;
    },
  },

  watch: {
    twoFactorMethod: function () {
      this.sendVerificationCode();
    },
  },

  mounted: function () {
    this.sendVerificationCode();
    this.$nextTick(function () {
      googleCaptchaProvider.initialize("recaptcha-two-factor");
    });
  },

  destroyed: function () {
    googleCaptchaProvider.dispose();
  },

  methods: {
    // checkCode: function () {
    //   let self = this;
    //   self.$store.commit("UserModule/login", self.credentials);
    //   self.$emit("loginSuccessful");
    // },
    changeTwoFactorMethod: function (twoFactorMethod) {
      this.twoFactorMethod = twoFactorMethod;
    },
    sendVerificationCode: function () {
      let self = this;
      let login = this.username;
      if (!login) login = this.socialLoginData.socialUserId;
      this.message = "";
      this.screenLocked = true;
      this.pageError = false;
      
      TwoFactor.sendVerificationCode(login, self.twoFactorMethod)
        .then(function (response) {
          self.screenLocked = false;
          self.pageError = false;
          self.phoneOrEmail = response.data.data.phoneOrEmail;
          self.userHasPhone = response.data.data.userHasPhone;
        })
        .catch(function (error) {
          self.screenLocked = true;
          self.pageError = true;
          self.message = error.response.data.message;
          self.phoneOrEmail = "";
        });
    },

    checkCode: function (code) {
      let self = this;
      self.message = "";
      let login = self.username;
      if (!login) login = this.socialLoginData.socialUserId;
      self.pageError = false;
      self.screenLocked = true;
      self.code = code;

      TwoFactor.checkVerificationCode(login, self.code, self.twoFactorMethod)
        .then(function () {
          self.twoFactorVerified = true;
          if (Object.entries(self.socialLoginData).length !== 0)
            self.socialLogin();
          else self.login();
        })
        .catch(function (error) {
          self.screenLocked = false;
          self.pageError = true;
          self.message = error.response.data.message;
        });
    },

    socialLogin: function () {
      let self = this;

      LoginService.socialLogin(
        self.socialLoginData.socialNetwork,
        self.socialLoginData.accessToken,
        self.socialLoginData.socialUserId,
        self.twoFactorVerified
      )
        .then(function (response) {
          self.$store.commit("UserModule/login", response.data.data);
          self.$emit("loginSuccessful");
        })
        .catch(function () {
          self.$emit("socialRegistrationRequested", {
            accessToken: self.socialLoginData.accessToken,
            socialNetwork: self.socialLoginData.socialNetwork,
          });
          self.$emit("socialRegistrationEnd");
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
</style>