<template>
  <div
    ref="modalContainer"
    role="dialog"
  >
    <div
      ref="modal"
      class="modal fade-in"
      :class="{'modal-indicator' : indicator, 'modal-center' : center}"
      :style="`width:${width}`"
    >
      <div class="modal-body">
        <slot name="body" />
      </div>
      <div class="modal-footer">
        <slot
          name="footer"
          :closing="closing"
        />
      </div>      
    </div>
    <div class="overlay" />
  </div>
</template>

<script>
import Button from "@/components/buttons/button.vue";

const TalkContainer = () => import(
    /* webpackChunkName: "TalkContainer" */  "@/components/talk/talk-container.vue"
);
const TalkContent = () => import(
    /* webpackChunkName: "TalkContent" */  "@/components/talk/talk-content.vue"
);

import { companyUidCookie } from "@/mixin/company-uid-cookie-mixin.js";

export default {

  components: {
    Button,
    TalkContent,
    TalkContainer, 
  },

  mixins: [companyUidCookie],
  props: {
    tutorialName: {
      type: String,
      default: "",      
    },
    tutorialPriceChange: {
      type: String,
      default: "",      
    },
    width: {
      type: String,
      default: null,
    },
    indicator: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {};
  },

  mounted() {
    // colocar em um mixin
    let self = this;
    window.addEventListener("keyup", function (event) {
      if (event.keyCode === 27) {
        self.$emit("closing");
      }
    });
  },

  methods: {
    closeDialogUnderstood: function () {
      if(this.tutorialName === "tutorialContexto") {
        this.setCookieTutorialContexto(1);
      }
      this.$emit("closing");
    },

    // https://stackoverflow.com/questions/67738496/how-to-handle-a-slots-onclick-event-in-vuejs
    closing() {
      if(this.tutorialName === "tutorialContexto") {
        this.setCookieTutorialContexto(1);
      } 
      if (this.tutorialPriceChange === "tutorialPriceChange") {
        this.setCookiePriceChange2025(1);
        
        // mudança de preço anterior
        this.setCookiePriceChange(1);
      }
      this.$emit("closing");
    }
  },
  
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/screen-size.less";

// Duplicado nas definições do site atual - Rever
.modal {
  z-index: 1031;
  position: absolute;
  top: 56px;
  display: flex;
  flex-direction: column;
  width: 374px;
  max-width: 98%;
  background: #ffffff;
  //overflow-x: auto;
  text-align: left;
  color: #5f5c5c;
  border: 1px solid @ColorSecGrey24;

  border-radius: 10px;
  box-shadow: 0px 2px 4px #00000029;

}
.modal-indicator {
  left:0;
  &:before {
    z-index: 1032;
    content: '';
    display: block;
    position: absolute;
    left: 42px;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid lighten(@ColorSecGrey24, 10%); 
    border-bottom-width: 0;
    border-right-width: 0;
    background-color: #FFF ;        
    transform: rotate(45deg) translateY(-12px);
    border-radius: 1px;
  }  
}

.modal-center {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-body {
  position: relative;
  padding: 1.25rem 1.5rem;
}
.modal-footer {
  position: relative;
  padding: 0 1.5rem 1.25rem 1.5rem;
}

.button-box {
    text-align: right !important;
}
button {
  width: auto;
}

.overlay {
  z-index: 1030;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

</style>