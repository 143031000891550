/**
 * @typedef {import("vue-router").NavigationGuard} NavigationGuard
 */

import $store from "@/state";
import claim from "@/router/bradesco/bradesco-claim";

// Módulo - Vagas e candidatos
const BradescoVacancy = () => import(
  /* webpackChunkName: "BradescoVacancyCandidates" */ "@/views/bradesco/vacancies.vue"
);
const BradescoCandidates = () => import(
  /* webpackChunkName: "BradescoVacancyCandidates" */ "@/views/bradesco/candidates.vue"
);
const BradescoSetupVacancy = () => import(
  /* webpackChunkName: "BradescoPositionSetup" */ "@/views/bradesco/position-setup.vue"
);
const BradescoNewPosition = () => import(
  /* webpackChunkName: "BradescoPositionSetup" */  "@/views/bradesco/new-position.vue"
);
// Módulo - Lotes e Integrações
const BradescoBatchIntegrations = () => import(
  /* webpackChunkName: "BradescoBatchIntegrations" */  "@/views/bradesco/batch-integrations.vue"
);

function createBradescoMeta(title) {
  return { requiresAuth: true, extraClaims: claim, title };
}

/**
 * Verifica se o usuário claim do bradesco, caso negativo, direcionado para home, do contrário, continua a rota
 * @type {NavigationGuard}
 */
function hasBradescoClaim(to, from, next) {
  if (!$store.getters["UserModule/extraClaims"].includes(claim)) {
    next("/home");
  } else {
    next();
  }
}

const routes = [
  {
    path: "/bradesco/vagas",
    name: "BradescoVacancy",
    component: BradescoVacancy,
    meta: createBradescoMeta(" Vagas | Exato"),
    beforeEnter: hasBradescoClaim,
  },
  {
    path: "/bradesco/vagas/busca",
    name: "BradescoVacancySearch",
    component: BradescoVacancy,
    meta: createBradescoMeta(" Busca Vagas | Exato"),
    beforeEnter: hasBradescoClaim,
  },

  {
    path: "/bradesco/vagas/:positionUid/candidatos/",
    name: "BradescoCandidates",
    component: BradescoCandidates,
    meta: createBradescoMeta(" Candidatos | Exato"),
    props: {
      positionUid: {
        type: String,
        required: true
      },
    },
    beforeEnter: hasBradescoClaim,
  },

  //filtro candidatos
  {
    path: "/bradesco/vagas/:positionUid/candidatos/busca/",
    name: "BradescoCandidatesBusca",
    component: BradescoCandidates,
    meta: createBradescoMeta(" Busca Candidatos | Exato"),
    props: {
      positionUid: {
        type: String,
        required: true
      },
    },
    beforeEnter: hasBradescoClaim,
  },
  //end filtro candidatos

  {
    path: "/bradesco/vagas/:positionUid/:applicationUid/:candidateUid", // provisório
    name: "BradescoCandidatesDetail",
    component: () => import(
          /* webpackChunkName: "BradescoVacancyCandidates" */ "@/views/bradesco/candidates-detail.vue"
    ),
    meta: createBradescoMeta(" Candidatos | Exato") // colocar nome do candidato
    ,
    props: {
      positionUid: {
        type: String,
        required: true
      },
      candidateUid: {
        type: String,
        required: true
      },
      applicationUid: {
        type: String,
        required: true
      },
    },
    beforeEnter: hasBradescoClaim,
  },

  {
    path: "/bradesco/setup-vagas",
    name: "BradescoSetupVacancy",
    component: BradescoSetupVacancy,
    meta: createBradescoMeta(" Setup de Vagas | Exato"),
    beforeEnter: hasBradescoClaim,
  },
  {
    path: "/bradesco/setup-vagas/busca",
    name: "BradescoSetupVacancySearch",
    component: BradescoSetupVacancy,
    meta: createBradescoMeta(" Busca de Vagas | Exato"),
    beforeEnter: hasBradescoClaim,
  },

  {
    path: "/bradesco/nova-vaga",
    name: "BradescoNewPosition",
    component: BradescoNewPosition,
    meta: createBradescoMeta(" Nova Vaga | Exato"),
    beforeEnter: hasBradescoClaim,
  },

  {
    path: "/bradesco/editar-vaga/:positionUid",
    name: "BradescoEditPosition",
    component: BradescoNewPosition,
    meta: createBradescoMeta(" Editar Vaga | Exato"),
    props: {
      positionUid: {
        type: String,
        required: false
      },
    },
    beforeEnter: hasBradescoClaim,
  },

  {
    path: "/bradesco/lotes-integracoes",
    name: "BradescoBatchIntegrations",
    component: BradescoBatchIntegrations,
    meta: createBradescoMeta("Lotes e Integrações | Exato"),
    beforeEnter: hasBradescoClaim,
  },
  {
    path: "/bradesco/lotes-integracoes/busca",
    name: "BradescoBatchIntegrationsSearch",
    component: BradescoBatchIntegrations,
    meta: createBradescoMeta("Busca Lotes e Integrações | Exato"),
    beforeEnter: hasBradescoClaim,
  },

  {
    path: "/bradesco/lotes-integracoes-detalhes/:batchRunUid",
    name: "BradescoBatchIntegrationsDetails",
    component: () => import(
          /* webpackChunkName: "BradescoBatchIntegrations" */  "@/views/bradesco/batch-integrations-detail.vue"
    ),
    meta: createBradescoMeta("Lotes e Integrações | Exato"),
    props: {
      batchRunUid: {
        type: String,
        required: true
      },
    },
    beforeEnter: hasBradescoClaim,
  },
];

export default routes;
