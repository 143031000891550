// o hubspot foi instalado via tagmanager
class HubSpotChat {
  // Open chat widget
  open() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      // TODO - avaliar e tratar melhor
      console.error("HubSpot Conversations widget is not loaded yet.");
    }
  }

  // Close chat widget
  close() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.close();
    } else {
      // TODO - avaliar e tratar melhor
      console.error("HubSpot Conversations widget is not loaded yet.");
    }
  }
}

export default new HubSpotChat();
