import i18n from "@/i18n/i18n-instance";

class NetworkError extends Error {
  constructor() {
    super(i18n.t("errors.NetworkError"));
    this.name = "NetworkError";
  }
}

export default NetworkError;
