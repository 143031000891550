import { render, staticRenderFns } from "./input-phone.vue?vue&type=template&id=027073cb&scoped=true"
import script from "./input-phone.vue?vue&type=script&lang=js"
export * from "./input-phone.vue?vue&type=script&lang=js"
import style0 from "./input-phone.vue?vue&type=style&index=0&id=027073cb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "027073cb",
  null
  
)

export default component.exports