var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert",class:[
    _vm.smallText ? 'small-text' : null,
    _vm.smallIcon ? 'small-icon' : null,
    _vm.topIcon ? 'top-icon' : null,
    _vm.getAlertType.class
  ]},[(_vm.showIcon)?_c('div',{staticClass:"alert-icon"},[_c('svg',{staticClass:"icon icon-big",class:_vm.loading
        ? 'icon-loading rotate'
        : _vm.iconName != null
          ? _vm.iconName
          : _vm.getAlertType.icon},[_c('use',{attrs:{"xlink:href":`${_vm.iconPath}#${_vm.loading
          ? 'icon-loading'
          : _vm.iconName != null
            ? _vm.iconName
            : _vm.getAlertType.icon
        }`}})])]):_vm._e(),_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.text)}}),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }