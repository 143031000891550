<template>
  <div>
    <h2 v-if="$mq === 'sm' || $mq === 'md'">
      Verificação em duas etapas
    </h2>

    <h4 class="header-text">
      Informe o código que foi enviado por SMS para o seu telefone
    </h4>
    <Alert
      v-if="pageError"
      alert-type="danger"
      :show-icon="false"
      :small-text="true"
      class="alert"
    >
      <slot v-if="loginErrorType === 'userLoginError'">
        {{ errorMessage }}
        <a
          class="error-forgot-password-link"
          href="#"
          @click.prevent="recoverPassword"
        >Esqueceu sua senha?</a>
      </slot>
      <slot v-else-if="loginErrorType === 'userBlocked'">
        {{ errorMessage }}
        <a
          class="error-forgot-pass-link"
          href="#"
          @click.prevent="recoverPassword"
        >Esqueci minha senha</a>.
      </slot>
      <slot v-else>
        {{ errorMessage }}
      </slot>
    </Alert>

    <div class="body-text-1 mt-1 user-phone">
      <img
        class="phone-icon"
        src="/images/phone.svg"
        alt="phone"
      >
      {{ userPhone }}
    </div>

    <div class="inputs-box">
      <div
        class="form-group"
        :class="{ 'form-group--error': $v.code.$error }"
      >
        <the-mask
          v-model.trim="$v.code.$model"
          placeholder="Digite o código de verificação"
          :mask="['######']"
          @keyup.enter.native="checkCode"
        />
        <div
          v-if="!$v.code.$error"
          id="phoneHelp"
          class="form-text"
        >
          Código de 6 dígitos
        </div>

        <div
          v-if="!$v.code.required && $v.code.$error"
          class="error-message"
        >
          O campo código é obrigatório
        </div>
        <div
          v-if="!$v.code.minLength && $v.code.$error"
          class="error-message"
        >
          O campo código deve ter no mínimo
          {{ $v.code.$params.minLength.min }} caracteres
        </div>
      </div>
    </div>

    <p class="body-text-1 mb-1-5">
      Não recebeu o SMS?
      <router-link
        to=""
        @click.native="resendCode"
      >
        Reenviar código
      </router-link>
    </p>

    <div class="body-text-1 mb-1">
      <router-link
        to=""
        @click.native="$emit('change-two-factor-method', 'email')"
      >
        <svg class="icon icon-small icon-email icon-with-text zoomIn">
          <use xlink:href="/images/icon-lib.svg#icon-email" />
        </svg>
        Enviar código pelo e-mail
      </router-link>
    </div>

    <div class="button-box">
      <Button
        btn-style="primary"
        :disabled="$v.$invalid || screenLocked"
        :loading="screenLocked"
        @click="$emit('check-code', code)"
      >
        Verificar
      </Button>
    </div>
  </div>
</template>


<script>
import InputText from "@/components/inputs/input-text.vue";
import Button from "@/components/buttons/button.vue";
import Alert from "@/components/misc/alert.vue";
import { TheMask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {

  components: {
    InputText,
    Button,
    TheMask,
    Alert,
  },
  mixins: [validationMixin],

  validations: {
    code: {
      required,
      minLength: minLength(6),
    },
  },

  props: {
    userPhone: {
      type: String,
      required: true,
    },
    pageError: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    screenLocked: {
      type: Boolean,
      required: false,
      default: false,
    },
    loginErrorType: {
      type: String,
      required: false,
    },
  },

  data: function () {
    return {
      code: "",
    };
  },

  methods: {
    resendCode: function () {
      this.$emit("send-verification-code");
      if (!this.pageError) {
        this.$bus.$emit(
          "global-notification",
          "O código de verificação foi reenviado pelo SMS com sucesso!",
          false
        );
      }
    },
    recoverPassword: function () {
      this.$bus.$emit("global-recover-password");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/base-modal.less";
@import "../../styles/base-inputs.less";

.button-box {
  margin: 0;
}

.phone-icon {
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
}
.user-phone {
  color: @ColorPriTurquoise;
}
.error-forgot-password-link {
  font-size: 12px;
  color: #721c24;
  text-decoration: underline;
}
</style>