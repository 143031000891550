import exatoClaim from "@/router/exato/exato-claim";
import bradescoClaim from "@/router/bradesco/bradesco-claim";
import DocCheckClaimsEnum from "@/router/doc-check/doc-check-claims-enum";
import HttpRequestService from "@/services/service-providers/httpRequest-service.js";

const userModule = {
    namespaced: true,

    state: {
        userAuthenticated: false,
        userUID: "",
        userMainEmail: "",
        userName: "",
        companyUID: "",
        companyName: "",
        indicationCode: "",
        currentCompanyCNPJ: "",
        impersonatedCompanyUid: "",
        userCPF: "",
        extraClaims: /** @type {string[]} */([]),
        userCompanies: {},
        emailValidated: false,
        userOnboarded: false,
        userBalance: -1,
        userPhone: "",
        userSocialLogin: false,
        currentBillingType: "",
        cameFromChatbot: false,
        haveAddress: false,
    },

    mutations: {

        login: function (state, token) {

            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let jwtCredentials = JSON.parse(window.atob(base64));


            state.userAuthenticated = true;
            state.userUID = jwtCredentials.unique_name;
            state.companyUID = jwtCredentials.companyUID;

            const extraClaims = [];
            if (jwtCredentials.extraClaim_0 != null) {
                extraClaims.push(jwtCredentials.extraClaim_0);
            }
            if (jwtCredentials.extraClaim_1 != null) {
                extraClaims.push(jwtCredentials.extraClaim_1);
            }
            state.extraClaims = extraClaims;

            HttpRequestService.setAuthToken(token);
        },

        setUserInfo: function (state, { userName, companyName, userCompanies, emailValidated, userOnboarded, userMainEmail, indicationCode, currentCompanyCNPJ, userCPF, extraClaims, currentBillingType, userPhone, cameFromChatbot, haveAddress, impersonatedCompanyUid }) {
            state.userName = userName;
            state.companyName = companyName;
            state.userCompanies = userCompanies;
            state.emailValidated = emailValidated;
            state.userOnboarded = userOnboarded;
            state.userMainEmail = userMainEmail;
            state.indicationCode = indicationCode;
            state.currentCompanyCNPJ = currentCompanyCNPJ;
            state.userCPF = userCPF;
            state.extraClaims = extraClaims == null ? [] : extraClaims;
            state.currentBillingType = currentBillingType;
            state.userPhone = userPhone;
            state.cameFromChatbot = cameFromChatbot;
            state.haveAddress = haveAddress;
            state.impersonatedCompanyUid = impersonatedCompanyUid;
        },

        logout: function (state) {
            state.userAuthenticated = false;
            state.userUID = "";
            state.companyUID = "";
            state.userName = "";
            state.companyName = "";
            state.userMainEmail = "";
            state.indicationCode = "";
            state.currentCompanyCNPJ = "";
            state.userCPF = "";
            state.userCompanies = {};
            state.extraClaims = [];
            state.currentBillingType = "";
            state.userSocialLogin = false;
            state.impersonatedCompanyUid = "";
            state.emailValidated =  false;
            state.userOnboarded =  false;
            state.userBalance =  -1;
            state.userPhone =  "";                        
            state.cameFromChatbot= false;
            state.haveAddress= false;
            HttpRequestService.deleteAuthToken();
        },

        userBalance: function (state, value) {
            state.userBalance = value;
        },
        userOnboarded: function (state, value) {
            state.userOnboarded = value;
        },
        emailValidated: function (state, value) {
            state.emailValidated = value;
        },
        userPhone: function (state, value) {
            state.userPhone = value;
        },
        cameFromChatbot: function (state, value) {
            state.cameFromChatbot = value;
        },
        haveAddress: function (state, value) {
            state.haveAddress = value;
        },
        userName: function (state,value) {
            state.userName = value;
        },
        userSocialLogin: function (state,value){
            state.userSocialLogin = value;
        }
    },

    getters: {

        userBalance: function (state) {
            return state.userBalance;
        },

        userAuthenticated: function (state) {
            return state.userAuthenticated;
        },

        indicationCode: function (state) {
            return state.indicationCode;
        },

        userAsCompany: function (state) {
            return state.userAuthenticated && state.companyUID ? true : false;
        },

        currentCompanyOnboarded: function (state) {
            if (!state.companyUID || !state.userCompanies.length)
                return false;

            let userCompanieFound = null;
            for (let i = 0; i < state.userCompanies.length; i++) {
                if (state.userCompanies[i].companyUID == state.companyUID) {
                    userCompanieFound = state.userCompanies[i];
                    break;
                }
            }
            if (userCompanieFound)
                return userCompanieFound.onboarded;
            else
                return false;
        },

        userHaveAnyCompany: function (state) {
            return state.userCompanies && state.userCompanies.length > 1 ? true : false;
        },

        userUID: function (state) {
            return state.userUID;
        },

        userName: function (state) {
            return state.userName;
        },

        userMainEmail: function (state) {
            return state.userMainEmail;
        },

        userOnboarded: function (state) {
            return state.userOnboarded;
        },

        emailValidated: function (state) {
            return state.emailValidated;
        },

        userFirstName: function (state) {
            if (state.userName && state.userName.indexOf(" ") > -1)
                return state.userName.substr(0, state.userName.indexOf(" "));
            return state.userName;
        },

        companyUID: function (state) {
            return state.companyUID;
        },

        companyName: function (state) {
            return state.companyName;
        },

        userCompanies: function (state) {
            return state.userCompanies;
        },

        userCPF: function (state) {
            return state.userCPF;
        },

        //
        // Extra Claims
        //

        extraClaims(state) {
            return state.extraClaims;
        },
        hasExtraClaims(state) {
            return state.extraClaims.length > 0;
        },
        hasUnknownClaimsOnly(state) {
            /**
             * DocCheckClaimsEnum.Admin não é incluída aqui por ser uma claim
             * especial apenas para disponibilização de funcionalidades extras.
             */
            const knownClaims = [exatoClaim, bradescoClaim, DocCheckClaimsEnum.Default];
            return state.extraClaims.every(extraClaim => !knownClaims.includes(extraClaim));
        },
        hasExtraClaim(state) {
            return function (claim) {
                return state.extraClaims.includes(claim);
            };
        },
        hasExatoClaim(state, getters) {
            return getters.hasExtraClaim(exatoClaim);
        },
        hasBradescoClaim(state, getters) {
            return getters.hasExtraClaim(bradescoClaim);
        },
        hasDocCheckClaim(state, getters) {
            return getters.hasExtraClaim(DocCheckClaimsEnum.Default);
        },
        hasDocCheckReadOnlyClaim(state, getters) {
            return getters.hasExtraClaim(DocCheckClaimsEnum.ReadOnly);
        },
        hasDocCheckAdminClaim(state, getters) {
            return getters.hasExtraClaim(DocCheckClaimsEnum.Admin);
        },
        hasDocCheckDashboardClaim(state, getters) {
            return getters.hasExtraClaim(DocCheckClaimsEnum.DashBoard);
        },
        canAccessExato(state, getters) {
            return !getters.hasExtraClaims || getters.hasUnknownClaimsOnly || getters.hasExatoClaim;
        },

        currentBillingType: function (state) {
            return state.currentBillingType;
        },

        currentCompanyCNPJ: function (state) {
            return state.currentCompanyCNPJ;
        },

        userPhone: function (state) {
            return state.userPhone;
        },

        cameFromChatbot: function (state) {
            return state.cameFromChatbot;
        },

        haveAddress: function (state) {
            return state.haveAddress;
        },
        
        userSocialLogin: function (state) {
            return state.userSocialLogin;
        },

        impersonatedCompanyUid: function (state) {
            return state.impersonatedCompanyUid;
        }
    }
};

export default userModule;
