import httpRequest from '@/services/service-providers/httpRequest-service.js';

class RouterRedirect {
    verifyUserIsMerged(userUid, organizationUid) {
        return httpRequest.axios.get('chatbot/checks-if-an-account-has-already-been-joined', {
            params: {
                userUid: userUid,
                organizationUid: organizationUid,                
            }
        });
    }
}

export default new RouterRedirect();
