<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        Complete o cadastro
      </h5>
      <!-- <h5 class="title2">Completando seu cadastro você terá acesso a mais consultas disponíveis na plataforma</h5> -->
      <BenefitsRegister />

      <StepIndicatorModal
        v-if="pendencyExecuteQuery !== 'PENDENCY-EMAIL'"
        class="step-modal"
        :step-number="2"
        change-text-for-step-one="Validar E-mail"
        change-text-for-step-two="Completar Cadastro"
        change-text-for-step-three="Cadastrar Empresa"
        :pendency-execute-query="pendencyExecuteQuery"
      />
    </template>
    <template slot="right">
      <h4 class="header-text">
        Confirme os dados:
      </h4>
      <p class="question-paragraph">
        {{ question }}
      </p>
      <div class="options-box">
        <ButtonMediumDark
          v-for="(item, index) in options"
          :key="index"
          @click="choose(item)"
        >
          {{ item.text }}
        </ButtonMediumDark>
      </div>
      <p
        v-if="messageError != ''"
        class="error-message"
      >
        {{ messageError }}&nbsp;
      </p>
    </template>
  </ModalFullScreenContent>
</template>


<script>
    import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
    import ProgressBar from "@/components/misc/progress-bar.vue";
    import ButtonMediumDark from "@/components/buttons/button-medium-dark.vue";
    import StepIndicatorModal from "@/views/product/step-indicator-modal.vue";

    const BenefitsRegister = () =>
        import(
            /* webpackChunkName: "BenefitsRegister" */ "@/components/onboarding/benefits/benefits-register.vue"
    );

    export default {

        components: {
            ModalFullScreenContent,
            ProgressBar,
            ButtonMediumDark,
            StepIndicatorModal,
            BenefitsRegister,
        },

        props: {
            question: "",
            options: Array,
            messageError: "",
            percent: 0,

            pendencyExecuteQuery: {
                type: String,
                required: false,
                default: ''
            }
        },

        methods: {

            choose: function (item) {
                this.$emit("optionChosen", item);               
            }

        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/colors.less";
    @import "../../styles/base-modal.less";

    .options-box {
        margin-top: 21px;
    }

    .options-box > * {
        width: 332px;
        margin-top: 15px;
        clear: both;
    }

    .options-box input {
        width: 100%;
    }

    .question-paragraph {
        margin-top: 30px;
        font-weight: 300;
        font-size: 16px;
        line-height: 120%;
    }
    
</style>