/**
 * @typedef {import("vue-router").default} Router
 */

import localePath from "@/i18n/locale-path";
import getRouteBaseName from "@/i18n/get-route-base-name";

/**
 * @typedef {(router: Router, localeCode: string) => string} SwitchLocalePath
 */

/** @type {SwitchLocalePath} */
const switchLocalePath = (router, localeCode) => {
    const name = getRouteBaseName(router.currentRoute)
    if (!name) {
        return ""
    }

    const { params, ...routeCopy } = router.currentRoute
    const baseRoute = Object.assign({}, routeCopy, {
        name,
        params: {
            ...params,
            0: params.pathMatch
        }
    })

    return localePath(router, baseRoute, localeCode)
}

export default switchLocalePath
