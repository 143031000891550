<template>
  <input
    type="text"
    :value="value"
    @paste="onCnpjPaste"
    @keyup="updateSelf($event.target.value)"
  >
</template>

<script>
    import Cleave from 'cleave.js';
    import base from "@/components/inputs/base.js";

    export default {

        mixins: [base],

        mounted: function () {
            new Cleave(this.$el, {
                delimiters: [".", ".", "/", "-"],
                blocks: [2, 3, 3, 4, 2],
                numericOnly: true
            });
        },

        methods: {
            onCnpjPaste: function (evt) {
                let cnpj = (evt.clipboardData || window.clipboardData).getData('text');
                var cnpjOnlyNumbers = cnpj.replace(/\D+/g, '');
                cnpjOnlyNumbers = cnpjOnlyNumbers.padStart(14, '0');
                this.$emit('input', this.formatCnpj(cnpjOnlyNumbers));
            },

            formatCnpj: function (cnpj) {
                let firstBlock = cnpj.substring(0, 2);
                let secondBlock = cnpj.substring(2, 5);
                let thirdBlock = cnpj.substring(5, 8);
                let forthBlock = cnpj.substring(8, 12);
                let fifthBlock = cnpj.substring(12);
                
                return firstBlock + "." + secondBlock + "." + thirdBlock + "/" + forthBlock + "-" + fifthBlock;        
            }
        }

    }
</script>

<style lang="less" scoped>
    @import "base.less";
</style>