import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("UserModule");

const extraClaimsMixin = {
  computed: {
    ...mapGetters([
      "extraClaims",
      "hasExtraClaims",
      "hasUnknownClaimsOnly",
      "hasExtraClaim",
      "hasExatoClaim",
      "hasBradescoClaim",
      "hasDocCheckClaim",
      "hasDocCheckReadOnlyClaim",
      "hasDocCheckAdminClaim",
      "hasDocCheckDashboardClaim",
      "canAccessExato",
    ]),
  },
};

export default extraClaimsMixin;
