import axios from "axios";
import cookieHelpers from "@/utils/cookie-helpers.js";
import $store from '@/state/index.js';
import NetworkError from "@/errors/network-error";
import InternalServerError from "@/errors/internal-server-error";

class HttpRequestService {

    constructor() {

        this._sessionEndCallback = null;
        this._lastTimeoutSessionEnd = null;
        this._lastTimeoutRenewToken = null;
        this._instanceIsHot = true;
        this._axiosInstance = axios.create({
            headers: { 'Cache-Control': 'no-cache' },
            baseURL: process.env.VUE_APP_URL_API
        });

        let self = this;

        //Interceptor to renew the auth token
        //------------------------------------------------------------------------
        this._axiosInstance.interceptors.request.use(function (config) {
            if (self.authCookiePresent && !config.url.endsWith(self.renewTokenURL) && !self._lastTimeoutRenewToken) {
                self._lastTimeoutRenewToken = window.setTimeout(function () {
                    if (self.authCookiePresent)
                        self.askForToken();
                    self._lastTimeoutRenewToken = null;
                }, 20000);
            }                

            return config;
        },
        function (error) {
            return Promise.reject(error);
        });

        //Interceptor to show the loading animation
        //------------------------------------------------------------------------
        this._axiosInstance.interceptors.request.use(function (config) {

            if (!config.url.endsWith(self.renewTokenURL))
                $store.commit("LoadingModule/startLoading"); 

            return config;
        },
        function (error) {
            return Promise.reject(error);
        });

        //Interceptor to hide the loading animation
        //------------------------------------------------------------------------
        this._axiosInstance.interceptors.response.use(function (response) {
            
            if (!response.config.url.endsWith(self.renewTokenURL))
                $store.commit("LoadingModule/endLoading");
            return response;
        },
        function (error) {
            $store.commit("LoadingModule/endLoading");
            return Promise.reject(error);
        });

        //Interceptor to expose a friendly error message for the user
        //------------------------------------------------------------------------
        this._axiosInstance.interceptors.response.use(function (response) {
            return response;
        },
        function (error) {
            if (error.message == "Network Error") {
                error.response = {
                    data: {
                        message: new NetworkError().message,
                    }
                };
            }
            else if (error.response && error.response.status == 500 && error.response.data == "") {
                error.response.data = {
                    message: new InternalServerError().message,
                };
            }
            return Promise.reject(error);
        });

        //If the user browser already has the auth token, then use in axios header
        //------------------------------------------------------------------------
        let token = cookieHelpers.getCookie("token");
        if (token)
            this._axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    /**
     * Get the renew token URL.
     */
    get renewTokenURL() { return "Login/RenewToken"; }

    /**
     * Axios instance to make http requests.
     */
    get axios() { return this._axiosInstance; }

    /**
     * Verify if the auth cookie is present and valid (expiration).
     */
    get authCookiePresent() { return cookieHelpers.getCookie("token") ? true : false; }

    /**
     * Session end callback
     */
    get sessionEndCallback() { return this._sessionEndCallback; }
    set sessionEndCallback(value) { this._sessionEndCallback = value; }

    /**
     * Ask for a new token
     */
    askForToken() {
        let self = this;
        this.axios.get(this.renewTokenURL).then((result) => {
            self.setAuthToken(result.data.data);
        });
    }

    /**
     * Set the jwt credentials in the axios instance to use on every request
     * @param {String} token
     */
    setAuthToken(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let jwtCredentials = JSON.parse(window.atob(base64));
        let expirationDate = new Date(jwtCredentials.exp * 1000);
        cookieHelpers.setCookie("token", token, expirationDate);
        this._axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
        let milisecondsToExpire = expirationDate.getTime() - new Date().getTime() - 30000; //tolerance time of 30 seconds
        if (this._lastTimeoutSessionEnd)
            window.clearTimeout(this._lastTimeoutSessionEnd);
        let self = this;
        this._lastTimeoutSessionEnd = window.setTimeout(function () {
            if (self.sessionEndCallback)
                self.sessionEndCallback();
            self._lastTimeoutSessionEnd = null;
        }, milisecondsToExpire);
    }

    /**
     * Delete the auth token 
     */
    deleteAuthToken() {
        cookieHelpers.eraseCookie("token");
        delete this._axiosInstance.defaults.headers.common["Authorization"];
    }

}

export default new HttpRequestService();
