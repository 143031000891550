<template>
  <ModalFullScreenContent show-logo>
    <template slot="left">
      <h5 class="title1">
        {{ msgLeft }}
      </h5>
      <h5 class="title2">                
        {{ 
          pendencyRegister
            ? 'Para fazer a consulta selecionada, finalize as estapas do cadastro'
            : ''
        }}
      </h5>

      <StepIndicatorModal
        v-if="pendencyExecuteQuery !== 'PENDENCY-EMAIL'"
        class="step-modal"
        :step-number="2"
        change-text-for-step-one="Validar E-mail"
        change-text-for-step-two="Completar Cadastro"
        change-text-for-step-three="Cadastrar Empresa"
        :pendency-execute-query="pendencyExecuteQuery"
      />
    </template>
    <template slot="right">
      <h2 v-if="$mq==='sm' || $mq==='md'">
        {{ msgLeft }}
      </h2>
      <h4 class="header-text">
        {{ 
          pendencyRegister
            ? ''
            : msgRight1 
        }}
      </h4>

      <p
        v-if="!pendencyRegister"
        v-html="msgRight2"
      />
      <div v-else>
        <p
          class="mb-1"
          v-html="msgRight2"
        />
        <p>Cadastre sua empresa para fazer a consulta selecionada.</p>
      </div>
      <!-- <p>
                {{ 
                    pendencyRegister
                    ? 'Muito bem! Agora para fazer a consulta selecionada, cadastre sua empresa.'
                    : msgRight2 
                }}
            </p> -->
      <div class="button-box">
        <ButtonLarge
          class="button"
          @click="proceed"
        >
          Continuar
        </ButtonLarge>
      </div>
    </template>
  </ModalFullScreenContent>
</template>


<script>
    import ModalFullScreenContent from "@/components/boxes/modal-fullscreen-content.vue";
    import ProgressBar from "@/components/misc/progress-bar.vue";
    import ButtonLarge from "@/components/buttons/button-large.vue";
    import StepIndicatorModal from "@/views/product/step-indicator-modal.vue";

    export default {

        components: {
            ModalFullScreenContent,
            ProgressBar,
            ButtonLarge,
            StepIndicatorModal
        },

        props: {
            msgLeft: "",
            msgRight1: "",
            msgRight2: "",

            pendencyExecuteQuery: {
                type: String,
                required: false,
                default: ''
            }
        },
        computed: {
            pendencyRegister: function () {
                return this.pendencyExecuteQuery === 'PENDENCY-COMPANY' ? true : false;
            }
        },

        methods: {
            proceed: function () {
                this.$emit("onboardEnd");
            }
        }        
    }
</script>

<style lang="less" scoped>

@import "../../styles/colors.less";
@import "../../styles/base-modal.less";

.email-info-paragraph{
margin-top: 2rem;
}

</style>