import $store from "@/state";
import i18n from "@/i18n/i18n-instance";
import createRouteConfigs from "@/i18n/create-route-configs";
import CookieHelpers from "@/utils/cookie-helpers";

//Se colocar no lazy load, os modais que checam parâmetros na rota, não abrem no primeiro acesso
import LandingPage from "@/views/landing/landing-generic.vue";
import Dossie from "@/views/product/view-product-pdf.vue";

//Dashboard
const Home = () => import(
  /* webpackChunkName: "Home" */  "@/views/dashboard/home.vue"
);
const LandingTestWhatsApp = () => import(
  /* webpackChunkName: "LandingTestWhatsApp" */  "@/views/landing/landing-test-whatsapp.vue"
);
const ProductHistory = () => import(
  /* webpackChunkName: "ProductHistory" */  "@/views/product-history/product-history.vue"
);
const BuyCreditConfirmation = () => import(
  /* webpackChunkName: "BuyCreditConfirmation" */  "@/views/credit/buy-credit-confirmation.vue"
);
const BuyCreditResult = () => import(
  /* webpackChunkName: "BuyCreditResult" */  "@/views/credit/buy-credit-result.vue"
);
const BuyProduct = () => import(
  /* webpackChunkName: "BuyProduct" */  "@/views/product/buy-product.vue"
);
const ConfirmBuy = () =>
  import(
    /* webpackChunkName: "ConfirmBuy" */ "@/views/product/confirm-buy.vue"
  );
const PrivacyPolicyPtBr = () =>
  import(
    /* webpackChunkName: "LegalDocs" */ "@/views/legal/privacy-policy.pt-BR.vue"
  );
const PrivacyPolicyEnUs = () =>
  import(
    /* webpackChunkName: "LegalDocs" */ "@/views/legal/privacy-policy.en-US.vue"
  );

/** @type {import("vue-router").NavigationGuard} */
function sendHomeIfAuthenticated(to, from, next) {
  if ($store.getters["UserModule/userAuthenticated"]) {
    next("/home");
  } else {
    next();
  }
}

function removeStorageExIdentifyAffiliateKeyId() {
  localStorage.removeItem("exIdentifyAffiliateKeyId");
}

function redirectAffiliateWithDocument(next, documentType, inputFields) {
  const externalId = documentType === "CNPJ"
    ? "16d4da51-febf-4303-b19c-91bf0e107238"
    : "e73b8677-effa-4dbe-a7a9-0f40cc2cfd7f";

  next({ path: `/confirmar-compra/${externalId}/input/${inputFields}` });
}

function sendConfirmQueryIfAuthenticated(to, from, next) {
  if ($store.getters["UserModule/userAuthenticated"]) {
    const documentType = to.params.documentType;
    const inputFields = to.params.inputFields;

    redirectAffiliateWithDocument(next, documentType, inputFields);
    removeStorageExIdentifyAffiliateKeyId();
  } else {
    next();
  }
}

const routes = [
  ...createRouteConfigs({
    baseConfig: {
      path: "/",
      name: "LandingPage",
      component: LandingPage,
      meta: { requiresAuth: false },
      beforeEnter: sendHomeIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        meta: {
          title: i18n.t("pages.LandingGeneric.meta.title", "en-US"),
          description: i18n.t("pages.LandingGeneric.meta.description", "en-US")
        }
      },
      {
        code: "pt-BR",
        meta: {
          title: i18n.t("pages.LandingGeneric.meta.title", "pt-BR"),
          description: i18n.t("pages.LandingGeneric.meta.description", "pt-BR")
        }
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "LandingResponsiveGeneric",
      component: LandingPage,
      meta: { requiresAuth: false },
      beforeEnter: sendHomeIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/data-check-antifraud",
        meta: {
          title: i18n.t("pages.LandingResponsiveGeneric.meta.title", "en-US")
        }
      },
      {
        code: "pt-BR",
        path: "/checagem-dados-antifraude",
        meta: {
          title: i18n.t("pages.LandingResponsiveGeneric.meta.title", "pt-BR")
        }
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "LandingPageRHGeneric",
      component: LandingPage,
      meta: { requiresAuth: false },
      beforeEnter: sendHomeIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/background-check-hiring-management-hr",
        title: i18n.t("pages.LandingPageRHGeneric.meta.title", "en-US")
      },
      {
        code: "pt-BR",
        path: "/background-check-contratacao-gestao-rh",
        title: i18n.t("pages.LandingPageRHGeneric.meta.title", "pt-BR")
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "RecoverPass",
      component: LandingPage,
      meta: { requiresAuth: false },
      props: {
        recoverPassRequestUID: {
          type: String,
          required: true
        }
      }
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/reset-password/:recoverPassRequestUID"
      },
      {
        code: "pt-BR",
        path: "/renovacao-senha/:recoverPassRequestUID"
      }
    ]
  }),
  // a abertura do login está no arquivo login-flow.vue
  ...createRouteConfigs({
    baseConfig: {
      path: "/login",
      name: "Login",
      component: LandingPage,
      meta: {
        requiresAuth: false,
        title: "Login | Exato"
      },
      beforeEnter: sendHomeIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        meta: {
          title: i18n.t("pages.Login.meta.title", "en-US")
        }
      },
      {
        code: "pt-BR",
        meta: {
          title: i18n.t("pages.Login.meta.title", "pt-BR")
        }
      }
    ]
  }),
  {
    path: "/login/ad",
    name: "ActiveDirectoryLogin",
    component: () =>
      import(
        /* webpackChunkName: "ActiveDirectoryLogin" */ "@/views/onboarding/active-directory-login.vue"
      ),
    // component: () => import(
    //   /* webpackChunkName: "Home" */  "@/views/onboarding/active-directory-login.vue"
    // ),
    params: {
      relatorioUID: {
        type: String,
        required: false
      }
    }
  },

  // a abertura do Cadastro está no arquivo landing-generic.vue
  ...createRouteConfigs({
    baseConfig: {
      name: "Cadastro",
      component: LandingPage,
      meta: { requiresAuth: false },
      beforeEnter: sendHomeIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/signup",
        meta: {
          title: i18n.t("pages.Cadastro.meta.title", "en-US")
        }
      },
      {
        code: "pt-BR",
        path: "/cadastro",
        meta: {
          title: i18n.t("pages.Cadastro.meta.title", "pt-BR")
        }
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "CadastroTeste",
      component: LandingPage,
      meta: { requiresAuth: false },
      props: { externalId: { type: String, required: true } },
      beforeEnter: sendHomeIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/signup/:externalId",
        meta: {
          title: i18n.t("pages.CadastroTeste.meta.title", "en-US")
        }
      },
      {
        code: "pt-BR",
        path: "/cadastro/:externalId",
        meta: {
          title: i18n.t("pages.CadastroTeste.meta.title", "pt-BR")
        }
      }
    ]
  }),

  // rota para emitir relatório completo do flashcheck
  // receber o UID do relatório, documento, exFlashDocumentHash
  // {
  //   path: "/cadastro/:externalId/:inputFields/:exFlashDocumentHash",
  //   name: "CadastroEmitirRelatorio",
  //   component: LandingPage,
  //   meta: {
  //     requiresAuth: false,
  //     title: "Cadastre-se para Emitir o Relatório Completo | Exato"
  //   },
  //   props: {
  //     externalId: {
  //       type: String,
  //       required: true
  //     },
  //     inputFields: {
  //       type: String,
  //       required: true
  //     },
  //   },
  //   beforeEnter: sendConfirmQueryIfAuthenticated
  // },

  // rota para emitir relatório completo do flashcheck usando o document-type e affiliateID
  ...createRouteConfigs({
    baseConfig: {
      name: "CadastroEmitirRelatorioAfiliado",
      component: LandingPage,
      meta: { requiresAuth: false },
      props: {
        documentType: { type: String, required: true },
        inputFields: { type: String, required: true },
        exIdentifyAffiliateKeyId: { type: String, required: true }
      },
      beforeEnter: sendConfirmQueryIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/signup/:documentType/:inputFields/:exIdentifyAffiliateKeyId",
        meta: {
          title: i18n.t(
            "pages.CadastroEmitirRelatorioAfiliado.meta.title",
            "en-US"
          )
        }
      },
      {
        code: "pt-BR",
        path: "/cadastro/:documentType/:inputFields/:exIdentifyAffiliateKeyId",
        meta: {
          title: i18n.t(
            "pages.CadastroEmitirRelatorioAfiliado.meta.title",
            "pt-BR"
          )
        }
      }
    ]
  }),
  // rota para emitir relatório completo do flashcheck usando o documentType
  ...createRouteConfigs({
    baseConfig: {
      name: "CadastroEmitirRelatorio",
      component: LandingPage,
      meta: { requiresAuth: false },
      props: {
        documentType: { type: String, required: true },
        inputFields: { type: String, required: true }
      },
      beforeEnter: sendConfirmQueryIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/signup/:documentType/:inputFields",
        meta: {
          title: i18n.t("pages.CadastroEmitirRelatorio.meta.title", "en-US")
        }
      },
      {
        code: "pt-BR",
        path: "/cadastro/:documentType/:inputFields",
        meta: {
          title: i18n.t("pages.CadastroEmitirRelatorio.meta.title", "pt-BR")
        }
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      path: "/dexter",
      name: "Dexter",
      component: LandingPage,
      meta: { requiresAuth: false }
    },
    localeConfigs: [{ code: "en-US" }, { code: "pt-BR" }]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "ValidateEmail",
      component: CookieHelpers.getCookie("token") ? Home : LandingPage,
      meta: { requiresAuth: false },
      props: {
        validateEmailRequestUID: {
          type: String,
          required: true
        }
      }
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/email-confirmation/:validateEmailRequestUID"
      },
      {
        code: "pt-BR",
        path: "/confirmacao-email/:validateEmailRequestUID"
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "Invitation",
      component: LandingPage,
      meta: { requiresAuth: false },
      props: {
        invitationCode: {
          type: String,
          required: true
        }
      }
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/invitation/:invitationCode"
      },
      {
        code: "pt-BR",
        path: "/convite/:invitationCode"
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "CompanyRegistration",
      component: CookieHelpers.getCookie("token") ? Home : LandingPage,
      meta: { requiresAuth: false },
      props: {
        tokenCompanyRegistration: {
          type: String,
          required: true
        }
      }
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/company-registration/:tokenCompanyRegistration"
      },
      {
        code: "pt-BR",
        path: "/registro-por-empresa/:tokenCompanyRegistration"
      }
    ]
  }),
  {
    path: "/consulte-pessoas-empresas",
    name: "LandingWhatsAppPerformance",
    component: () =>
      import(
        /* webpackChunkName: "LandingWhatsAppPerformance" */ "@/views/landing/landing-whatsapp-performance.vue"
      ),
    meta: {
      requiresAuth: false
    },
    beforeEnter: sendHomeIfAuthenticated
  },
  {
    path: "/consulte-cpf-cnpj",
    name: "LandingWhatsAppBranding",
    component: () =>
      import(
        /* webpackChunkName: "LandingWhatsAppBranding" */ "@/views/landing/landing-whatsapp-branding.vue"
      ),
    meta: {
      requiresAuth: false
    },
    beforeEnter: sendHomeIfAuthenticated
  },
  {
    path: "/match",
    name: "LandingWhatsAppMetro",
    component: () =>
      import(
        /* webpackChunkName: "LandingWhatsAppMetro" */ "@/views/landing/landing-whatsapp-metro.vue"
      ),
    meta: {
      requiresAuth: false,
      title: "Consulte um CPF em 5 minutos | Exato"
    },
    beforeEnter: sendHomeIfAuthenticated
  },

  {
    path: "/antifraude",
    name: "LandingWhatsAppMetroPerformanceAntiFraude",
    component: () =>
      import(
        /* webpackChunkName: "LandingWhatsAppPerformance" */ "@/views/landing/landing-whatsapp-metro-performance-antifraude.vue"
      ),
    meta: {
      requiresAuth: false
    },
    beforeEnter: sendHomeIfAuthenticated
  },
  {
    path: "/evite-golpes-fraudes",
    name: "LandingWhatsAppMetroPerformance",
    component: () =>
      import(
        /* webpackChunkName: "LandingWhatsAppPerformance" */ "@/views/landing/landing-whatsapp-metro-performance-antifraude.vue"
      ),
    meta: {
      requiresAuth: false
    },
    beforeEnter: sendHomeIfAuthenticated
  },
  ...createRouteConfigs({
    baseConfig: {
      path: "/venture",
      name: "LandingWhatsAppVenture",
      component: () =>
        import(
          /* webpackChunkName: "LandingWhatsAppVenture" */ "@/views/landing/landing-whatsapp-venture.vue"
        ),
      meta: {
        requiresAuth: false,
        robots: "noindex"
      },
      beforeEnter: sendHomeIfAuthenticated
    },
    localeConfigs: [
      {
        code: "en-US",
        meta: {
          title: i18n.t("pages.LandingWhatsAppVenture.meta.title", "en-US")
        }
      },
      {
        code: "pt-BR",
        meta: {
          title: i18n.t("pages.LandingWhatsAppVenture.meta.title", "pt-BR")
        }
      }
    ]
  }),
  {
    path: "/testegratis",
    name: "LandingTestWhatsApp",
    component: LandingTestWhatsApp,
    meta: {
      requiresAuth: false
    },
    beforeEnter: sendHomeIfAuthenticated
  },
  {
    path: "/try",
    name: "LandingTestWhatsAppTry",
    component: LandingTestWhatsApp,
    meta: {
      requiresAuth: false,
      title: "Background check in minutes | Exato"
    },
    beforeEnter: sendHomeIfAuthenticated
  },
  {
    path: "/cpf",
    name: "LandingTestWhatsAppCpf",
    component: LandingTestWhatsApp,
    meta: {
      requiresAuth: false
    },
    beforeEnter: sendHomeIfAuthenticated
  },
  {
    path: "/cnpj",
    name: "LandingTestWhatsAppCnpj",
    component: LandingTestWhatsApp,
    meta: {
      requiresAuth: false
    },
    beforeEnter: sendHomeIfAuthenticated
  },
  // {
  //   path: "/landing-dev",
  //   name: "LandingPageDev",
  //   component: () => import(
  //     /* webpackChunkName: "LandingPageDev" */  "@/views/landing/landing-dev.vue"
  //   ),
  //   meta: {
  //     requiresAuth: false,
  //     title: "Automação de Consultas via API | Exato"
  //   },
  //   beforeEnter: sendHomeIfAuthenticated
  // },
  // {
  //   path: "/landing-rh",
  //   name: "LandingPageRH",
  //   component: () => import(
  //     /* webpackChunkName: "LandingPageRH" */  "@/views/landing/landing-rh.vue"
  //   ),
  //   meta: {
  //     requiresAuth: false,
  //     title: "Checagem de Dados para Recrutamento e Seleção | Exato"
  //   },
  //   beforeEnter: sendHomeIfAuthenticated
  // },
  // {
  //   path: "/landing-autos",
  //   name: "LandingPageAutos",
  //   component: () => import(
  //     /* webpackChunkName: "LandingPageAutos" */  "@/views/landing/landing-autos.vue"
  //   ),
  //   meta: {
  //     requiresAuth: false,
  //     title: "Checagem de Dados para Motoristas e Veículos | Exato"
  //   },
  //   beforeEnter: sendHomeIfAuthenticated
  // },

  // rotas para landin safecheck voltada para escolas
  {
    path: "/verificacao-antecedentes-instituicoes-ensino",
    name: "LandingVerificacaoAntecedentesInstituicoesEnsino",
    component: () =>
      import(
        /* webpackChunkName: "LandingVerificacaoAntecedentesInstituicoesEnsino" */ "@/views/landing/landing-verificacao-antecedentes-instituicoes-ensino.vue"
      ),
    meta: {
      requiresAuth: false,
      title: "Verifique antecedentes de colaboradores e prestadores de serviços nas instituições de ensino. Fale conosco!"
    },
  },
  {
    path: "/safecheck-verificacao-antecedentes-instituicoes-ensino",
    name: "LandingSafeCheckVerificacaoAntecedentesInstituicoesEnsino",
    component: () =>
      import(
        /* webpackChunkName: "LandingSafeCheckVerificacaoAntecedentesInstituicoesEnsino" */ "@/views/landing/landing-verificacao-antecedentes-instituicoes-ensino.vue"
      ),
    meta: {
      requiresAuth: false,
      title: "Verifique antecedentes de colaboradores e prestadores de serviços nas instituições de ensino. Fale conosco!"
    },    
  },
  {
    path: "/escolas",
    name: "LandingSafeCheckEscolas",
    component: () =>
      import(
        /* webpackChunkName: "LandingSafeCheckEscolas" */ "@/views/landing/landing-verificacao-antecedentes-instituicoes-ensino.vue"
      ),
    meta: {
      requiresAuth: false,
      title: "Verifique antecedentes de colaboradores e prestadores de serviços nas instituições de ensino. Fale conosco!"
    },
  },

  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      title: "Consultar | Exato"
    },
  },
  {
    // somente ativa se estiver logado
    // o onboarding será iniciado no individual-person-validation-flow.vue
    path: "/completar-cadastro",
    name: "CompletarCadastro",
    component: Home,
    meta: {
      requiresAuth: true,
      title: "Completar Cadastro | Exato"
    },
    props: {}
  },
  {
    path: "/historico-consultas/:mode",
    name: "ProductHistory",
    component: ProductHistory,
    meta: {
      requiresAuth: true,
      title: "Histórico de Consultas | Exato"
    },
    props: {
      mode: {
        type: String,
        required: true
      },
      batchMessage: {
        type: Boolean,
        required: false
      }
    }
  },

  {
    path: "/historico-consultas/:mode/busca",
    name: "ProductHistorySearch",
    component: ProductHistory,
    meta: {
      requiresAuth: true,
      title: "Histórico de Consultas | Exato"
    },
    props: {
      mode: {
        type: String,
        required: true
      },
      batchMessage: {
        type: Boolean,
        required: false
      }
    }
  },

  {
    path: "/historico-consultas/lote/:uid",
    name: "ProductHistoryBatchDetail",
    component: () =>
      import(
        /* webpackChunkName: "ProductHistoryBatchDetail" */ "@/views/product-history/product-history-batch-detail.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Histórico de Consultas | Lote Detalhe | Exato"
    },
    props: {
      uid: {
        type: String,
        required: true
      }
    }
  },
  {
    path: "/procurar-produto/:sourceId?/:queryTarget?",
    name: "SearchProduct",
    component: () =>
      import(
        /* webpackChunkName: "SearchProduct" */ "@/views/dashboard/search-product.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Procurar Consultas | Exato"
    },
    props: {
      sourceId: {
        type: Number,
        required: false
      },
      queryTarget: {
        type: Number,
        required: false
      }
    }
  },
  {
    path: "/saldo-extrato",
    name: "SaldoExtrato",
    component: () =>
      import(
        /* webpackChunkName: "Balance" */ "@/views/edit-profile/balance.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Saldo e Extrato | Exato"
    }
  },
  {
    path:
      "/exibir-consulta/:externalId/input/:inputFields/autoconfirmar/:autoconfirm",
    name: "EfetivarCompraProduto",
    component: Dossie,
    meta: {
      requiresAuth: true
    },
    props: {
      externalId: {
        type: String,
        required: true
      },
      inputFields: {
        type: String,
        required: true
      },
      autoconfirm: {
        type: Boolean,
        required: true
      }
    }
  },
  {
    path:
      "/exibir-consulta/resultado/:externalId/input/:inputFields/identificador/:uniqueIdentifier",
    name: "ExibirProdutoResultado",
    component: Dossie,
    meta: {
      requiresAuth: true
    },
    props: {
      externalId: {
        type: String,
        required: true
      },
      inputFields: {
        type: String,
        required: true
      },
      uniqueIdentifier: {
        type: String,
        required: true
      }
    }
  },
  {
    //path: "/exibir-consulta/resultado/consulta",
    path:
      "/exibir-consulta/unitaria/:externalId/input/:inputFields/identificador/:uniqueIdentifier",
    name: "ExibirProdutoResultadoNaoDossie",
    component: Dossie,
    meta: {
      requiresAuth: true
    },
    props: {
      externalId: {
        type: String,
        required: true
      },
      inputFields: {
        type: String,
        required: true
      },
      uniqueIdentifier: {
        type: String,
        required: true
      }
    }
  },
  {
    path:
      "/exibir-consulta/unitaria/:externalId/identificador/:uniqueIdentifier",
    name: "ExibirProdutoUnitaria",
    component: Dossie,
    meta: {
      requiresAuth: true
    },
    props: {
      externalId: {
        type: String,
        required: true
      },
      uniqueIdentifier: {
        type: String,
        required: true
      }
    }
  },

  {
    path: "/editar-perfil",
    name: "EditProfile",
    component: () =>
      import(
        /* webpackChunkName: "EditProfile" */ "@/views/edit-profile/edit-profile.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Editar Perfil | Exato"
    }
  },
  {
    path: "/cancelar-conta",
    name: "CancelAccount",
    component: () =>
      import(
        /* webpackChunkName: "CancelAccount" */ "@/views/edit-profile/cancel-account.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Cancelar Conta | Exato"
    }
  },

  {
    path: "/relatorio/:uid",
    name: "ReportDonwload",
    component: () =>
      import(
        /* webpackChunkName: "ReportDonwload" */ "@/views/product/report-download.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Download Relatório | Exato"
    },
    props: {
      urlPdfReport: {
        type: String,
        required: true
      }
    }
    //beforeEnter: openReport
  },
  {
    path: "/comprar-credito",
    name: "ComprarCredito",
    component: () =>
      import(
        /* webpackChunkName: "BuyCredit" */ "@/views/credit/buy-credit.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Comprar Créditos | Exato"
    }
  },
  {
    path: "/confirmacao-compra-credito/:packageUID",
    name: "ConfirmarCompraPackageUID",
    component: BuyCreditConfirmation,
    meta: {
      requiresAuth: true,
      title: "Comprar Créditos | Pagamento | Exato"
    },
    props: {
      packageUID: {
        type: String,
        required: true
      }
    }
  },
  {
    path: "/confirmacao-compra-credito/valor/:price",
    name: "ConfirmarCompraValor",
    component: BuyCreditConfirmation,
    meta: {
      requiresAuth: true,
      title: "Comprar Créditos | Pagamento | Exato"
    },
    props: {
      packageUID: {
        type: Number,
        required: true
      }
    }
  },
  {
    path: "/resultado-compra-credito/:transactionCode",
    name: "BuyCreditResult",
    component: BuyCreditResult,
    meta: {
      requiresAuth: true,
      title: "Comprar Créditos | Confirmação | Exato"
    },
    props: {
      transactionCode: {
        type: String,
        required: true
      }
    }
  },
  {
    path: "/resultado-compra-credito/",
    name: "BuyCreditResultRedirect",
    component: BuyCreditResult,
    meta: {
      requiresAuth: true,
      title: "Comprar Créditos | Confirmação | Exato"
    }
  },
  ...createRouteConfigs({
    baseConfig: {
      name: "ComprarProduto",
      component: BuyProduct,
      meta: { requiresAuth: false },
      props: {
        externalId: {
          type: String,
          required: true
        },
        preInputValue: {
          type: String,
          required: false
        }
      }
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/buy-product/:externalId/:preInputValue?"
      },
      {
        code: "pt-BR",
        path: "/comprar-produto/:externalId/:preInputValue?"
      }
    ]
  }),
  {
    path: "/comprar-produto/:externalId/lote/:mode?",
    name: "ComprarProdutoLote",
    component: BuyProduct,
    meta: { requiresAuth: false },
    props: {
      externalId: {
        type: String,
        required: true
      },
      mode: {
        type: String,
        required: false
      }
    }
  },
  {
    path: "/comprar-produto-validando-onboard/:externalId/:preInputValue?",
    name: "ComprarProdutoValidandoOnboard",
    component: BuyProduct,
    meta: { requiresAuth: true },
    props: {
      externalId: {
        type: String,
        required: true
      },
      preInputValue: {
        type: String,
        required: false
      }
    }
  },
  {
    path: "/confirmar-compra/:externalId/entidade/:entityId",
    name: "ConfirmarCompraEntidadeExplicita",
    component: ConfirmBuy,
    meta: { requiresAuth: true },
    props: {
      externalId: {
        type: String,
        required: true
      },
      entityId: {
        type: String,
        required: true
      }
    }
  },
  {
    path: "/confirmar-compra/:externalId/input/:inputFields",
    name: "ConfirmarCompraManualInput",
    component: ConfirmBuy,
    meta: { requiresAuth: true },
    props: {
      externalId: {
        type: String,
        required: true
      },
      inputFields: {
        type: String,
        required: true
      }
    }
  },

  //Provisório: Fechar rota na integração com o back
  {
    path: "/confirmar-compra/:externalId/input/:inputFields/lote",
    name: "ConfirmarCompraLote",
    component: ConfirmBuy,
    meta: { requiresAuth: true },
    props: {
      externalId: {
        type: String,
        required: true
      },
      inputFields: {
        type: String,
        required: true
      }
    }
  },
  ...createRouteConfigs({
    baseConfig: {
      name: "TermsConditions",
      meta: { requiresAuth: false }
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/terms-of-use",
        component: () =>
          import(
            /* webpackChunkName: "LegalDocs" */
            "@/views/legal/terms-conditions.en-US.vue"
          ),
        meta: { title: i18n.t("pages.TermsConditions.meta.title", "en-US") }
      },
      {
        code: "pt-BR",
        path: "/termos-condicoes",
        alias: ["/termos-de-uso", "/termos-uso", "/termos"],
        component: () =>
          import(
            /* webpackChunkName: "LegalDocs" */
            "@/views/legal/terms-conditions.pt-BR.vue"
          ),
        meta: { title: i18n.t("pages.TermsConditions.meta.title", "pt-BR") }
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "PrivacyPolicy",
      meta: { requiresAuth: false }
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/privacy-policy",
        component: PrivacyPolicyEnUs,
        meta: { title: i18n.t("pages.PrivacyPolicy.meta.title", "en-US") }
      },
      {
        code: "pt-BR",
        path: "/politica-privacidade",
        component: PrivacyPolicyPtBr,
        alias: "/politica-de-privacidade",
        meta: { title: i18n.t("pages.PrivacyPolicy.meta.title", "pt-BR") }
      }
    ]
  }),
  ...createRouteConfigs({
    baseConfig: {
      name: "GovernancePolicy",
      meta: { requiresAuth: false }
    },
    localeConfigs: [
      {
        code: "en-US",
        path: "/governance-policy",
        component: () =>
          import(
            /* webpackChunkName: "LegalDocs" */
            "@/views/legal/governance-policy.en-US.vue"
          ),
        meta: { title: i18n.t("pages.GovernancePolicy.meta.title", "en-US") }
      },
      {
        code: "pt-BR",
        path: "/politica-governanca",
        component: () =>
          import(
            /* webpackChunkName: "LegalDocs" */
            "@/views/legal/governance-policy.pt-BR.vue"
          ),
        meta: { title: i18n.t("pages.GovernancePolicy.meta.title", "pt-BR") }
      }
    ]
  })
];

export default routes;
