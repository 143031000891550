import {
  Hooper,
  Slide as HooperSlide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "hooper/dist/hooper.css";

export const HooperCarousel = {
  components: {
    Hooper,
    HooperSlide,
    HooperNavigation,
    HooperPagination,
  },
}
