<template>
  <div class="pdf-password-instruction-widget">
    <div class="pdf-password-instruction-widget-icon">
      <svg
        class="pdf-password-instruction-widget-icon-image icon icon-medium"
        tabindex="-1"
      >
        <use xlink:href="/images/icon-lib.svg#icon-notification-info" />
      </svg>
    </div>
    <div class="pdf-password-instruction-widget-divider" />
    <div class="pdf-password-instruction-widget-content">
      <div class="pdf-password-instruction-widget-title">
        <p class="pdf-password-instruction-widget-title-text">
          Senha do relatório
        </p>
        <TooltipIcon
          icon-class="pdf-password-instruction-widget-title-icon icon-extrasmall"
          :text="tooltipHtml"
        />
      </div>
      <div
        v-if="pdfPassword"
        class="pdf-password-instruction-widget-password"
      >
        <p
          v-if="isShowingPassword"
          class="pdf-password-instruction-widget-password-text"
        >
          {{ pdfPassword }}
        </p>
        <div
          v-else
          class="pdf-password-instruction-widget-password-placeholder"
        >
          <span
            v-for="i in 6"
            :key="i"
            class="pdf-password-instruction-widget-password-placeholder-character"
          />
        </div>
        <button class="pdf-password-instruction-widget-password-button">
          <svg
            v-if="isShowingPassword"
            class="pdf-password-instruction-widget-password-button-icon icon icon-eye"
            tabindex="-1"
            @click="togglePasswordVisibility"
          >
            <use xlink:href="/images/icon-lib.svg#icon-eye-closed" />
          </svg>
          <svg
            v-else
            class="pdf-password-instruction-widget-password-button-icon icon icon-eye"
            tabindex="-1"
            @click="togglePasswordVisibility"
          >
            <use xlink:href="/images/icon-lib.svg#icon-eye" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TooltipIcon from "@/components/shared/tooltip/tooltip-icon.vue";
import PdfPasswordTypeEnum from "@/constants/pdf-password-type-enum";

export default Vue.extend({
  name: "PdfPasswordInstructionWidget",
  components: {
    TooltipIcon
  },
  props: {
    pdfPassword: {
      type: String,
      required: true
    },
    pdfPasswordType: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      isShowingPassword: false
    };
  },
  computed: {
    tooltipHtml() {
      let html =
        "A senha para abrir o relatório são:<br><strong>6 primeiros dígitos do SEU ";
      switch (this.pdfPasswordType) {
        case PdfPasswordTypeEnum.PersonDocumentFirstSixNumericDigits:
          html += "CPF";
          break;
        case PdfPasswordTypeEnum.OrganizationDocumentFirstSixNumericDigits:
          html += "CNPJ";
          break;
        default:
          html += "CPF ou CNPJ";
          break;
      }
      html += "</strong>";
      return html;
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.isShowingPassword = !this.isShowingPassword;
    }
  }
});
</script>

<style scoped lang="less">
@import "../../styles/colors.less";

.pdf-password-instruction-widget {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  background-color: #d1ecf1;
  border-radius: 0.375rem; // 6px
}

.pdf-password-instruction-widget-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem; // 12px
}

.pdf-password-instruction-widget-icon-image {
  display: block;
  color: @blueExato;
}

.pdf-password-instruction-widget-divider {
  display: block;
  width: 0.0625rem; // 1px
  background-color: rgba(@blueExato, 0.2);
}

.pdf-password-instruction-widget-content {
  display: flex;
  flex-flow: column wrap;
  gap: 0.25rem; // 4px
  justify-content: center;
  padding: 0.375rem 0.625rem; // 6px 10px
}

.pdf-password-instruction-widget-title {
  display: flex;
  gap: 0.375rem; // 6px
  align-items: center;
  justify-content: flex-start;
}

.pdf-password-instruction-widget-title-text {
  display: block;
  font-size: 0.6875rem; // 11px
  font-weight: 300;
  line-height: 1;
  color: #0c5460;
  text-transform: uppercase;
}

/deep/ .pdf-password-instruction-widget-title-icon {
  display: block;
  color: @ColorSecGrey24;
}

.pdf-password-instruction-widget-password {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.pdf-password-instruction-widget-password-text {
  display: block;
  font-size: 1.125rem; // 18px
  font-weight: 700;
  line-height: 1;
  color: #0c5460;
  letter-spacing: 0.6875rem; // 11px
}

.pdf-password-instruction-widget-password-placeholder {
  display: flex;
  gap: 0.375rem; // 6px
  align-items: flex-end;
  padding-right: 0.75rem; // 12px
}

.pdf-password-instruction-widget-password-placeholder-character {
  display: inline-block;
  width: 1rem; // 16px
  height: 1.125rem; // 18px
  border-bottom: solid 0.125rem #0c5460; // 2px
}

.pdf-password-instruction-widget-password-button {
  display: block;
  padding: 0;
  border: none;
  background: none;
  appearance: none;
}

.pdf-password-instruction-widget-password-button-icon {
  display: block;
  width: 1.125rem; // 18px
  height: 1.125rem; // 18px
  color: #0c5460;
}
</style>
