<template>
  <div>
    <div class="content-divider body-text-1">
      <span :class="estiloDivider">{{ $t("components.SocialLogin2.title") }}</span>
    </div>

    <div class="other-autentication">
      <a
        :title="$t('components.SocialLogin2.facebook')"
        class="autentication-item"
        @click.prevent="facebookLogin"
      >
        <div
          class="btn-floating btn-floating-small btn-floating-facebook"                
        >
          <svg class="icon icon-small icon-facebook">
            <use xlink:href="/images/icon-lib.svg#icon-facebook" />
          </svg>
        </div>
      </a>

      <div
        ref="googleLoginBtn"
        class="autentication-item"
      />
    </div><!--other-autentication end-->
  </div>
</template>

<script>
    import FacebookLoginProvider from '@/services/login-providers/facebook.service.js';    
    import LoginService from '@/services/service-providers/webapp-services/login-service.js';
    import { checkWhatsAppText } from "@/mixin/whatsapp-param-text-mixin.js";
    import { loginGoogle } from "@/mixin/google/login-google-mixin.js";

    export default {

        mixins: [checkWhatsAppText, loginGoogle],

        props: {
            borderColor: '',
            fontColor: '',
            bgColorDivider: ''
        },

        computed: {
            estiloDoBotao() {
                if (this.fontColor) {
                    return `${this.fontColor} ${this.borderColor} `;
                }
            },
            estiloDivider() {
                if (this.bgColorDivider) {
                    return this.bgColorDivider;
                }
            },
        },

        mounted: function() {
            this.initializeGoogleGsi();
            this.renderButtonGoogle();            
        },

        methods: {

            renderButtonGoogle: function () {
                let self = this;
                window.google.accounts.id.renderButton(
                    self.$refs.googleLoginBtn, {
                        type: 'icon',
                        shape: 'circle',
                    }
                );
            },

            handleGoogleCredentialResponse: function(response) {
                let respCredential = response.credential;
                let base64Url = respCredential.split('.')[1];
                let base64 = base64Url.replace('-', '+').replace('_', '/');
                let jwtCredentials = JSON.parse(window.atob(base64));
                
                let self = this;
                self.setDataWhatsAppParamToPost();
                
                LoginService.socialLogin(
                    'Google',                    
                    respCredential,
                    jwtCredentials.sub,
                    false,
                    self.accountUserExternalId,
                    self.accountOrganizationExternalId,
                ).then(function (response) {
                    self.$store.commit("UserModule/login", response.data.data);
                    self.$store.commit("UserModule/userSocialLogin", true);
                    
                    if (self.$store.getters["UserModule/userAuthenticated"]) {
                        self.$bus.$emit("global-needReloadUserEnvironment", true);
                        self.$gtm.trackEvent({
                            event: "login", 
                            loginMethod: "google",
                            partner_key_id: self.exIdentifyAffiliateKeyId
                        });
                    }
                })
                .catch(function (error) {
                    if(error.response.data.message === "TwoFactor") {
                        return self.$emit('socialLoginRequested', {
                            socialNetwork: 'Google',
                            accessToken: respCredential, 
                            socialUserId: jwtCredentials.sub
                        });
                    }
                    else if(error.response.data.message === "SocialLoginUserBlocked") {
                        return self.$bus.$emit("global-recover-password");
                    }
                    else {
                        self.$emit("socialRegistrationRequested", {
                        accessToken: respCredential,
                        socialNetwork: 'Google'
                    });
                    self.$emit("socialRegistrationEnd")
                    }
                });
            },

            facebookLogin: function () {
                let self = this;

                self.setDataWhatsAppParamToPost();

                FacebookLoginProvider.login(function (tokenInfo) {
                    self.$emit('socialLoginStarted');
                    LoginService.socialLogin(
                        'Facebook',
                        tokenInfo.accessToken,
                        tokenInfo.socialUserId,
                        false,
                        self.accountUserExternalId,
                        self.accountOrganizationExternalId,
                    )
                        .then(function (response) {
                        self.$store.commit("UserModule/login", response.data.data);
                        self.$store.commit("UserModule/userSocialLogin", true); 
                        if (self.$store.getters["UserModule/userAuthenticated"]) {
                            self.$bus.$emit("global-needReloadUserEnvironment", true);
                            self.$gtm.trackEvent({
                                event: "login", 
                                loginMethod: "facebook",
                                partner_key_id: self.exIdentifyAffiliateKeyId
                            });
                            self.$emit('closeRegister');
                        }
                    })
                    .catch(function (error) {
                        if(error.response.data.message === "TwoFactor") {
                            return self.$emit('socialLoginRequested', {
                                socialNetwork: 'Facebook',
                                accessToken: tokenInfo.accessToken, 
                                socialUserId: tokenInfo.socialUserId
                            });
                        }
                        else if(error.response.data.message === "SocialLoginUserBlocked") {
                            return self.$bus.$emit("global-recover-password");
                        }
                        // Envia para a home caso o token seja inválido
                        // Feito assim devido ao cache do FB.getLoginStatus. Se desabilitar o cache exibe msg de erro no console
                        // Acontece quando o usuário excluí manualmente as credenciais no facebook
                        else if(error.response.data.message === "Token inválido do Facebook.") {
                            location.href = self.localePath("LandingPage");
                        }
                        else {
                            self.$emit("socialRegistrationRequested", {
                                accessToken: tokenInfo.accessToken,
                                socialNetwork: 'Facebook'
                            });
                            self.$emit("socialRegistrationEnd")
                        }
                    });
                });
            },
        }
    }
</script>

<style lang="less" scoped>

 @import "../../styles/colors.less";
 @import "../../styles/screen-size.less";

    .btn-floating{
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
        border: 1px solid @ColorSecGrey24;
        border-radius: 50%;
        border-color: @ColorSecGrey24;
        color:@ColorSecGrey24;
        background-color: #fff;

        transition: all .3s;

       /*  &:hover {
            color: @ColorPriYellow;
            border-color: @ColorPriYellow;
        } */
    }
    .btn-floating-medium{
        width: 70px;
        height: 70px;
    }
    .btn-floating-small{
        width: 40px;
        height: 40px;
    }

    .btn-floating-facebook {
        color: #3b5998;
        border-color: #dadce0;

        &:hover {
            border-color: #d2e3fc;            
            background-color: #F8FAFF;
        }
    }

    .other-autentication{
        display: flex;
        justify-content: center;

    }

    .autentication-item{
        height: 40px;
        margin: 0 .75rem;        
        overflow: hidden;
    }

    .content-divider {
        text-align: center;
        position: relative;
        margin-bottom: 1.25rem;
        z-index: 1;
        color: @ColorSecGrey47;

        @media (min-width: @screenLarge) {
            margin-bottom: 1.25rem;

        }

        span {
            background-color: #fff;
            display: inline-block;
            padding-left: 16px;
            padding-right: 16px;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                height: 1px;
                background-color: #ddd;
                width: 100%;
                z-index: -1;
            }
        }
    }

    .icon-facebook {
        width: 18px;
        height: 18px;
    }


</style>
