export const redirectIdHash = {

  methods: {
    redirectToIdOrHash(routeName, id) {
      if (this.getRouteBaseName() === routeName) {
        this.goToElementId(id, 'start');
      } else {
        //TODO validar nas páginas de lgpd
        this.$router.push({ name: routeName, hash: `#${id}` })
      }
    }
  }
}