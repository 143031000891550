/**
 * Code adapted from the following sources:
 * https://github.com/nuxt-modules/i18n/blob/v7.3.1/src/templates/utils-common.js#L193
 */

/**
 * @typedef {(localeCode: import("./types").LocaleCode) => void} SetLocaleCodeInLocalStorage
 */

/** @type {SetLocaleCodeInLocalStorage} */
const setLocaleCodeInLocalStorage = (localeCode) => {
  window.localStorage.setItem("exI18nRedirected", localeCode)
}

export default setLocaleCodeInLocalStorage
